import React from "react";
import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Divider, Stack, Box, Typography } from "@mui/joy";
import useKeyToDict from "../../Utils/useKeyToDict";

interface FAQProps {
  section: string;
}

type FAQSectionProps = {
  [key: string]: {
    headline: string;
    more: string;
  }[];
};

const FAQ: React.FC<FAQProps> = ({ section }) => {
  const [openIndices, setOpenIndicies] = useState<number[]>([]);
  const { faqArray } = useKeyToDict("faq");
  const faq = faqArray(section);

  const toggle = (i: number) => {
    if (openIndices.includes(i)) {
      setOpenIndicies((indices: number[]) =>
        indices.filter((index) => index !== i)
      );
    } else {
      setOpenIndicies((indices: number[]) => [...indices, i]);
    }
  };

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 2, mx: 2 }}>
      {faq.map(
        //@ts-ignore
        (item, i) => (
          <Box key={i}>
            <Typography
              variant="plain"
              onClick={() => toggle(i)}
              sx={{ cursor: "pointer" }}
              component={"span"} //to avoid DOM error of <div> in <p>
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography level="body2" sx={{ fontWeight: "bold" }}>
                  {item.headline}
                </Typography>
                <KeyboardArrowDown
                  sx={{
                    transition: "0.3s",
                    transform: openIndices.includes(i)
                      ? "rotate(180deg)"
                      : "rotate(360deg)",
                  }}
                />
              </Box>
            </Typography>
            {openIndices.includes(i) && (
              <Typography level="body2" sx={{ my: 1 }} component={"span"}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: item.more.replace(/\n/g, "<br />"),
                  }}
                />
              </Typography>
            )}
            <Divider />
          </Box>
        )
      )}
    </Stack>
  );
};

export default FAQ;
