import {
  ArrowForwardRounded,
  ArrowRightRounded,
  GavelRounded,
  HailRounded,
  KeyboardArrowRightRounded,
  PaidRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ListItem,
  List,
  Button,
  Alert,
  FormControl,
  Input,
  FormHelperText,
} from "@mui/joy";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import WageSurveyContext from "./WageSurveyContext";

const Wage = () => {
  const { t } = useTranslation("wage_comparison");

  const context = useContext(WageSurveyContext);

  return (
    <Stack spacing={2}>
      <Alert sx={{ p: 4 }}>
        <Typography level="h1" sx={{ color: "inherit" }}>
          {t("wage.title")}
        </Typography>
      </Alert>

      <Box>
        <Alert
          color="neutral"
          variant="plain"
          sx={{
            border: "1px solid #DDD",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: "none",
          }}
        >
          <Stack spacing={1}>
            <Typography startDecorator={<GavelRounded />}>
              {t("wage.law")}
            </Typography>
            <Typography startDecorator={<VisibilityOffRounded />}>
              {t("wage.anonymous")}
            </Typography>
          </Stack>
        </Alert>
        <Alert
          color="neutral"
          variant="soft"
          sx={{
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            border: "1px solid #DDD",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <FormControl sx={{ flexGrow: 2 }}>
              <Box>
                <FormHelperText>{t("wage.wage")}</FormHelperText>

                <Input
                  type="number"
                  slotProps={{
                    input: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                      max: 100,
                    },
                  }}
                  sx={{ fontSize: "1.5rem" }}
                  placeholder="15"
                  startDecorator={<Typography level="h4">$</Typography>}
                  endDecorator={
                    <Typography level="body2">
                      {t("common:per_hour")}
                    </Typography>
                  }
                  value={context?.wage || ""}
                  onChange={(e) => {
                    if (e.target.value === null || e.target.value === "") {
                      context?.setWage(null);
                      return;
                    }
                    const value = parseFloat(e.target.value);
                    if (value === 0) {
                      context?.setWage(null);
                      return;
                    }
                    if (value > 0 && value < 100) {
                      context?.setWage(value);
                    }
                  }}
                />
              </Box>
            </FormControl>

            <FormControl sx={{ flexGrow: 1 }}>
              <Box>
                <FormHelperText>{t("wage.tips")}</FormHelperText>

                <Input
                  type="number"
                  slotProps={{
                    input: {
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                      max: 100,
                    },
                  }}
                  sx={{ fontSize: "1.5rem" }}
                  placeholder="4"
                  startDecorator={<Typography level="h4">$</Typography>}
                  endDecorator={
                    <Typography level="body2">
                      {t("common:per_hour")}
                    </Typography>
                  }
                  value={context?.tips || ""}
                  onChange={(e) => {
                    if (e.target.value === null || e.target.value === "") {
                      context?.setTips(null);
                      return;
                    }
                    const value = parseFloat(e.target.value);
                    if (value === 0) {
                      context?.setTips(null);
                      return;
                    }
                    if (value > 0 && value < 100) {
                      context?.setTips(value);
                    }
                  }}
                />
              </Box>
            </FormControl>
          </Box>
        </Alert>
      </Box>

      <Button
        sx={{ fontSize: "1.5rem", p: 2 }}
        endDecorator={<ArrowForwardRounded />}
        onClick={() => context?.setActive("more")}
        disabled={context?.wage === null}
      >
        {t("wage.next")}
      </Button>
    </Stack>
  );
};

export default Wage;
