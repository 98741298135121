import useKeyToDict from "../../Utils/useKeyToDict";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";
import { DocumentReference, Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Item } from "../../Services/Db/types";
import WageChip from "../Common/WageChip";
import dayjs from "dayjs";
import {
  PauseCircleRounded,
  PlayCircleFilledRounded,
} from "@mui/icons-material";
import { BusinessService, ListingsService } from "../../Services/Db/service";
import { useTranslation } from "react-i18next";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import useConfirm from "src/Utils/useConfirm";
import { FullBusinessService } from "src/Services/Db/business";

interface RoleHeaderBusinessProps {
  listingId: string;
  status: string;
  role: string;
  category: string;
  wage: number;
  tips: number;
  posted_at: Timestamp;
  businessRef?: DocumentReference;
  editable?: boolean;
  linkedListings?: DocumentReference[];
}

const RoleHeaderBusiness: React.FC<RoleHeaderBusinessProps> = ({
  listingId,
  status,
  role,
  category,
  wage,
  tips,
  posted_at,
  businessRef,
  editable,
  linkedListings,
}) => {
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const [statusState, setStatusState] = useState<string>(status);
  const [statusLoading, setStatusLoading] = useState<boolean>(false);
  const { t } = useTranslation("common");

  const [businessNames, setBusinessNames] = useState<string[]>();

  useEffect(() => {
    BusinessService.getResolvedReference(businessRef!).then(
      async (business) => {
        var linkedNames: (string | undefined)[] = [];
        if (linkedListings) {
          linkedNames = await Promise.all(
            linkedListings.map(async (listingRef) => {
              const tmpListing = await ListingsService.getResolvedReference(
                listingRef
              );
              if (tmpListing) {
                const tmpBusiness = await BusinessService.getResolvedReference(
                  tmpListing.businessRef!
                );
                if (tmpBusiness) {
                  return tmpBusiness.name;
                }
              }
            })
          );
        }
        if (business) {
          setBusinessNames([
            business.name,
            ...(linkedNames.filter((name) => name !== undefined) as string[]),
          ]);
        }
      }
    );
  }, []);

  return (
    <React.Fragment>
      <Stack>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
            mb: 1,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <WageChip wage={wage} tips={tips} />
            <Stack>
              <Typography level="h6" sx={{ fontWeight: "bold" }}>
                {getRoleNameFromRole({ category: category, item: role })}
              </Typography>
              <Typography level="body1" sx={{ pt: 0, lineHeight: 1 }}>
                {t("posted")}
                {": "}
                {dayjs.unix(posted_at.seconds).format("h:mma, MMM D, YYYY")}
              </Typography>
            </Stack>
          </Box>

          {editable && (
            <Button
              variant="soft"
              color={statusState === "active" ? "success" : "warning"}
              onClick={() => {
                setStatusLoading(true);
                FullBusinessService.updateListingStatus(
                  listingId,
                  statusState === "active" ? "inactive" : "active"
                )
                  .then(() => {
                    setStatusState(
                      statusState === "active" ? "inactive" : "active"
                    );
                  })
                  .finally(() => {
                    setStatusLoading(false);
                  });
              }}
            >
              {statusState === "active" ? (
                <Typography
                  startDecorator={
                    statusLoading ? (
                      <CircularProgress />
                    ) : (
                      <PauseCircleRounded />
                    )
                  }
                >
                  {t("active")}
                </Typography>
              ) : (
                <Typography
                  startDecorator={
                    statusLoading ? (
                      <CircularProgress />
                    ) : (
                      <PlayCircleFilledRounded />
                    )
                  }
                >
                  {t("inactive")}
                </Typography>
              )}
            </Button>
          )}
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {businessNames?.map((businessName) => (
            <Chip variant="outlined" color="neutral" sx={{ lineHeight: 1.5 }}>
              {businessName}
            </Chip>
          ))}
        </Box>
      </Stack>
      <Divider sx={{ mx: -2, opacity: 1, mt: 1, mb: 2 }} />
    </React.Fragment>
  );
};

export default RoleHeaderBusiness;
