import { Box, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";

const PhoneText = styled(Typography)(({ theme }) => ({
  paragraph: true,
  marginBottom: 0,
  color: "gray",
  fontSize: ".8em",
}));

interface NumberFieldProps {
  numbers: string[];
  setNumbers: (n: string[]) => void;
  isPhone?: boolean;
  autoFocus?: boolean;
}

const NumberField: React.FC<NumberFieldProps> = ({
  numbers,
  setNumbers,
  isPhone,
  autoFocus,
}) => {
  const [firstTouch, setFirstTouch] = React.useState<boolean>(true);
  const [curSelected, setCurSelected] = React.useState<number>(0);

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) {
    const val = event.target.value;

    // if the input is a number, go to next input
    if (val != "" && !isNaN(val as any)) {
      setCurSelected(
        Math.min(Number(event.target.name) + 1, numbers.length - 1)
      );
    }
    numbers[index] = val;

    // update state
    setNumbers([...numbers]);
  }

  function handleClick(index: number) {
    if (firstTouch) {
      setFirstTouch(false);
      setCurSelected(0);
    } else {
      setCurSelected(index);
    }
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLDivElement>,
    _index: number
  ) {
    // FIXME cursor needs to be at end on change
    const val = event.key;
    if (val == "Backspace" && numbers[curSelected] == "") {
      setCurSelected(curSelected - 1);
    }
    if (val == "ArrowLeft" || val == "ArrowRight") {
      event.preventDefault();
    }
  }

  return (
    <Box sx={{ display: "inline-flex", alignItems: "center" }}>
      {numbers.map((el, i) => (
        // TODO suppress key warning
        // TODO the hyphen should be better but i cant figure out <Typography> rn lol
        <React.Fragment key={i}>
          {isPhone && i == 6 && <PhoneText>–</PhoneText>}
          {isPhone && i == 0 && <PhoneText>(</PhoneText>}
          {isPhone && i == 3 && <PhoneText>)</PhoneText>}
          <TextField
            label=""
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center" },
              inputMode: "numeric",
            }}
            sx={{
              width: "auto",
              m: 0,
              p: 0.1,
            }}
            inputRef={(input) => input && i == curSelected && input.focus()}
            onChange={(e) => handleChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            onClick={() => {
              handleClick(i);
            }}
            onPaste={(e) => {
              e.preventDefault();
              const paste = e.clipboardData.getData("text");
              if (!isNaN(paste as any)) {
                setNumbers([...paste]);
              }
            }}
            type="tel"
            name={i.toString()}
            // autoFocus={i == curSelected}
            variant="standard"
            autoFocus={autoFocus && i == 0}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default NumberField;
