import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend"; // <---- add this

//Import all translation files
import tCommonEn from "./Text/English/common.json";
import tCommonEs from "./Text/Spanish/common.json";

import tHeaderEn from "./Text/English/header.json";
import tHeaderEs from "./Text/Spanish/header.json";

import tCreateProfileEn from "./Text/English/create_profile.json";
import tCreateProfileEs from "./Text/Spanish/create_profile.json";

import tExperienceBuilderEn from "./Text/English/experience_builder.json";
import tExperienceBuilderEs from "./Text/Spanish/experience_builder.json";

import tEducationBuilderEn from "./Text/English/education_builder.json";
import tEducationBuilderEs from "./Text/Spanish/education_builder.json";

import tReferenceBuilderEn from "./Text/English/reference_builder.json";
import tReferenceBuilderEs from "./Text/Spanish/reference_builder.json";

import tAvailabilityBuilderEn from "./Text/English/availability_builder.json";
import tAvailabilityBuilderEs from "./Text/Spanish/availability_builder.json";

import tBusinessEn from "./Text/English/business.json";
import tBusinessEs from "./Text/Spanish/business.json";

import tUserFlowEn from "./Text/English/user_flow.json";
import tUserFlowEs from "./Text/Spanish/user_flow.json";

import tJobSeekerHomeEn from "./Text/English/job_seeker.json";
import tJobSeekerHomeEs from "./Text/Spanish/job_seeker.json";

import tInterviewEn from "./Text/English/interview.json";
import tInterviewEs from "./Text/Spanish/interview.json";

import tListsEn from "./Text/English/lists.json";
import tListsEs from "./Text/Spanish/lists.json";

import tFAQEn from "./Text/English/faq.json";
import tFAQEs from "./Text/Spanish/faq.json";

import tApplyEn from "./Text/English/apply.json";
import tApplyEs from "./Text/Spanish/apply.json";

import tNewBusinessEn from "./Text/English/new_business.json";
import tNewBusinessEs from "./Text/Spanish/new_business.json";

import tTermsEn from "./Text/English/terms.json";
import tTermsEs from "./Text/Spanish/terms.json";

import tGPTEn from "./Text/English/gpt.json";
import tGPTEs from "./Text/Spanish/gpt.json";

import tMarketingEn from "./Text/English/marketing.json";
import tMarketingEs from "./Text/Spanish/marketing.json";

import tWageComparisonEn from "./Text/English/wage_comparison.json";
import tWageComparisonEs from "./Text/Spanish/wage_comparison.json";

const resources = {
  en: {
    common: tCommonEn,
    header: tHeaderEn,
    create_profile: tCreateProfileEn,
    experience_builder: tExperienceBuilderEn,
    education_builder: tEducationBuilderEn,
    reference_builder: tReferenceBuilderEn,
    availability_builder: tAvailabilityBuilderEn,
    business: tBusinessEn,
    user_flow: tUserFlowEn,
    job_seeker: tJobSeekerHomeEn,
    interview: tInterviewEn,
    lists: tListsEn,
    faq: tFAQEn,
    apply: tApplyEn,
    new_business: tNewBusinessEn,
    terms: tTermsEn,
    gpt: tGPTEn,
    marketing: tMarketingEn,
    wage_comparison: tWageComparisonEn,
  },
  es: {
    common: tCommonEs,
    header: tHeaderEs,
    create_profile: tCreateProfileEs,
    experience_builder: tExperienceBuilderEs,
    education_builder: tEducationBuilderEs,
    reference_builder: tReferenceBuilderEs,
    availability_builder: tAvailabilityBuilderEs,
    business: tBusinessEs,
    user_flow: tUserFlowEs,
    job_seeker: tJobSeekerHomeEs,
    interview: tInterviewEs,
    lists: tListsEs,
    faq: tFAQEs,
    apply: tApplyEs,
    new_business: tNewBusinessEs,
    terms: tTermsEs,
    gpt: tGPTEs,
    marketing: tMarketingEs,
    wage_comparison: tWageComparisonEs,
  },
};

const options = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

i18next
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: "en", //default language, comment this out for language detection to work
    detection: options,
    fallbackLng: "en",
    supportedLngs: ["en", "es"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18next;
