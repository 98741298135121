import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Input,
  Typography,
  Card,
  Divider,
  FormControl,
  Box,
  Chip,
  Alert,
  CardOverflow,
  Grid,
} from "@mui/joy";
import { styled } from "@mui/joy";
import { alpha } from "@mui/material/styles";
import AppBarContext from "../Context/AppBarContext";
import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import EmailSignIn from "../Components/Authentication/EmailSignIn";
import useInViewport from "src/Utils/useInViewport";
import {
  CloseRounded,
  EmailRounded,
  KeyboardArrowDown,
  PhoneRounded,
  SmsRounded,
} from "@mui/icons-material";
import { Image } from "mui-image";
import { Skeleton } from "@mui/material";

const Demo = () => {
  const navigate = useNavigate();

  const { t } = useTranslation(["new_business", "common"]);

  const context = useContext(AppBarContext);

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BASIC);
  });

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        bgcolor: "background.level1",
        mt: { xs: -3, md: -10 },
        px: "5vw",
      }}
    >
      <Typography level="h1" sx={{ color: "white", py: "4vh" }}>
        👋🏼 Hey, Triangle!
      </Typography>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={8}>
          {/* 56.25% is is 16:9 */}
          <Card sx={{ position: "relative", pb: "55.25%" }}>
            <iframe
              height="100%"
              width="100%"
              style={{ position: "absolute", top: 0, left: 0, padding: "10px" }}
              src="https://www.youtube.com/embed/hBvFcNBqAHs?autoplay=1&mute=1&controls=1&showinfo=0&rel=0&loop=1"
            ></iframe>
          </Card>
        </Grid>

        <Grid xs={12} sm={12} md={4}>
          <Card>
            <Typography level="cardHeader">
              {t("new_business:headline")}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Typography level="body1">
              Get started in 3 minutes with just your email. No CC required.
            </Typography>
            <EmailSignIn
              newUser
              onComplete={() => navigate(APP_ROUTES.BUSINESS_HOME)}
            />
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={4}>
          <Card>
            <Typography level="cardHeader">Questions?</Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Stack spacing={2}>
              <Button
                variant="outlined"
                startDecorator={<EmailRounded />}
                onClick={() => {
                  window.open(`mailto:alex@hourlead.com`);
                }}
              >
                Email Alex
              </Button>
              <Button
                variant="outlined"
                startDecorator={<PhoneRounded />}
                onClick={() => {
                  window.open(`tel:+13107457252`);
                }}
              >
                Call Alex
              </Button>
              <Button
                variant="outlined"
                startDecorator={<SmsRounded />}
                onClick={() => {
                  window.open(`sms:+13107457252`);
                }}
              >
                Text Alex
              </Button>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Demo;
