import { Timestamp } from "firebase/firestore";
import { Listings } from "src/Services/Db/types";

export enum APP_ROUTES {
  ROOT = "/",
  TEST_EDIT_ROLE = "/testEditRole",
  HOME = "/:businessId?",
  LOGIN = "/login",
  START_PAGE = "/start",
  ROLES = "/roles",
  CANDIDATES = "/candidates",
  WAGES = "/wages",
  ROLE_MAKER = "/role_maker",
  ROLE = "/role/:listingId?",
  CANDIDATE_PROFILE = "/candidate_profile",
  PAGE_MAKER = "/page_maker",
  BUSINESS_PAGE = "/business/:businessId",
  NEW_BUSINESS = "/newBusiness", //don't change this -- printed on business cards
  NEW_USER = "/new_user",
  NEW_USER_FLOW = "/new_user_flow",
  CREATE_PROFILE = "/create_profile",
  PHONE_STEP = "/verify_phone",
  JOB_SEEKER_HOME = "/home",
  SAVED_ROLES = "/saved_roles",
  APPLICATIONS = "/applications",
  BUSINESS_INTERVIEWS = "/business_interviews",
  BUSINESS_HOME = "/business_home",
  APPLY = "/apply/:listingId",
  SIGN_UP = "/sign_up",
  TEST_ADD_BUSINESS = "/testAddBusiness",
  ADD_ROLE = "/add_role",
  TEST = "/test",
  PRIVACY_POLICY = "/privacy_policy",
  TERMS_OF_SERVICE = "/terms_of_service",
  EXAMPLE_ROLE = "/example_role",
  WAGE_DISCLAIMER = "/wage_disclaimer",
  INTERVIEW_CONFIRMATION = "/interview_confirmation/:interviewId",
  GROUP_PAGE = "/group/:groupId",
  INVITATION = "/invitation",
  DEMO = "/demo",
  SCHEDULE_CALL = "/schedule_call",
  WAGE_COMPARISON = "/am_i_underpaid",
  SIGN_MAKER = "/sign_maker",
}

export enum USER_TYPES {
  EMPLOYED,
  NOT_EMPLOYED,
  STUDENT,
  NOT_SET,
}

export enum HEADER_TYPES {
  BASIC,
  BUSINESS,
  SEARCH,
  USER,
  GPT,
  NONE,
}

export const DIVIDER_HEADER_SX = {
  height: 1.1,
  mt: 2,
  mb: 0,
  pb: 0,
  opacity: 1,
};

export const DIVIDER_HEADER_SX_NO_Y = {
  height: 1.1,
  opacity: 1,
};

export const DIVIDER_HEADER_SX_MY = {
  height: 1.1,
  my: 2,
  opacity: 1,
};

export const DEFAULT_MAP_PARAMS = {
  zoom: 12,
  lat: 35.994,
  lng: -78.898,
  durham_lat: 35.994,
  durham_lng: -78.898,
  raleigh_lat: 35.7796,
  raleigh_lng: -78.6382,
};

export const DEFAULT_LISTING: Listings = {
  category: "",
  role: "",
  wage: 0,
  tips: 0,
  perks: [],
  skills: [],
  schedule: [],
  years_of_experience: 0,
  status: "active",
  posted_at: Timestamp.now(),
  description: "",
  businessRef: undefined,
  application_requirements: [],
  custom_questions: [],
};

export const DEFAULT_MARKER_VALS = {
  website: "https://indeed.com",
  photo: "",
};

export const CARD_OVERFLOW_SX = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transform: "scale(1)",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    color: "primary.main",
    transition: "background-color 0.2s ease-in-out, color 0.2s ease-in-out",
  },
  "&:hover > button": {
    transform: "scale(1.2)",
    transition: "transform 0.2s ease-in-out",
    color: "primary.main",
  },
  transition:
    "background-color 0.2s ease-in-out, color 0.2s ease-in-out, transform 0.2s ease-in-out",
  py: 2,
};
