import PageSpacer from "../Components/Utilities/PageSpacer";
import { Typography, Card, Divider } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX_MY, HEADER_TYPES } from "../Utils/Constants";
import { useContext } from "react";

const WageDisclaimer = () => {
  const { t } = useTranslation(["terms"]);

  const context = useContext(AppBarContext);

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BASIC);
  });

  return (
    <PageSpacer>
      <Card>
        <Typography level="sectionHeader">
          {t("wage_disclaimer_header")}
        </Typography>
        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Typography level="body1">{t("wage_disclaimer_body")}</Typography>
      </Card>
    </PageSpacer>
  );
};

export default WageDisclaimer;
