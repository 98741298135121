import { InfoRounded } from "@mui/icons-material";
import { Alert, Box, Button, Stack, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { Listings } from "src/Services/Db/types";
import { DEFAULT_LISTING } from "src/Utils/Constants";
import useConfirm from "src/Utils/useConfirm";
import RoleAbout from "../Inputs/RoleEditing/RoleAbout";
import RoleCustomQs from "../Inputs/RoleEditing/RoleCustomQs";
import RoleLocations from "../Inputs/RoleEditing/RoleLocations";
import RoleReqs from "../Inputs/RoleEditing/RoleReqs";

const BusinessListingSetup = ({
  setDone,
}: {
  setDone: (b: boolean) => void;
}) => {
  const [listing, setListing] = useState<Listings>(DEFAULT_LISTING);
  const [locations, setLocations] = useState<string[]>([]);
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [canSubmit, setCanSubmit] = useState(true);

  const { business } = useBusinessAuth();
  const { t } = useTranslation("business");

  useEffect(() => {
    if (business) {
      FullBusinessService.getBusinessProfile(business.email!)
        .then((b) => {
          if (b?.locations) {
            setLocations(b.locations.map((l) => l.id));
          }
        })
        .finally(() => setLoadingLocations(false));
    }
  }, [business]);

  useEffect(() => {
    setCanSubmit(
      listing.wage !== 0 &&
        listing.role !== "" &&
        Boolean(business && business.email) &&
        locations.length > 0
    );
  }, [listing, locations, business]);

  async function handleSubmitListing(): Promise<void> {
    if (listing && locations.length > 0) {
      setCanSubmit(false);
      FullBusinessService.createBusinessListingAllLocations(
        business!.email!,
        listing,
        locations
      )
        .then((listingIds) => {
          setDone(true);
        })
        .catch((err) => {
          setCanSubmit(true);
        });
    }
  }

  return (
    <Stack spacing={4}>
      <Alert color="neutral" sx={{ mt: 2 }}>
      <Stack spacing={1}>
        <Typography startDecorator={<InfoRounded />} level="h4">
          {t("add_listing_alert")}
        </Typography>
      </Stack>
    </Alert>
      <Button
        variant="solid"
        disabled={!canSubmit}
        fullWidth
        onClick={handleSubmitListing}
      >
        {t("post_role", { ns: ["business"] })}
      </Button>
      <RoleAbout listing={listing} setListing={setListing} />
      <RoleReqs listing={listing} setListing={setListing} collapsable />
      <RoleCustomQs listing={listing} setListing={setListing} collapsable />
      <RoleLocations
        selectedLocations={locations}
        setSelectedLocations={setLocations}
        listing={listing}
        setListing={setListing}
        collapsable
      />
      <Button
        variant="solid"
        disabled={!canSubmit}
        fullWidth
        onClick={handleSubmitListing}
      >
        {t("post_role", { ns: ["business"] })}
      </Button>
    </Stack>
  );
};

export default BusinessListingSetup;
