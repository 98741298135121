import React from "react";
import { HEADER_TYPES } from "../Utils/Constants";

interface AppBarContextType {
  setHeaderType: (type: HEADER_TYPES) => void;
  showAlerts: string[];
  setShowAlerts: (type: string[]) => void;
  backKey?: string;
  setBackKey: (key: string) => void;
  setShowHeader: (show: boolean) => void;
  showHeader: boolean;
  setAlertOffset: (offset: number) => void;
  alertOffset: number;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

// Context for setting the correct app bar
const AppBarContext = React.createContext<AppBarContextType | null>(null);

export default AppBarContext;
