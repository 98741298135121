import { List, ListItem, Stack, Typography } from "@mui/joy";
import useKeyToDict from "../../Utils/useKeyToDict";
import { useTranslation } from "react-i18next";
import { Item } from "../../Services/Db/types";

interface ListItemProps {
  titleKey?: string;
  ns?: string;
  items: Item[];
  parentKey: string;
  appendKeys?: string[];
  appendKeysListKey?: string;
}

const ListItems: React.FC<ListItemProps> = ({
  items,
  parentKey,
  ns,
  titleKey,
  appendKeys,
  appendKeysListKey,
}) => {
  const { itemsToValues, keyToValueInList } = useKeyToDict("lists");

  const values = itemsToValues(parentKey, items);

  const { t } = useTranslation([ns || "common"]);

  if (values.length === 0) {
    return null;
  }

  return (
    <Stack>
      {titleKey && (
        <Typography level="h6" fontWeight="bold" sx={{ mb: 1 }}>
          {t(titleKey)}
        </Typography>
      )}

      <List
        wrap
        orientation="horizontal"
        sx={{
          "--List-gap": "8px",
          "--List-item-radius": "10px",
        }}
      >
        {values.map((val: string) => (
          <ListItem
            key={val}
            sx={{
              border: "1px solid #D8D8DE",
              borderRadius: 1,
              bgcolor: "#F5F5F5",
              py: 0,
              px: 1,
            }}
          >
            <Typography>{val}</Typography>
          </ListItem>
        ))}
        {appendKeys &&
          appendKeysListKey &&
          appendKeys.map((key) => (
            <ListItem
              key={key}
              sx={{
                border: "1px solid #D8D8DE",
                borderRadius: 1,
                bgcolor: "#F5F5F5",
                py: 0,
                px: 1,
              }}
            >
              <Typography>
                {keyToValueInList(key, appendKeysListKey)}
              </Typography>
            </ListItem>
          ))}
      </List>
    </Stack>
  );
};

export default ListItems;
