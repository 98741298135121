import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Card,
  CardContent,
  Input,
  styled,
} from "@mui/joy";
import AppBarContext from "../Context/AppBarContext";
import NumberField from "../Components/Inputs/NumberField";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { APP_ROUTES, HEADER_TYPES } from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import PhoneSignIn from "../Components/Authentication/PhoneSignIn";
import { useAuth } from "../Context/AuthContext";

interface PhoneStepProps {
  onComplete: () => void;
}

const PhoneStep: React.FC<PhoneStepProps> = ({ onComplete }) => {
  const [phone, setPhone] = useState<string[]>(new Array(10).fill(""));
  const [codeSent, setCodeSent] = useState(false);
  const { t } = useTranslation(["common"]);

  //   phone complete, show space for code -- this doesn't work, why doesn't phone update?
  useEffect(() => {
    if (phone.join("").length === 10) {
      setCodeSent(true);
    }
  }, [phone]);

  return (
    <Stack spacing={4}>
      <Typography level="h3" textAlign="center">
        Your personalized wage map is ready and waiting for you
      </Typography>

      <Card>
        <CardContent>
          <Stack spacing={4}>
            <Typography level="h4" textAlign="center">
              What's your phone number?
            </Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: { sm: "100%", md: "60%" },
                }}
              >
                <PhoneSignIn
                  //isSignUp
                  onComplete={onComplete}
                ></PhoneSignIn>
                <Typography level="body2" textAlign="center" sx={{ mt: 2 }}>
                  We'll never share your phone number. You'll use it to sign in.
                  No password needed.
                </Typography>
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>

      {codeSent && (
        <Card>
          <CardContent>
            <Stack spacing={4}>
              <Typography level="h4" textAlign="center">
                Enter the code we sent you
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      )}
    </Stack>
  );
};

export default PhoneStep;
