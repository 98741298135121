import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  ArrowForwardIosRounded,
  BugReport,
  CloseRounded,
  FlagCircle,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Modal,
  Textarea,
  Input,
  Typography,
  Button,
} from "@mui/joy";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useText from "../../Utils/useText";
import { BugAndFeatureService } from "../../Services/Db/service";

const Reporter = () => {
  const [reporter, setReporter] = useState("");
  const [request, setRequest] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [modal, setModal] = useState(false);
  const { t } = useTranslation("common");
  const { textAdminsAlert } = useText();

  useEffect(() => {
    setCanSubmit(reporter.length > 0 && request.length > 0);
  }, [reporter, request]);

  return (
    <Fragment>
      <Box sx={{ position: "sticky", bottom: 0, left: 0, height: 0 }}>
        <IconButton
          variant="solid"
          size="sm"
          onClick={() => setModal(true)}
          sx={{
            position: "absolute",
            bottom: { xs: 2, md: 5 },
            left: { xs: 2, md: 5 },
          }}
        >
          <FlagCircle />
        </IconButton>
      </Box>
      <Modal
        open={modal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={() => {
          setModal(false);
        }}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            outline: "none",
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ fontWeight: 600 }}>
              {t("report_bug", { ns: "common" })}
            </Typography>
            <IconButton onClick={() => setModal(false)}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              overflow: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              pb: 2,
              pt: 2,
            }}
          >
            <Typography>
              {t("report_bug_description", { ns: "common" })}
            </Typography>
            <Textarea
              onChange={(e) => setRequest(e.target.value)}
              placeholder={
                t("report_bug_placeholder", { ns: "common" }) ??
                "Describe your bug or feature request"
              }
              sx={{ mt: 2 }}
            />
            <Input
              placeholder={
                t("report_bug_contact", { ns: "common" }) ??
                "Provide your email or phone so we can contact you"
              }
              onChange={(e) => setReporter(e.target.value)}
              sx={{ mt: 2 }}
            />
            <Button
              fullWidth
              sx={{ mt: 2 }}
              disabled={!canSubmit}
              onClick={() => {
                textAdminsAlert(
                  `Bug Report: \n From: ${reporter} \n Report: ${request}`
                );
                BugAndFeatureService.create({
                  description: request,
                  contact: reporter,
                });
                setModal(false);
              }}
            >
              {t("report_bug_btn", { ns: "common" })}
            </Button>
          </Box>
        </Card>
      </Modal>
    </Fragment>
  );
};

export default Reporter;
