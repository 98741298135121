import { Card, Divider, Stack, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { where } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import { BusinessService, ListingsService } from "src/Services/Db/service";
import { Item } from "src/Services/Db/types";
import { getBusinessRef } from "src/Services/Db/utils";
import { DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import ExampleRole from "../Roles/ExampleRole";
import RoleGeneric from "../Roles/RoleGeneric";

const BusinessPageBody = ({ placeId }: { placeId: string | null }) => {
  const [sections, setSections] = useState<Item[]>([]);
  const [loadedSections, setLoadedSections] = useState(false);

  const [listingIds, setListingIds] = useState<string[]>([]);
  const [loadedListingIds, setLoadedListingIds] = useState(false);
  const name = usePlaceDetails(placeId ?? "", ["name"])?.name;

  useEffect(() => {
    if (placeId) {
      BusinessService.getOne(placeId)
        .then((business) => {
          if (business && business.sections) {
            setSections(business.sections);
          } else {
            setSections([
              {
                category: "Sample About Us",
                item: "This is where you can tell employees more about your business!",
              },
            ]);
          }
        })
        .finally(() => {
          setLoadedSections(true);
        });
    }
  }, [placeId]);

  useEffect(() => {
    if (placeId) {
      ListingsService.getMany(
        where("businessRef", "==", getBusinessRef(placeId))
      )
        .then((listings) => {
          if (listings) {
            setListingIds(
              listings
                .filter(
                  (listing) =>
                    listing.id !== undefined && listing.status === "active"
                )
                .map((listing) => listing.id) as string[]
            );
          }
        })
        .finally(() => {
          setLoadedListingIds(true);
        });
    } else if (placeId === null) {
      setListingIds([]);
    }
  }, [placeId]);

  return (
    <Stack spacing={3}>
      {loadedListingIds ? (
        listingIds.map((lid) => <RoleGeneric listingId={lid} />)
      ) : (
        <Card>
          <Stack spacing={1} direction="row">
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} />
          </Stack>
          <Divider sx={DIVIDER_HEADER_SX_MY} />
          <Skeleton variant="rounded" width={"100%"} />
        </Card>
      )}
      {listingIds.length === 0 && loadedListingIds && (
        <ExampleRole businessName={name ?? ""} />
      )}
      {loadedSections ? (
        sections.map((section) => (
          <Card key={section.category}>
            <Typography level="cardHeader">{section.category}</Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            {section.item}
          </Card>
        ))
      ) : (
        <Card>
          <Skeleton variant="text" width={250} height={60} />

          <Divider sx={DIVIDER_HEADER_SX_MY} />
          <Skeleton variant="text" width={"100%"} />
        </Card>
      )}
    </Stack>
  );
};

export default BusinessPageBody;
