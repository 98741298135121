import {
  Stack,
  Typography,
  Box,
  Card,
  Textarea,
  Input,
  FormLabel,
  FormControl,
  Button,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

import WageSurveyContext from "./WageSurveyContext";
import NewItemSelector from "src/Components/Inputs/NewItemSelector";
import {
  CancelRounded,
  InfoRounded,
  ArrowForwardRounded,
  CheckCircleOutlineRounded,
  AddRounded,
} from "@mui/icons-material";

import useKeyToDict from "src/Utils/useKeyToDict";

const Job = () => {
  const { t } = useTranslation("wage_comparison");

  const context = useContext(WageSurveyContext);
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const [showTextBox, setShowTextBox] = useState(false);
  const [other, setOther] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (context?.job && other === "") {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [context?.job]);

  return (
    <Stack spacing={2}>
      <Typography
        color="primary"
        level="h1"
        variant="soft"
        sx={{
          mt: {
            xs: "3vh",
            md: "10vh",
          },
          borderRadius: 1,
          p: 4,
          lineHeight: "120%",
          m: 0,
        }}
      >
        {t("job.title")}
      </Typography>
      {context?.job && !!context?.job[0] && !other ? (
        <Alert
          onClick={() => {
            context?.setActive("wage");
          }}
          sx={{ fontSize: "1.75rem", minHeight: "10vh", lineHeight: "120%" }}
          color={loading ? "neutral" : "success"}
          startDecorator={
            loading ? (
              <CircularProgress color="neutral" size="sm" />
            ) : (
              <CheckCircleOutlineRounded sx={{ fontSize: "2rem" }} />
            )
          }
          endDecorator={
            !loading ? (
              <IconButton color="success">
                <ArrowForwardRounded />
              </IconButton>
            ) : null
          }
        >
          {loading
            ? t("job.loading")
            : t("job.we_found_wages_for", {
                role: getRoleNameFromRole(context.job[0]),
              })}
        </Alert>
      ) : null}
      {context ? (
        <Box>
          <NewItemSelector
            dictKey={"roles"}
            dictHeaderKey={"roles_headers"}
            selectedItems={other ? null : context!.job}
            setSelectedItems={context!.setJob}
          />
          <Typography
            startDecorator={showTextBox ? <CancelRounded /> : <InfoRounded />}
            onClick={() => {
              setShowTextBox(!showTextBox);
              if (showTextBox) {
                //clear previous selection
                context!.setJob(null);
              } else {
                //clear other
                setOther("");
              }
            }}
            sx={{ cursor: "pointer" }}
            level="body2"
          >
            {showTextBox ? t("common:cancel") : t("job.not_here")}
          </Typography>
          {showTextBox ? (
            <FormControl sx={{ mt: 1 }}>
              <FormLabel>{t("job.label")}</FormLabel>
              <Input
                onChange={(e) => {
                  setOther(e.target.value);
                  context!.setJob([
                    { item: e.target.value, category: "other_entered" },
                  ]);
                }}
                placeholder={t("job.placeholder")!}
              />
            </FormControl>
          ) : null}
        </Box>
      ) : null}

      {other ? (
        <Alert
          sx={{ fontSize: "1.75rem", lineHeight: "120%" }}
          color="warning"
          startDecorator={<AddRounded sx={{ fontSize: "2rem" }} />}
        >
          {other}
        </Alert>
      ) : null}

      <Button
        sx={{ fontSize: "1.5rem", p: 2 }}
        endDecorator={<ArrowForwardRounded />}
        onClick={() => context?.setActive("wage")}
        disabled={context?.job || other ? false : true}
      >
        {t("job.next")}
      </Button>
    </Stack>
  );
};

export default Job;
