import * as React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AppBarContext from "../Context/AppBarContext";
import {
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import { useAuth, useBusinessAuth } from "../Context/AuthContext";
import { Listings } from "../Services/Db/types";
import ListItems from "../Components/Common/ListItems";
import { ListingsService } from "../Services/Db/service";
import {
  Stack,
  Button,
  Box,
  Card,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/joy";
import ListingDetails from "../Components/Listings/ListingDetails";
import useKeyToDict from "../Utils/useKeyToDict";
import { Skeleton } from "@mui/material";
import RoleBusiness from "../Components/Roles/RoleBusiness";
import RoleGeneric from "../Components/Roles/RoleGeneric";
import { FullBusinessService } from "../Services/Db/business";

interface FullRoleProps {}

const FullRole: React.FC<FullRoleProps> = () => {
  const { user } = useAuth();
  const { business } = useBusinessAuth();
  const context = useContext(AppBarContext);

  const { getRoleNameFromRole } = useKeyToDict("lists");

  const loadedListing = React.useRef(false);
  const [listing, setListing] = useState<Listings | null>(null);
  const [canEdit, setCanEdit] = useState(false);
  const listingId = useParams().listingId;
  console.log(listingId);

  useEffect(() => {
    console.log(loadedListing.current);
    if (!loadedListing.current && listingId) {
      console.log(listingId);
      console.log("getting listing", listingId);
      ListingsService.getOne(listingId)
        .then((listing) => {
          console.log(listing);
          setListing(listing);
          if (business && listing?.businessRef) {
            FullBusinessService.getBusinessCanEditListing(
              business.email!,
              listing.businessRef
            ).then((canEdit) => {
              setCanEdit(canEdit);
            });
          }
        })
        .finally(() => {
          loadedListing.current = true;
        });
    }
  }, [listingId]);

  useEffect(() => {
    if (business) {
      context?.setHeaderType(HEADER_TYPES.BUSINESS);
    } else {
      //check if business or user account
      context?.setHeaderType(HEADER_TYPES.USER);
    }
  }, [user, business]);

  const { t } = useTranslation(["business", "common"]);

  return (
    <PageSpacer>
      <Stack spacing={4}>
        {business ? (
          <RoleBusiness
            listingId={listingId}
            displayingFull
            editable={canEdit}
          />
        ) : (
          <RoleGeneric listingId={listing ? listing.id! : ""} displayingFull />
        )}

        {listing ? (
          <Card>
            <Typography level="cardHeader">
              {t("apply:about_role", {
                role: getRoleNameFromRole({
                  item: listing.role,
                  category: listing.category,
                }),
              })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <ListingDetails listing={listing} />
          </Card>
        ) : (
          <Skeleton variant="rounded" height={100} />
        )}
      </Stack>
    </PageSpacer>
  );
};

export default FullRole;
