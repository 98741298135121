import { InterviewLocation } from "../../Services/Db/types";
import {
  Done,
  PersonRounded,
  PhoneRounded,
  VideocamRounded,
} from "@mui/icons-material";
import { Box, Checkbox, List, ListItem } from "@mui/joy";
import { useTranslation } from "react-i18next";

const INTERVIEW_OPTIONS: InterviewLocation[] = ["video", "phone", "in-person"];
const ICON_SX = {
  ml: -0.5,
  mr: 0.5,
  zIndex: 2,
  pointerEvents: "none",
};

interface InterviewLocationSelectProps {
  single?: boolean;
  selected: InterviewLocation[];
  setSelected: (selected: InterviewLocation[]) => void;
  values?: InterviewLocation[];
}

function getIconForType(item: InterviewLocation) {
  switch (item) {
    case "video":
      return <VideocamRounded fontSize="medium" sx={ICON_SX} />;
    case "phone":
      return <PhoneRounded fontSize="medium" sx={ICON_SX} />;
    case "in-person":
      return <PersonRounded fontSize="medium" sx={ICON_SX} />;
    default:
      return null;
  }
}

const InterviewLocationSelect = ({
  single,
  selected,
  setSelected,
  values,
}: InterviewLocationSelectProps) => {
  const { t } = useTranslation(["interview"]);
  return (
    <List
      wrap
      orientation="horizontal"
      sx={{
        "--List-gap": "8px",
        "--List-item-radius": "10px",
      }}
    >
      {(values ?? INTERVIEW_OPTIONS).map((item, index) => (
        <ListItem key={item}>
          {selected.includes(item) ? (
            <Done
              fontSize="medium"
              color="primary"
              sx={{ ...ICON_SX, color: "primary.main" }}
            />
          ) : (
            getIconForType(item)
          )}
          <Checkbox
            size="sm"
            disableIcon
            overlay
            label={t(item)}
            checked={selected.includes(item)}
            variant="outlined"
            onChange={(event) => {
              if (event.target.checked) {
                setSelected(single ? [item] : [...selected, item]);
              } else {
                setSelected(selected.filter((text) => text !== item));
              }
            }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default InterviewLocationSelect;
