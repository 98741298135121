import { useEffect, useState } from "react";

const useCycle = (messages: string[]): string => {
  const [messageIndex, setMessageIndex] = useState(0);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (messageIndex === messages.length - 1) {
      setMessageIndex(0);
    }
    if (messageIndex < messages.length - 1) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 10000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [messages, messageIndex]);
  return messages[messageIndex];
};

export default useCycle;
