import {
  Stack,
  Typography,
  Box,
  Divider,
  Modal,
  Card,
  Input,
  IconButton,
  Button,
  Checkbox,
  useTheme,
  TextField,
  Chip,
} from "@mui/joy";
import React, { useEffect, useState, useContext } from "react";
import SelectGroupSingle from "../Inputs/SelectGroupSingle";
import {
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_NO_Y,
} from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import { Close, CloseRounded, CheckRounded } from "@mui/icons-material";

import useKeyToDict from "../../Utils/useKeyToDict";
import useConfirm from "../../Utils/useConfirm";

import { FullUserService } from "../../Services/Db/user";
import { useAuth } from "../../Context/AuthContext";
import WeekToggle from "../Inputs/WeekToggle";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScheduleSelector from "react-schedule-selector";
import dayjs, { Dayjs } from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

interface AvailabilityBuilderProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const AvailabilityBuilder: React.FC<AvailabilityBuilderProps> = ({
  open,
  setOpen,
}) => {
  const { t, i18n } = useTranslation(["availability_builder"]);

  const { user } = useAuth();

  const [dayOffset, setDayOffset] = useState(0);
  const [availability, setAvailability] = useState<Date[]>([]);

  const handleSave = async () => {
    const availabilityToSave = availability.map((date) => {
      return dayjs(date).format("d-HH");
    });

    await FullUserService.setUserAvailability(
      availabilityToSave,
      user?.phoneNumber!
    ).then(() => {
      console.log("Saved availability", availabilityToSave);
    });
    if (setOpen) setOpen(false);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (!user) return;
    FullUserService.loadUserAvailability(user.phoneNumber!).then(
      (availabilities) => {
        setAvailability(
          availabilities.map((availability) => {
            const [day, hour] = availability.split("-");
            return dayjs()
              .day(parseInt(day))
              .hour(parseInt(hour))
              .minute(0)
              .second(0)
              .millisecond(0)
              .toDate();
          })
        );

        console.log("Loaded availability", availabilities);
      }
    );
  }, []);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => setOpen(false)}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          pb: 0,
          px: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 2,
          }}
        >
          <Typography level="h4" sx={{ fontWeight: 600 }}>
            {t("availability_builder:header")}
          </Typography>
          <IconButton onClick={() => handleSave()} variant={"plain"}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Divider sx={{ ...DIVIDER_HEADER_SX, mx: 0 }} />
        <Box
          sx={{
            overflowY: "scroll",
            pb: 2,
            px: 2,
            width: "100%",
            pt: mobile ? 2 : 0,
          }}
        >
          <WeekToggle
            daysShown={mobile ? 3 : 7}
            dayOffset={dayOffset}
            setDayOffset={setDayOffset}
            maxOffset={7}
          />

          <Box sx={{ pt: 2 }}>
            <ScheduleSelector
              selection={availability}
              onChange={(newAvailability: Date[]) => {
                setAvailability(newAvailability);
              }}
              startDate={dayjs()
                .add(dayOffset || 0, "day")
                .toDate()}
              numDays={mobile ? 3 : 7}
              minTime={5}
              maxTime={29}
              hourlyChunks={1}
              endDate={dayjs().add(1, "week").toDate()}
              selectionScheme={"square"} // "linear" to select between consecutive dates
              renderDateLabel={(date: any) => {
                return (
                  <Typography textAlign={"left"} sx={{ fontWeight: "500" }}>
                    {dayjs(date).locale(i18n.language).format("ddd")}
                  </Typography>
                );
              }}
              renderTimeLabel={(time: any) => {
                const t = dayjs(time).locale(i18n.language);

                return (
                  <Typography textAlign={"right"} sx={{ fontWeight: "500" }}>
                    {t.format("h")}
                    <Typography level="body2">{t.format("a")}</Typography>
                  </Typography>
                );
              }}
              renderDateCell={(
                datetime: Date,
                selected: boolean,
                refSetter: (dateCell: HTMLElement | null) => void
              ) => {
                return (
                  <React.Fragment>
                    <Box
                      ref={refSetter}
                      id={"#" + datetime.toString()}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        backgroundColor: selected ? "primary.main" : "#DDD",
                        color: "common.white",
                        fontSize: "0.8rem",
                        p: 0.25,
                        borderRadius: 0.25,
                      }}
                    />
                  </React.Fragment>
                );
              }}
            />
          </Box>
        </Box>
      </Card>
    </Modal>
  );
};

export default AvailabilityBuilder;
