import React from "react";
import { useEffect, useContext } from "react";
import {
  Stack,
  Box,
  Button,
  Typography,
  Card,
  LinearProgress,
  Divider,
} from "@mui/joy";
import { Add, Launch } from "@mui/icons-material";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import AppBarContext from "../Context/AppBarContext";
import { APP_ROUTES, HEADER_TYPES } from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useBusinessAuth } from "../Context/AuthContext";
import { FullBusinessService } from "../Services/Db/business";
import RoleBusiness from "../Components/Roles/RoleBusiness";
import { Skeleton } from "@mui/material";
import { filterDuplicateListings } from "src/Services/Db/utils";

const Roles = () => {
  const navigate = useNavigate();

  const context = useContext(AppBarContext);

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const { t } = useTranslation(["business"]);

  const { business } = useBusinessAuth();
  const [loadedListings, setLoadedListings] = React.useState(false);
  const [listingIds, setListingIds] = React.useState<string[]>([]);
  const [anyPid, setAnyPid] = React.useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (!anyPid && business) {
      const loadAnyPid = async (bid: string) => {
        const loadedProfile = await FullBusinessService.getBusinessProfile(bid);
        if (loadedProfile && loadedProfile.locations) {
          setAnyPid(loadedProfile.locations[0].id);
        }
      };
      loadAnyPid(business.email!);
    }
  }, [business]);

  useEffect(() => {
    if (!loadedListings && business) {
      const loadListingIds = async (bid: string) => {
        let ids =
          (await FullBusinessService.getBusinessProfile(bid))!.listings?.map(
            (l) => l.id
          ) ?? [];
        const finIds = await filterDuplicateListings(ids);
        setListingIds(finIds);
      };
      loadListingIds(business.email!).then(() => setLoadedListings(true));
    }
  }, [business, loadedListings]);

  return (
    <PageSpacer>
      <Stack spacing={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          <Typography level="sectionHeader">
            {t("roles", { ns: ["business"] })}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 1,
              width: { xs: "100%", md: "auto" },
            }}
          >
            <Button
              variant="solid"
              color="primary"
              startDecorator={<Add />}
              onClick={() =>
                navigate(APP_ROUTES.ROLE_MAKER, {
                  state: { edit: false, listingId: null },
                })
              }
              fullWidth
            >
              {t("add_role", { ns: "business" })}
            </Button>
            <Button
              variant="outlined"
              endDecorator={<Launch />}
              fullWidth
              onClick={() => {
                if (anyPid)
                  navigate(
                    generatePath(APP_ROUTES.BUSINESS_PAGE, {
                      businessId: anyPid,
                    })
                  );
              }}
            >
              {t("business_page", { ns: "business" })}
            </Button>
          </Box>
        </Box>

        {listingIds.length > 0 ? (
          <Stack spacing={3}>
            {listingIds.map((id) => (
              <RoleBusiness listingId={id} editable={true} />
            ))}
          </Stack>
        ) : loadedListings ? (
          <Card>
            <Typography>{t("no_roles", { ns: "business" })}</Typography>
          </Card>
        ) : (
          <Card>
            <Box sx={{ display: "flex" }}>
              <Skeleton variant="circular" width={50} height={50} />
              <Skeleton variant="text" width={250} style={{ marginLeft: 10 }} />
            </Box>
            <Divider sx={{ mx: -2, opacity: 1, mt: 1, mb: 2 }} />
            <Skeleton
              variant="rounded"
              width="100%"
              height={40}
              style={{ marginBottom: 10 }}
            />
            <Skeleton variant="rounded" width="100%" height={40} />
          </Card>
        )}
      </Stack>
    </PageSpacer>
  );
};

export default Roles;
