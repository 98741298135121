import * as React from "react";
import createMarker from "./PriceMarker";
import Marker from "./PriceMarker";

export interface MarkerEventHandler {
  event_name: string;
  callback: (m: google.maps.marker.AdvancedMarkerView) => void;
}

interface AdvancedMarkerProps
  extends google.maps.marker.AdvancedMarkerViewOptions {
  markerEl: HTMLElement;
  events?: MarkerEventHandler[];
  //place_id: string;
  //onClick: (place_id: string) => void;
  //selectedMarker: string | undefined;
}

const AdvancedMarker: React.FC<AdvancedMarkerProps> = ({
  markerEl,
  events,
  //place_id,
  ...options
}) => {
  const [marker, setMarker] =
    React.useState<google.maps.marker.AdvancedMarkerView>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(
        new google.maps.marker.AdvancedMarkerView({
          content: markerEl,
          ...options,
        })
      );
    }

    return () => {
      if (marker) {
        marker.map = null;
      }
    };
  }, [marker]);

  React.useEffect(() => {
    setMarker(
      new google.maps.marker.AdvancedMarkerView({
        content: markerEl,
        ...options,
      })
    );

    return () => {
      if (marker) {
        marker.map = null;
      }
    };
  }, [markerEl]);

  React.useEffect(() => {
    if (marker) {
      if (events) {
        events.forEach((e) => {
          google.maps.event.clearListeners(marker, e.event_name);
          marker.addListener(e.event_name, () => e.callback(marker));
        });
      }
    }
  }, [marker, events]);

  // for when map updates child props
  React.useEffect(() => {
    if (marker && options.map) {
      marker.map = options.map;
    }
  }, [options]);

  return null;
};

export default AdvancedMarker;
