import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Input,
  FormControl,
  Typography,
  Divider,
} from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import useCycle from "./useCycle";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../../src/animations.css";

const CyclePrompts = () => {
  const { t } = useTranslation(["gpt"]);

  const cyclePrompts = useCycle(
    Object.values(t("header_prompts", { returnObjects: true }) as string[])
  );

  const previousPrompt = useRef(cyclePrompts);
  useEffect(() => {
    previousPrompt.current = cyclePrompts;
  }, [cyclePrompts]);

  const nodeRef = useRef(null);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: "1.25rem",
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        paddingLeft: "0.5rem",
        pointerEvents: "none",
        overflow: "hidden",
      }}
    >
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={cyclePrompts}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="slide"
        >
          <Typography
            ref={nodeRef}
            level="body1"
            sx={{ color: "text.tertiary" }}
          >
            {cyclePrompts}
          </Typography>
        </CSSTransition>
      </SwitchTransition>
    </Box>
  );
};

export default CyclePrompts;
