import React, { useState } from "react";
import { generatePath, useNavigate } from "react-router-dom";
import { useEffect, useContext } from "react";
import AppBarContext from "../Context/AppBarContext";
import {
  HEADER_TYPES,
  APP_ROUTES,
  DIVIDER_HEADER_SX,
  CARD_OVERFLOW_SX,
  DIVIDER_HEADER_SX_NO_Y,
} from "../Utils/Constants";

import {
  Typography,
  Stack,
  Box,
  Button,
  Grid,
  Card,
  Divider,
  CardOverflow,
  IconButton,
  Alert,
  LinearProgress,
} from "@mui/joy";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import {
  ArrowForwardIosRounded,
  EditRounded,
  InfoRounded,
  ListRounded,
  NewReleasesRounded,
  PeopleRounded,
  PermContactCalendarRounded,
  PublicRounded,
} from "@mui/icons-material";
import { useBusinessAuth } from "../Context/AuthContext";
import { FullBusinessService } from "../Services/Db/business";
import useConfirm from "../Utils/useConfirm";
import { auth } from "../firebase";
import BusinessSetup from "../Components/Business/BusinessSetup";
import useEmail from "../Utils/useEmail";
import { BusinessService } from "../Services/Db/service";
import SetupGuide from "../Components/Business/SetupGuide";
import BusinessProfile from "src/Components/Business/BusinessProfile";

const BusinessHome: React.FC = () => {
  const appBarContext = useContext(AppBarContext);
  const [anyPid, setAnyPid] = useState<string>();

  const items = [
    {
      icon: <ListRounded />,
      titleKey: "job_postings",
      route: APP_ROUTES.ROLES,
    },
    {
      icon: <PeopleRounded />,
      titleKey: "candidates",
      route: APP_ROUTES.CANDIDATES,
    },
    {
      icon: <PermContactCalendarRounded />,
      titleKey: "interviews",
      route: APP_ROUTES.BUSINESS_INTERVIEWS,
    },
    {
      icon: <EditRounded />,
      titleKey: "edit_business_page",
      route: APP_ROUTES.PAGE_MAKER,
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const confirm = useConfirm();

  const { business } = useBusinessAuth();

  const [isSetup, setIsSetup] = React.useState(false);
  const [isAwaitingVerification, setIsAwaitingVerification] =
    React.useState(false);
  const [isInterviewSetup, setIsInterviewSetup] = React.useState(true);
  const [isPostingsSetup, setIsPostingsSetup] = React.useState(true);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (!anyPid && business) {
      const loadAnyPid = async (bid: string) => {
        const loadedProfile = await FullBusinessService.getBusinessProfile(bid);
        if (
          loadedProfile &&
          loadedProfile.locations &&
          loadedProfile.locations.length > 0
        ) {
          setAnyPid(loadedProfile.locations[0].id);
          loadedProfile.locations.forEach((location) => {
            if (location.id) {
              BusinessService.getOne(location.id).then((business) => {
                if (
                  business?.interviewPrefs === undefined ||
                  business?.interviewAvailabilities === undefined ||
                  business?.interviewAvailabilities.length === 0
                ) {
                  setIsInterviewSetup(false);
                }
              });
            }
          });
        }
        if (loadedProfile) {
          if (
            loadedProfile.listings === undefined ||
            loadedProfile.listings.length === 0
          ) {
            setIsPostingsSetup(false);
          }
        }
      };
      loadAnyPid(business.email!);
    }
  }, [business, isSetup]);

  useEffect(() => {
    if (business) {
      const loadIsSetUp = async (bid: string) => {
        setIsSetup(await FullBusinessService.getBusinessIsSetup(bid));
        setIsAwaitingVerification(
          await FullBusinessService.getBusinessIsAwaitingVerification(bid)
        );
        setLoading(false);
      };
      loadIsSetUp(business.email!);
    }
  }, [business, isSetup]);

  const { t } = useTranslation(["business", "header"]);

  const { sendEmail } = useEmail();

  if (loading) {
    return null;
  }

  return (
    <PageSpacer>
      <Box>
        <Stack spacing={4}>
          {isSetup ? (
            <BusinessProfile />
          ) : (
            <Alert>
              <Stack>
                <Typography level="h2" sx={{ color: "inherit" }}>
                  {t("business:welcome_header_setup")}
                </Typography>
                <Typography>
                  {t("business:welcome_header_setup_sub")}
                </Typography>
              </Stack>
            </Alert>
          )}
          <Stack>
            {!isSetup ? (
              // if the business is not set up, show the setup button
              <BusinessSetup
                setIsSetup={setIsSetup}
                awaitingVerification={isAwaitingVerification}
              />
            ) : (
              <React.Fragment>
                {(!isInterviewSetup || !isPostingsSetup) && (
                  <SetupGuide
                    isPostingCompleted={isPostingsSetup}
                    isInterviewCompleted={isInterviewSetup}
                  />
                )}
                {items.map((item) => (
                  <Card sx={{ my: 1 }}>
                    <CardOverflow
                      sx={{ ...CARD_OVERFLOW_SX, cursor: "pointer" }}
                      onClick={() => {
                        navigate(item.route);
                      }}
                    >
                      <Typography level="cardHeader" startDecorator={item.icon}>
                        {t(item.titleKey, { ns: ["business", "header"] })}
                      </Typography>

                      <IconButton>
                        <ArrowForwardIosRounded />
                      </IconButton>
                    </CardOverflow>
                  </Card>
                ))}
                <Card sx={{ my: 1 }}>
                  <CardOverflow
                    sx={{ ...CARD_OVERFLOW_SX, cursor: "pointer" }}
                    onClick={() => {
                      if (anyPid)
                        navigate(
                          generatePath(APP_ROUTES.BUSINESS_PAGE, {
                            businessId: anyPid,
                          })
                        );
                    }}
                  >
                    <Typography
                      level="cardHeader"
                      startDecorator={<PublicRounded />}
                    >
                      {t("view_business_page", { ns: ["business", "header"] })}
                    </Typography>

                    <IconButton>
                      <ArrowForwardIosRounded />
                    </IconButton>
                  </CardOverflow>
                </Card>
              </React.Fragment>
            )}
          </Stack>
          <Button
            variant="plain"
            onClick={() =>
              confirm(
                "common",
                "confirm_logout",
                "confirm_logout_subtitle_email",
                "log_out",
                () => auth.signOut()
              )
            }
          >
            {t("log_out", { ns: "common" })}
          </Button>
        </Stack>
      </Box>
    </PageSpacer>
  );
};

export default BusinessHome;

// View business page
// Edit business page
// Edit profile
// View roles
// View candidates
// View interviews
