import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Card,
  Divider,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { DIVIDER_HEADER_SX } from "src/Utils/Constants";

const GenericModal = ({
  header,
  bodyComponent,
  show,
  setShow,
  noClose,
}: {
  header: string;
  bodyComponent: JSX.Element;
  show: boolean;
  setShow: (show: boolean) => void;
  noClose?: boolean;
}) => {
  return (
    <Modal
      open={show}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => {
        setShow(false);
      }}
    >
      <ModalDialog sx={{ width: { xs: "90vw", md: "30vw" } }}>
        {!noClose && (
          <ModalClose
            onClick={() => {
              setShow(false);
            }}
          />
        )}
        <Typography level="h4" sx={{ fontWeight: 600 }}>
          {header}
        </Typography>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Box
          sx={{
            overflow: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            pb: 2,
            pt: 2,
          }}
        >
          {bodyComponent}
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default GenericModal;
