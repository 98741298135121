import {
  Stack,
  Typography,
  Box,
  Divider,
  Modal,
  Card,
  Input,
  IconButton,
  Button,
  Checkbox,
} from "@mui/joy";
import React, { useEffect, useState, useContext } from "react";
import SelectGroupSingle from "../Inputs/SelectGroupSingle";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import dayjs, { Dayjs } from "dayjs";
import MonthYearPopover from "../Inputs/MonthYearPopover";
import { useTranslation } from "react-i18next";
import { Close, CloseRounded } from "@mui/icons-material";
import ConfirmationModalContext from "../../Context/ConfirmationModalContext";
import useKeyToDict from "../../Utils/useKeyToDict";
import useConfirm from "../../Utils/useConfirm";
import { FullUserService } from "../../Services/Db/user";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../../Context/AuthContext";
import useToast from "../../Utils/useToast";
import { Education } from "../../Services/Db/types";
import { EducationService } from "../../Services/Db/service";
import WageSurveyContext from "src/Marketing/WageComparison/WageSurveyContext";

interface EducationBuilderProps {
  educationID: string | null; //if there is an education ID (or something?), we are editing this education
  open: boolean;
  setOpen: (type: boolean) => void;
}

const EducationBuilder: React.FC<EducationBuilderProps> = ({
  educationID,
  open,
  setOpen,
}) => {
  const { t } = useTranslation(["common", "education_builder", "lists"]);

  //This should be used for editing too... load in from firebase and prepopulate the below states:
  const [educationType, setEducationType] = useState<string | null>(null);

  const [studied, setStudied] = useState<string | null>(null);
  const [educationStartMonth, setEducationStartMonth] = useState<Dayjs | null>(
    null
  );
  const [educationStartYear, setEducationStartYear] = useState<Dayjs | null>(
    null
  );
  const [currentlyStudyHere, setCurrentlyStudyHere] = useState<boolean>(false);
  const [educationEndMonth, setEducationEndMonth] = useState<Dayjs | null>(
    null
  );
  const [educationEndYear, setEducationEndYear] = useState<Dayjs | null>(null);
  const [graduated, setGraduated] = useState<boolean>(true);
  const [credits, setCredits] = useState<number | null>(null);

  const handleDeleteConfirmed = () => {
    setOpen(false);
    FullUserService.deleteUserEducation(user?.phoneNumber!, educationID!);
  };

  useEffect(() => {
    if (educationID) {
      const loadUserEducation = async (eid: string) => {
        const education: Education | null = await EducationService.getOne(eid);
        if (education) {
          setEducationType(education.type);
          const startDate = education.startDate.toDate();
          setEducationStartMonth(dayjs().month(startDate.getMonth()));
          setEducationStartYear(dayjs().year(startDate.getFullYear()));
          setStudied(education.studied);
          if (education.graduationDate) {
            const endDate = education.graduationDate.toDate();
            setEducationEndMonth(dayjs().month(endDate.getMonth()));
            setEducationEndYear(dayjs().year(endDate.getFullYear()));
            setGraduated(true);
          } else {
            setEducationEndMonth(null);
            setEducationEndYear(null);
            setGraduated(false);
          }
          setCredits(education.credits);
          setCurrentlyStudyHere(education.currentlyEnrolled);
        }
      };
      loadUserEducation(educationID);
    } else {
      //reset all variables to null if not editing

      setEducationType(null);
      setEducationStartMonth(null);
      setEducationStartYear(null);
      setEducationEndMonth(null);
      setEducationEndYear(null);
      setCredits(null);
      setGraduated(true);
      setStudied(null);
    }
  }, [open]);

  const { keyToObjArray: keyToDict, keyToValueInList } = useKeyToDict("lists");
  const confirm = useConfirm();

  const { user } = useAuth();
  const toast = useToast();

  const education_options_dict = keyToDict("education_types");

  const handleClose = () => {
    if (
      educationType ||
      educationStartMonth ||
      educationStartYear ||
      educationEndMonth ||
      educationEndYear ||
      credits ||
      studied
    ) {
      //if there's progress
      confirm(
        "education_builder",
        "scrub_education_message",
        "scrub_education_subtitle",
        "scrub",
        () => {
          setOpen(false);
        },
        [
          educationType
            ? keyToValueInList(educationType, "education_types")
            : "",
        ]
      );
    } else {
      setOpen(false);
    }
  };

  const surveyContext = useContext(WageSurveyContext);
  const handleSave = () => {
    //validation
    const startDate: Date = new Date(
      educationStartYear!.year(),
      educationStartMonth!.month()
    );
    var endDate: Date | undefined;

    if (!currentlyStudyHere) {
      endDate = new Date(educationEndYear!.year(), educationEndMonth!.month());
      if (endDate < startDate) {
        toast("create_profile", "end_before_start", "", () => {});
        return;
      }
    }

    if (!user) {
      surveyContext?.setEducation({
        type: educationType!,
        currentlyEnrolled: currentlyStudyHere,
        studied: studied,
        credits: credits,
        graduationDate: endDate ? Timestamp.fromDate(endDate) : null,
        startDate: Timestamp.fromDate(startDate),
      });
      setOpen(false);
      return;
    }

    FullUserService.createUserEducation(
      {
        type: educationType!,
        currentlyEnrolled: currentlyStudyHere,
        studied: studied,
        credits: credits,
        graduationDate: endDate ? Timestamp.fromDate(endDate) : null,
        startDate: Timestamp.fromDate(startDate),
      },
      user.phoneNumber!,
      educationID || undefined
    );
    setOpen(false);
  };

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  useEffect(() => {
    // FIXME looks like sometimes map will persist on location but context could be clear
    if (
      educationType &&
      educationStartMonth &&
      educationStartYear &&
      ((educationEndMonth && educationEndYear) || currentlyStudyHere) &&
      (graduated || credits)
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [
    educationType,
    educationStartMonth,
    educationStartYear,
    educationEndMonth,
    educationEndYear,
    currentlyStudyHere,
    graduated,
    credits,
  ]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
          overflowX: "hidden",
        }}
        onClose={handleClose}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ fontWeight: 600 }}>
              {t("header", { ns: ["education_builder"] })}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { display: "none" },
              pb: 10,
            }}
          >
            <Stack
              spacing={4}
              divider={<Divider />}
              sx={{ mt: 2, overflowX: "none" }}
            >
              <SelectGroupSingle
                selected={educationType}
                setSelected={setEducationType}
                title={t("education_type", {
                  ns: ["education_builder", "experience_builder"],
                })}
                items={education_options_dict}
              />

              {educationType !== "high_school" && !!educationType && (
                <Stack>
                  <Typography level="selectLabel">
                    {t("studied", {
                      ns: ["education_builder", "experience_builder"],
                    })}
                  </Typography>
                  <Input
                    onChange={(e) => setStudied(e.target.value)}
                    value={studied || ""}
                  />
                </Stack>
              )}

              <Stack>
                <Typography level="selectLabel">
                  {t("dates", {
                    ns: ["education_builder", "experience_builder"],
                  })}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100",
                    flexWrap: "wrap",
                    gap: "8px", //same as SelectGroup
                  }}
                >
                  <MonthYearPopover
                    placeholder={
                      t("start_date", {
                        ns: ["education_builder", "experience_builder"],
                      }) || "Start Date"
                    }
                    month={educationStartMonth}
                    setMonth={setEducationStartMonth}
                    year={educationStartYear}
                    setYear={setEducationStartYear}
                  />
                  <Typography level="body2" sx={{ px: 2 }}>
                    {t("to", { ns: ["education_builder"] })}
                  </Typography>
                  <MonthYearPopover
                    placeholder={
                      t("end_date", {
                        ns: ["education_builder", "experience_builder"],
                      }) || "End Date"
                    }
                    month={educationEndMonth}
                    setMonth={setEducationEndMonth}
                    year={educationEndYear}
                    setYear={setEducationEndYear}
                    disabled={currentlyStudyHere}
                  />
                </Box>

                <Checkbox
                  checked={currentlyStudyHere}
                  onChange={() =>
                    setCurrentlyStudyHere((prevState) => !prevState)
                  }
                  label={
                    t("currently_study_here", {
                      ns: ["education_builder"],
                    }) || ""
                  }
                  sx={{ my: 3 }}
                />
              </Stack>

              <Stack>
                <Typography level="selectLabel">
                  {t("academic_progress", {
                    ns: ["education_builder"],
                  })}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  <Checkbox
                    checked={graduated}
                    onChange={() => {
                      setGraduated((prevState) => !prevState);
                      setCredits(null);
                    }}
                    label={
                      t("graduated", {
                        ns: ["education_builder"],
                      }) || ""
                    }
                  />
                  {graduated ? null : (
                    <Input
                      onChange={(e) => setCredits(parseFloat(e.target.value))}
                      value={credits || ""}
                      placeholder={
                        t("credits", {
                          ns: ["education_builder"],
                        }) || ""
                      }
                      type="number"
                    />
                  )}
                </Box>
              </Stack>

              <Stack spacing={2}>
                <Button onClick={handleSave} disabled={!canSubmit}>
                  {t("save", {
                    ns: ["common"],
                  })}
                </Button>
                {educationID && (
                  <Button
                    variant="plain"
                    onClick={() => {
                      confirm(
                        "education_builder",
                        "delete_education_message",
                        "delete_education_subtitle",
                        "delete",
                        handleDeleteConfirmed,
                        [keyToValueInList(educationType, "education_types")]
                      );
                    }}
                  >
                    {t("delete", {
                      ns: ["common"],
                    })}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default EducationBuilder;
