import React, { useEffect, useState, useContext } from "react";
import PageSpacer from "../Utilities/PageSpacer";
import AppBarContext from "../../Context/AppBarContext";
import { APP_ROUTES, HEADER_TYPES } from "../../Utils/Constants";
import { Typography, Stack, Card, Chip, Box, Button } from "@mui/joy";
import { useAuth } from "../../Context/AuthContext";
import { useTranslation } from "react-i18next";
import { Listings } from "../../Services/Db/types";
import { ListingsService } from "../../Services/Db/service";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentReference } from "firebase/firestore";
import { BusinessService } from "../../Services/Db/service";
import useKeyToDict from "../../Utils/useKeyToDict";
import { Item } from "../../Services/Db/types";
import ApplicationAndName from "./ApplicationAndName";
import { LinearProgress } from "@mui/material";

const Apply = () => {
  const { t } = useTranslation(["apply"]);

  const appBarContext = useContext(AppBarContext);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.BASIC);
  });

  const { listingId } = useParams();
  const navigate = useNavigate();
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const loadListing = async (listingId: string): Promise<Listings> => {
    const listing = await ListingsService.getOne(listingId).then(
      (listing) => listing
    );

    if (listing) {
      return listing;
    }

    return Promise.reject("Listing not found");
  };

  const [listing, setListing] = useState<Listings>();
  const [businessName, setBusinessName] = useState("");
  const [role, setRole] = useState<Item | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (listingId) {
      loadListing(listingId).then((listing) => {
        setListing(listing);
        setLoading(false);
        getBusinessName(listing?.businessRef!).then((name) =>
          setBusinessName(name as string)
        );
        setRole({ item: listing.role!, category: listing.category! } as Item);
      });
    }
  }, [listingId]);

  const getBusinessName = async (businessRef: DocumentReference) => {
    const loadedBusiness = await BusinessService.getResolvedReference(
      businessRef
    );
    return loadedBusiness?.name;
  };

  if (loading) {
    return (
      <PageSpacer>
        <LinearProgress />
      </PageSpacer>
    );
  }

  return (
    <PageSpacer>
      {listing ? (
        <Stack spacing={4}>
          <Typography level="sectionHeader">
            {t("apply_header", {
              business: businessName,
              role: getRoleNameFromRole(role),
            })}
          </Typography>
          <Card>
            <Stack spacing={2}>
              {[1, 2, 3].map((i) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Box sx={{ mt: 0.5 }}>
                    <Chip>{i}</Chip>
                  </Box>
                  <Box sx={{ ml: 2 }}>
                    <Typography level="h6" sx={{ fontWeight: "bold" }}>
                      {t(`step${i}`)}
                    </Typography>
                    <Typography level="body2">
                      {t(`step${i}_sub`, { business: businessName })}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Card>
          <Card>
            <ApplicationAndName listing={listing} businessName={businessName} />
          </Card>
        </Stack>
      ) : (
        <Stack sx={{ display: "flex", alignItems: "center" }} spacing={4}>
          <Typography level="h3" sx={{ textAlign: "center" }}>
            {t("no_listing")}
          </Typography>
          <Box>
            <Button onClick={() => navigate(APP_ROUTES.HOME)}>
              {t("no_listing_sub")}
            </Button>
          </Box>
        </Stack>
      )}
    </PageSpacer>
  );
};

export default Apply;
