import React, { useCallback } from "react";
import AdvancedMarker, { MarkerEventHandler } from "./AdvancedMarker";

interface WageMapMarkerProps
  extends google.maps.marker.AdvancedMarkerViewOptions {
  value: number;
  color: number[];
  cluster: boolean;
  isSelected: boolean;
  events?: MarkerEventHandler[];
}

function createNewMarker(isSelected: boolean, cluster: boolean, value: number) {
  const newMarker = document.createElement("div");

  // dollar sign
  const dollar = document.createElement("span");
  dollar.textContent = "$";
  dollar.style.color = isSelected ? "#D75143" : "#FFF";
  dollar.style.fontSize = cluster ? ".5rem" : ".75rem";
  if (!cluster) dollar.style.paddingRight = "1px";
  newMarker.appendChild(dollar);

  // wage value
  const wage = document.createElement("span");
  wage.textContent = value.toString();
  wage.style.color = isSelected ? "#D75143" : "#FFF";
  wage.style.fontSize = cluster ? ".75rem" : "1.25rem";
  newMarker.appendChild(wage);

  // marker styling
  newMarker.style.borderRadius = "50%";
  newMarker.style.display = "flex";
  newMarker.style.justifyContent = "center";
  newMarker.style.alignItems = "center";
  newMarker.style.cursor = "pointer";
  newMarker.style.boxShadow = "2px 2px 5px rgba(50, 50, 50, .3)";
  if (!cluster) newMarker.style.padding = "8px";
  newMarker.style.position = "relative";

  // marker animation
  newMarker.style.transition = "all .2s ease-in-out";
  newMarker.style.transform = "scale(1)";
  newMarker.addEventListener("mouseover", () => {
    newMarker.style.transform = "scale(1.2)";
  });
  newMarker.addEventListener("mouseout", () => {
    newMarker.style.transform = "scale(1)";
  });
  return newMarker;
}

const WageMapMarker: React.FC<WageMapMarkerProps> = ({
  value,
  color,
  cluster,
  isSelected,
  events,
  ...options
}) => {
  const marker = React.useRef<HTMLDivElement | null>(
    createNewMarker(isSelected, cluster, value)
  );

  if (marker.current) {
    // transition dimensions
    marker.current.style.width = cluster ? "1.5rem" : "2.6rem";
    marker.current.style.height = cluster ? "1.5rem" : "2.6rem";
    // transition background color
    marker.current.style.backgroundColor = isSelected
      ? "#FFF"
      : `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    (marker.current.children[0] as HTMLSpanElement).style.fontSize = cluster
      ? ".5rem"
      : ".75rem";
    (marker.current.children[1] as HTMLSpanElement).style.fontSize = cluster
      ? ".75rem"
      : "1.25rem";
    // transition font color on selected
    (marker.current.children[0] as HTMLSpanElement).style.color = isSelected
      ? "#D75143"
      : "#FFF";
    (marker.current.children[1] as HTMLSpanElement).style.color = isSelected
      ? "#D75143"
      : "#FFF";
    return (
      <AdvancedMarker markerEl={marker.current} events={events} {...options} />
    );
  } else {
    console.log("returning empty");
    return null;
  }
};

export default React.memo(WageMapMarker, (prevProps, nextProps): boolean => {
  if (prevProps.value !== nextProps.value) {
    return false;
  }
  if (JSON.stringify(prevProps.color) !== JSON.stringify(nextProps.color)) {
    return false;
  }
  if (prevProps.cluster !== nextProps.cluster) {
    return false;
  }
  if (prevProps.isSelected !== nextProps.isSelected) {
    return false;
  }

  return true;
});
