import { Fragment, useContext, useEffect, useState } from "react";
import GPTContext from "./GPTContext";
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/joy";
import RoleItem from "src/Components/Apply/RoleItem";
import { Listings } from "src/Services/Db/types";
import { ListingsService } from "src/Services/Db/service";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { LinearProgress } from "@mui/joy";
import ListIcon from "@mui/icons-material/List";
import AppBarContext from "src/Context/AppBarContext";
import { MapContext } from "src/Context/MapContext";
import { Skeleton, useMediaQuery } from "@mui/material";
import LoadingCircle from "./LoadingCircle";
import { useTranslation } from "react-i18next";
import { useAuth } from "src/Context/AuthContext";
import { analytics } from "src/firebase";
import { logEvent } from "firebase/analytics";

export const RoleItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Skeleton variant="circular" width={50} height={50} />
        <Stack>
          <Skeleton variant="text" width={200} />
          <Skeleton variant="text" width={200} height={20} />
        </Stack>
      </Box>
      <Skeleton variant="rounded" width={40} height={40} />
    </Box>
  );
};

const Recommendations = () => {
  const context = useContext(GPTContext);
  const appBarContext = useContext(AppBarContext);
  const mapContext = useContext(MapContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("gpt");
  const { user } = useAuth();

  const [loadingListings, setLoadingListings] = useState(false);
  const [loadedListings, setLoadedListings] = useState<Listings[]>([]);

  useEffect(() => {
    if (context?.recommendations && context?.recommendations.length > 0) {
      setLoadingListings(true);
      Promise.all(
        context?.recommendations.map(async (listingId) => {
          const listing = await ListingsService.getOne(listingId);
          return listing;
        })
      )
        .then((listings) => {
          setLoadedListings(
            listings.filter(
              (listing) => listing !== undefined && listing !== null
            ) as Listings[]
          );
        })
        .finally(() => {
          setLoadingListings(false);
        });
    } else {
      setLoadingListings(false);
    }
  }, [context?.recommendations]);

  return (
    <Box
      sx={{
        width: { xs: "94vw", md: "90vw" },
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
        zIndex: 1000,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        variant="plain"
        onClick={() =>
          context?.setShowRecommendations(!context?.showRecommendations)
        }
        sx={{
          mb: context?.showRecommendations ? 0.5 : 3,
          zIndex: 1000,
          transition: "all 0.5s",
          borderRadius: 100,
          bgcolor: context?.showRecommendations ? "background.paper" : "black",
          "&:hover": {
            bgcolor: context?.showRecommendations
              ? "background.paper"
              : "black",
          },
          boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.1)",
          border: context?.showRecommendations ? "1px solid #DDD" : "none",
        }}
      >
        {appBarContext?.loading ? (
          <LoadingCircle />
        ) : context?.showRecommendations ? (
          <KeyboardArrowDown fontSize="large" />
        ) : (
          <ListIcon sx={{ color: "common.white" }} />
        )}
      </IconButton>

      <Card
        sx={{
          width: "100%",
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxHeight: context?.showRecommendations ? "40vh" : 0,
          transition: "max-height 0.5s",
          py: 0,
          overflow: context?.showRecommendations ? "auto" : "hidden",
        }}
      >
        {loadingListings && (
          <Typography sx={{ my: 1, textDecoration: "underline" }} level="body2">
            {t("loading_results", { count: loadedListings.length })}
          </Typography>
        )}
        {!loadingListings && loadedListings.length > 0 && (
          <Typography sx={{ my: 1, textDecoration: "underline" }} level="body2">
            {t("showing_results", { count: loadedListings.length })}
          </Typography>
        )}
        <Stack spacing={2} sx={{ my: 2 }} divider={<Divider />}>
          {loadingListings || context?.loading ? (
            <Fragment>
              <RoleItemSkeleton />
              <Divider />
              <RoleItemSkeleton />
              <Divider />
              <RoleItemSkeleton />
            </Fragment>
          ) : loadedListings.length > 0 ? (
            loadedListings.map((listing) => {
              return (
                <RoleItem
                  listing={listing}
                  onClick={() => {
                    mapContext?.setPlaceId(listing.businessRef?.id);
                    context?.setShowRecommendations(false);
                    logEvent(analytics, "view_role", {
                      listing_id: listing.id,
                      from: "gpt",
                    });
                    if (mobile) {
                      appBarContext?.setShowHeader(false);
                    }
                  }}
                  displayName
                  onlyTips
                />
              );
            })
          ) : (
            <Typography
              sx={{ textAlign: "center", fontWeight: "600" }}
              level="body1"
            >
              {user
                ? context?.noResults
                  ? t("no_results")
                  : t("no_recommendations")
                : t("no_recommendations_guest")}
            </Typography>
          )}
        </Stack>
      </Card>
    </Box>
  );
};

export default Recommendations;
