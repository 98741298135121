import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
} from "@mui/joy";
import React, { useEffect, useContext, useState, useRef } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import { useTranslation } from "react-i18next";
import { Add, Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { useAuth } from "../Context/AuthContext";
import { Applications } from "../Services/Db/types";
import RoleApplied from "../Components/Roles/RoleApplied";
import { FullUserService } from "../Services/Db/user";
import { LinearProgress } from "@mui/material";

const statuses = ["submitted", "interviewing", "hired", "closed"];

const ApplicationsPage = () => {
  const appBarContext = useContext(AppBarContext);
  const { t, i18n } = useTranslation(["common", "create_profile"]);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.USER);
  }, []);

  const { user } = useAuth();
  const [applications, setApplications] = useState<Applications[]>([]);
  const loadedApplications = useRef(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(true);

  const hired = applications.filter((app) => app.status === "hired");
  const interviewing = applications.filter(
    (app) => app.status === "interviewing"
  );
  const pending = applications.filter((app) => app.status === "submitted");
  const rejected = applications.filter((app) => app.status === "rejected");

  useEffect(() => {
    if (user && !loadedApplications.current) {
      const loadApplications = async (phoneNumber: string) => {
        const loadedApps = await FullUserService.loadUserApplications(
          phoneNumber
        );
        setApplications(loadedApps);
        loadedApplications.current = true;
        setLoading(false);
      };
      loadApplications(user.phoneNumber!);
    }
  }, [user, update]);

  if (loading) {
    return (
      <PageSpacer>
        <Card>
          <LinearProgress></LinearProgress>
        </Card>
      </PageSpacer>
    );
  }

  return (
    <PageSpacer>
      <Stack spacing={2}>
        <Typography level="sectionHeader">
          {t("applications", { ns: "job_seeker" })}
        </Typography>
        {applications.length === 0 && (
          <Card>
            <Typography level="h3">
              {t("job_seeker:no_applications")}
            </Typography>
            <Typography level="body1">
              {t("job_seeker:no_applications_sub")}
            </Typography>
          </Card>
        )}
        {hired && hired.length > 0 && (
          <Typography level="h3">{t("hired", { ns: "common" })}</Typography>
        )}
        {hired.map((app) => (
          <React.Fragment key={app.id!}>
            <RoleApplied
              listingId={app.listingRef.id}
              application={app}
              update={update}
              setUpdate={setUpdate}
            />
          </React.Fragment>
        ))}
        {interviewing && interviewing.length > 0 && (
          <Typography level="h3">
            {t("interviewing", { ns: "common" })}
          </Typography>
        )}
        {interviewing.map((app) => (
          <React.Fragment key={app.id!}>
            <RoleApplied
              listingId={app.listingRef.id}
              application={app}
              update={update}
              setUpdate={setUpdate}
            />
          </React.Fragment>
        ))}
        {pending && pending.length > 0 && (
          <Typography level="h3">{t("pending", { ns: "common" })}</Typography>
        )}

        {pending.map((app) => (
          <React.Fragment key={app.id!}>
            <RoleApplied
              listingId={app.listingRef.id}
              application={app}
              update={update}
              setUpdate={setUpdate}
            />
          </React.Fragment>
        ))}
        {rejected && rejected.length > 0 && (
          <Typography level="h3">{t("rejected", { ns: "common" })}</Typography>
        )}
        {rejected.map((app) => (
          <React.Fragment key={app.id!}>
            <RoleApplied
              listingId={app.listingRef.id}
              application={app}
              update={update}
              setUpdate={setUpdate}
            />
            {app.name}
          </React.Fragment>
        ))}
      </Stack>
    </PageSpacer>
  );
};

export default ApplicationsPage;
