import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import AppBarContext from "../Context/AppBarContext";
import { HEADER_TYPES, APP_ROUTES } from "../Utils/Constants";

import { Typography, Stack, Box, Button } from "@mui/joy";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import TabsPage from "../Components/Inputs/TabsPage";
import ProfileCard from "../Components/JobSeekerHome/ProfileCard";

import SavedRolesCard from "../Components/JobSeekerHome/SavedRolesCard";
import ApplicationsCard from "../Components/JobSeekerHome/ApplicationsCard";
import { auth } from "../firebase";
import useConfirm from "../Utils/useConfirm";

const JobSeekerHome: React.FC = () => {
  const appBarContext = useContext(AppBarContext);

  const navigate = useNavigate();
  const confirm = useConfirm();

  //this page links back to map, any other page links back here
  useEffect(() => {
    if (appBarContext) {
      appBarContext.setHeaderType(HEADER_TYPES.USER);

      appBarContext.setBackKey("back_to_map");
    }
  });

  const { t } = useTranslation(["job_seeker"]);

  return (
    <PageSpacer>
      <Stack spacing={2}>
        <Typography level="sectionHeader">
          {t("home", { ns: "job_seeker" })}
        </Typography>
        <ProfileCard />
        <SavedRolesCard />
        <ApplicationsCard />

        <Button
          variant="plain"
          onClick={() =>
            confirm(
              "common",
              "confirm_logout",
              "confirm_logout_subtitle",
              "log_out",
              () => auth.signOut()
            )
          }
        >
          {t("log_out", { ns: "common" })}
        </Button>
      </Stack>
    </PageSpacer>
  );
};

export default JobSeekerHome;
