import { DIVIDER_HEADER_SX_MY, HEADER_TYPES } from "../../Utils/Constants";
import { Card, Divider, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import PageSpacer from "../Utilities/PageSpacer";
import ExampleRole from "./ExampleRole";
import ListItems from "../Common/ListItems";
import { Fragment, useContext, useEffect } from "react";
import AppBarContext from "../../Context/AppBarContext";

const ExampleRoleFull = () => {
  const location = useLocation();
  const { t } = useTranslation(["common", "apply"]);
  const context = useContext(AppBarContext);
  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.USER);
  }, []);
  return (
    <PageSpacer>
      <Stack spacing={4}>
        <ExampleRole
          displayingFull
          businessName={location.state.businessName as string}
        />

        <Card>
          <Typography level="cardHeader">
            {t("example_role_details")}
          </Typography>
          <Divider sx={DIVIDER_HEADER_SX_MY} />
          <Stack sx={{}} spacing={2} divider={<Divider />}>
            <ListItems
              titleKey="schedule"
              ns="common"
              items={[
                { item: "full_time", category: "job_types" },
                { item: "day", category: "shifts" },
              ]}
              parentKey="schedules"
            />
            <ListItems
              titleKey="perks"
              ns="common"
              items={[
                { item: "training", category: "development" },
                { item: "pto", category: "time_off" },
              ]}
              parentKey="perks"
            />
            <Fragment>
              <Typography level="h6" fontWeight="bold" sx={{ mb: 1 }}>
                {t("description")}
              </Typography>
              <Typography level="body1">
                {"Tell candidates more about this role."}
              </Typography>
            </Fragment>
          </Stack>
        </Card>
      </Stack>
    </PageSpacer>
  );
};

export default ExampleRoleFull;
