import React, { useState, useEffect, useRef } from "react";
import useKeyToDict from "../../Utils/useKeyToDict";
import { Item, PlaceDetails, Listings } from "../../Services/Db/types";
import {
  Stack,
  Button,
  Box,
  AspectRatio,
  Typography,
  Divider,
  Chip,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { ChevronRight, SendRounded } from "@mui/icons-material";
import { BusinessService, ListingsService } from "../../Services/Db/service";
import { getBusinessRef } from "../../Services/Db/utils";
import { where } from "@firebase/firestore";
import WageChip from "../Common/WageChip";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/Utils/Constants";
import RoleItem from "./RoleItem";
import { analytics } from "src/firebase";
import { logEvent } from "firebase/analytics";

interface RolesStepProps {
  markerPopupDetails: PlaceDetails | undefined;
  placeId: string;
  setSelectedListing: (l: Listings) => void;
  setActive: (active: number) => void;
}

const RolesStep: React.FC<RolesStepProps> = ({
  markerPopupDetails,
  placeId,
  setSelectedListing,
  setActive,
}) => {
  const { t } = useTranslation(["apply", "common"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const [listings, setListings] = useState<Listings[]>([]);
  const loadingListings = useRef(true);

  useEffect(() => {
    if (placeId) {
      const loadListings = async () => {
        loadingListings.current = true;
        const loadedListings = await ListingsService.getMany(
          where("businessRef", "==", getBusinessRef(placeId))
        );
        console.log(
          "filtered listings",
          loadedListings.filter((l) => l.status === "active")
        );
        setListings(loadedListings.filter((l) => l.status === "active"));
        loadingListings.current = false;
      };
      loadListings();
    }
  }, [placeId]);

  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        pb: 2,
        overflowY: "auto",

        maxHeight: { xs: "52vh", md: "70vh" },
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      {markerPopupDetails?.img ? (
        <AspectRatio
          minHeight="50px"
          maxHeight="120px"
          sx={{ mb: 2, borderRadius: 1.5 }}
        >
          <img src={markerPopupDetails?.img} loading="lazy" alt="" />
        </AspectRatio>
      ) : (
        <Skeleton sx={{ mb: 2 }} variant="rounded" height={120} />
      )}
      <Stack spacing={2} divider={<Divider />}>
        {loadingListings.current ? (
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Skeleton variant="circular" height={50} sx={{ flexGrow: 1 }} />
            <Skeleton variant="rounded" height={50} sx={{ flexGrow: 4 }} />
            <Skeleton variant="rounded" height={50} sx={{ flexGrow: 2 }} />
          </Box>
        ) : listings.length > 0 ? (
          listings.map((listing) => (
            <RoleItem
              listing={listing}
              onClick={() => {
                setActive(1);
                setSelectedListing(listing);
                logEvent(analytics, "view_role", {
                  listing_id: listing.id,
                  from: "RolesStep",
                });
              }}
              setActive={setActive}
              setSelectedListing={setSelectedListing}
            />
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              flexDirection: "column",
            }}
          >
            <Typography level="body1" textAlign="center">
              {t("no_roles")}
            </Typography>
            <Button
              onClick={() => navigate(APP_ROUTES.NEW_BUSINESS)}
              fullWidth
              variant="soft"
            >
              {t("claim_this_business")}
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default RolesStep;
