import { useState } from "react";
import { Stack } from "@mui/joy";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useParams } from "react-router-dom";
import BusinessPageHeader from "src/Components/BusinessPage/BusinessPageHeader";
import BusinessPageBody from "src/Components/BusinessPage/BusinessPageBody";

const BusinessPage = () => {
  const [placeId, setPlaceId] = useState<string | null>(
    useParams().businessId ?? null
  );

  return (
    <PageSpacer>
      <Stack spacing={3}>
        <BusinessPageHeader placeId={placeId ?? ""} setPlaceId={setPlaceId} />
        <BusinessPageBody placeId={placeId} />
      </Stack>
    </PageSpacer>
  );
};

export default BusinessPage;
