import {
  AddRounded,
  Delete,
  KeyboardArrowDown,
  PersonAdd,
  PersonAddRounded,
  Refresh,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/joy";
import { Collapse, Skeleton } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import useEmail from "src/Utils/useEmail";
import InviteToAccountModal from "./InviteToAccountModal";
import useToast from "src/Utils/useToast";
import { useTranslation } from "react-i18next";
import { isNull } from "lodash";

const BusinessUserManagement = () => {
  const { business } = useBusinessAuth();
  const { sendEmail } = useEmail();
  const toast = useToast();

  const [addedUsers, setAddedUsers] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (business?.email) {
      FullBusinessService.getBusinessProfile(business.email)
        .then((biz) => {
          if (biz?.addedUsers) {
            setAddedUsers(biz.addedUsers);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [business]);

  async function sendInviteEmail(email: string): Promise<void> {
    await sendEmail({
      to: email,
      text: `You have been invited to your company's Hourlead account by ${business?.email}. Please sign up at https://hourlead.com/invitation with this email (${email}) to get started.`,
      subject: "Invitation to Hourlead",
    });
  }

  async function handleComplete(email: string): Promise<void> {
    setAddedUsers([...addedUsers, email]);
    FullBusinessService.updateAddedUsers(business?.email!, email).then(() =>
      sendInviteEmail(email)
    );
  }

  const { t } = useTranslation("business");

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography level="h6">{t("linked_users")}</Typography>
        <IconButton variant="plain" onClick={() => setOpen(true)}>
          <AddRounded />
        </IconButton>
      </Box>
      <Stack
        direction={{ xs: "column" }}
        spacing={0}
        pl={addedUsers.length > 0 ? 1 : 0}
        borderLeft={addedUsers.length > 0 ? 1 : 0}
        borderColor="divider"
      >
        {loading && <Skeleton height={60} />}
        {addedUsers.length === 0 ? (
          <Button
            variant="soft"
            color="neutral"
            size="sm"
            onClick={() => setOpen(true)}
          >
            {t("invite_user_to_account")}
          </Button>
        ) : (
          addedUsers.map((user) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography>{user}</Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <IconButton
                    onClick={() => {
                      sendInviteEmail(user).then(() => {
                        toast("business", "user_invited", "", () => {}, [
                          user!,
                        ]);
                      });
                    }}
                  >
                    <Refresh />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      FullBusinessService.removeAddedUser(
                        business?.email!,
                        user
                      ).then(() => {
                        setAddedUsers(addedUsers.filter((u) => u !== user));
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            );
          })
        )}
      </Stack>
      <InviteToAccountModal
        open={open}
        setOpen={setOpen}
        onComplete={handleComplete}
      />
    </Box>
  );
};

export default BusinessUserManagement;
