import {
  Stack,
  Typography,
  Box,
  Button,
  Input,
  Card,
  CardContent,
  CardOverflow,
  AspectRatio,
  Alert,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, Dispatch } from "react";
import AppBarContext from "../Context/AppBarContext";
import { CheckCircle, Edit, Paid } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Stepper, Step, StepContent } from "@mui/material";

// User Type
import { HEADER_TYPES, USER_TYPES } from "../Utils/Constants";

// Role
import SelectGroupSingle from "../Components/Inputs/SelectGroupSingle";
import RoleContext from "../Context/NewUserFlow/RoleContext";

// Components
import FAQ from "../Components/NewUserFlow/FAQ";
import { APP_ROUTES } from "../Utils/Constants";
import StepNumber from "../Components/NewUserFlow/StepNumber";
import { NewUserAction } from "./NewUserFlow";

const FAQs = {
  role: [
    {
      question:
        "We need to know your most recent role to give you personalized job recommendations",
      answer:
        "For instance, if you were a barista making $13 an hour, we'll show you similar barista roles that pay more in your area.",
    },
  ],
  wage: [
    {
      question:
        "Over 93% of Gen-Z and Millenials are comfortable sharing their wages",
      answer:
        "Wage transparency is the future!\n\nRest assured that any and all info you share is completely anonymous — we don't even know your name!",
    },
    {
      question: "Why we ask you to share your wage",
      answer:
        "Employers know things (like wage) that job-seekers don't. We depend on information pooled from businesses and our community to help you get a better paying job.",
    },
  ],
  location: [
    {
      question:
        "Everything you share on Hourlead is anonymous. We don’t even know your name!",
      answer: "Don't worry: your information is fully anonymous.",
    },
  ],
};

interface Props {
  //setUserType: (userType: USER_TYPES) => void;
  //setStep: (step: "selectUserType" | "stepper" | "phone") => void;
  dispatch: Dispatch<NewUserAction>;
}

const NotEmployedStepper: React.FC<Props> = ({ dispatch }) => {
  // const appBarContext = useContext(AppBarContext);
  // useEffect(() => {
  //   appBarContext?.setHeaderType(HEADER_TYPES.BASIC);
  // });

  const navigate = useNavigate();

  const [role, setRole] = useState<string | null>(null);

  const [wage, setWage] = useState("");
  const [tips, setTips] = useState("");

  const [address, setAddress] = useState("123 Main St, New York, NY");
  const [businessName, setBusinessName] = useState("Monuts");
  const [editBusinessName, setEditBusinessName] = useState(false);

  const [phone, setPhone] = useState("");

  const [activeStep, setActiveStep] = useState(0);
  const [completedTo, setCompletedTo] = useState(0);

  const { t } = useTranslation(["common", "lists"]);

  const handleNext = () => {
    //if we're moving to the next step, we've completed the current step, so we set completedTo to the next step
    setCompletedTo((prevCompletedTo) =>
      Math.max(prevCompletedTo, activeStep + 1)
    );
    //move on to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleWage = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setWage(e.target.value);
    }
  };

  const handleTips = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setTips(e.target.value);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTouch = (step: number) => {
    if (step <= completedTo) {
      setActiveStep(step);
    }
  };

  var employment_options = t("employment_options", {
    returnObjects: true,
    ns: ["lists"],
  });

  const employment_options_dict: Record<string, string>[] = Object.values(
    employment_options
  ).reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {});

  return (
    <Stack>
      <Typography>NOT SURE WHAT THIS FLOW SHOULD LOOK LIKE YET...</Typography>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ mt: -2 }}>
        <Step key={"role"}>
          <StepNumber
            stepNumber={1}
            title={"Role"}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />

          <StepContent>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexWrap: "wrap",
                  mb: 2,
                }}
              >
                <Typography level="h6">
                  What was your most recent role?
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  level="body2"
                >
                  Never been employed?
                </Typography>
              </Box>
              <SelectGroupSingle
                handleNext={handleNext}
                selected={role}
                setSelected={setRole}
                items={employment_options_dict}
              />
            </Card>

            <FAQ section="role" />
          </StepContent>
        </Step>
      </Stepper>
    </Stack>
  );
};

export default NotEmployedStepper;
