import { auth, db } from "../../firebase";
import {
  collection,
  doc,
  DocumentReference,
  Timestamp,
  where,
} from "firebase/firestore";
import DatabaseService, {
  ApplicationsService,
  BusinessService,
  EducationService,
  ExperiencesService,
  InterviewsService,
  ListingsService,
  ReferencesService,
  UserService,
  WageService,
} from "./service";
import {
  Applications,
  Businesses,
  Education,
  Experiences,
  Listings,
  References,
  UserData,
  Users,
  Wages,
} from "./types";
import DatabaseError from "./errors";
import { ensureBusinessExists, getBusinessRef } from "./utils";
import { TABLES } from "./consts";
import { deleteUser } from "firebase/auth";

class UserDbService {
  constructor() {}

  /**
   * READING routes
   */
  loadBasicUser = async (uid: string): Promise<Users> => {
    const loadedUser = await UserService.getOne(uid);
    if (!loadedUser) {
      throw new DatabaseError("User does not exist in DB");
    }
    return loadedUser;
  };

  loadUserWage = async (uid: string): Promise<Wages | undefined> => {
    const user = await this.loadBasicUser(uid);
    var wage;
    if (user) {
      const wageRef = user.wage;
      if (wageRef) {
        const loadedWage = await WageService.getResolvedReference(wageRef);
        if (loadedWage) {
          wage = loadedWage;
        }
      }
    } else {
      throw new DatabaseError("User does not exist in DB");
    }
    return wage;
  };

  loadUserExperiences = async (uid: string): Promise<Experiences[]> => {
    return await ExperiencesService.getMany(
      where("user", "==", doc(db, TABLES.USERS, uid))
    );
  };

  loadUserStart = async (uid: string): Promise<Timestamp | null> => {
    const user = await UserService.getOne(uid);
    if (user && user.start) {
      return user.start;
    } else {
      return null;
    }
  };

  loadUserLanguages = async (uid: string): Promise<string[]> => {
    const user = await UserService.getOne(uid);
    if (user && user.languages) {
      return user.languages;
    } else {
      return [];
    }
  };

  loadUserNoExperience = async (uid: string): Promise<Boolean> => {
    const user = await UserService.getOne(uid);
    return user?.noExperience || false;
  };

  loadUserEducation = async (uid: string): Promise<Education> => {
    return (
      await EducationService.getMany(
        where("user", "==", doc(db, TABLES.USERS, uid))
      )
    )[0];
  };

  loadUserReferences = async (uid: string): Promise<References[]> => {
    return await ReferencesService.getMany(
      where("user", "==", doc(db, TABLES.USERS, uid))
    );
  };

  loadUserSavedListingIds = async (uid: string): Promise<string[]> => {
    const user = await UserService.getOne(uid);
    if (user && user.savedListings) {
      return user.savedListings.map((ref) => ref.id);
    } else {
      return [];
    }
  };

  loadUserSavedListings = async (uid: string): Promise<Listings[]> => {
    const savedListingIds = await FullUserService.loadUserSavedListingIds(uid);
    const loadedSavedRoles = await Promise.all(
      savedListingIds.map((listingId) =>
        ListingsService.getResolvedReference(
          doc(db, TABLES.LISTINGS, listingId)
        )
      )
    );
    //@ts-ignore b/c there arent nulls but it wants to say there are
    return loadedSavedRoles.filter((listing) => listing !== null);
  };

  loadUserProfile = async (uid: string): Promise<UserData> => {
    // like loadFullUser without wage or current business
    var profile: UserData = { uid: uid };

    profile = {
      ...profile,
      experiences: await this.loadUserExperiences(uid),
      education: await this.loadUserEducation(uid),
      references: await this.loadUserReferences(uid),
      noExperience: await this.loadUserNoExperience(uid),
    };
    console.log("loading user profile", profile);

    return profile;
  };

  loadFullUser = async (uid: string): Promise<UserData> => {
    const profile = await this.loadUserProfile(uid);

    return {
      ...profile,
      wage: await this.loadUserWage(uid),
      languages: await this.loadUserLanguages(uid),
      availability: await this.loadUserAvailability(uid),
      start: await this.loadUserStart(uid),
    };
  };

  checkUserExists = async (id: string) => {
    const user = await UserService.getOne(id);
    if (user) return true;
    else return false;
  };

  /**
   * CREATION ROUTES (user need not be signed in, ID is provided)
   */
  assertUserDoesNotExist = async (id: string) => {
    const user = await UserService.getOne(id);
    if (user) throw new DatabaseError("User already exists");
  };

  async createUserExperience(
    experience: Experiences,
    uid: string,
    id?: string
  ): Promise<void> {
    const { businessRef, ...data } = experience;
    ensureBusinessExists(businessRef.id);

    const userRef: DocumentReference = doc(db, TABLES.USERS, uid);
    const experienceRef: DocumentReference | null =
      await this.createUserFieldIfExists<Experiences>(
        ExperiencesService,
        userRef,
        {
          ...data,
          businessRef: businessRef,
        },
        id
      );
    const experiences = (await UserService.getOne(uid))?.experiences || [];
    if (
      experienceRef &&
      !experiences.some((exp) => experienceRef.id == exp.id)
    ) {
      UserService.update(uid, { experiences: [...experiences, experienceRef] });
    }
  }

  async createUserLanguages(languages: string[], uid: string): Promise<void> {
    UserService.update(uid, { languages: languages });
  }

  async createUserStart(start: Timestamp, uid: string): Promise<void> {
    UserService.update(uid, { start: start });
  }

  async createUserEducation(
    education: Education,
    uid: string,
    id?: string
  ): Promise<void> {
    const userRef: DocumentReference = doc(db, TABLES.USERS, uid);
    const educationRef: DocumentReference | null =
      await this.createUserFieldIfExists<Education>(
        EducationService,
        userRef,
        education,
        id
      );
    UserService.update(uid, { education: educationRef });
  }

  async createUserReference(
    reference: References,
    uid: string,
    id?: string
  ): Promise<void> {
    // create reference and store in firebase
    const userRef: DocumentReference = doc(db, TABLES.USERS, uid);
    const referenceRef: DocumentReference | null =
      await this.createUserFieldIfExists<References>(
        ReferencesService,
        userRef,
        reference,
        id
      );
    const references = (await UserService.getOne(uid))?.references || [];
    if (referenceRef && !references.some((ref) => referenceRef.id == ref.id)) {
      UserService.update(uid, { references: [...references, referenceRef] });
    }
  }

  async createUserFieldIfExists<Type>(
    service: DatabaseService<Type>,
    userRef: DocumentReference,
    data?: Type,
    id?: string
  ): Promise<DocumentReference | null> {
    if (data && userRef) return service.create({ ...data, user: userRef }, id);
    return null;
  }

  async createUserFieldsIfExists<Type>(
    service: DatabaseService<Type>,
    userRef: DocumentReference,
    data?: Type[]
  ): Promise<void> {
    data?.forEach((datum) =>
      this.createUserFieldIfExists<Type>(service, userRef, datum)
    );
  }

  createUser = async (uid: string): Promise<void> => {
    // Create empty user in Db
    if (await UserService.getOne(uid)) return;
    await UserService.create({}, uid);
  };

  createUserWithData = async (userData: UserData): Promise<void> => {
    this.assertUserDoesNotExist(userData.uid);
    const { uid, experiences, education, references, wage } = userData;
    // create empty user and load userref
    this.createUser(uid);

    // basic creation
    var userInfo = {};
    if (wage) {
      if (wage.business) await ensureBusinessExists(wage.business.id);
      const wageRef = await WageService.create({
        ...wage,
        userRef: doc(db, TABLES.USERS, uid),
      });
      userInfo = { ...userInfo, wage: wageRef };
    }
    if (userData.textOptIn) userInfo = { ...userInfo, textOptIn: true };
    UserService.update(uid, userInfo);

    // additional info
    // experiences
    experiences?.forEach((exp) => this.createUserExperience(exp, uid));
    // education
    if (education) this.createUserEducation(education, uid);
    // langauges
    if (userData.languages) this.createUserLanguages(userData.languages, uid);
    // references
    references?.forEach((ref) => this.createUserReference(ref, uid));
    // toadd: availability, start
  };

  deleteUserExperience = async (uid: string, eid: string): Promise<void> => {
    ExperiencesService.remove(eid);
    const experiences = (await UserService.getOne(uid))?.experiences;
    UserService.update(uid, {
      experiences: experiences?.filter((exp) => exp.id != eid) || [],
    });
  };

  deleteUserEducation = async (uid: string, eid: string): Promise<void> => {
    EducationService.remove(eid);
    UserService.update(uid, { education: null });
  };

  deleteUserReference = async (uid: string, rid: string): Promise<void> => {
    ReferencesService.remove(rid);
    const references = (await UserService.getOne(uid))?.references;
    UserService.update(uid, {
      references: references?.filter((ref) => ref.id != rid) || [],
    });
  };

  removeSavedListing = async (uid: string, lid: string): Promise<void> => {
    const savedListings = (await UserService.getOne(uid))?.savedListings;
    UserService.update(uid, {
      savedListings:
        savedListings?.filter((listing) => listing.id != lid) || [],
    });
  };

  addSavedListing = async (uid: string, lid: string): Promise<void> => {
    const savedListings = (await UserService.getOne(uid))?.savedListings || [];
    const listingRef = doc(db, TABLES.LISTINGS, lid);
    UserService.update(uid, {
      savedListings: [...savedListings, listingRef],
    });
  };

  addUserApplication = async (
    uid: string,
    appData: Applications
  ): Promise<void> => {
    const { id, ...data } = appData;
    const appRef = await ApplicationsService.create(data, id);
    const applications = (await UserService.getOne(uid))?.applications || [];
    UserService.update(uid, {
      applications: [...applications, appRef],
    });
  };

  loadUserApplications = async (uid: string): Promise<Applications[]> => {
    const appIds = (await UserService.getOne(uid))?.applications || [];
    const loadedApps = await Promise.all(
      appIds.map(async (app) => {
        const appData = await ApplicationsService.getOne(app.id);
        return appData;
      })
    );
    return loadedApps.filter((app) => app != null) as Applications[];
  };

  removeCurrentUser = async (): Promise<void> => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const phone = currentUser.phoneNumber;
    if (!phone) return;
    deleteUser(currentUser).then(async () => {
      // get all user info and delete
      const user = await UserService.getOne(phone);
      if (user) {
        if (user.education) EducationService.remove(user.education.id);
        if (user.experiences)
          user.experiences.forEach((exp) => ExperiencesService.remove(exp.id));
        if (user.references)
          user.references.forEach((ref) => ReferencesService.remove(ref.id));
        if (user.applications)
          user.applications.forEach((app) =>
            ApplicationsService.remove(app.id)
          );
      }
      // remove existing account
      await UserService.remove(phone);

      // delete interviews and applications
      const userRef = doc(db, TABLES.USERS, phone);
      const apps = await ApplicationsService.getMany(
        where("userRef", "==", userRef)
      );
      const interviews = await InterviewsService.getMany(
        where("userRef", "==", userRef)
      );

      apps.forEach((app) => {
        if (app.id) ApplicationsService.remove(app.id);
      });
      interviews.forEach((interview) => {
        if (interview.id) InterviewsService.remove(interview.id);
      });
    });
  };

  setUserAvailability = async (
    newAvailability: string[],
    uid: string
  ): Promise<void> => {
    await UserService.update(uid, {
      availability: newAvailability,
    });
  };

  loadUserAvailability = async (uid: string): Promise<string[]> => {
    const user = await UserService.getOne(uid);
    return user?.availability || [];
  };

  loadUserTextOptIn = async (uid: string): Promise<boolean> => {
    const user = await UserService.getOne(uid);
    return user?.textOptIn || false;
  };

  setUserTextOptIn = async (uid: string, optIn: boolean): Promise<void> => {
    await UserService.update(uid, {
      textOptIn: optIn,
    });
  };
}

export const FullUserService = new UserDbService();
