import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import ExperienceBuilder from "../Components/Profile/ExperienceBuilder";
import EducationBuilder from "../Components/Profile/EducationBuilder";
import ReferenceBuilder from "../Components/Profile/ReferenceBuilder";
import AvailabilityBuilder from "../Components/Profile/AvailabilityBuilder";

import ExperienceSection from "./ExperienceSection";
import EducationSection from "./EducationSection";
import ReferenceSection from "./ReferenceSection";
import LanguagesSection from "./LanguagesSection";
import AvailabilitySection from "./AvailabilitySection";

import { useTranslation } from "react-i18next";
import { NewReleasesRounded } from "@mui/icons-material";

import { useAuth } from "../Context/AuthContext";
import { FullUserService } from "../Services/Db/user";
import { UserData } from "../Services/Db/types";
import WageSurveyContext from "../Marketing/WageComparison/WageSurveyContext";

interface CreateProfileProps {
  reqs?: string[];
}

const CreateProfile: React.FC<CreateProfileProps> = ({ reqs }) => {
  const appBarContext = useContext(AppBarContext);
  const surveyContext = useContext(WageSurveyContext);
  const { t } = useTranslation(["create_profile"]);

  const [experienceModalOpen, setExperienceModalOpen] =
    useState<boolean>(false);
  const [educationModalOpen, setEducationModalOpen] = useState<boolean>(false);
  const [referenceModalOpen, setReferenceModalOpen] = useState<boolean>(false);
  const [availabilityModalOpen, setAvailabilityModalOpen] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { user } = useAuth();
  const [userData, setUserData] = useState<UserData>();

  useEffect(() => {
    if (user) {
      const loadUserData = async () => {
        setUserData(await FullUserService.loadFullUser(user.phoneNumber!));
      };
      loadUserData();
      console.log(userData);
    }
  }, [
    user,
    experienceModalOpen,
    referenceModalOpen,
    educationModalOpen,
    availabilityModalOpen,
  ]);

  return (
    <React.Fragment>
      <Stack spacing={4}>
        {reqs === undefined || reqs.includes("experiences") ? (
          <ExperienceSection
            setModalOpen={setExperienceModalOpen}
            experiences={
              userData?.experiences || surveyContext?.experiences || []
            }
            setSelectedId={setSelectedId}
            none={userData?.noExperience}
          />
        ) : null}
        {reqs === undefined || reqs.includes("education") ? (
          <EducationSection
            setModalOpen={setEducationModalOpen}
            education={
              userData?.education || surveyContext?.education || undefined
            }
            setSelectedId={setSelectedId}
          />
        ) : null}
        {reqs === undefined || reqs.includes("references") ? (
          <ReferenceSection
            setModalOpen={setReferenceModalOpen}
            references={userData?.references}
            setSelectedId={setSelectedId}
          />
        ) : null}
        {reqs === undefined || reqs.includes("languages") ? (
          <LanguagesSection />
        ) : null}
        {reqs === undefined || reqs.includes("availability") ? (
          <AvailabilitySection setModalOpen={setAvailabilityModalOpen} />
        ) : null}
      </Stack>
      {/* modals */}
      <ExperienceBuilder
        experienceID={selectedId}
        open={experienceModalOpen}
        setOpen={setExperienceModalOpen}
      />
      <EducationBuilder
        educationID={selectedId}
        open={educationModalOpen}
        setOpen={setEducationModalOpen}
      />
      <AvailabilityBuilder
        open={availabilityModalOpen}
        setOpen={setAvailabilityModalOpen}
      />
      <ReferenceBuilder
        referenceID={selectedId}
        open={referenceModalOpen}
        setOpen={setReferenceModalOpen}
      />
    </React.Fragment>
  );
};

const CreateProfilePage = () => {
  const { t } = useTranslation(["create_profile"]);

  const appBarContext = useContext(AppBarContext);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.USER);
  }, [appBarContext]);

  return (
    <PageSpacer>
      <Stack spacing={2}>
        <Typography level="sectionHeader">
          {t("header", { ns: ["create_profile"] })}
          <Alert color="neutral" startDecorator={<NewReleasesRounded />}>
            {t("profile_description", { ns: ["create_profile"] })}
          </Alert>
        </Typography>
        <CreateProfile />
      </Stack>
    </PageSpacer>
  );
};

export { CreateProfile, CreateProfilePage };
