import { APP_ROUTES } from "../../Utils/Constants";
import { SendRounded, WysiwygRounded } from "@mui/icons-material";
import { Box, Button, Card, Divider, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import WageChip from "../Common/WageChip";
import ExampleRoleApply from "./ExampleRoleApply";
import { Fragment, useState } from "react";

const ExampleRole = ({
  displayingFull,
  businessName,
}: {
  displayingFull?: boolean;
  businessName: string;
}) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <Card>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              cursor: "pointer",
            }}
          >
            <WageChip wage={15} tips={5} />
            <Stack sx={{ pl: 2 }}>
              <Typography level="h6" sx={{ fontWeight: "bold" }}>
                {t("example_role")}
              </Typography>
              {displayingFull && (
                <Typography level="body1" sx={{ mt: -0.5, pt: 0 }}>
                  {businessName}
                </Typography>
              )}
            </Stack>
          </Box>
        </Box>

        <Divider sx={{ mx: -2, opacity: 1, mt: 1, mb: 2 }} />
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "nowrap" },
            gap: { xs: 1, md: 2 },
            alignItems: "center",
          }}
        >
          {!displayingFull && (
            <Button
              color="primary"
              fullWidth
              startDecorator={<WysiwygRounded fontSize="small" />}
              onClick={() => {
                navigate(APP_ROUTES.EXAMPLE_ROLE, {
                  state: { businessName: businessName },
                });
              }}
            >
              {t("view_example", { ns: ["common"] })}
            </Button>
          )}

          <Button
            color="primary"
            fullWidth
            startDecorator={<SendRounded fontSize="small" />}
            onClick={() => setOpen(true)}
          >
            {t("apply_example", { ns: ["common"] })}
          </Button>
        </Box>
      </Card>
      <ExampleRoleApply
        open={open}
        setOpen={setOpen}
        businessName={businessName}
      />
    </Fragment>
  );
};

export default ExampleRole;
