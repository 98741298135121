import { APP_ROUTES } from "../../Utils/Constants";
import {
  Route,
  redirect,
  RouteProps,
  Navigate,
  useLocation,
} from "react-router-dom";
import { User } from "firebase/auth";
import { auth } from "../../firebase";
import { useAuth, useBusinessAuth } from "../../Context/AuthContext";

interface RequireAuthProps {
  children: JSX.Element;
  redirectTo?: string;
  business?: boolean;
  depth?: number;
}

const RequireAuth: React.FC<RequireAuthProps> = ({
  children,
  redirectTo = APP_ROUTES.HOME,
  business = false,
}): JSX.Element => {
  const regUser = useAuth();
  const businessUser = useBusinessAuth();
  const location = useLocation();
  if (business) {
    if (businessUser === undefined || businessUser.loading) {
      return <></>;
    }
    if (businessUser.business) {
      return children;
    }
  } else {
    if (regUser === undefined || regUser.loading) {
      return <></>;
    }
    if (regUser.user) {
      return children;
    }
  }

  return (
    <Navigate to={redirectTo} state={{ path: location.pathname }} replace />
  );
};

export default RequireAuth;
