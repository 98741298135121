import React from "react";
import { Button, Alert, Stack, IconButton, Grid } from "@mui/joy";
import { APP_ROUTES } from "../Utils/Constants";
import { useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AppBarContext from "../Context/AppBarContext";
import { useTranslation } from "react-i18next";

const closeAlert = (context: any, alert: string) => {
  context?.setShowAlerts((alerts: string[]) =>
    alerts.filter((a: string) => a !== alert)
  );
};

const heightOfAlertsAbove = (context: any, myAlert: string) => {
  if (context) {
    //@ts-ignore
    const alertsAbove = context.showAlerts.slice(
      0,
      //@ts-ignore
      context.showAlerts.indexOf(myAlert)
    );

    //@ts-ignore
    return alertsAbove.reduce((acc, alert) => {
      const a = document.getElementById("#" + alert) || null;
      if (a) {
        return acc + a.offsetHeight;
      }
      return acc;
    }, 0);
  }
  return 0;
};

const WageAlert = () => {
  const navigate = useNavigate();
  const context = React.useContext(AppBarContext);
  const { t } = useTranslation(["header"]);

  return (
    <Alert
      variant="solid"
      id="#addWage"
      sx={{
        position: "fixed",
        top: heightOfAlertsAbove(context, "addWage"),
        left: 0,
        zIndex: 1000,
        width: "100%",
        borderRadius: 0,
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        px: "5vw",
        py: 1,
      }}
      endDecorator={
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            color="neutral"
            size="sm"
            sx={{ borderRadius: 20, color: "common.white" }}
            onClick={() => {
              navigate(APP_ROUTES.NEW_USER_FLOW);
            }}
          >
            {t("add_my_wage")}
          </Button>
          <IconButton onClick={() => closeAlert(context, "addWage")}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
      }
    >
      {t("help_others")}
    </Alert>
  );
};

const BusinessAlert = () => {
  const navigate = useNavigate();
  const context = React.useContext(AppBarContext);
  const { t } = useTranslation(["header"]);

  return (
    <Alert
      id="#business"
      // color="neutral"
      sx={{
        bgcolor: "common.white",
        position: "fixed",
        borderRadius: 0,
        fontWeight: "600",
        px: "5vw",
        zIndex: 1000,
        width: "100%",
        top: heightOfAlertsAbove(context, "business"),
        left: 0,
        py: 1,
      }}
      endDecorator={
        <Stack direction="row" spacing={2}>
          <Button
            variant="solid"
            color="primary"
            size="sm"
            sx={{ borderRadius: 20 }}
            onClick={() => {
              navigate(APP_ROUTES.NEW_BUSINESS);
            }}
          >
            {t("create_business")}
          </Button>
          <IconButton onClick={() => closeAlert(context, "business")}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
      }
    >
      {t("create_business_message")}
    </Alert>
  );
};

export { WageAlert, BusinessAlert };
