// interviews should show upcoming and availability...

import PageSpacer from "../../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  Tooltip,
  ModalDialog,
  Modal,
  LinearProgress,
} from "@mui/joy";
import { Skeleton } from "@mui/material";
import React, { useEffect, useContext, useState, useRef } from "react";
import AppBarContext from "../../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../../Utils/Constants";
import InterviewAvailability from "./InterviewAvailability";
import UpcomingInterview from "../../Components/Common/UpcomingInterview";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TabsPage from "../../Components/Inputs/TabsPage";
import {
  Applications,
  Interviews,
  Item,
  InterviewLocation,
} from "../../Services/Db/types";
import { FullBusinessService } from "../../Services/Db/business";
import { useBusinessAuth } from "../../Context/AuthContext";
import dayjs from "dayjs";
import LocationDropdown from "../../Components/Inputs/LocationDropdown";
import { DocumentReference } from "firebase/firestore";
import InterviewContext from "./InterviewContext";
import useInterviewLogicFirebase from "./useInterviewLogicFirebase";

const BusinessInterviews = () => {
  const appBarContext = useContext(AppBarContext);
  const interviewContext = useContext(InterviewContext);
  const { t, i18n } = useTranslation(["create_profile"]);
  const { business } = useBusinessAuth();
  const { loadInterviews } = useInterviewLogicFirebase();

  const location = useLocation();

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.BUSINESS);
  }, []);

  const [index, setIndex] = useState(location.state ? location.state.index : 0);

  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [locations, setLocations] = useState<DocumentReference[]>([]);

  //FOR CONTEXT PROVIDER

  // Place ID
  const [placeId, setPlaceId] = useState("");

  // Upcoming interviews
  const [upcomingInterviews, setUpcomingInterviews] = useState<
    (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  >([]);
  const [loadedUpcomingInterviews, setLoadedUpcomingInterviews] =
    useState(false);

  // Availability
  const [availabilityDiscrepancy, setAvailabilityDiscrepancy] = useState(false);
  const [loadedAvailability, setLoadedAvailability] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  // Interview prefs
  const [loadingPrefs, setLoadingPrefs] = useState(true);
  const [canSavePrefs, setCanSavePrefs] = useState(false);
  const [prefDiscrepancy, setPrefDiscrepancy] = useState(false);

  // Interview time details
  const [interviewDuration, setInterviewDuration] = useState<number>(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [dayOffset, setDayOffset] = useState(0);
  const [changeType, setChangeType] = useState<"adding" | "removing">("adding");
  const [changed, setChanged] = useState(false);

  // Other interview details
  const [interviewTypes, setInterviewTypes] = useState<InterviewLocation[]>([]);
  const [videoLink, setVideoLink] = useState("");

  const [reminderMsg, setReminderMsg] = useState("");
  const [recurring, setRecurring] = useState(false);
  const [collisions, setCollisions] = useState<
    (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  >([]);
  const [collisionsOpen, setCollisionsOpen] = useState(false);
  const [recurringAvailability, setRecurringAvailability] = useState<Date[]>(
    []
  );
  const [removeRecurringAvailability, setRemoveRecurringAvailability] =
    useState<Date[]>([]);
  const [oneTimeAvailability, setOneTimeAvailability] = useState<Date[]>([]);
  const [holding, setHolding] = useState<Date[]>([]);

  useEffect(() => {
    if (business) {
      console.log("ESE EFFECT");
      const loadProfile = async (bid: string) => {
        const profile = await FullBusinessService.getBusinessProfile(bid);
        return profile;
      };
      loadProfile(business.email!).then((profile) => {
        if (profile) {
          console.log("LOCATION LOADED", locations);
          setLocations(profile.locations ?? []);
          if (profile.locations?.length === 1) {
            console.log("SETTING LOCATION", profile.locations[0].id);
            setSelectedLocation(profile.locations[0].id);
            setPlaceId(locations[0].id);
          } else {
            console.log("SETTING MULTIPLE LOCATIONs", profile.locations);
            setPlaceId(selectedLocation ?? "");
          }
        }
      });
      const loadInterviewsAsync = async (bid: string) => {
        await loadInterviews(business.email!).then((interviews) => {
          if (interviews) {
            console.log("INTERVIEWS LOADED", interviews);
            setUpcomingInterviews(interviews);
          }
          setLoadedUpcomingInterviews(true);
        });
      };
      loadInterviewsAsync(business.email!);
    }
  }, [business, selectedLocation]);

  const [upcomingInterviewsFiltered, setUpcomingInterviewsFiltered] = useState<
    (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  >([]);

  useEffect(() => {
    setUpcomingInterviewsFiltered(
      upcomingInterviews.filter(
        (interview) =>
          selectedLocation === null ||
          interview.businessRef.id === selectedLocation
      )
    );
    console.log("UPCOMING INTERVIEWS FILTERED", upcomingInterviewsFiltered);
  }, [selectedLocation, upcomingInterviews]);

  return (
    <InterviewContext.Provider
      value={{
        //placeId
        placeId: placeId,
        setPlaceId: setPlaceId,
        upcomingInterviews: upcomingInterviews,
        setUpcomingInterviews: setUpcomingInterviews,
        loadedUpcomingInterviews: loadedUpcomingInterviews,
        setLoadedUpcomingInterviews: setLoadedUpcomingInterviews,
        availabilityDiscrepancy: availabilityDiscrepancy,
        setAvailabilityDiscrepancy: setAvailabilityDiscrepancy,
        loadingAvailability: loadedAvailability,
        setLoadedAvailability: setLoadedAvailability,
        savingInProgress: savingInProgress,
        setSavingInProgress: setSavingInProgress,
        loadingPrefs: loadingPrefs,
        setLoadingPrefs: setLoadingPrefs,
        canSavePrefs: canSavePrefs,
        setCanSavePrefs: setCanSavePrefs,
        prefDiscrepancy: prefDiscrepancy,
        setPrefDiscrepancy: setPrefDiscrepancy,
        interviewDuration: interviewDuration,
        setInterviewDuration: setInterviewDuration,
        startTime: startTime,
        setStartTime: setStartTime,
        endTime: endTime,
        setEndTime: setEndTime,
        dayOffset: dayOffset,
        setDayOffset: setDayOffset,
        changeType: changeType,
        setChangeType: setChangeType,
        changed: changed,
        setChanged: setChanged,
        interviewTypes: interviewTypes,
        setInterviewTypes: setInterviewTypes,
        videoLink: videoLink,
        setVideoLink: setVideoLink,
        reminderMsg: reminderMsg,
        setReminderMsg: setReminderMsg,
        recurring: recurring,
        setRecurring: setRecurring,
        collisions: collisions,
        setCollisions: setCollisions,
        collisionsOpen: collisionsOpen,
        setCollisionsOpen: setCollisionsOpen,
        recurringAvailability: recurringAvailability,
        setRecurringAvailability: setRecurringAvailability,
        removeRecurringAvailability: removeRecurringAvailability,
        setRemoveRecurringAvailability: setRemoveRecurringAvailability,
        oneTimeAvailability: oneTimeAvailability,
        setOneTimeAvailability: setOneTimeAvailability,
        holding: holding,
        setHolding: setHolding,
      }}
    >
      <PageSpacer>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Typography level="sectionHeader">
              {t("interviews", { ns: "interview" })}
            </Typography>
          </Box>

          {locations.length > 1 && (
            <LocationDropdown
              locations={locations}
              setSelected={setSelectedLocation}
            />
          )}

          <Box sx={{ ml: -1, mt: 1, mb: 2 }}>
            <TabsPage
              index={index}
              updateIndex={setIndex}
              options={[
                {
                  title: t("upcoming", { ns: "interview" }),
                  value: interviewContext?.upcomingInterviews.filter(
                    (interview) =>
                      selectedLocation === null ||
                      interview.businessRef.id === selectedLocation
                  ).length,
                },
                { title: t("availability", { ns: "interview" }) },
              ]}
            />
          </Box>

          {index === 0 ? (
            <React.Fragment>
              <Stack spacing={2}>
                {!loadedUpcomingInterviews && (
                  <Stack spacing={2}>
                    <Skeleton variant="rounded" height={300} />
                    <Skeleton variant="rounded" height={300} />
                  </Stack>
                )}
                {upcomingInterviewsFiltered.length === 0 && (
                  <Card>
                    <Typography level="body1">
                      {t("no_interviews", { ns: "interview" })}
                    </Typography>
                  </Card>
                )}
                {upcomingInterviewsFiltered.map((interview) => (
                  <Box key={interview.id}>
                    <UpcomingInterview
                      appId={interview.id!}
                      phone={interview.userRef.id}
                      interviewId={interview.interview?.id}
                      mode={interview.interview?.mode}
                      role={interview.role}
                      name={interview.name}
                      business={interview.business}
                      scheduled_at={
                        interview.interview?.scheduledAt
                          ? dayjs
                              .unix(interview.interview?.scheduledAt.seconds)
                              .format("h:mm A, ddd MMM D")
                          : undefined
                      }
                      status={interview.interview?.status}
                      applied_time={dayjs
                        .unix(interview.submitted.seconds)
                        .format("MMM D, YYYY")}
                    />
                  </Box>
                ))}
              </Stack>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Card>
                <Typography level="cardHeader">
                  {t("select_interview_availability_business", {
                    ns: "interview",
                  })}
                </Typography>
                <Divider sx={DIVIDER_HEADER_SX} />
                <InterviewAvailability />
              </Card>
            </React.Fragment>
          )}
        </Box>
      </PageSpacer>
      <Modal open={savingInProgress || false}>
        <ModalDialog>
          <LinearProgress />
          <Typography sx={{ fontWeight: "bold" }}>Saving</Typography>
          <Typography level="body2">Please don't close this page.</Typography>
        </ModalDialog>
      </Modal>
    </InterviewContext.Provider>
  );
};

export default BusinessInterviews;
