import {
  ArrowForwardRounded,
  ArrowRightRounded,
  GavelRounded,
  HailRounded,
  Info,
  KeyboardArrowRightRounded,
  NearMeRounded,
  PaidRounded,
  TextsmsRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ListItem,
  List,
  Button,
  Alert,
  FormControl,
  Input,
  Chip,
  Checkbox,
  Card,
  IconButton,
} from "@mui/joy";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneSignIn from "src/Components/Authentication/PhoneSignIn";
import GenericModal from "src/Components/Common/GenericModal";
import { useAuth } from "src/Context/AuthContext";
import { CreateProfile } from "src/UserProfile/CreateProfile";
import WageSurveyContext from "./WageSurveyContext";
import useKeyToDict from "src/Utils/useKeyToDict";
import { ListingsService } from "src/Services/Db/service";
import { doc, limit, orderBy, where } from "firebase/firestore";
import { Listings } from "../../Services/Db/types";
import RoleItem from "../../Components/Apply/RoleItem";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/Utils/Constants";
import { FullUserService } from "src/Services/Db/user";
import { User } from "firebase/auth";
import { TABLES } from "src/Services/Db/consts";
import { db } from "src/firebase";
import ShareSheet from "src/Marketing/ShareSheet";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";
import useText from "src/Utils/useText";

const dist: { [key: string]: number[] } = {
  "medical:nursing_assistant": [12.97, 13.94, 15.76, 17.97, 17.98],
  "medical:medical_assistant": [14.49, 17.59, 18.0, 18.2, 22.22],
  "restaurant:manager": [12.59, 13.75, 18.65, 22.35, 27.31],
  "restaurant:supervisor": [12.59, 13.75, 18.65, 22.35, 27.31],
  "cafe:general_manager": [12.59, 13.75, 18.65, 22.35, 27.31],
  "cafe:assistant_manager": [12.59, 13.75, 18.65, 22.35, 27.31],
  "bar:shift_lead": [12.59, 13.75, 18.65, 22.35, 27.31],
  "bar:general_manager": [12.59, 13.75, 18.65, 22.35, 27.31],
  "bar:assistant_manager": [12.59, 13.75, 18.65, 22.35, 27.31],
  "cafe:shift_lead": [12.59, 13.75, 18.65, 22.35, 27.31],
  "restaurant:shift_lead": [12.59, 13.75, 18.65, 22.35, 27.31],
  "restaurant:line_cook": [10.6, 13.27, 14.39, 15.79, 17.77],
  "cafe:line_cook": [10.6, 13.27, 14.39, 15.79, 17.77],
  "bar:line_cook": [10.6, 13.27, 14.39, 15.79, 17.77],
  "restaurant:back_of_house": [10.68, 11.49, 13.74, 14.67, 18.41],
  "bar:back_of_house": [10.68, 11.49, 13.74, 14.67, 18.41],
  "cafe:back_of_house": [10.68, 11.49, 13.74, 14.67, 18.41],
  "restaurant:bartender": [8.59, 8.8, 13.55, 15.76, 23.41],
  "bar:bartender": [8.59, 8.8, 13.55, 15.76, 23.41],
  "bar:front_of_house": [8.85, 10.05, 12.06, 13.73, 15.16],
  "cafe:front_of_house": [8.85, 10.05, 12.06, 13.73, 15.16],
  "restaurant:front_of_house": [8.85, 10.05, 12.06, 13.73, 15.16],
  "restaurant:waiter": [8.77, 8.82, 11.46, 11.75, 17.18],
  "restaurant:server": [8.77, 8.82, 11.46, 11.75, 17.18],
  "cafe:server": [8.77, 8.82, 11.46, 11.75, 17.18],
  "bar:server": [8.77, 8.82, 11.46, 11.75, 17.18],
  "restaurant:cleaning": [8.97, 9.88, 12.05, 13.73, 14.63],
  "bar:dishwasher": [8.97, 9.88, 12.05, 13.73, 14.63],
  "bar:cleaning": [8.97, 9.88, 12.05, 13.73, 14.63],
  "restaurant:dishwasher": [8.97, 9.88, 12.05, 13.73, 14.63],
  "cafe:cleaning": [8.97, 9.88, 12.05, 13.73, 14.63],
  "cafe:dishwasher": [8.97, 9.88, 12.05, 13.73, 14.63],
  "restaurant:host": [8.62, 9.54, 11.25, 13.4, 14.33],
  "cafe:barista": [8.62, 9.54, 11.25, 13.4, 14.33],
  "restaurant:barista": [8.62, 9.54, 11.25, 13.4, 14.33],
  "restaurant:barback": [9.87, 11.78, 14.12, 17.34, 18.62],
  "corner_store:food_service": [9.87, 11.78, 14.12, 17.34, 18.62],
  "bar:barback": [9.87, 11.78, 14.12, 17.34, 18.62],
  "clothing_store:tailor": [9.87, 11.78, 14.12, 17.34, 18.62],
  "medical:animal_caretaker": [8.98, 10.69, 13.39, 14.37, 18.11],
  "retail:manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "food_store:store_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "clothing_store:general_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "clothing_store:assistant_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "clothing_store:store_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "food_store:department_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "food_store:produce_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "specialty_store:shift_lead": [14.46, 17.67, 23.34, 27.59, 33.95],
  "specialty_store:store_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "specialty_store:general_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "specialty_store:assistant_manager": [14.46, 17.67, 23.34, 27.59, 33.95],
  "clothing_store:shift_lead": [14.46, 17.67, 23.34, 27.59, 33.95],
  "retail:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "clothing_store:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "specialty_store:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "restaurant:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "food_store:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "cafe:cashier": [8.71, 9.13, 11.68, 13.32, 14.45],
  "retail:sales_associate": [9.71, 11.08, 14.46, 14.64, 20.35],
  "clothing_store:sales_associate": [9.71, 11.08, 14.46, 14.64, 20.35],
  "retail:customer_service": [11.32, 14.4, 19.49, 22.85, 28.61],
  "clothing_store:customer_service": [11.32, 14.4, 19.49, 22.85, 28.61],
  "auto:receptionist": [10.92, 13.73, 15.75, 17.85, 21.96],
  "medical:receptionist": [10.92, 13.73, 15.75, 17.85, 21.96],
  "construction:carpenter": [14.49, 16.98, 20.65, 23.5, 28.73],
  "construction:electrician": [17.8, 22.56, 25.5, 29.02, 36.11],
  "construction:painter": [12.98, 14.74, 18.75, 22.5, 27.02],
  "construction:plumber": [16.56, 19.68, 24.82, 29.02, 32.05],
  "construction:laborer": [18.33, 18.97, 22.8, 23.95, 29.42],
  "construction:handyman": [18.33, 18.97, 22.8, 23.95, 29.42],
  "facilities:landscaper": [18.33, 18.97, 22.8, 23.95, 29.42],
  "facilities:maintenance": [18.33, 18.97, 22.8, 23.95, 29.42],
  "auto:mechanic": [11.35, 14.46, 23.74, 29.22, 46.62],
  "auto:technician": [11.35, 14.46, 23.74, 29.22, 46.62],
  "auto:parts_specialist": [11.35, 14.46, 23.74, 29.22, 46.62],
  "auto:service_advisor": [11.35, 14.46, 23.74, 29.22, 46.62],
  "auto:store_manager": [18.19, 22.51, 28.27, 33.3, 40.25],
  "cafe:baker": [9.79, 11.42, 14.53, 17.92, 18.48],
  "restaurant:baker": [9.79, 11.42, 14.53, 17.92, 18.48],
  "food_store:butcher": [11.44, 16.64, 18.58, 20.86, 23.66],
  "auto:assistant": [18.19, 22.51, 28.27, 33.3, 40.25],
  "auto:sales_associate": [18.19, 22.51, 28.27, 33.3, 40.25],
  "restaurant:delivery_driver": [8.77, 8.88, 12.4, 14.4, 22.63],
  "administration:delivery_driver": [15.08, 18.53, 23.39, 28.52, 30.97],
  "administration:inventory_clerk": [11.73, 14.44, 16.09, 17.59, 21.03],
  "clothing_store:stockroom": [11.73, 14.44, 16.09, 17.59, 21.03],
  "corner_store:stock_clerk": [11.73, 14.44, 16.09, 17.59, 21.03],
  "specialty_store:cleaning": [10.84, 13.43, 15.64, 18.04, 21.85],
  "clothing_store:cleaning": [10.84, 13.43, 15.64, 18.04, 21.85],
  "corner_store:cleaning": [10.84, 13.43, 15.64, 18.04, 21.85],
  "food_store:cleaning": [10.84, 13.43, 15.64, 18.04, 21.85],
  "specialty_store:loss_prevention": [11.36, 14.15, 23.13, 38.14, 38.14],
  "clothing_store:loss_prevention": [11.36, 14.15, 23.13, 38.14, 38.14],
  "facilities:pest_control": [13.94, 14.7, 19.07, 21.82, 29.7],
  "other:florist": [9.54, 13.87, 15.35, 18.01, 21.96],
  "medical:childcare": [14.47, 17.53, 21.24, 23.86, 28.82],
  "construction:hvac_installer": [17.52, 18.96, 24.39, 28.73, 30.49],
  "facilities:janitor": [10.84, 13.43, 14.04, 18.04, 21.85],
  "facilities:security_guard": [11.36, 14.15, 23.13, 38.14, 38.14],
  "administration:supervisor": [18.21, 23.08, 31.65, 36.98, 47.15],
  "administration:receptionist": [10.92, 13.73, 15.75, 17.85, 21.96],
};

function calc_percentile(pct: number, ind: number) {
  if (ind == 0) return 0.1 * pct;
  if (ind == 1) return 0.1 + 0.15 * pct;
  if (ind == 2) return 0.25 + 0.25 * pct;
  if (ind == 3) return 0.5 + 0.25 * pct;
  if (ind == 4) return 0.75 + 0.15 * pct;
  if (ind == 5) return Math.min(0.95, 0.9 + 0.1 * pct);
  else return 1;
}

export function calc_percentile_from_key(key: string, wage: number) {
  const values = dist[key];
  if (!values) {
    return 0;
  }
  var ind = 0;
  // iterate over values
  for (const val of values) {
    if (val < wage) {
      ind += 1;
    } else {
      break;
    }
  }

  const dist_vals = [...values, values[0] + values[4]];
  var floor;
  if (ind == 0) {
    floor = 0;
  } else {
    floor = dist_vals[ind - 1];
  }
  const ceil = dist_vals[ind];
  const pct = (wage - floor) / (ceil - floor);
  return calc_percentile(pct, ind);
}

export function calc_diff_from_key(key: string, wage: number) {
  const values = dist[key];
  if (!values) {
    return 0;
  }

  return wage - values[2];
}

export function calc_diff_10_from_key(key: string, wage: number) {
  const values = dist[key];
  if (!values) {
    return 0;
  }

  return values[4] - wage;
}

const Result = () => {
  const { t } = useTranslation("wage_comparison");

  const context = useContext(WageSurveyContext);
  const navigate = useNavigate();
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const [signedIn, setSignedIn] = useState(false);
  const [textOptIn, setTextOptIn] = useState(true);
  const [percentile, setPercentile] = useState<number>();
  const [tenthPercentile, setTenthPercentile] = useState<number>();
  const [recommendations, setRecommendations] = useState<Listings[]>([]);
  const [haveRolesInIndustry, setHaveRolesInIndustry] = useState<boolean>();
  const [otherEntered, setOtherEntered] = useState<boolean>(false);

  // from the get-go, find 3 jobs that pay more than the user's wage
  useEffect(() => {
    if (context && context.job) {
      ListingsService.getMany(
        where("status", "==", "active"),
        where("category", "==", context.job[0].category),
        where("role", "==", context.job[0].item)
      ).then((jobs) => {
        const jobsWithWages = jobs.filter(
          (job) => job.wage + job.tips > (context.wage ?? 0)
        );
        if (jobsWithWages.length === 0) {
          // get 3 highest paying jobs from listings serivce
          ListingsService.getMany(
            where("category", "==", context.job![0].category)
          ).then((newJobs) => {
            if (newJobs.length > 0) {
              setHaveRolesInIndustry(true);
              setRecommendations(
                newJobs
                  .filter((job) => job.status === "active")
                  .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
                  .slice(0, 3)
              );
            } else {
              ListingsService.getMany().then((newJobs) => {
                setRecommendations(
                  newJobs
                    .filter((job) => job.status === "active")
                    .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
                    .slice(0, 3)
                );
                setHaveRolesInIndustry(false);
              });
            }
          });
        } else {
          // sort jobswithwages by wage + tips and take the top 3
          setRecommendations(
            jobsWithWages
              .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
              .slice(0, 3)
          );
          setHaveRolesInIndustry(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (signedIn && context && context.job && context.wage) {
      if (context.job[0].category === "other_entered") {
        setOtherEntered(true);
        return;
      }
      // set earning percentile
      const pct = calc_diff_from_key(
        context.job[0].category + ":" + context.job[0].item,
        context.wage + (context.tips ?? 0)
      );
      setPercentile(Math.round(pct * 10) / 10);
      const pct10 = calc_diff_10_from_key(
        context.job[0].category + ":" + context.job[0].item,
        context.wage + (context.tips ?? 0)
      );
      setTenthPercentile(Math.round(pct10 * 10) / 10);
    }
  }, [signedIn]);

  useEffect(() => {
    if (
      percentile &&
      tenthPercentile &&
      context &&
      context.job &&
      context.wage &&
      user &&
      signedIn
    ) {
      const message = getWageMessage() || {
        main: "broken",
        sub: "broken",
      };

      sendText(
        t("result.text_intro") +
          message.main +
          (message.sub.length > 0 ? " " : ".") +
          message.sub +
          (message.sub.length > 0 ? "." : "") +
          t("result.text_outro"),
        user!.phoneNumber!
      );
    }
  }, [percentile, tenthPercentile]);

  const [user, setUser] = useState<User | undefined>(undefined);
  const handleTextOptIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!user) return;
    setTextOptIn(e.target.checked);
    FullUserService.setUserTextOptIn(user!.phoneNumber!, e.target.checked);
  };

  const { sendText } = useText();

  const getWageMessage = () => {
    if (
      !context ||
      !context.job ||
      !context.wage ||
      !percentile ||
      !tenthPercentile
    )
      return;

    console.log("percentile", percentile, tenthPercentile);

    const no_result = t("result.result_other", { role: context?.job![0].item });

    const percentileFormatted = Math.abs(percentile).toFixed(2);
    const moreOrLess = percentile > 0 ? t("result.more") : t("result.less");
    const role = getRoleNameFromRole(context.job[0]);
    const tenthPercentileFormatted = Math.abs(tenthPercentile).toFixed(2);
    const moreOrLess10 =
      tenthPercentile > 0 ? t("result.less") : t("result.more");

    const resultAvg = t("result.result_avg", {
      amount: percentileFormatted,
      more: moreOrLess,
      role: role,
    });

    const result = t("result.result", {
      amount: percentileFormatted,
      more: moreOrLess,
      role: role,
    });

    const result10 = t("result.result10", {
      amount: tenthPercentileFormatted,
      more: moreOrLess10,
      role: role,
    });

    const main =
      context?.job[0] && context?.job[0].item === "other_entered"
        ? no_result
        : percentile > 0
        ? result
        : resultAvg;

    const sub = percentile > 0 ? result10 : "";

    return {
      main,
      sub,
    };
  };

  return (
    <Stack spacing={2}>
      {otherEntered ? (
        <Stack
          sx={{
            borderRadius: 1,
            p: 5,
            bgcolor: "gray",
          }}
          spacing={2}
        >
          <Typography level="h1" sx={{ color: "common.white" }}>
            {t("result.result_other", { role: context?.job![0].item })}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            borderRadius: 1,
            p: 5,
            bgcolor:
              percentile && percentile > 0 ? "success.main" : "error.main",
          }}
          spacing={2}
        >
          {percentile !== undefined &&
            tenthPercentile !== undefined &&
            context?.job && (
              <Fragment>
                <Typography level="h1" sx={{ color: "common.white" }}>
                  {getWageMessage()?.main}
                </Typography>

                <Typography level="h3" sx={{ color: "common.white" }}>
                  {getWageMessage()?.sub}
                </Typography>
                <Box>
                  <Chip
                    startDecorator={<Info />}
                    color={percentile && percentile > 0 ? "success" : "warning"}
                    variant="soft"
                    size="sm"
                  >
                    Data per the Bureau of Labor Statitstics
                  </Chip>
                </Box>
              </Fragment>
            )}
        </Stack>
      )}

      <ShareSheet
        text={t("result.share_text")!}
        subject={t("result.share_subject")!}
        url={window.location.href}
      />

      <Card>
        <Alert
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 2,
            p: 2,
          }}
        >
          <Checkbox
            checked={textOptIn}
            onChange={() => {
              setTextOptIn(!textOptIn);
              handleTextOptIn({ target: { checked: !textOptIn } } as any);
            }}
          />
          <Stack>
            <Typography sx={{ fontWeight: "bold", color: "inherit" }}>
              {textOptIn
                ? t("result.text_header_in")
                : t("result.text_header_out")}
            </Typography>
            <Typography
              startDecorator={<PaidRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_higher_wage")}
            </Typography>
            <Typography
              startDecorator={<HailRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_good_role")}
            </Typography>
            <Typography
              startDecorator={<NearMeRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_in_your_area")}
            </Typography>
          </Stack>
        </Alert>
      </Card>

      {haveRolesInIndustry ? (
        <Typography level="h4">{t("result.new_roles")}</Typography>
      ) : (
        <Typography level="h4">{t("result.no_roles")}</Typography>
      )}
      {recommendations.map((job) => {
        return (
          <Card>
            <RoleItem
              listing={job}
              onClick={() => {
                navigate(
                  generatePath(APP_ROUTES.ROLE, { listingId: job.id ?? "" })
                );
                logEvent(analytics, "view_role", {
                  listing_id: job.id,
                  from: "survey",
                });
              }}
              displayName
            />
          </Card>
        );
      })}

      <GenericModal
        header={t("result.phone_header")}
        show={!signedIn}
        setShow={() => {}}
        bodyComponent={
          <Stack spacing={2}>
            <PhoneSignIn
              newUser
              onComplete={(u: User) => {
                FullUserService.createUserWithData({
                  uid: u.phoneNumber!,
                  wage: {
                    wage: context?.wage!,
                    tips: context?.tips,
                    business: context?.placeId
                      ? doc(db, TABLES.BUSINESSES, context.placeId)
                      : null,
                    role: context?.job![0],
                  },
                  experiences: context?.experiences,
                  education: context?.education,
                  textOptIn: textOptIn,
                }).then(() => {
                  localStorage.setItem("completedSurvey", "true");
                  logEvent(analytics, "sign_up", {
                    method: "survey",
                  });
                  setSignedIn(true);
                });

                setUser(u);
              }}
            />
          </Stack>
        }
        noClose
      />
    </Stack>
  );
};

export default Result;
