import React, { useState, useEffect, useRef } from "react";
import { Popper, Popover } from "@mui/material";
import { Card, Typography, Divider, Box, Chip, CardOverflow } from "@mui/joy";
import {
  DeleteForeverRounded,
  CloseRounded,
  CheckCircleRounded,
  CheckRounded,
} from "@mui/icons-material";
import { DIVIDER_HEADER_SX } from "../Utils/Constants";
import { MapContext } from "../Context/MapContext";
import { useTranslation } from "react-i18next";
import NewItemSelector from "../Components/Inputs/NewItemSelector";

interface FilterPopoverProps {
  titleKey: string;
  promptKey: string;
  itemKey: string;
  itemHeaderKey: string;

  popoverAnchorEl: HTMLElement | null;
  setPopoverAnchorEl: (wagePopoverAnchorEl: HTMLElement | null) => void;
  selected: any[] | null;
  setSelected: (val: any[] | null) => void;

  secondPromptKey?: string;
  secondDictKey?: string;
  secondHeaderKey?: string;
  selectedSecondItems?: any[] | null;
  setSelectedSecondItems?: (value: any[] | null) => void;
}

const FilterPopover: React.FC<FilterPopoverProps> = ({
  titleKey,
  promptKey,
  itemKey,
  itemHeaderKey,
  popoverAnchorEl,
  setPopoverAnchorEl,
  selected,
  setSelected,

  secondPromptKey,
  secondDictKey,
  secondHeaderKey,
  selectedSecondItems,
  setSelectedSecondItems,
}) => {
  const mapContext = React.useContext(MapContext);
  const { t } = useTranslation(["header", "common", "lists"]);

  const [changed, setChanged] = React.useState(false);
  const prevState = useRef(selected);
  const prevStateSecond = useRef(selectedSecondItems);

  useEffect(() => {
    if (
      prevState.current !== selected ||
      prevStateSecond.current !== selectedSecondItems
    ) {
      setChanged(true);
    }
  }, [selected, selectedSecondItems]);

  useEffect(() => {
    setChanged(false);
  }, [popoverAnchorEl]);

  return (
    <Popover
      open={Boolean(popoverAnchorEl)}
      anchorEl={popoverAnchorEl}
      onClose={() => setPopoverAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box>
        <Card variant="outlined" sx={{ width: { xs: "92vw", md: 400 }, pb: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h6" sx={{ fontWeight: "bold" }}>
              {t(titleKey)}
            </Typography>
            {changed ? (
              <CheckRounded
                onClick={() => {
                  setPopoverAnchorEl(null);
                }}
                sx={{ fontSize: "1.5rem" }}
              />
            ) : (
              <CloseRounded
                onClick={() => {
                  setPopoverAnchorEl(null);
                }}
                sx={{ fontSize: "1.5rem" }}
              />
            )}
          </Box>

          <Divider sx={DIVIDER_HEADER_SX} />

          <CardOverflow
            sx={{
              overflowY: "auto",

              maxHeight: secondDictKey ? 200 : 400, //make max height shorter if there is a second item selector
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Typography level="selectLabel">{t(promptKey)}</Typography>
              <Box>
                <Chip
                  size="sm"
                  variant="soft"
                  endDecorator={<DeleteForeverRounded />}
                  onClick={() => {
                    setSelected([]);
                  }}
                  color="neutral"
                >
                  {t("clear", { ns: ["common"] })}
                </Chip>
              </Box>
            </Box>

            <Box
              sx={{
                mr: -0.5,
                mt: -1,
              }}
            >
              <NewItemSelector
                selectMultiple
                dictKey={itemKey}
                dictHeaderKey={itemHeaderKey}
                selectedItems={selected}
                setSelectedItems={setSelected}
              />
            </Box>
          </CardOverflow>

          {secondPromptKey && (
            <React.Fragment>
              <Divider sx={{ opacity: 1, mx: -2 }} />
              <CardOverflow
                sx={{
                  bgcolor: "background.paper",
                  overflowY: "auto",
                  maxHeight: secondDictKey ? 200 : 400, //make max height shorter if there is a second item selector
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <Typography level="selectLabel">
                    {t(secondPromptKey)}
                  </Typography>
                  <Box>
                    <Chip
                      size="sm"
                      variant="soft"
                      endDecorator={<DeleteForeverRounded />}
                      onClick={() => {
                        //@ts-ignore
                        setSelectedSecondItems([]);
                      }}
                      color="neutral"
                    >
                      {t("clear", { ns: ["common"] })}
                    </Chip>
                  </Box>
                </Box>

                <Box>
                  <NewItemSelector
                    selectMultiple
                    //@ts-ignore
                    dictKey={secondDictKey}
                    //@ts-ignore

                    //@ts-ignore
                    selectedItems={selectedSecondItems}
                    //@ts-ignore
                    setSelectedItems={setSelectedSecondItems}
                  />
                </Box>
              </CardOverflow>
            </React.Fragment>
          )}
        </Card>
      </Box>
    </Popover>
  );
};

export default FilterPopover;
