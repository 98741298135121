import { Listings } from "../../Services/Db/types";
import { Fragment, useEffect, useRef, useState } from "react";
import { ListingsService } from "../../Services/Db/service";
import { Box, Button, Card, Modal, Typography } from "@mui/joy";
import RoleHeaderBusiness from "./RoleHeaderBusiness";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import useConfirm from "../../Utils/useConfirm";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../Utils/Constants";
import { EditRounded, WysiwygRounded } from "@mui/icons-material";

interface RoleBusinessProps {
  listingId?: string;
  displayingFull?: boolean;
  editable?: boolean;
}

const RoleBusiness: React.FC<RoleBusinessProps> = ({
  listingId,
  displayingFull,
  editable,
}) => {
  const [listing, setListing] = useState<Listings | null>(null);
  const listingLoaded = useRef(false);
  const { t } = useTranslation(["common"]);
  const confirm = useConfirm();
  const navigate = useNavigate();

  useEffect(() => {
    if (!listingLoaded.current && listingId) {
      const loadListing = async (id: string) => {
        const loadedListing = await ListingsService.getOne(id);
        setListing(loadedListing);
        listingLoaded.current = true;
      };
      loadListing(listingId);
    }
  }, [listingId]);

  function handleEditListing() {}

  return (
    <Fragment>
      <Card>
        {listing && listingId ? (
          <RoleHeaderBusiness
            editable={editable}
            listingId={listingId}
            {...listing}
          />
        ) : (
          <Box sx={{ display: "flex" }}>
            <Skeleton variant="circular" width={50} height={50} />
            <Skeleton variant="text" width={250} style={{ marginLeft: 10 }} />
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "nowrap" },
            gap: { xs: 1, md: 2 },
            alignItems: "center",
          }}
        >
          {!displayingFull && (
            <Button
              color="primary"
              variant="solid"
              fullWidth
              onClick={() => {
                navigate(
                  generatePath(APP_ROUTES.ROLE, {
                    listingId: listingId ?? "",
                  })
                );
              }}
              startDecorator={<WysiwygRounded fontSize="small" />}
            >
              {t("view", { ns: ["common"] })}
            </Button>
          )}
          {editable && (
            <Button
              fullWidth
              variant="soft"
              startDecorator={<EditRounded fontSize="small" />}
              onClick={() =>
                navigate(APP_ROUTES.ROLE_MAKER, {
                  state: { edit: true, listingId: listingId },
                })
              }
            >
              {t("common:edit")}
            </Button>
          )}
        </Box>
      </Card>
    </Fragment>
  );
};

export default RoleBusiness;
