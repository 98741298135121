import {
  BusinessAccService,
  BusinessService,
  ListingsService,
} from "./Services/Db/service";
import {
  addListing,
  ensureBusinessExists,
  getBusinessRef,
  listingToString,
} from "./Services/Db/utils";
import { ArrowForwardRounded } from "@mui/icons-material";
import {
  Button,
  Card,
  Stack,
  Box,
  Typography,
  FormControl,
  Input,
  List,
  ListItem,
  Divider,
} from "@mui/joy";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SelectGroupSingle from "./Components/Inputs/SelectGroupSingle";
import WageSlider from "./Components/Inputs/WageSlider";
import InlineBusinessSelectMap from "./Components/Maps/InlineBusinessSelectMap";
import { usePlaceDetails } from "./Components/Maps/PlaceDetails";
import { loadFullBusiness } from "./Components/Maps/Utils";
import PageSpacer from "./Components/Utilities/PageSpacer";
import AppBarContext from "./Context/AppBarContext";
import { MapContext } from "./Context/MapContext";
import useKeyToDict from "./Utils/useKeyToDict";
import useToast from "./Utils/useToast";
import NewItemSelector from "./Components/Inputs/NewItemSelector";
import { Item, Businesses } from "./Services/Db/types";
import {
  deleteField,
  doc,
  GeoPoint,
  Timestamp,
  where,
} from "firebase/firestore";
import SelectGroup from "../src/Components/Inputs/SelectGroup";
import RoleMakerPage from "./Pages_Business/RoleMakerPage";
import useText from "./Utils/useText";
import useEmail from "./Utils/useEmail";
import { TABLES } from "./Services/Db/consts";
import { db } from "./firebase";
import { Amplify, API } from "aws-amplify";
import awsExports from "../src/aws-exports";
import { geohashForLocation } from "geofire-common";

Amplify.configure(awsExports);

let apiName = "server";

/*

const names = require("./names.json");

async function generateCode(
  placeResult: google.maps.places.PlaceResult & { code?: string }
) {
  return BusinessService.getOne(placeResult.place_id!).then((business) => {
    if (business && business.code && business.code !== "") {
      return business.code;
    }

    var code = "";
    const name = placeResult.name!;
    if (name.replace(" ", "").length < 8) {
      code = name.replace(" ", "").toUpperCase();
    } else if (name.replace(" ", "").length === name.length) {
      code = name.replace(" ", "").substring(0, 7).toUpperCase();
    } else if (name.split(" ").length < 4) {
      code = name
        .split(" ")
        .map((word) => word.substring(0, 2))
        .join("")
        .toUpperCase();
    } else {
      code = name
        .split(" ")
        .map((word) => word[0])
        .join("")
        .toUpperCase();
    }

    if (business) {
      BusinessService.update(placeResult.place_id!, { code: code });
    } else {
      const latlong = new GeoPoint(
        //@ts-ignore
        placeResult.geometry!.location!.lat,
        placeResult.geometry!.location!.lng
      );

      console.log(latlong);

      BusinessService.create(
        {
          name: placeResult.name,
          address: placeResult.formatted_address,
          geohash: geohashForLocation([latlong.latitude, latlong.longitude]),
          code: code,
          latlong: latlong,
          listingWageMap: {},
          listingCtMap: {},
        },
        placeResult.place_id!
      );
    }
    return code;
  });
}

const PlaceSearchName = ({
  placeResult,
}: {
  placeResult: google.maps.places.PlaceResult & { email: string } & {
    ogName: string;
  };
}) => {
  const [code, setCode] = React.useState<string | undefined>();
  const [generatingSign, setGeneratingSign] = React.useState(true);
  const [pdfUrl, setPdfUrl] = React.useState<string | undefined>();
  const [retry, setRetry] = React.useState(false);

  React.useEffect(() => {
    generateCode(placeResult)
      .then((code) => {
        setCode(code);
        //BusinessService.update(placeResult.place_id!, { code: code });
      })
      .catch((e) => {
        console.log(e, "FAILED TO GNEERATE CODE");
      });
  }, [placeResult]);

  React.useEffect(() => {
    if (code && generatingSign) {
      setRetry(false);
      API.post(apiName, "/sign", {
        body: {
          code: code,
          businessName: placeResult.name,
          placeId: placeResult.place_id,
        },
      })
        .then((response: { pdf_url: string }) => {
          console.log("response: ", response);
          console.log("response.pdf_url: ", response.pdf_url);
          setPdfUrl(response.pdf_url);
          setGeneratingSign(false);
        })
        .catch((e: any) => {
          setRetry(true);
          console.log("API REQUEST FILAED");
        });
    }
  }, [code, retry]);

  return (
    <Stack>
      <Typography>
        {placeResult?.place_id},{placeResult?.ogName},{placeResult?.name!},
        {code},{placeResult?.email},
        {generatingSign ? (retry ? "retry" : "generating sign") : pdfUrl}
      </Typography>
      <Divider />
    </Stack>
  );
};
*/

const AddRole: React.FC = () => {
  const { t } = useTranslation(["common", "user_flow", "lists"]);
  const { keyToObjArray } = useKeyToDict("lists");
  const [role, setRole] = React.useState<Item[] | null>([]);
  const context = React.useContext(MapContext);
  const [placeId, setPlaceId] = React.useState<string | undefined>();
  const placeDetails = usePlaceDetails(placeId, [
    "name",
    "formatted_address",
    "geometry",
    "place_id",
  ]);
  const [wage, setWage] = React.useState(0);
  const [tips, setTips] = React.useState(0);
  const [isCompleted, setIsCompleted] = React.useState(false);
  const appBarContext = React.useContext(AppBarContext);

  const [jobTypesSelected, setJobTypesSelected] = useState<string[]>([]);
  const [scheduleTypesSelected, setScheduleSelected] = useState<Item[] | null>(
    []
  );
  const [responsibilitiesSelected, setResponsibilitiesSelected] = useState<
    string[]
  >([]);
  const [perksSelected, setPerksSelected] = React.useState<Item[] | null>([]);
  const [skillsSelected, setSkillsSelected] = React.useState<Item[] | null>([]);
  const [yearsOfExperienceSelected, setYearsOfExperienceSelected] =
    useState<number>(0);
  const yearsOfExperienceDefault = useRef(0);
  const [requirementsSelected, setRequirementsSelected] = useState<string[]>(
    []
  );
  const [languagesSelected, setLanguagesSelected] = useState<string[]>([]);
  const [daysSelected, setDaysSelected] = useState<string[]>([]);
  const [educationType, setEducationType] = useState<string | null>(null);
  const [description, setDescription] = useState<string | undefined>(undefined);

  const job_types_dict = keyToObjArray("job_types");
  const schedule_types_dict = keyToObjArray("schedule_types");
  const education_options_dict = keyToObjArray("education_types");

  const languages_dict = keyToObjArray("languages");

  React.useEffect(() => appBarContext?.setShowHeader(false), []);

  React.useEffect(() => {
    if (wage && role && placeDetails) {
      setIsCompleted(true);
    }
  }, [wage, role, placeDetails]);

  const toast = useToast();

  const getBizName = async (id: string) => {
    const loadedBiz = await BusinessService.getOne(id);
    return loadedBiz?.name;
  };

  //get all businesses on load and on change
  const loadAllBusinesses = async (uid: string): Promise<Businesses[]> => {
    return await BusinessService.getMany();
  };

  const [allBusinesses, setAllBusinesses] = React.useState<Businesses[]>([]);

  React.useEffect(() => {
    loadAllBusinesses("").then((bizs) => {
      setAllBusinesses(bizs);

      const rows = bizs.map((biz) => {
        return [biz.id, biz.name];
      });

      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");

      console.log("csvContent", csvContent);

      var encodedUri = encodeURI(csvContent);
      window.open(encodedUri);
    });
  }, []);

  const { textInviteToInterview, sendText } = useText();
  const { sendEmail } = useEmail();

  return (
    <Box sx={{ width: "100vw", display: "flex", flexDirection: "column" }}>
      <Button
        onClick={() => {
          sendText("3107457252", "hello");
        }}
      >
        TEXT
      </Button>

      <RoleMakerPage adminPower />
      {/* <Button onClick={() => textInviteToInterview(, "3107457252")}>
        TEXT
      </Button> */}

      <Button
        onClick={() => {
          ListingsService.getMany().then((listings) => {
            if (listings) {
              console.log(
                listings
                  .map((listing) => {
                    return listingToString(listing);
                  })
                  .join("\n")
              );
            }
          });
        }}
      >
        GETLISTINGSTRING
      </Button>

      <Card sx={{ width: "100vw" }}>
        <Typography level="h1">Add business</Typography>
        {placeDetails && (
          <Typography level="h2">{placeDetails.place_id}</Typography>
        )}
        <InlineBusinessSelectMap setPlaceId={setPlaceId} placeId={placeId} />
        <Button
          variant="solid"
          disabled={!placeDetails}
          onClick={() => {
            if (!placeDetails) {
              toast("user_flow", "select_business", "", () => {});
            } else {
              ensureBusinessExists(placeDetails?.place_id!).then(() => {
                setAllBusinesses([
                  ...allBusinesses,
                  loadFullBusiness(placeDetails),
                ]);
              });
            }
          }}
          fullWidth
          endDecorator={<ArrowForwardRounded />}
        >
          {t("done", { ns: "common" })}
        </Button>
        <Typography level="h2">{allBusinesses.length}</Typography>
        <List>
          {allBusinesses.map((biz, i) => (
            <ListItem key={biz.id}>
              {biz.name} -- {biz.id}
            </ListItem>
          ))}
        </List>
      </Card>

      {/* <Card sx={{ width: "50vw" }}>
        <Typography level="h1">Add role</Typography>
        <NewItemSelector
          dictKey={"roles"}
          dictHeaderKey={"roles_headers"}
          selectedItems={role}
          setSelectedItems={setRole}
        />
        <Card>
          <Stack spacing={4} sx={{ my: 2 }}>
            <Stack
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
              spacing={2}
            >
              <Typography
                level="body2"
                sx={{ fontWeight: "bold", width: "3rem" }}
              >
                {t("base_wage", { ns: ["user_flow"] })}
              </Typography>
              <WageSlider defaultVal={0} setWage={setWage} />
            </Stack>
            <Stack
              direction="row"
              sx={{ display: "flex", alignItems: "center" }}
              spacing={2}
            >
              <Typography
                level="body2"
                sx={{ fontWeight: "bold", width: "3rem" }}
              >
                {t("tips", { ns: ["user_flow"] })}
              </Typography>
              <WageSlider defaultVal={0} setWage={setTips} />
            </Stack>
          </Stack>
        </Card>
        <SelectGroup
          title={t("job_types", { ns: ["business"] }) || ""}
          items={job_types_dict}
          selected={jobTypesSelected}
          setSelected={setJobTypesSelected}
        />

        <SelectGroup
          title={t("schedule_types", { ns: ["business"] }) || ""}
          items={schedule_types_dict}
          selected={scheduleTypesSelected}
          setSelected={setScheduleSelected}
        />

        <SelectGroup
          title={t("responsibilities", { ns: ["business"] }) || ""}
          items={responsibilities_dict}
          selected={responsibilitiesSelected}
          setSelected={setResponsibilitiesSelected}
        />

        <Typography>Skills</Typography>
        <NewItemSelector
          dictKey={"skills"}
          dictHeaderKey={"skills_headers"}
          selectedItems={skillsSelected}
          setSelectedItems={setSkillsSelected}
        />

        <Typography>Perks</Typography>
        <NewItemSelector
          dictKey={"perks"}
          dictHeaderKey={"perks_headers"}
          selectedItems={perksSelected}
          setSelectedItems={setPerksSelected}
        />

        {/* <InlineBusinessSelectMap setPlaceId={setPlaceId} placeId={placeId} /> */}

      {/* <Button
          variant="solid"
          disabled={!isCompleted}
          onClick={() => {
            if (!placeDetails) {
              toast("user_flow", "select_business", "", () => {});
            } else if (!role) {
              toast("user_flow", "select_role", "", () => {});
            } else if (!wage && !tips) {
              toast("user_flow", "select_wage", "", () => {});
            } else {
              addListing(loadFullBusiness(placeDetails), {
                role: role[0].item,
                category: role[0].category,
                years_of_experience: yearsOfExperienceSelected,
                languages: languagesSelected,
                job_types: jobTypesSelected,
                wage: wage,
                tips: tips,
                skills: skillsSelected || [],
                perks: perksSelected || [],
                schedule: scheduleTypesSelected,
                application_requirements: requirementsSelected,
                posted_at: Timestamp.fromDate(new Date()),
                status: "active",
                days: daysSelected,
                custom_questions: [],
              });
            }
          }}
          fullWidth
          endDecorator={<ArrowForwardRounded />}
        >
          {t("done", { ns: "common" })}
        </Button>
      </Card> */}
    </Box>
  );
};

export default AddRole;
