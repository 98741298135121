import AppBarContext from "../../Context/AppBarContext";
import { Box, Button, Card, Select, Stack } from "@mui/joy";
import { useContext, useEffect } from "react";

const SelectBox = () => {
  return (
    <Box
      draggable
      sx={{
        backgroundColor: "black",
        height: 100,
        width: 100,
        borderRadius: 1,
        m: 2,
      }}
      onClick={(e) => {
        console.log("clicked");
      }}
      onDragStart={(e) => {
        console.log("drag started");
        console.log(e);
        // make the dragged element a small red circle
        // create red circle div with height and width of 20px
        // set the div as the drag image
        // set the drag image offset to 0,0
        // set the drag image opacity to 0.5
        // set the drag image as the drag image
        const redCircle = document.createElement("div");
        redCircle.style.height = "20px";
        redCircle.style.width = "20px";
        redCircle.style.backgroundColor = "red";
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("drag ended");
      }}
      onDragOver={(e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("drag over");
      }}
    />
  );
};

const DaySelect: React.FC = () => {
  const appBarContext = useContext(AppBarContext);
  useEffect(() => appBarContext?.setShowHeader(false), []);
  return (
    <Stack>
      <SelectBox />
      <SelectBox />
      <SelectBox />
      <SelectBox />
    </Stack>
  );
};

export default DaySelect;
