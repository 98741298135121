import React, { useEffect } from "react";
import {
  Typography,
  Input,
  Stack,
  Button,
  Card,
  Box,
  Divider,
  Chip,
} from "@mui/joy";
import { Popover } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StoreRounded, PersonRounded } from "@mui/icons-material";
import {
  DIVIDER_HEADER_SX_MY,
  APP_ROUTES,
  DIVIDER_HEADER_SX_NO_Y,
} from "../../Utils/Constants";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import EmailSignIn from "../Authentication/EmailSignIn";
import PhoneSignIn from "../Authentication/PhoneSignIn";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";

const NumberFieldBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
}));

interface LogInModalProps {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
  onComplete?: () => void;
}

const LogInModal: React.FC<LogInModalProps> = ({
  anchorEl,
  setAnchorEl,
  onComplete,
}) => {
  const [business, setBusiness] = useState(false);
  const [email, setEmail] = useState("");

  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card sx={{ width: { xs: "100%", md: 300 }, borderRadius: 2 }}>
        <Typography level="h6" sx={{ fontWeight: "bold" }}>
          {t("sign_in_to_hourlead", { ns: ["common"] })}
        </Typography>

        <Divider sx={DIVIDER_HEADER_SX_MY} />

        {business ? (
          <Stack spacing={2} sx={{ mb: 2 }}>
            {/* <Button
              sx={{ cursor: "pointer", mt: 2 }}
              onClick={() => navigate(APP_ROUTES.NEW_BUSINESS)}
              startDecorator={<StoreRounded />}
            >
              {t("create_business_account", { ns: ["common"] })}
            </Button>
            <Divider sx={{ color: "text.secondary" }}>
              {t("or", { ns: ["common"] }).toUpperCase()}
            </Divider> */}
            <Typography level="body2" sx={{ fontWeight: "bold" }}>
              {t("sign_in", { ns: ["common"] })}
            </Typography>
            <EmailSignIn
              onComplete={() => navigate(APP_ROUTES.BUSINESS_HOME)}
            />
          </Stack>
        ) : (
          <Stack spacing={2}>
            <Typography level="body2" sx={{ fontWeight: "bold" }}>
              {t("sign_in", { ns: ["common"] })}
            </Typography>
            <PhoneSignIn
              //isSignUp={false}
              onComplete={
                onComplete ||
                (() => {
                  navigate(APP_ROUTES.HOME);
                  setAnchorEl(null);
                  logEvent(analytics, "sign_up", {
                    method: "login_modal",
                  });
                })
              }
            />
          </Stack>
        )}

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Chip
            sx={{
              cursor: "pointer",
              mt: 4,
              "&:hover": { textDecoration: "underline" },
            }}
            onClick={() => setBusiness((state) => !state)}
            startDecorator={business ? <PersonRounded /> : <StoreRounded />}
          >
            {business
              ? t("job_seeker_sign_in", { ns: ["common"] })
              : t("business_sign_in", { ns: ["common"] })}
          </Chip>
        </Box>
      </Card>
    </Popover>
  );
};

export default LogInModal;
