import {
  ArrowForwardRounded,
  ArrowRightRounded,
  GavelRounded,
  HailRounded,
  Info,
  KeyboardArrowRightRounded,
  NearMeRounded,
  PaidRounded,
  TextsmsRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ListItem,
  List,
  Button,
  Alert,
  FormControl,
  Input,
  Chip,
  Checkbox,
  Card,
  IconButton,
} from "@mui/joy";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneSignIn from "src/Components/Authentication/PhoneSignIn";
import GenericModal from "src/Components/Common/GenericModal";
import { useAuth } from "src/Context/AuthContext";
import { CreateProfile } from "src/UserProfile/CreateProfile";
import WageSurveyContext from "./WageSurveyContext";
import useKeyToDict from "src/Utils/useKeyToDict";
import { ListingsService, UserService } from "src/Services/Db/service";
import { doc, limit, orderBy, where } from "firebase/firestore";
import { Item, Listings } from "../../Services/Db/types";
import RoleItem from "../../Components/Apply/RoleItem";
import { generatePath, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/Utils/Constants";
import { FullUserService } from "src/Services/Db/user";
import { User } from "firebase/auth";
import { TABLES } from "src/Services/Db/consts";
import { db } from "src/firebase";
import ShareSheet from "src/Marketing/ShareSheet";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";
import {
  calc_diff_10_from_key,
  calc_diff_from_key,
  calc_percentile_from_key,
} from "./Result";
import { Skeleton } from "@mui/material";
import { RoleItemSkeleton } from "src/GPT/Recommendations";

const ResultSignedIn = () => {
  const { t } = useTranslation("wage_comparison");
  const { user } = useAuth();

  const navigate = useNavigate();
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const [showModal, setShowModal] = useState<boolean>(user ? false : true);
  const [percentile, setPercentile] = useState<number>();
  const [tenthPercentile, setTenthPercentile] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);
  const [job, setJob] = useState<Item>();
  const [recommendations, setRecommendations] = useState<Listings[]>([]);
  const [haveRolesInIndustry, setHaveRolesInIndustry] = useState<boolean>();
  const [otherEntered, setOtherEntered] = useState<boolean>(false);

  const [textOptIn, setTextOptIn] = useState<boolean>(true);

  // from the get-go, find 3 jobs that pay more than the user's wage
  useEffect(() => {
    if (user) {
      FullUserService.loadUserWage(user.phoneNumber!).then((wage) => {
        // set earning percentile
        const pct = calc_diff_from_key(
          wage?.role?.category + ":" + wage?.role?.item,
          (wage?.wage ?? 0) + (wage?.tips ?? 0)
        );
        setPercentile(Math.round(pct * 10) / 10);
        const pct10 = calc_diff_10_from_key(
          wage?.role?.category + ":" + wage?.role?.item,
          (wage?.wage ?? 0) + (wage?.tips ?? 0)
        );
        setTenthPercentile(Math.round(pct10 * 10) / 10);
        setJob(wage?.role);
        if (wage?.role?.category === "other_entered") {
          setOtherEntered(true);
        }

        ListingsService.getMany(
          where("status", "==", "active"),
          where("category", "==", wage?.role?.category),
          where("role", "==", wage?.role?.item)
        )
          .then((jobs) => {
            const jobsWithWages = jobs.filter(
              (job) =>
                job.wage + job.tips >
                ((wage?.wage ?? 0) + (wage?.tips ?? 0) ?? 0)
            );
            if (jobsWithWages.length === 0) {
              // get 3 highest paying jobs from listings serivce
              ListingsService.getMany(
                where("category", "==", wage?.role?.category)
              ).then((newJobs) => {
                if (newJobs.length > 0) {
                  setHaveRolesInIndustry(true);
                  setRecommendations(
                    newJobs
                      .filter((job) => job.status === "active")
                      .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
                      .slice(0, 3)
                  );
                } else {
                  ListingsService.getMany().then((newJobs) => {
                    setRecommendations(
                      newJobs
                        .filter((job) => job.status === "active")
                        .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
                        .slice(0, 3)
                    );
                    setHaveRolesInIndustry(false);
                  });
                }
              });
            } else {
              // sort jobswithwages by wage + tips and take the top 3
              setRecommendations(
                jobsWithWages
                  .sort((a, b) => b.wage + b.tips - a.wage - a.tips)
                  .slice(0, 3)
              );
              setHaveRolesInIndustry(true);
            }
          })
          .finally(() => setLoading(false));
      });

      FullUserService.loadUserTextOptIn(user.phoneNumber!).then((result) => {
        setTextOptIn(result);
      });
    }
  }, [user]);

  const handleTextOptIn = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptIn(e.target.checked);
    FullUserService.setUserTextOptIn(user?.phoneNumber!, e.target.checked);
  };

  const getWageMessage = (percentile: number, tenthPercentile: number) => {
    if (!job) return;

    const no_result = t("result.result_other", { role: job.item });

    const percentileFormatted = Math.abs(percentile).toFixed(2);
    const moreOrLess = percentile > 0 ? t("result.more") : t("result.less");
    const role = getRoleNameFromRole(job);
    const tenthPercentileFormatted = Math.abs(tenthPercentile).toFixed(2);
    const moreOrLess10 =
      tenthPercentile > 0 ? t("result.less") : t("result.more");

    const resultAvg = t("result.result_avg", {
      amount: percentileFormatted,
      more: moreOrLess,
      role: role,
    });

    const result = t("result.result", {
      amount: percentileFormatted,
      more: moreOrLess,
      role: role,
    });

    const result10 = t("result.result10", {
      amount: tenthPercentileFormatted,
      more: moreOrLess10,
      role: role,
    });

    const main = otherEntered ? no_result : percentile > 0 ? result : resultAvg;

    const sub = percentile > 0 ? result10 : "";

    return {
      main,
      sub,
    };
  };

  return (
    <Stack spacing={2}>
      {otherEntered ? (
        <Stack
          sx={{
            borderRadius: 1,
            p: 5,
            bgcolor: "gray",
          }}
          spacing={2}
        >
          <Typography level="h1" sx={{ color: "common.white" }}>
            {t("result.result_other", { role: job?.item })}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            borderRadius: 1,
            p: 5,
            bgcolor:
              percentile && percentile > 0 ? "success.main" : "error.main",
          }}
          spacing={2}
        >
          {percentile !== undefined && tenthPercentile !== undefined && job && (
            <Fragment>
              <Typography level="h1" sx={{ color: "common.white" }}>
                {getWageMessage(percentile, tenthPercentile)?.main}
              </Typography>

              <Typography level="h3" sx={{ color: "common.white" }}>
                {getWageMessage(percentile, tenthPercentile)?.sub}
              </Typography>
              <Box>
                <Chip
                  startDecorator={<Info />}
                  color={percentile && percentile > 0 ? "success" : "warning"}
                  variant="soft"
                  size="sm"
                >
                  Data per the Bureau of Labor Statitstics
                </Chip>
              </Box>
            </Fragment>
          )}
        </Stack>
      )}

      <ShareSheet
        text={t("result.share_text")!}
        subject={t("result.share_subject")!}
        url={window.location.href}
      />

      <Card>
        <Alert
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 2,
            p: 2,
          }}
          variant="soft"
        >
          <Checkbox
            checked={textOptIn}
            onChange={() => {
              setTextOptIn(!textOptIn);
              handleTextOptIn({
                target: { checked: !textOptIn },
              } as React.ChangeEvent<HTMLInputElement>);
            }}
          />
          <Stack>
            <Typography
              level="h5"
              sx={{ fontWeight: "bold", color: "inherit" }}
            >
              {textOptIn
                ? t("result.text_header_in")
                : t("result.text_header_out")}
            </Typography>
            <Typography
              startDecorator={<PaidRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_higher_wage")}
            </Typography>
            <Typography
              startDecorator={<HailRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_good_role")}
            </Typography>
            <Typography
              startDecorator={<NearMeRounded />}
              sx={{ color: "inherit" }}
            >
              {t("result.text_in_your_area")}
            </Typography>
          </Stack>
        </Alert>
      </Card>

      {loading && (
        <Fragment>
          <Card>
            <RoleItemSkeleton />
          </Card>
          <Card>
            <RoleItemSkeleton />
          </Card>
          <Card>
            <RoleItemSkeleton />
          </Card>
        </Fragment>
      )}
      {!loading &&
        (haveRolesInIndustry ? (
          <Typography level="h4">{t("result.new_roles")}</Typography>
        ) : (
          <Typography level="h4">{t("result.no_roles")}</Typography>
        ))}
      {!loading &&
        recommendations.map((job) => {
          return (
            <Card>
              <RoleItem
                listing={job}
                onClick={() => {
                  navigate(
                    generatePath(APP_ROUTES.ROLE, { listingId: job.id ?? "" })
                  );
                  logEvent(analytics, "view_role", {
                    listing_id: job.id,
                    from: "survey",
                  });
                }}
                displayName
              />
            </Card>
          );
        })}
    </Stack>
  );
};

export default ResultSignedIn;
