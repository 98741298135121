import { useContext } from "react";
import ConfirmationModalContext from "../Context/ConfirmationModalContext";

function useConfirm() {
  const confirmationModalContext = useContext(ConfirmationModalContext);

  const confirm = (
    namespace: string,
    mainMessageKey: string,
    subtitleKey: string,
    actionKey: string,
    toDo: () => void,
    toInsertInSubtitle?: string[],
    reauth?: "business" | "user"
  ) => {
    confirmationModalContext?.setNamespace(namespace);
    confirmationModalContext?.setMainMessageKey(mainMessageKey);
    confirmationModalContext?.setSubtitleKey(subtitleKey);
    confirmationModalContext?.setActionKey(actionKey);
    confirmationModalContext?.setToInsertInSubtitle(toInsertInSubtitle || []);
    confirmationModalContext?.setToDo(() => toDo);
    confirmationModalContext?.setShow(true); //always show modal when we confirm, of course -- is this variable redundant? idk
    confirmationModalContext?.setRequiresReauth(reauth);
  };

  return confirm;
}

export default useConfirm;
