import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Typography,
  Button,
  Box,
  Stack,
  Tooltip,
  Chip,
  Grid,
  IconButton,
} from "@mui/joy";
import LivePausedChip from "./LivePausedChip";
import WageChip from "./WageChip";
import { APP_ROUTES, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import {
  PeopleRounded,
  WysiwygRounded,
  ScheduleRounded,
  PersonRounded,
  PersonAddRounded,
  PersonAddDisabledRounded,
  EditLocationAltRounded,
  LocationOnRounded,
} from "@mui/icons-material";
import dayjs from "dayjs";
import useText from "../../Utils/useText";
import useConfirm from "../../Utils/useConfirm";
import useKeyToDict from "../../Utils/useKeyToDict";
import { SelectInterviewSlotModal } from "../Inputs/SelectInterviewSlot";
import { Item } from "../../Services/Db/types";
import { useBusinessAuth } from "../../Context/AuthContext";
import {
  ApplicationsService,
  InterviewsService,
  UserService,
} from "../../Services/Db/service";
import InterviewStatus from "./InterviewStatus";

interface UpcomingInterviewProps {
  appId: string;
  interviewId?: string;
  role: Item;
  name: string;
  applied_time: string;
  business: string;
  phone: string;
  mode?: string;
  status?: string;

  scheduled_at?: string;
}

const UpcomingInterview: React.FC<UpcomingInterviewProps> = ({
  appId,
  role,
  name,
  applied_time,
  business,
  phone,
  mode,
  status,

  interviewId,
  scheduled_at,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "business", "interview"]);
  const {
    textRescheduleInterview,
    textCancelInterview,
    textInviteToInterviewReminder,
    textConfirmInterviewReminder,
  } = useText();
  const confirm = useConfirm();
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const setStatus = (status: string) => {
    ApplicationsService.update(appId, { status: status });
  };

  const handleReschedule = () => {
    InterviewsService.remove(interviewId!);
    ApplicationsService.update(appId, { interviewRef: null });
    textRescheduleInterview(business, phone);
  };

  const handleRerequest = () => {
    textInviteToInterviewReminder(business, phone);
  };

  const handleConfirmInterview = () => {
    textConfirmInterviewReminder(phone, business, interviewId!);
  };

  return (
    <Card>
      <Grid container>
        <Grid xs={6}>
          <Box>
            <Typography level="cardHeader">{name}</Typography>
            <Typography
              level="body1"
              sx={{ color: "text.secondary" }}
              startDecorator={<PersonRounded />}
            >
              {getRoleNameFromRole(role)}
            </Typography>
            <Chip
              variant="soft"
              color={scheduled_at ? "success" : "warning"}
              sx={{ mt: 1 }}
            >
              {scheduled_at || t("common:pending")}
            </Chip>
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            <Chip variant="outlined" color="neutral" sx={{ borderRadius: 1 }}>
              {t("applied_on", {
                ns: ["interview"],
                date: applied_time,
              })}
            </Chip>
            {scheduled_at && (
              <Chip
                variant="soft"
                color={status === "confirmed" ? "success" : "warning"}
              >
                {status ? t(`common:${status}`) : t("common:unconfirmed")}
              </Chip>
            )}
            {mode && (
              <Chip
                variant="outlined"
                color="neutral"
                startDecorator={<LocationOnRounded />}
              >
                {t("interview:" + mode)}
              </Chip>
            )}
          </Box>
        </Grid>
      </Grid>

      <Divider sx={DIVIDER_HEADER_SX_MY} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: { xs: 1, md: 2 },
          alignItems: "center",
        }}
      >
        {interviewId ? (
          <Button
            onClick={() =>
              confirm(
                "interview",
                "reschedule_interview",
                "reschedule_interview_sub",
                "confirm",
                handleReschedule
              )
            }
            variant="outlined"
            fullWidth
            startDecorator={<WysiwygRounded fontSize="small" />}
          >
            {t("interview:reschedule")}
          </Button>
        ) : (
          <Button
            variant="outlined"
            fullWidth
            startDecorator={<WysiwygRounded fontSize="small" />}
            onClick={() => handleRerequest()}
          >
            {t("interview:rerequest_interview")}
          </Button>
        )}
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            variant="soft"
            startDecorator={<PersonAddDisabledRounded />}
            onClick={() =>
              confirm(
                "common",
                "confirm_move_to_rejected",
                "confirm_move_to_rejected_subtitle",
                "confirm",
                () => setStatus("rejected"),
                []
              )
            }
            fullWidth
          >
            {t("decline")}
          </Button>
          <Button
            variant="soft"
            color="success"
            startDecorator={<PersonAddRounded />}
            onClick={() =>
              confirm(
                "common",
                "confirm_move_to_hired",
                "confirm_move_to_hired_subtitle",
                "confirm",
                () => setStatus("hired"),
                []
              )
            }
            fullWidth
          >
            {t("hire")}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default UpcomingInterview;
