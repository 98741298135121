import React from "react";
import { useState, useContext } from "react";
import {
  Button,
  Box,
  Input,
  List,
  ListItem,
  Checkbox,
  Typography,
} from "@mui/joy";
import { styled } from "@mui/material/styles";
import { Done } from "@mui/icons-material";

// const AddBox = styled(TextField)(({ theme }) => ({
//   border: "1px solid " + theme.palette.grey[200],
//   borderRadius: 20,
//   paddingInline: theme.spacing(1),
// }));

interface SelectGroupProps {
  title?: string;
  items: { [key: string]: any };
  selected: string[];
  setSelected: (val: string[]) => void;
}

const SelectGroup: React.FC<SelectGroupProps> = ({
  title,
  items,
  selected,
  setSelected,
}) => {
  const update = (item: string) => {
    if (selected.includes(item)) {
      setSelected(selected.filter((text) => text !== item));
    } else {
      setSelected([...selected, item]);
    }
  };

  return (
    <Box>
      {title && (
        <Typography level="selectLabel" sx={{ pb: 1, wordWrap: "break-word" }}>
          {title}
        </Typography>
      )}

      <List
        wrap
        orientation="horizontal"
        sx={{
          "--List-gap": "8px",
          "--List-item-radius": "10px",
        }}
      >
        {items.map((pair: any) => (
          <ListItem key={pair[0]}>
            {selected.includes(pair[0]) && (
              <Done
                fontSize="medium"
                sx={{
                  color: "primary.main",
                  ml: -0.5,
                  mr: 0.5,
                  zIndex: 2,
                  pointerEvents: "none",
                }}
              />
            )}
            <Checkbox
              overlay
              disableIcon
              variant="outlined"
              label={pair[1]}
              sx={{
                fontWeight: selected.includes(pair[0]) ? 600 : "normal",
              }}
              checked={selected.includes(pair[0])}
              onChange={() => update(pair[0])}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SelectGroup;
