export enum TABLES {
  USERS = "users",
  BUSINESSES = "businesses",
  EDUCATION = "education",
  EXPERIENCES = "experiences",
  LISTINGS = "listings",
  WAGES = "wages",
  REFERENCES = "references",
  APPLICATIONS = "applications",
  BUSINESS_ACCOUNTS = "business_accounts",
  INTERVIEWS = "interviews",
  INTERVIEW_AVAILABILITIES = "interview_availabilities",
  INTERVIEW_PREFS = "interview_prefs",
  BUG_AND_FEATURE = "bug_and_feature",
  MESSAGES = "messages",
  LINKED_USERS = "linked_users",
}
