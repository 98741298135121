import React, { useContext, useEffect } from "react";
import InterviewContext from "./InterviewContext";
import { InterviewPrefs } from "src/Services/Db/types";
import { FullBusinessService } from "src/Services/Db/business";
import useConfirm from "src/Utils/useConfirm";
import { useBusinessAuth } from "src/Context/AuthContext";
import useInterviewLogicCollisions from "./useInterviewLogicCollisions";
import dayjs from "dayjs";

function useInterviewLogicFirebase() {
  const context = useContext(InterviewContext);
  const { business } = useBusinessAuth();
  const confirm = useConfirm();
  const { findCollisionsPrefs } = useInterviewLogicCollisions();

  const loadInterviews = async (bid: string) => {
    console.log("loadInterviews");
    if (!context?.loadedUpcomingInterviews && business) {
      console.log("loadInterviews NOW", bid);
      const loadedInterviews =
        await FullBusinessService.getFullBusinessInterviews(bid);
      context?.setUpcomingInterviews(loadedInterviews);
      context?.setLoadedUpcomingInterviews(true);
      console.log("loadedInterviews", loadedInterviews);
      return loadedInterviews;
    }
  };

  const restoreDefaultPrefs = () => {
    context?.setInterviewDuration(30);
    context?.setStartTime(8);
    context?.setEndTime(17);
    context?.setInterviewTypes(["in-person"]);
    context?.setVideoLink("");
    context?.setReminderMsg("");

    const payload: InterviewPrefs = {
      start: 8,
      end: 17,
      duration: 30,
      video: "",
      types: ["in-person"],
      reminderMsg: "",
    };

    FullBusinessService.createBusinessInterviewPrefsAllLocations(
      payload,
      business!.email!
    );
    context?.setPrefDiscrepancy(false);
  };

  const handleSavePrefs = () => {
    const collisions = findCollisionsPrefs();
    if (collisions.length > 0) {
      context?.setCollisions(collisions);
      context?.setCollisionsOpen(true);
    }

    console.log("SAVING PREFS WITH ", context!.interviewDuration);

    //check if any availability now doesn't match prefs
    const recurringAvailabilityMatchingPrefs =
      context?.recurringAvailability.filter(
        (x) =>
          dayjs(x).isAfter(dayjs(context!.startTime), "hour") &&
          dayjs(x).isBefore(dayjs(context!.endTime), "hour") &&
          dayjs(x).minute() % context!.interviewDuration === 0
      );

    const oneTimeAvailabilityMatchingPrefs =
      context?.oneTimeAvailability.filter(
        (x) =>
          dayjs(x).isAfter(dayjs(context!.startTime), "hour") &&
          dayjs(x).isBefore(dayjs(context!.endTime), "hour") &&
          dayjs(x).minute() % context!.interviewDuration === 0
      );

    const removeRecurringAvailabilityMatchingPrefs =
      context?.recurringAvailability.filter(
        (x) =>
          dayjs(x).isAfter(dayjs(context!.startTime), "hour") &&
          dayjs(x).isBefore(dayjs(context!.endTime), "hour") &&
          dayjs(x).minute() % context!.interviewDuration !== 0
      );

    context?.setRecurringAvailability(recurringAvailabilityMatchingPrefs || []);
    context?.setOneTimeAvailability(oneTimeAvailabilityMatchingPrefs || []);
    context?.setRemoveRecurringAvailability(
      removeRecurringAvailabilityMatchingPrefs || []
    );

    FullBusinessService.createBusinessInterviewAvailability(
      recurringAvailabilityMatchingPrefs || [],
      removeRecurringAvailabilityMatchingPrefs || [],
      oneTimeAvailabilityMatchingPrefs || [],
      context!.placeId
    );

    const payload: InterviewPrefs = {
      start: context!.startTime,
      end: context!.endTime,
      duration: context!.interviewDuration,
      video: context!.videoLink,
      types: context!.interviewTypes,
      reminderMsg: context!.reminderMsg,
    };
    if (context?.placeId) {
      FullBusinessService.createBusinessInterviewPrefs(
        payload,
        context?.placeId
      ).then(() => context!.setCanSavePrefs(false));
    } else if (business) {
      confirm(
        "interview",
        "save_prefs_all",
        "save_prefs_all_desc",
        "confirm",
        () =>
          FullBusinessService.createBusinessInterviewPrefsAllLocations(
            payload,
            business.email!
          )
      );
    }

    loadAvailability();
  };

  //sets prefs on load
  const setInterviewPrefs = () => {
    if (business !== null || context?.placeId !== undefined) {
      console.log("placeId: ", context?.placeId);
      FullBusinessService.getBusinessInterviewPrefs(
        context?.placeId,
        business?.email!
      )
        .then((prefs) => {
          //prefs exist
          if (prefs) {
            context!.setInterviewDuration(prefs.duration);
            console.log("SET DURATION", prefs.duration);
            context!.setStartTime(prefs.start);
            context!.setEndTime(prefs.end);
            context!.setInterviewTypes(prefs.types || ["in-person"]);
            context!.setVideoLink(prefs.video || "");
            context!.setReminderMsg(prefs.reminderMsg || "");
          }
          // if prefs dont exist, need to create default prefs, set default prefs for redundancy
          else if (context?.placeId) {
            context!.setInterviewDuration(30);
            context!.setStartTime(8);
            context!.setEndTime(17);
            context!.setInterviewTypes(["in-person"]);
            context!.setReminderMsg("");
            context!.setVideoLink("");
            FullBusinessService.createBusinessInterviewPrefs(
              {
                start: context!.startTime,
                end: context!.endTime,
                duration: context!.interviewDuration,
                types: ["in-person"],
              },
              context!.placeId
            );
          }
          context?.setPrefDiscrepancy(false);
          console.log("prefs loaded");
        })
        .catch((err) => {
          context?.setPrefDiscrepancy(true);
        })
        .finally(() => context?.setLoadingPrefs(false));
    }
  };

  const loadAvailability = () => {
    if (business !== null || context?.placeId !== undefined) {
      context?.setHolding([]);

      FullBusinessService.getBusinessInterviewAvailabilities(
        context?.placeId,
        business?.email!
      )
        .then((availabilities) => {
          if (availabilities) {
            const oneTime = [];
            const recurring = [];
            const removeRecurring = [];
            for (const availability of availabilities) {
              if (availability.type === "one-time") {
                oneTime.push(availability.time.toDate());
              } else if (availability.type === "recurring") {
                recurring.push(availability.time.toDate());
              } else if (availability.type === "remove") {
                removeRecurring.push(availability.time.toDate());
              }
            }

            context!.setOneTimeAvailability([...oneTime]);
            context!.setRecurringAvailability([...recurring]);
            context!.setRemoveRecurringAvailability([...removeRecurring]);
            context!.setChanged(false);

            console.log("availabilities loaded", availabilities);
          }
        })
        .catch((err) => context?.setAvailabilityDiscrepancy(true))
        .finally(() => {
          context?.setLoadedAvailability(true);
        });
    }
  };

  const handleSaveAvailability = () => {
    context?.setSavingInProgress(true);

    console.log("PLACEID", context?.placeId, "BUSINESS", business);

    if (context?.placeId) {
      FullBusinessService.createBusinessInterviewAvailability(
        context!.recurringAvailability,
        context!.removeRecurringAvailability,
        context!.oneTimeAvailability,
        context!.placeId
      ).finally(() => {
        context?.setSavingInProgress(false);
        context?.setChanged(false);
      });
    } else if (business) {
      FullBusinessService.createBusinessInterviewAvailabilityAllLocations(
        context!.recurringAvailability,
        context!.removeRecurringAvailability,
        context!.oneTimeAvailability,
        business.email!
      ).finally(() => {
        context?.setSavingInProgress(false);
        context?.setChanged(false);
      });
    }
    context!.setChanged(false);
  };

  const clearAllAvailability = () => {
    console.log("CLEAR ALL");
    context!.setRecurringAvailability([]);
    context!.setRemoveRecurringAvailability([]);
    context!.setOneTimeAvailability([]);
    FullBusinessService.createBusinessInterviewAvailabilityAllLocations(
      [],
      [],
      [],
      business?.email!
    );
    context?.setAvailabilityDiscrepancy(false);
  };

  return {
    restoreDefaultPrefs,
    handleSavePrefs,
    setInterviewPrefs,
    loadAvailability,
    handleSaveAvailability,
    clearAllAvailability,
    loadInterviews,
  };
}

export default useInterviewLogicFirebase;
