import { Box, Stack, Typography } from "@mui/joy";
import { User } from "firebase/auth";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailSignIn from "src/Components/Authentication/EmailSignIn";
import PageSpacer from "src/Components/Utilities/PageSpacer";
import AppBarContext from "src/Context/AppBarContext";
import { auth } from "src/firebase";
import { FullBusinessService } from "src/Services/Db/business";
import { LinkedUsersService } from "src/Services/Db/service";
import { APP_ROUTES, HEADER_TYPES } from "src/Utils/Constants";
import useToast from "src/Utils/useToast";

const Invitation = () => {
  const appBarContext = useContext(AppBarContext);
  const [signedOut, setSignedOut] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!signedOut) {
      setSignedOut(true);
      auth.signOut();
    }
  }, [signedOut]);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.BASIC);
  });

  async function handleComplete(u: User) {
    FullBusinessService.getLinkedBusinessRef(u.email!)
      .then((ref) => {
        console.log(ref);
        if (ref) {
          LinkedUsersService.create({ businessAccRef: ref }, u.email!).then(
            (_data) => {
              navigate(APP_ROUTES.BUSINESS_HOME);
            }
          );
        } else {
          toast("business", "no_invite", "", () => {});
        }
      })
      .catch((e) => {
        toast("business", "no_invite", "close", () => {});
      });
  }

  return (
    <PageSpacer>
      <Stack spacing={1}>
        <Typography level="cardHeader">Welcome to Hourlead!</Typography>
        <Typography level="body1">
          Thank you for accepting your business's invitation to join Hourlead.
          Sign in with the email you received the invitation on to get started.
        </Typography>
        <EmailSignIn onComplete={handleComplete} />
      </Stack>
    </PageSpacer>
  );
};

export default Invitation;
