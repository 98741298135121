import React from "react";
import { Checkbox, Box, List, ListItem, Typography } from "@mui/joy";
import { Done } from "@mui/icons-material";

interface SelectGroupSingleProps {
  title?: string | null;
  items: { [key: string]: any };
  selected: string | null;
  setSelected: (selected: string) => void;
  handleNext?: () => void;
}

const SelectGroupSingle: React.FC<SelectGroupSingleProps> = ({
  title,
  items: options,
  selected,
  setSelected,
  handleNext,
}) => {
  const handleSelect = (item: string) => {
    // if already selected, deselect
    if (selected === item) {
      setSelected("");
      return;
    }
    setSelected(item);
    if (handleNext) {
      handleNext();
    }
  };

  return (
    <Box>
      {title && <Typography level="selectLabel">{title}</Typography>}

      <List
        wrap
        orientation="horizontal"
        sx={{
          ml: -0.75,
          "--List-gap": "8px",
          "--List-item-radius": "10px",
        }}
      >
        {options.map((pair: any) => (
          <ListItem key={pair[0]}>
            {selected === pair[0] && (
              <Done
                fontSize="medium"
                sx={{
                  color: "primary.main",
                  ml: -0.5,
                  mr: 0.5,
                  zIndex: 2,
                  pointerEvents: "none",
                }}
              />
            )}
            <Checkbox
              overlay
              disableIcon
              variant="outlined"
              label={pair[1]}
              checked={selected === pair[0]}
              onChange={() => handleSelect(pair[0])}
              sx={{
                fontWeight: selected == pair[0] ? 600 : "normal",
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SelectGroupSingle;
