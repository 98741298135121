import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Popover, Toolbar, AppBar, Divider } from "@mui/material";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Box,
  IconButton,
  Card,
  Modal,
  Badge,
} from "@mui/joy";
import {
  AccountCircleRounded,
  AttachMoneyOutlined,
  Tune,
  FavoriteRounded,
  GroupsRounded,
} from "@mui/icons-material";
import AppBarContext from "../Context/AppBarContext";
import LogInModal from "../Components/Common/LogInModal";
import { APP_ROUTES, USER_TYPES } from "../Utils/Constants";
import { WageAlert, BusinessAlert } from "./Alerts";
import { useTranslation } from "react-i18next";
import HeaderLocationSearch from "../Components/Maps/HeaderLocationSearch";
import { NewUserFlow } from "../Pages_JobSeeker/NewUserFlow";
import WagePopover from "./WagePopover";
import FilterPopover from "./FilterPopover";
import Slide from "@mui/material/Slide";
import { useAuth, useBusinessAuth } from "../Context/AuthContext";
import { MapContext } from "../Context/MapContext";
import { Item } from "../Services/Db/types";
import { FullUserService } from "../Services/Db/user";
import Trio from "./Trio";

const Filter = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1.2px solid #D8D8DE",
  backgroundColor: "none",
  width: "100%",
  height: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: "0.45rem 0",
  "> *": {
    height: "100%",
    textAlign: "center",
    overflow: "hidden",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    whiteSpace: "nowrap",
  },
}));

export default function SearchAppBar() {
  const navigate = useNavigate();
  const context = React.useContext(AppBarContext);
  const mapContext = React.useContext(MapContext);

  const { t } = useTranslation(["header", "common"]);

  const { user } = useAuth();
  const { business } = useBusinessAuth();

  const [showNewUserFlow, setShowNewUserFlow] = useState(false);

  const alertOffset = context?.showAlerts.reduce((acc, alert) => {
    const a = document.getElementById("#" + alert) || null;

    if (a) {
      return acc + a.offsetHeight;
    }
    return acc;
  }, 0);

  useEffect(() => {
    context?.setAlertOffset(alertOffset || 0);
  }, [alertOffset]);

  const [wagePopoverAnchorEl, setWagePopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const handleWagePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    setWagePopoverAnchorEl(event.currentTarget);
  };
  const [wageRange, setWageRange] = useState<number[]>([0, 100]);

  const [rolePopoverAnchorEl, setRolePopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const handleRolePopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    setRolePopoverAnchorEl(event.currentTarget);
  };

  const [selectedRole, setSelectedRole] = useState<Item[] | null>([]);
  useEffect(() => {
    mapContext?.setRoles(selectedRole ? selectedRole : []);
  }, [selectedRole]);

  const [selectedSchedule, setSelectedSchedule] = useState<Item[] | null>([]);
  useEffect(() => {
    mapContext?.setSchedules(selectedSchedule ? selectedSchedule : []);
  }, [selectedSchedule]);

  const [benefitsPopoverAnchorEl, setBenefitsPopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const handleBenefitsPopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    setBenefitsPopoverAnchorEl(event.currentTarget);
  };
  const [selectedBenefits, setSelectedBenefits] = useState<Item[] | null>([]);
  useEffect(() => {
    mapContext?.setBenefits(selectedBenefits ? selectedBenefits : []);
  }, [selectedBenefits]);

  const [skillsPopoverAnchorEl, setSkillsPopoverAnchorEl] =
    useState<HTMLElement | null>(null);
  const handleSkillsPopoverClick = (event: React.MouseEvent<HTMLElement>) => {
    setSkillsPopoverAnchorEl(event.currentTarget);
  };
  const [selectedSkills, setSelectedSkills] = useState<Item[] | null>([]);
  // set selected skills to map context
  useEffect(() => {
    mapContext?.setSkills(selectedSkills ? selectedSkills : []);
  }, [selectedSkills]);

  const [loginPopoverAnchorEl, setLoginPopoverAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleLoginClick = (event: React.MouseEvent<HTMLElement>) => {
    if (user) {
      context?.setBackKey("back_to_map");
      navigate(APP_ROUTES.JOB_SEEKER_HOME);
    } else if (business) {
      navigate(APP_ROUTES.BUSINESS_HOME);
    } else {
      console.log("setting anchor");
      setLoginPopoverAnchorEl(event.currentTarget);
    }
  };

  return (
    <Box id="search-app-bar">
      <Stack>
        {context?.showAlerts!.includes("business") && <BusinessAlert />}
        {context?.showAlerts!.includes("addWage") && <WageAlert />}
      </Stack>
      <Slide
        direction="down"
        in={context?.showHeader}
        appear={false}
        // mountOnEnter
        // unmountOnExit
        timeout={400}
      >
        <Box
          component={Grid}
          container
          id="#search-app-bar"
          spacing={1}
          sx={{
            position: "fixed",
            left: { xs: "4vw", md: "5vw" },
            zIndex: 1000,
            top: context?.alertOffset ? context?.alertOffset + 10 : "3vh",
            width: { xs: "94vw", md: "90vw" },
            bgcolor: "header.bg",
            borderRadius: 2,
            border: "1px solid #D8D8DE",
            boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.25)",
            display: "flex",
            px: { xs: 1, md: 2 },
            pt: { xs: 0, md: 0.5 },
            pb: { xs: 1, md: 0.5 },
            // justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid xs={6} md={2}>
            <Typography
              level="h5"
              noWrap
              sx={{
                fontWeight: "bold",
                cursor: "pointer",
                color: "header.main",
                alignSelf: "center",
              }}
              onClick={() => navigate(APP_ROUTES.HOME)}
            >
              Hourlead
            </Typography>
          </Grid>

          <Grid
            xs={6}
            display={{ xs: "flex", md: "none" }}
            sx={{
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Trio signedIn={user != undefined} />
          </Grid>

          <Grid xs={12} md={4} sx={{ mt: { xs: -0.75, md: 0 } }}>
            <HeaderLocationSearch />
          </Grid>

          <Grid xs={3} md={1}>
            <Filter
              onClick={handleWagePopoverClick}
              color="neutral"
              variant="outlined"
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                {wageRange[0] === 0 && wageRange[1] === 100
                  ? `${t("hourly_wage")}`
                  : `$${wageRange[0]}-${wageRange[1]}${t("/hr")}`}
              </Typography>
            </Filter>
          </Grid>

          <Grid xs={3} md={1}>
            <Filter
              color="neutral"
              variant="outlined"
              onClick={handleRolePopoverClick}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                {t("role_count", {
                  ns: ["header"],
                  count: selectedRole ? selectedRole.length : 0,
                })}
              </Typography>
            </Filter>
          </Grid>

          <Grid xs={3} md={1}>
            <Filter
              color="neutral"
              variant="outlined"
              onClick={handleBenefitsPopoverClick}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                {t("perks_count", {
                  ns: ["header"],
                  count: selectedBenefits ? selectedBenefits.length : 0,
                })}
              </Typography>
            </Filter>
          </Grid>

          <Grid xs={3} md={1}>
            <Filter
              color="neutral"
              variant="outlined"
              onClick={handleSkillsPopoverClick}
            >
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                {t("skills_count", {
                  ns: ["header"],
                  count: selectedSkills ? selectedSkills.length : 0,
                })}
              </Typography>
            </Filter>
          </Grid>

          <Grid
            md={2}
            display={{ xs: "none", md: "flex" }}
            sx={{ justifyContent: "flex-end" }}
          >
            <Trio signedIn={user !== undefined} />
          </Grid>
        </Box>
      </Slide>

      <LogInModal
        anchorEl={loginPopoverAnchorEl}
        setAnchorEl={setLoginPopoverAnchorEl}
      />
      <WagePopover
        wagePopoverAnchorEl={wagePopoverAnchorEl}
        setWagePopoverAnchorEl={setWagePopoverAnchorEl}
        wageRange={wageRange}
        setWageRange={setWageRange}
      />
      <FilterPopover
        titleKey="role"
        promptKey="role_type"
        itemKey="roles"
        itemHeaderKey="roles_headers"
        popoverAnchorEl={rolePopoverAnchorEl}
        setPopoverAnchorEl={setRolePopoverAnchorEl}
        selected={selectedRole}
        setSelected={setSelectedRole}
        secondPromptKey="schedule_type"
        secondDictKey="schedules"
        secondHeaderKey="schedules_headers"
        selectedSecondItems={selectedSchedule}
        setSelectedSecondItems={setSelectedSchedule}
      />
      <FilterPopover
        titleKey="perks"
        promptKey="perks_type"
        itemKey="perks"
        itemHeaderKey="perks_headers"
        popoverAnchorEl={benefitsPopoverAnchorEl}
        setPopoverAnchorEl={setBenefitsPopoverAnchorEl}
        selected={selectedBenefits}
        setSelected={setSelectedBenefits}
      />
      <FilterPopover
        titleKey="skills"
        promptKey="skills_type"
        itemKey="skills"
        itemHeaderKey="skills_headers"
        popoverAnchorEl={skillsPopoverAnchorEl}
        setPopoverAnchorEl={setSkillsPopoverAnchorEl}
        selected={selectedSkills}
        setSelected={setSelectedSkills}
      />

      {/* TODO not sure how i feel about this -- too agro? */}
      {/* TODO fix box, it wont close when clicked away */}
      <Modal open={showNewUserFlow} onClose={() => setShowNewUserFlow(false)}>
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Card sx={{ width: { xs: "90vw", md: 600 } }}>
            <Box sx={{ mt: 2 }}>
              <NewUserFlow />
            </Box>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}
