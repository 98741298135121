import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import {
  CheckRounded,
  CloseRounded,
  ErrorRounded,
  HelpCenterRounded,
  HighlightOffRounded,
  InfoRounded,
  LockPersonRounded,
  SendRounded,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const ExampleRoleApply = ({
  open,
  setOpen,
  businessName,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  businessName: string;
}) => {
  const { t } = useTranslation(["common", "apply"]);
  const [openCustomQuestions, setOpenCustomQuestions] = useState(false);
  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
      }}
      onClose={() => setOpen(false)}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },

          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography level="cardHeader">
            Example Role at {businessName}
          </Typography>

          <IconButton
            variant="plain"
            color="primary"
            size="md"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseRounded />
          </IconButton>
        </Box>

        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Stack
          spacing={2}
          sx={{
            maxHeight: open ? "55vh" : "none",
            overflowY: "scroll",
            my: 1,
          }}
        >
          <Alert startDecorator={<ErrorRounded />} color="warning">
            <Typography level="body1">
              Real applications require users to sign in with their phone number
              and they'll receive a confirmation text when they apply.
            </Typography>
          </Alert>
          <Alert startDecorator={<HelpCenterRounded />} color="info">
            <Typography level="body1">
              Businesses can ask for additional information, such as past
              experiences and referrals.
            </Typography>
          </Alert>
          <Typography level="h6" sx={{ fontWeight: "bold" }}>
            1 Question
          </Typography>

          <Card
            variant="outlined"
            sx={{ cursor: "pointer", boxShadow: "none" }}
          >
            <Typography level="h6" sx={{ fontWeight: "bold" }}>
              {t("apply:custom_questions")}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Stack spacing={1}>
              <Typography
                startDecorator={
                  <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
                }
                sx={{
                  textDecoration: "underline",
                }}
                noWrap
                onClick={() => setOpenCustomQuestions(true)}
              >
                Why are you an awesome candidate for {businessName}?
              </Typography>
            </Stack>
          </Card>
          <Typography
            level="h6"
            sx={{
              fontWeight: "700",
              color: "text",
            }}
          >
            {t("enter_name_on_apply", {
              ns: "apply",
            })}
          </Typography>

          <FormControl>
            <Input
              placeholder={t("name_on_application", { ns: "apply" }) || ""}
              color="neutral"
              autoFocus
              startDecorator={<LockPersonRounded />}
              sx={{
                borderRadius: 1,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                height: "3rem",
              }}
            />
            <Button
              fullWidth
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                border: "1px solid #d8d8de",
                borderTop: "none",
              }}
              type="submit"
              color="primary"
              startDecorator={<SendRounded />}
              onClick={() => setOpen(false)}
            >
              {t("apply", { ns: "common" })}
            </Button>
            <Typography level="body2" sx={{ color: "text.tertiary", m: 1 }}>
              {t("never_store_name_with_profile", {
                ns: "apply",
                business: businessName,
              })}
            </Typography>
          </FormControl>

          <Modal
            open={openCustomQuestions}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClose={() => {
              setOpenCustomQuestions(false);
            }}
          >
            <Card
              sx={{
                maxHeight: { xs: "80vh", sm: "70vh" },
                outline: "none",
                width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
                pb: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography level="h4" sx={{ fontWeight: 600 }}>
                  {t("apply:questions_from_business", {
                    business: businessName,
                    count: 1,
                  })}
                </Typography>

                <Chip
                  size="sm"
                  variant="solid"
                  color="neutral"
                  sx={{ fontWeight: "bold", borderColor: "black" }}
                  onClick={() => setOpenCustomQuestions(false)}
                  startDecorator={<CheckRounded />}
                >
                  {t("common:save").toUpperCase()}
                </Chip>
              </Box>
              <Divider sx={DIVIDER_HEADER_SX} />
              <Box
                sx={{
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": { display: "none" },
                  py: 2,
                }}
              >
                <Stack spacing={2}>
                  <FormControl key={0}>
                    <FormLabel>
                      1. Why are you an awesome candidate for {businessName}?{" "}
                    </FormLabel>
                    <Textarea />
                  </FormControl>
                </Stack>
              </Box>
            </Card>
          </Modal>
        </Stack>
      </Card>
    </Modal>
  );
};

export default ExampleRoleApply;
