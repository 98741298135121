import { Modal, ModalDialog, Typography, Button, Divider, Box } from "@mui/joy";
import React from "react";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import { AnnouncementRounded } from "@mui/icons-material";
import ConfirmationModalContext from "../../Context/ConfirmationModalContext";
import EmailSignIn from "../Authentication/EmailSignIn";
import PhoneSignIn from "../Authentication/PhoneSignIn";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";

interface ConfirmationModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  setShow,
}) => {
  const confirmationModalContext = React.useContext(ConfirmationModalContext);
  const { t } = useTranslation([
    confirmationModalContext?.namespace || "",
    "common",
  ]);
  const [showReauth, setShowReauth] = React.useState<"user" | "business">();

  const handleCancel = () => {
    setShowReauth(undefined);
    setShow(false);
  };

  const handleConfirm = () => {
    logEvent(analytics, "login", {
      method: "confirmation_modal",
    });
    if (confirmationModalContext?.requiresReauth) {
      setShowReauth(confirmationModalContext.requiresReauth);
      confirmationModalContext.setRequiresReauth(undefined);
      return;
    }
    setShow(false);
    setShowReauth(undefined);
    confirmationModalContext?.toDo();
  };

  //enter {0}, {1}, etc. in the translation string to replace with the corresponding value in the array
  const formatString = (str: string, val?: string[]) => {
    if (val) {
      for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
      }
    }
    return str;
  };

  if (showReauth) {
    return (
      <Modal open={show}>
        <ModalDialog
          variant="outlined"
          role="alertdialog"
          sx={{ width: { xs: "90vw", md: "30vw" } }}
        >
          <Box>
            <Typography level="h6" startDecorator={<AnnouncementRounded />}>
              {t("common:reauth_required")}
            </Typography>
            <Divider sx={{ ...DIVIDER_HEADER_SX, mb: 2 }} />
            {showReauth === "business" && (
              <EmailSignIn onComplete={handleConfirm} noDisclaimer />
            )}
            {showReauth === "user" && (
              <PhoneSignIn onComplete={handleConfirm} noDisclaimer />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 1,
              }}
            >
              <Button onClick={handleCancel} color="neutral" variant="plain">
                {t("cancel", { ns: ["common"] })}
              </Button>
            </Box>
          </Box>
        </ModalDialog>
      </Modal>
    );
  }

  return (
    <Modal open={show}>
      <ModalDialog
        variant="outlined"
        role="alertdialog"
        sx={{ maxWidth: { xs: "90vw", md: "30vw" } }}
      >
        <Typography level="h6" startDecorator={<AnnouncementRounded />}>
          {t(confirmationModalContext?.mainMessageKey || "", {
            ns: [confirmationModalContext?.namespace || ""],
          })}
        </Typography>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Typography level="body1" sx={{ color: "text.tertiary", my: 3 }}>
          {formatString(
            t(confirmationModalContext?.subtitleKey || "", {
              ns: [confirmationModalContext?.namespace || ""],
            }),
            confirmationModalContext?.toInsertInSubtitle || []
          )}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
          <Button onClick={handleCancel} color="neutral" variant="plain">
            {t("cancel", { ns: ["common"] })}
          </Button>
          <Button onClick={() => handleConfirm()} color="warning">
            {t(confirmationModalContext?.actionKey || "", {
              ns: [confirmationModalContext?.namespace || "", "common"],
            })}
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModal;
