import React from "react";

interface ToastContextType {
  show: boolean;
  setShow: (show: boolean) => void;
  namespace: string;
  setNamespace: (namespace: string) => void;
  messageKey: string;
  setMessageKey: (messageKey: string) => void;
  actionKey: string;
  setActionKey: (actionKey: string) => void;
  toDo: () => void;
  setToDo: (toDo: () => () => void) => void;
  toInsertInMessage: string[] | undefined;
  setToInsertInMessage: (toInsertInMessage: string[] | undefined) => void;
}

const ToastContext = React.createContext<ToastContextType>({
  show: false,
  setShow: () => {},
  namespace: "",
  setNamespace: () => {},
  messageKey: "",
  setMessageKey: () => {},
  actionKey: "",
  setActionKey: () => {},
  toDo: () => {},
  setToDo: () => {},
  toInsertInMessage: [],
  setToInsertInMessage: () => {},
});

export default ToastContext;
