import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  FormControl,
  FormLabel,
  Card,
  Chip,
  FormHelperText,
  Typography,
  Input,
  Checkbox,
  Textarea,
  Box,
  Divider,
  Stack,
  IconButton,
} from "@mui/joy";
import SectionView from "../Business/SectionView";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { Item } from "../../Services/Db/types";

interface SectionOrderProps {
  sections: Item[];
  setSections: (sections: Item[]) => void;
  setSave: (save: boolean) => void;
}

const SectionOrder: React.FC<SectionOrderProps> = ({
  sections,
  setSections,
  setSave,
}) => {
  useEffect(() => {
    console.log("sections", sections);
  }, [sections]);

  const moveUp = (index: number) => {
    if (index === 0) return; //can't move up
    const newSections = [...sections]; //copy sections
    const temp = newSections[index]; //copy section to tempt

    //swap below and current
    newSections[index] = newSections[index - 1];
    newSections[index - 1] = temp;

    setSections(newSections);
    setSave(true);
  };

  const moveDown = (index: number) => {
    if (index === sections.length - 1) return; //can't move down
    const newSections = [...sections]; //copy sections
    const temp = newSections[index]; //copy section to tempt

    //swap below and current
    newSections[index] = newSections[index + 1];
    newSections[index + 1] = temp;

    setSections(newSections);
    setSave(true);
  };

  return (
    <Stack spacing={2}>
      {sections.map((section, index) => (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              ml: { xs: 0, md: -6 },
              pr: { xs: 0, md: 1 },
              "&:hover + #section-view": {
                boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.25)",
              },
            }}
          >
            <IconButton onClick={() => moveUp(index)} disabled={index === 0}>
              <ArrowUpwardRounded />
            </IconButton>

            <IconButton
              onClick={() => moveDown(index)}
              disabled={index === sections.length - 1}
            >
              <ArrowDownwardRounded />
            </IconButton>
          </Box>
          <Box id="section-view" sx={{ width: "100%", borderRadius: 3 }}>
            <SectionView
              editable
              setSave={setSave}
              sections={sections}
              setSections={setSections}
              index={index}
            />
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default SectionOrder;
