import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import { Check } from "@mui/icons-material";
import { Typography } from "@mui/joy";

const StepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  display: "flex",
  height: 40,
  width: 40,
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  marginLeft: -7,
  backgroundColor: theme.palette.grey[300],
  ...(ownerState.active && {
    transition: "background-color .3s ease-in-out, transform 0.3s ease-in-out",
    transform: "scale(1.1)",
    backgroundColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    transition: "background-color .3s ease-in-out, transform 0.3s ease-in-out",
    transform: "scale(0.8)",
    backgroundColor: theme.palette.primary.main,
  }),
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {completed ? (
        <Check
          sx={{
            color: "common.white",
            fontSize: 20,
            fontWeight: 600,
          }}
        />
      ) : (
        <Typography
          sx={{ color: "common.white", fontSize: 20, fontWeight: 600 }}
        >
          {props.icon}
        </Typography>
      )}
    </StepIconRoot>
  );
}

export default StepIcon;
