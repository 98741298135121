import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import ReferenceItem from "../Components/Common/ReferenceItem";

import { useTranslation } from "react-i18next";
import {
  Add,
  CheckCircleRounded,
  Edit,
  HighlightOffRounded,
  NewReleasesRounded,
} from "@mui/icons-material";

import { SectionProps } from "./sectionProps";

const ReferenceSection: React.FC<SectionProps> = ({
  setModalOpen,
  setSelectedId,
  references,
}) => {
  const { t } = useTranslation(["create_profile"]);
  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={
            references && references.length > 0 ? (
              <CheckCircleRounded sx={{ color: "success.solidBg" }} />
            ) : (
              <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
            )
          }
          level="h4"
          sx={{ fontWeight: 600 }}
        >
          {t("reference", { ns: ["create_profile"] })}
        </Typography>
        <IconButton
          onClick={() => {
            setModalOpen(true);
            if (setSelectedId) setSelectedId(null);
          }}
        >
          <Add />
        </IconButton>
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />
      <Stack spacing={3} divider={<Divider />} sx={{ mt: 2 }}>
        {references?.length ? (
          references.map((ref) => (
            <Box
              //TODO: this should open experience modal to edit role
              onClick={() => {
                setModalOpen(true);
                if (setSelectedId) setSelectedId(ref.id!);
              }}
              sx={{ cursor: "pointer" }}
              key={ref.id}
            >
              <ReferenceItem
                name={ref.name}
                referenceType={ref.relationship}
                email={ref.email}
                phone={ref.phone}
              />
            </Box>
          ))
        ) : (
          <Button
            variant="outlined"
            color="neutral"
            onClick={() => {
              setModalOpen(true);
              if (setSelectedId) setSelectedId(null);
            }}
          >
            {t("add_reference", { ns: ["create_profile"] })}
          </Button>
        )}
      </Stack>
    </Card>
  );
};

export default ReferenceSection;
