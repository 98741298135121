import React, { useState, useEffect } from "react";
import useKeyToDict from "../../Utils/useKeyToDict";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import {
  Stack,
  Button,
  Input,
  Typography,
  FormControl,
  FormLabel,
  Textarea,
  Card,
  Box,
  IconButton,
  Modal,
  Divider,
  Chip,
  Alert,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import {
  Check,
  CheckBoxOutlineBlankRounded,
  CheckCircleOutlineOutlined,
  CheckCircleOutlineRounded,
  CheckRounded,
  CloseRounded,
} from "@mui/icons-material";

import { Listings } from "../../Services/Db/types";

interface QuestionModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listing: Listings;
  businessName: string;
  customAnswers: string[];
  setCustomAnswers: React.Dispatch<React.SetStateAction<string[]>>;
}

const QuestionModal: React.FC<QuestionModalProps> = ({
  open,
  setOpen,
  listing,
  businessName,
  customAnswers,
  setCustomAnswers,
}) => {
  const { t } = useTranslation();

  //load blank strings into each custom question
  useEffect(() => {
    if (listing.custom_questions) {
      setCustomAnswers(listing.custom_questions.map((q) => ""));
    }
  }, [listing.custom_questions]);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          outline: "none",
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="h4" sx={{ fontWeight: 600, lineHeight: "120%" }}>
            {t("apply:questions_from_business", {
              business: businessName,
              count: listing.custom_questions.length,
            })}
          </Typography>
          <IconButton
            sx={{ fontWeight: "bold", borderColor: "black" }}
            onClick={() => setOpen(false)}
          >
            <CloseRounded />
          </IconButton>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Box
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": { display: "none" },
            py: 2,
          }}
        >
          <Stack spacing={2}>
            {listing.custom_questions.map((q, i) => {
              return (
                <FormControl key={i}>
                  <FormLabel sx={{ fontSize: "1em" }}>
                    {i + 1}: {q}
                  </FormLabel>
                  <Textarea
                    defaultValue={customAnswers[i]}
                    onChange={(e) => {
                      const val = e.target.value;
                      setCustomAnswers((prev) => {
                        const newQuestions = [...prev];
                        newQuestions[i] = val;

                        return newQuestions;
                      });
                    }}
                  />
                </FormControl>
              );
            })}
            <Button
              size="sm"
              variant="solid"
              startDecorator={<CheckCircleOutlineRounded />}
              onClick={() => setOpen(false)}
            >
              {t("common:done")}
            </Button>
          </Stack>
        </Box>
      </Card>
    </Modal>
  );
};

export default QuestionModal;
