import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import {
  APP_ROUTES,
  CARD_OVERFLOW_SX,
  DIVIDER_HEADER_SX_NO_Y,
  HEADER_TYPES,
} from "../../Utils/Constants";
import AppBarContext from "../../Context/AppBarContext";
import dayjs from "dayjs";

import {
  Typography,
  Stack,
  Divider,
  Card,
  Box,
  IconButton,
  CardOverflow,
  ListItemDecorator,
  List,
  ListItem,
  ListItemContent,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

import {
  ArrowForwardIosRounded,
  CheckCircleRounded,
  HowToRegRounded,
  PersonRounded,
  UnpublishedRounded,
} from "@mui/icons-material";

const ProfileCard: React.FC = () => {
  const { t } = useTranslation(["job_seeker"]);
  const navigate = useNavigate();
  const appBarContext = useContext(AppBarContext);

  return (
    <Card>
      <CardOverflow
        sx={CARD_OVERFLOW_SX}
        onClick={() => {
          // change header accordingly
          appBarContext?.setBackKey("back_to_account");

          // navigate to profile page
          navigate(APP_ROUTES.CREATE_PROFILE);
        }}
      >
        <Typography level="cardHeader" startDecorator={<PersonRounded />}>
          {t("profile", { ns: "job_seeker" })}
        </Typography>

        <IconButton>
          <ArrowForwardIosRounded />
        </IconButton>
      </CardOverflow>
      {/* <Divider sx={DIVIDER_HEADER_SX_NO_Y} />
      <Box sx={{ mt: 2 }}>
        <Typography level="body2">
          {t("profile_description", { ns: "job_seeker" })}
        </Typography>
        <p>TODO: do we like this? what's helpful here?</p>
        <Typography
          startDecorator={<CheckCircleRounded sx={{ color: "green" }} />}
        >
          Experience
        </Typography>
        <Typography
          startDecorator={<UnpublishedRounded sx={{ color: "red" }} />}
        >
          Education
        </Typography> 
      </Box>*/}
    </Card>
  );
};

export default ProfileCard;
