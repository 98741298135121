import React from "react";
import { Box, Stack } from "@mui/joy";

interface PageSpacerProps {
  children: JSX.Element;
}

const PageSpacer: React.FC<PageSpacerProps> = ({ children }) => {
  return (
    <Stack
      spacing={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "> *": { width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" } },
        pb: 20,
      }}
    >
      {children}
    </Stack>
  );
};

export default PageSpacer;
