import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  AddAlertRounded,
  ChevronLeft,
  ChevronLeftRounded,
  HelpCenterRounded,
  InfoRounded,
  KeyboardBackspace,
  KeyboardBackspaceRounded,
  NewReleasesRounded,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import InlineBusinessSelectMap from "../Maps/InlineBusinessSelectMap";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "../../Context/AuthContext";
import { FullBusinessService } from "../../Services/Db/business";
import { getAllLocationsByPid } from "../../Services/Db/utils";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import { updateProfile } from "firebase/auth";
import { LocationList } from "./LocationList";

const BusinessLocationSetup = ({
  setDone,
}: {
  setDone: (b: boolean) => void;
}) => {
  const { t } = useTranslation("business");
  const [newPlaceId, setNewPlaceId] = useState<string>();
  const { business } = useBusinessAuth();
  const placeDetails = usePlaceDetails(newPlaceId, ["name"]);
  const [places, setPlaces] = useState<string[]>([]);
  const [loadedAllPlaces, setLoadedAllPlaces] = useState(false);
  const [loadingPlaces, setLoadingPlaces] = useState(false);
  const [unverified, setUnverified] = useState(false);

  async function handleLocationSelect() {
    setLoadingPlaces(true);
    if (newPlaceId && business) {
      const loadAllPlaces = async (pid: string) => {
        const locations = await getAllLocationsByPid(pid);
        setLoadedAllPlaces(true);
        if (locations.length === 0) {
          //setUnverified(true);
          setPlaces([pid]);
        } else {
          setPlaces(locations);
        }
        setLoadingPlaces(false);
      };
      loadAllPlaces(newPlaceId);
    }
  }

  return (
    <Card>
      <Box>
        <Typography
          startDecorator={
            !loadedAllPlaces ? (
              <InfoRounded />
            ) : (
              <Button
                variant="plain"
                sx={{ px: 0 }}
                onClick={() => setLoadedAllPlaces(false)}
              >
                <KeyboardBackspaceRounded />
              </Button>
            )
          }
          level="cardHeader"
        >
          {!loadedAllPlaces ? t("find_your_business") : t("confirm_locations")}
        </Typography>
        <Typography level="body2">
          {!loadedAllPlaces
            ? t("find_your_business_sub")
            : t("confirm_locations_sub")}
        </Typography>
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />
      <Box
        sx={{
          mt: 2,
        }}
      >
        {!loadedAllPlaces && (
          <Alert
            color="neutral"
            startDecorator={<NewReleasesRounded />}
            sx={{ mb: 2 }}
          >
            {t("find_your_business_description")}
          </Alert>
        )}

        {!loadedAllPlaces ? (
          <Fragment>
            <InlineBusinessSelectMap
              placeId={newPlaceId}
              setPlaceId={setNewPlaceId}
            />

            <Button
              fullWidth
              variant="solid"
              sx={{ my: 2 }}
              disabled={newPlaceId === undefined}
              onClick={handleLocationSelect}
              loading={loadingPlaces}
            >
              {t("find_your_business_btn", {
                ns: "business",
              })}
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <LocationList
              locations={places}
              setIsSetup={setDone}
              unverified={unverified}
              setLocations={setPlaces}
              canAdd
            />
          </Fragment>
        )}
      </Box>
    </Card>
  );
};

export default BusinessLocationSetup;
