import React, { useEffect, useRef, useState } from "react";
import { Popover } from "@mui/material";
import {
  Card,
  Typography,
  Divider,
  Stack,
  Box,
  Chip,
  Input,
  Slider,
  Button,
} from "@mui/joy";
import { Cancel, CheckRounded } from "@mui/icons-material";
import { CloseRounded, CheckCircleRounded } from "@mui/icons-material";
import { DIVIDER_HEADER_SX_MY } from "../Utils/Constants";
import { MapContext } from "../Context/MapContext";
import { useTranslation } from "react-i18next";
import WageSlider from "../Components/Inputs/WageSlider";

interface WagePopoverProps {
  wagePopoverAnchorEl: HTMLElement | null;
  setWagePopoverAnchorEl: (wagePopoverAnchorEl: HTMLElement | null) => void;
  wageRange: number[];
  setWageRange: (wage: number[]) => void;
}

const WagePopover: React.FC<WagePopoverProps> = ({
  wagePopoverAnchorEl,
  setWagePopoverAnchorEl,
  wageRange,
  setWageRange,
}) => {
  const { t } = useTranslation(["header", "common"]);
  const context = React.useContext(MapContext);

  const [changed, setChanged] = useState(false);

  const prevWage = useRef(wageRange);

  useEffect(() => {
    if (
      prevWage.current[0] !== wageRange[0] ||
      prevWage.current[1] !== wageRange[1]
    ) {
      setChanged(true);
    }
  }, [wageRange]);

  useEffect(() => {
    setChanged(false);
  }, [wagePopoverAnchorEl]);

  return (
    <Popover
      open={Boolean(wagePopoverAnchorEl)}
      anchorEl={wagePopoverAnchorEl}
      onClose={() => {
        setWagePopoverAnchorEl(null);
        context?.setWageRange(wageRange);
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Card
        variant="outlined"
        sx={{ width: { xs: "90vw", md: 300 }, borderRadius: 2 }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="h6" sx={{ fontWeight: "bold" }}>
            {t("wage", { ns: ["header"] })}
          </Typography>

          {changed ? (
            <CheckRounded
              cursor="pointer"
              onClick={() => {
                setWagePopoverAnchorEl(null);
                context?.setWageRange(wageRange);
              }}
              sx={{ fontSize: "1.5rem" }}
            />
          ) : (
            <CloseRounded
              cursor="pointer"
              onClick={() => {
                setWagePopoverAnchorEl(null);
                context?.setWageRange(wageRange);
              }}
              sx={{ fontSize: "1.5rem" }}
            />
          )}
        </Box>
        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Stack>
          <Typography level="selectLabel">
            {t("select_range", { ns: ["header"] })}
          </Typography>

          <Box sx={{ mx: 2, pt: 2 }}>
            <WageSlider
              defaultVal={context?.wageRange || [0, 100]}
              setWage={setWageRange}
            />
          </Box>
        </Stack>
      </Card>
    </Popover>
  );
};

export default WagePopover;
