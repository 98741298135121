import {
  ArrowForwardRounded,
  ArrowRightRounded,
  HailRounded,
  KeyboardArrowRightRounded,
  PaidRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ListItem,
  List,
  Button,
  Chip,
  Alert,
} from "@mui/joy";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/Utils/Constants";
import WageSurveyContext from "./WageSurveyContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";

const Intro = () => {
  const { t } = useTranslation("wage_comparison");

  const context = useContext(WageSurveyContext);

  const navigate = useNavigate();

  return (
    <Stack spacing={2}>
      <Stack
        sx={{
          borderRadius: 1,
          p: 5,
          bgcolor: "background.level1",
        }}
        spacing={2}
      >
        <Box>
          <Chip
            sx={{
              bgcolor: "common.white",
              color: "primary.main",
            }}
          >
            {t("intro.raleigh_durham")}
          </Chip>
        </Box>
        <Typography
          level="h1"
          sx={{ color: "common.white", lineHeight: "110%" }}
        >
          {t("intro.title")}
        </Typography>
        <Typography
          level="h4"
          sx={{ color: "common.white", lineHeight: "110%" }}
        >
          {t("intro.subtitle")}
        </Typography>
      </Stack>

      <Stack
        sx={{
          borderRadius: 1,
          p: 5,
          bgcolor: "background.level2",
        }}
        spacing={2}
      >
        <Typography level="h4" sx={{ fontWeight: "bold", mb: -1 }}>
          {t("intro.instructions.ask_for")}
        </Typography>
        <List
          sx={{
            "--List-gap": "8px",
            "--List-item-radius": "10px",
          }}
          orientation="horizontal"
          wrap
        >
          <ListItem
            variant="soft"
            color="primary"
            sx={{ width: "fit-content" }}
          >
            <Typography
              level="h4"
              sx={{ color: "inherit" }}
              startDecorator={<HailRounded />}
              onClick={() => {
                context?.setActive("job");
                logEvent(analytics, "start_survey", {});
              }}
            >
              {t("intro.instructions.current_job")}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography>{t("common:and")}</Typography>
          </ListItem>
          <ListItem
            variant="soft"
            color="primary"
            sx={{ width: "fit-content" }}
            onClick={() => {
              context?.setActive("job");
              logEvent(analytics, "start_survey", {});
            }}
          >
            <Typography
              level="h4"
              startDecorator={<PaidRounded />}
              sx={{ color: "inherit" }}
            >
              {t("intro.instructions.hourly_wage")}
            </Typography>
          </ListItem>
        </List>
        <Typography level="h4" sx={{ fontWeight: "bold" }}>
          {t("intro.instructions.then")}
        </Typography>
      </Stack>
      <Box>
        <Alert
          color="neutral"
          variant="outlined"
          startDecorator={<VisibilityOffRounded />}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          {t("intro.instructions.anonymous")}
        </Alert>
        <Button
          fullWidth
          sx={{
            fontSize: "1.5rem",
            p: 2,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: "none",
            textAlign: "left",
          }}
          endDecorator={<ArrowForwardRounded />}
          onClick={() => {
            context?.setActive("job");
            logEvent(analytics, "start_survey", {});
          }}
        >
          {t("intro.action")}
        </Button>
      </Box>
      <Button
        startDecorator={<HailRounded />}
        variant="soft"
        onClick={() => navigate(APP_ROUTES.HOME)}
      >
        {t("intro.unemployed")}
      </Button>
    </Stack>
  );
};

export default Intro;
