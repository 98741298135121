import { APP_ROUTES } from "../../Utils/Constants";
import { InfoRounded, StoreRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Stack,
  Input,
  Typography,
  FormControl,
  CircularProgress,
  LinearProgress,
  FormLabel,
  FormHelperText,
  Tooltip,
} from "@mui/joy";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../firebase";
import { SignInProps } from "./PhoneSignIn";
import useToast from "../../Utils/useToast";
import { FullBusinessService } from "../../Services/Db/business";
import TermsAndPrivacySub from "../Common/TermsAndPrivacySub";
import ForgotPassword from "./ForgotPassword";

const EmailSignIn: React.FC<SignInProps> = ({
  onComplete,
  noDisclaimer,
  newUser,
}) => {
  const { t } = useTranslation(["common"]);

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = React.useState<boolean>(false);
  const toast = useToast();

  async function handleSubmit(e: React.MouseEvent<HTMLAnchorElement>) {
    setLoading(true);
    if (await FullBusinessService.getBusinessExists(email)) {
      // first sign in with email and password
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          onComplete(userCredential.user);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          if (errorCode === "auth/user-not-found") {
            createUserWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                sendEmailVerification(userCredential.user);
                // Signed in
                onComplete(userCredential.user);
                // ...
              })
              .catch((error) => {
                const errorCode = error.code;

                toast("user_flow", error.code, "", () => {});
                // ..
              });
          } else {
            toast("user_flow", error.code, "", () => {});
          }
          // ..
        });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          sendEmailVerification(userCredential.user);
          // Signed in
          onComplete(userCredential.user);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;

          toast("user_flow", error.code, "", () => {});
          // ..
        });
    }
    setLoading(false);
  }

  if (forgotPassword) {
    return <ForgotPassword setForgotPassword={setForgotPassword} />;
  }

  return (
    <Stack spacing={2}>
      <FormControl required>
        <Input
          placeholder={t("common:email") || ""}
          type="email"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
          endDecorator={
            <Tooltip
              title={t("common:email_new") || ""}
              sx={{ maxWidth: 200 }}
              arrow
            >
              <InfoRounded sx={{ color: "text.tertiary" }} />
            </Tooltip>
          }
        />
      </FormControl>

      <FormControl required>
        <Input
          placeholder={
            (newUser ? t("common:create_password") : t("common:password")) || ""
          }
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>
      <Button
        type="submit"
        fullWidth
        variant="outlined"
        onClick={(e) => handleSubmit(e)}
      >
        {loading ? (
          <LinearProgress />
        ) : newUser ? (
          t("common:create_account")
        ) : (
          t("common:sign_in") || ""
        )}
      </Button>
      {noDisclaimer ? null : <TermsAndPrivacySub noWageDisclaimer email />}
      {newUser ? null : (
        <Button
          type="submit"
          fullWidth
          variant="plain"
          size="sm"
          sx={{
            my: 0,
            color: "text.secondary",
            textDecoration: "underline",
          }}
          onClick={(e) => setForgotPassword(true)}
        >
          {t("common:forgot_password")}
        </Button>
      )}
    </Stack>
  );
};

export default EmailSignIn;
