import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate, useLocation, generatePath } from "react-router-dom";
import { IconButton, Typography, Box, Divider, Stack } from "@mui/joy";
import { OpenInNewRounded } from "@mui/icons-material";
import SelectGroup from "../Components/Inputs/SelectGroup";
import AppBarContext from "../Context/AppBarContext";
import {
  APP_ROUTES,
  HEADER_TYPES,
  DIVIDER_HEADER_SX_MY,
} from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import useKeyToDict from "../Utils/useKeyToDict";
import SectionCreator from "../Components/Business/SectionCreator";
import SectionView from "../Components/Business/SectionView";
import SectionOrder from "../Components/Common/SectionOrder";
import { useBusinessAuth } from "../Context/AuthContext";
import { Item } from "../Services/Db/types";
import { FullBusinessService } from "../Services/Db/business";
import { DocumentReference } from "firebase/firestore";
import { usePlaceDetails } from "src/Components/Maps/PlaceDetails";
import LocationDropdown from "src/Components/Inputs/LocationDropdown";
import SelectLocationAlert from "src/Components/Business/SelectLocationAlert";
import { BusinessService } from "src/Services/Db/service";

const PageMaker = () => {
  const navigate = useNavigate();
  const context = useContext(AppBarContext);
  const { t } = useTranslation(["business"]);

  const { business } = useBusinessAuth();

  const [sections, setSections] = useState<Item[]>([]);
  const [loadedSections, setLoadedSections] = useState(false);
  const [locations, setLocations] = useState<DocumentReference[]>([]);
  const [loadedLocations, setLoadedLocations] = useState(false);
  const [save, setSave] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);

  useEffect(() => {
    if (business && !loadedLocations) {
      FullBusinessService.getBusinessProfile(business.email!)
        .then((profile) => {
          if (profile && profile.locations) {
            setLocations(profile.locations);
            if (profile.locations.length === 1) {
              setSelectedLocation(profile.locations[0].id);
            }
          }
        })
        .finally(() => {
          setLoadedLocations(true);
        });
    }
  }, [business]);

  useEffect(() => {
    if (selectedLocation) {
      setLoadedSections(false);
      BusinessService.getOne(selectedLocation).then((business) => {
        if (business && business.sections) {
          setSections(business.sections);
          setLoadedSections(true);
        } else {
          setSections([]);
          setLoadedSections(true);
        }
      });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (save) {
      handleSave();
      setSave(false);
    }
  }, [save]);

  const handleSave = () => {
    if (selectedLocation && loadedSections) {
      BusinessService.update(selectedLocation, {
        sections: sections,
      });
    }
  };

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  return (
    <React.Fragment>
      <PageSpacer>
        <Stack spacing={2}>
          <Box>
            <Typography level="sectionHeader">
              {t("page_maker_header")}
            </Typography>
            <Typography
              level="body1"
              endDecorator={
                <IconButton
                  disabled={!selectedLocation}
                  onClick={() => {
                    navigate(
                      generatePath(APP_ROUTES.BUSINESS_PAGE, {
                        businessId: selectedLocation ?? "",
                      })
                    );
                  }}
                >
                  <OpenInNewRounded />
                </IconButton>
              }
            >
              {t("page_maker_subtitle")}
            </Typography>
          </Box>
          <Divider />
          {locations.length > 1 && (
            <LocationDropdown
              locations={locations}
              setSelected={setSelectedLocation}
              defaultVal={t("common:select_location") ?? "Select a location"}
            />
          )}
          {selectedLocation ? (
            <Fragment>
              <SectionOrder
                sections={sections}
                setSections={setSections}
                setSave={setSave}
              />
              <SectionCreator
                sections={sections}
                setSections={setSections}
                setSave={setSave}
              />
            </Fragment>
          ) : (
            <SelectLocationAlert
              alert={t("common:select_location_pagemaker") ?? undefined}
            />
          )}
        </Stack>
      </PageSpacer>
    </React.Fragment>
  );
};

export default PageMaker;
