import { useState, useEffect } from "react";
import {
  useTheme,
  AspectRatio,
  Box,
  Button,
  Card,
  IconButton,
  Typography,
  Divider,
  Stack,
  Chip,
} from "@mui/joy";
import {
  Close,
  ChevronRight,
  CloseRounded,
  ArrowForwardIos,
} from "@mui/icons-material";
import * as React from "react";
import {
  APP_ROUTES,
  DEFAULT_MARKER_VALS,
  DIVIDER_HEADER_SX_MY,
} from "../../Utils/Constants";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import PopupSlider from "./ApplySteps";
import AppBarContext from "../../Context/AppBarContext";
import { generatePath, useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/base";

interface MarkerPopupProps {
  placeId: string;
  closeCallback: () => void;
  // FIXME just dawned on me this should be separated with tips for display purposes (I think?)
  wageRoleMap: { [key: string]: number };
}

const MarkerPopup: React.FC<MarkerPopupProps> = ({
  placeId,
  closeCallback,
  wageRoleMap,
}) => {
  const [open, setOpen] = React.useState<boolean>(true);

  const { t } = useTranslation(["common"]);

  const [header, setHeader] = useState("");

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const context = React.useContext(AppBarContext);
  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, [placeId]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
        closeCallback();
      }}
    >
      <Slide
        direction={mobile ? "down" : "left"}
        in={open}
        timeout={400}
        unmountOnExit
        mountOnEnter
      >
        <Card
          sx={{
            width: { xs: "94vw", md: 400 },
            position: "fixed",
            pb: 0,
            top: context?.alertOffset
              ? { xs: context?.alertOffset + 10, md: context?.alertOffset + 80 }
              : { xs: "1vh", md: "6rem" },
            right: { xs: "3vw", md: "5.5vw" },
            boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.25)",
            my: 0,
          }}
          id="#marker-popup"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              my: -1,
              mr: -1,
            }}
          >
            <Button
              variant="plain"
              color="neutral"
              sx={{
                mt: 0,
                px: 0.5,
                ml: -1,
                overflowX: "hidden",
              }}
              onClick={() =>
                navigate(
                  generatePath(APP_ROUTES.BUSINESS_PAGE, {
                    businessId: placeId,
                  })
                )
              }
              // endDecorator={<ChevronRight />}
            >
              <Typography level="cardHeader" noWrap>
                {header}
              </Typography>
            </Button>

            <IconButton
              variant="plain"
              color="primary"
              size="md"
              // sx={{ position: "absolute", top: "1rem", right: "0.5rem" }}
              onClick={() => {
                closeCallback();
                setOpen(false);
              }}
            >
              <CloseRounded />
            </IconButton>
          </Box>

          <Divider sx={DIVIDER_HEADER_SX_MY} />
          <PopupSlider placeId={placeId} setHeader={setHeader} />
        </Card>
      </Slide>
    </ClickAwayListener>
  );
};

export default MarkerPopup;
