import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  AddAlertRounded,
  ChevronLeft,
  ChevronLeftRounded,
  InfoRounded,
  KeyboardBackspace,
  KeyboardBackspaceRounded,
  NewReleasesRounded,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/joy";
import InlineBusinessSelectMap from "../Maps/InlineBusinessSelectMap";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "../../Context/AuthContext";
import { FullBusinessService } from "../../Services/Db/business";
import { getAllLocationsByPid } from "../../Services/Db/utils";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import { updateProfile } from "firebase/auth";
import { LocationList } from "./LocationList";
import RoleLocations from "../Inputs/RoleEditing/RoleLocations";
import BusinessLocationSetup from "./BusinessLocationSetup";
import BusinessListingSetup from "./BusinessListingSetup";

interface BusinessSetupProps {
  awaitingVerification: boolean;
  setIsSetup: (b: boolean) => void;
}

const BusinessSetup: React.FC<BusinessSetupProps> = ({
  awaitingVerification,
  setIsSetup,
}) => {
  const [locationsSetup, setLocationsSetup] = useState(false);
  const { business } = useBusinessAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (business) {
      FullBusinessService.getBusinessProfile(business.email!)
        .then((b) => {
          if (b?.locations && b.locations.length > 0) {
            setLocationsSetup(true);
          } else if (b?.listings && b.listings.length > 0) {
            setIsSetup(true);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [business]);

  if (loading) {
    return (
      <Card>
        <LinearProgress />
      </Card>
    );
  }
  if (locationsSetup) {
    return <BusinessListingSetup setDone={setIsSetup} />;
  } else {
    return <BusinessLocationSetup setDone={setLocationsSetup} />;
  }
};
export default BusinessSetup;
