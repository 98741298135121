import React from "react";
import { GPTMessage } from "src/Services/Db/types";

interface GPTContextType {
  messages: GPTMessage[];
  setMessages: (messages: GPTMessage[]) => void;
  suggestions: string[];
  setSuggestions: (suggestions: string[]) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  recommendations: string[];
  setRecommendations: (recommendations: string[]) => void;
  showRecommendations: boolean;
  setShowRecommendations: (showRecommendations: boolean) => void;
  noResults: boolean;
  setNoResults: (noResults: boolean) => void;
}

// Context for setting the correct app bar
const GPTContext = React.createContext<GPTContextType | null>(null);

export default GPTContext;
