import { useAuth } from "../../Context/AuthContext";
import { APP_ROUTES } from "../../Utils/Constants";
import { Businesses } from "../../Services/Db/types";
import { employedSignUp } from "../../Services/Db/utils";
import { Box, CircularProgress } from "@mui/joy";
import React from "react";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

export interface EmployedSignUpData {
  business: Businesses;
  wage: number;
  tips: number;
  role: string;
}

export interface StudentSignUpData {}

export interface UnemployedSignUpData {}

export interface SignUpData {
  employedData?: EmployedSignUpData;
  studentData?: StudentSignUpData;
  unemployedData?: UnemployedSignUpData;
  uid: string;
}

const SignUp: React.FC = () => {
  const location = useLocation();
  const state: SignUpData = location.state;
  const [confirmed, setConfirmed] = React.useState<boolean>(false);
  const [failed, setFailed] = React.useState<boolean>(false);

  if (state.employedData && !confirmed) {
    console.log("employed signup");
    setConfirmed(true);
    //employedSignUp(state.uid, state.employedData)
    //  .then(() => setConfirmed(true))
    //  .catch(() => setFailed(true));
  }

  if (confirmed) return <Navigate to={APP_ROUTES.JOB_SEEKER_HOME} />;
  else if (failed) return <Navigate to={APP_ROUTES.NEW_USER_FLOW} />;
  // FIXME this should include error
  else
    return (
      <Box
        sx={{
          height: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
};

export default SignUp;
