import * as React from "react";
import Map from "./Map";
import WageMapMarker from "./WageMapMarker";
import { calculateColor, getMaxValueKey } from "./Utils";
import {
  getBusinessesOnMap,
  loadSelectedMarkerMapByPid,
} from "../../Services/Db/utils";
import { MapField } from "../../Services/Db/types";
import createPriceMarker from "./PriceMarker";
import MarkerPopup from "../Apply/MarkerPopup";
import { MapContext } from "../../Context/MapContext";
import { useTheme } from "@mui/joy";
import AppBarContext from "../../Context/AppBarContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import createClusterMarker from "./ClusterMarker";
import LocationMarkerBasic from "./LocationMarkerBasic";
import useMarkers from "./UseMarkers";
import { DEFAULT_MAP_PARAMS } from "src/Utils/Constants";

const WageMap: React.FC = () => {
  // instantiate array using useref
  const [selectedMarker, setSelectedMarker] = React.useState<string>();
  const [selectedMarkerMap, setSelectedMarkerMap] = React.useState<MapField>();
  const [center, setCenter] = React.useState<google.maps.LatLng>(
    new google.maps.LatLng(DEFAULT_MAP_PARAMS.lat, DEFAULT_MAP_PARAMS.lng)
  );
  const [zoom, setZoom] = React.useState<number>(DEFAULT_MAP_PARAMS.zoom);
  const [bounds, setBounds] = React.useState<google.maps.LatLngBounds>();

  const context = React.useContext(MapContext);

  const { markers, maxWage } = useMarkers(zoom, center, bounds);

  function handleIdle(m: google.maps.Map) {
    const mapZoom = m.getZoom();
    if (mapZoom) {
      setZoom(mapZoom);
    }
    const mapCenter = m.getCenter();
    if (mapCenter) {
      setCenter(mapCenter);
    }
    setBounds(m.getBounds());
  }

  React.useEffect(() => {
    if (context) {
      const bids = getBusinessesOnMap(markers, bounds);
      context.setMarkerIds(bids);
    }
  }, [markers]);

  React.useEffect(() => {
    if (context?.placeId) {
      setSelectedMarker(context?.placeId);
      const loadSelectedMarkerMap = async (pid: string) => {
        const markerMap: MapField = await loadSelectedMarkerMapByPid(pid);
        setSelectedMarkerMap(markerMap);
      };
      loadSelectedMarkerMap(context?.placeId);
    }
  }, [context?.placeId]);

  const appBarContext = React.useContext(AppBarContext);
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  function timeout(delay: number) {
    return new Promise((res) => setTimeout(res, delay));
  }

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const curPos = new google.maps.LatLng(lat, lng);
      if (curPos) {
        const durhamCenter = new google.maps.LatLng(
          DEFAULT_MAP_PARAMS.durham_lat,
          DEFAULT_MAP_PARAMS.durham_lng
        );
        const raleighCenter = new google.maps.LatLng(
          DEFAULT_MAP_PARAMS.raleigh_lat,
          DEFAULT_MAP_PARAMS.raleigh_lng
        );
        const distToDurm =
          google.maps.geometry.spherical.computeDistanceBetween(
            durhamCenter,
            curPos
          );
        const distToRaleigh =
          google.maps.geometry.spherical.computeDistanceBetween(
            raleighCenter,
            curPos
          );

        if (distToDurm < distToRaleigh) {
          setCenter(durhamCenter);
        } else {
          setCenter(raleighCenter);
        }
      }
    });
  }, []);

  return (
    <Map
      onIdle={handleIdle}
      center={center}
      zoom={zoom}
      disableDefaultUI={true} // a way to quickly hide all controls
      //mapTypeControl={false}
      //scaleControl={false}
      zoomControl={true}
      //events={[{ event_name: "idle", callback: updateMarkers }]}
    >
      {markers.map((marker, i) => {
        if (marker.ignore) {
          return null;
        }
        const value = getMaxValueKey(marker.listingWageMap!);
        return (
          <WageMapMarker
            value={value}
            color={calculateColor(value / maxWage)}
            cluster={marker.cluster || false}
            isSelected={selectedMarker == marker.id}
            position={{
              lat: marker.latlong.latitude,
              lng: marker.latlong.longitude,
            }}
            collisionBehavior={google.maps.CollisionBehavior.REQUIRED}
            key={i}
            events={
              marker.cluster
                ? [
                    {
                      event_name: "click",
                      callback: (m: google.maps.marker.AdvancedMarkerView) => {
                        if (
                          marker.latlong.latitude &&
                          marker.latlong.longitude &&
                          m.map
                        ) {
                          m.map.setCenter(
                            new google.maps.LatLng(
                              marker.latlong.latitude,
                              marker.latlong.longitude
                            )
                          );
                          m.map.setZoom((m.map.getZoom() ?? zoom) + 2);
                        }
                      },
                    },
                  ]
                : [
                    {
                      event_name: "click",
                      callback: () => {
                        setSelectedMarker(marker.id);
                        setSelectedMarkerMap(marker.listingWageMap!);
                        //hide header on mobile
                        if (mobile) {
                          appBarContext?.setShowHeader(false);
                        }
                      },
                    },
                  ]
            }
          />
        );
      })}
      {selectedMarker && selectedMarkerMap && (
        <MarkerPopup
          closeCallback={() => {
            appBarContext?.setShowHeader(true);
            context?.setPlaceId(undefined);
            //delay for slide out animation
            timeout(500).then(() => setSelectedMarker(undefined));
          }}
          placeId={selectedMarker}
          wageRoleMap={selectedMarkerMap || {}}
        />
      )}
      {/* if searched marker is not in Db need to manually load it */}
      {selectedMarker && !markers.some((e) => e.id == selectedMarker) && (
        <LocationMarkerBasic position={center} />
      )}
    </Map>
  );
};

export default WageMap;
