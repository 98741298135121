import {
  Card,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditRoleHeader from "src/Components/Business/EditRoleHeader";
import { DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import { RoleEditType } from "./RoleAbout";

interface RoleCustomQTypes extends RoleEditType {}

const RoleCustomQs = ({
  listing,
  setListing,
  collapsable,
}: RoleCustomQTypes) => {
  const { t } = useTranslation("business");

  const [expand, setExpand] = useState(false);

  function setCustomQuestion(q: string, i: number) {
    const newCustomQuestions = [...listing.custom_questions];
    newCustomQuestions[i] = q;
    setListing({ ...listing, custom_questions: newCustomQuestions });
  }

  return (
    <Card>
      <EditRoleHeader
        headerKey="additional_questions"
        expand={expand}
        setExpand={setExpand}
        collapsable={collapsable}
        desc={"additional_questions_desc"}
      />

      <Divider sx={DIVIDER_HEADER_SX_MY} />
      <Collapse in={expand || !collapsable} timeout="auto">
        <Stack spacing={2}>
          {[1, 2, 3].map((i) => {
            return (
              <FormControl key={i}>
                <FormLabel>
                  {t("question_num", { ns: ["business"], num: i })}
                </FormLabel>
                <Textarea
                  placeholder={
                    t(`additional_questions_placeholder_${i}`, {
                      ns: ["business"],
                    }) || ""
                  }
                  onChange={(e) => {
                    const val = e.target.value;
                    setCustomQuestion(val, i - 1);
                  }}
                  value={listing.custom_questions[i - 1] ?? ""}
                />
              </FormControl>
            );
          })}
        </Stack>
      </Collapse>
    </Card>
  );
};

export default RoleCustomQs;
