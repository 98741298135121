import { Alert, IconButton, Typography, useTheme, Box, Button } from "@mui/joy";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ToastContext from "../../Context/ToastContext";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CampaignRounded, CloseRounded } from "@mui/icons-material";

interface ToastProps {}

const Toast: React.FC<ToastProps> = () => {
  const toastContext = React.useContext(ToastContext);

  const { t } = useTranslation([toastContext?.namespace, "common"]);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleX = () => {
    toastContext.setShow(false);
  };

  const handleAction = () => {
    toastContext?.toDo();
  };

  //   close toast after 10 seconds on new message or new toast
  useEffect(() => {
    if (toastContext?.show) {
      toastContext?.setShow(true);
    }
    const timeoutId = setTimeout(() => {
      toastContext?.setShow(false);
    }, 10000);
    return () => clearTimeout(timeoutId);
  }, [toastContext?.messageKey]);

  //enter {0}, {1}, etc. in the translation string to replace with the corresponding value in the array
  const formatString = (str: string, val?: string[]) => {
    if (val) {
      for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
      }
    }
    return str;
  };

  return (
    <Slide
      direction={mobile ? "up" : "right"}
      in={toastContext?.show}
      mountOnEnter
      unmountOnExit
      timeout={400}
    >
      <Alert
        variant="solid"
        sx={{
          position: "absolute",
          zIndex: 10000,
          left: { xs: 0, md: 16 },
          bottom: 16,
          mx: { xs: 2, md: 0 },
          color: "common.white",
        }}
        startDecorator={<CampaignRounded sx={{ fontSize: "1.5rem" }} />}
        endDecorator={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            {toastContext?.actionKey && (
              <Button
                variant="plain"
                color="neutral"
                onClick={() => handleAction()}
                sx={{ color: "inherit", textDecoration: "underline" }}
              >
                {t(toastContext?.actionKey || "", {
                  ns: "common",
                })}
              </Button>
            )}
            <IconButton
              onClick={() => handleX()}
              color="neutral"
              sx={{ color: "inherit" }}
            >
              <CloseRounded />
            </IconButton>
          </Box>
        }
      >
        <Typography sx={{ color: "inherit", fontWeight: "bold", mr: 3 }}>
          {formatString(
            t(toastContext?.messageKey),
            toastContext?.toInsertInMessage
          )}
        </Typography>
      </Alert>
    </Slide>
  );
};

export default Toast;
