import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Input,
  Typography,
  Card,
  Divider,
  FormControl,
  Box,
  Chip,
  Alert,
} from "@mui/joy";
import { styled } from "@mui/joy";
import { alpha } from "@mui/material/styles";
import AppBarContext from "../Context/AppBarContext";
import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import EmailSignIn from "../Components/Authentication/EmailSignIn";
import useInViewport from "src/Utils/useInViewport";
import { CloseRounded, KeyboardArrowDown } from "@mui/icons-material";
import { Image } from "mui-image";
import { Skeleton } from "@mui/material";

const NewBusiness = () => {
  const navigate = useNavigate();

  const context = useContext(AppBarContext);

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BASIC);
  });

  const signUpRef = useRef<null | HTMLDivElement>(null);
  const inViewport = useInViewport(signUpRef);

  useEffect(() => {
    console.log(inViewport.isVisible, "inViewport.isVisible");
  }, [inViewport.isVisible]);

  const { t } = useTranslation(["new_business", "business", "common"]);

  const HeadlineBox = styled(Alert)(({ theme }) => ({
    boxShadow: "5px 6px 0px 0px #D75143",
    padding: 18,
    backgroundColor: alpha(theme.palette.primary.softBg, 0.11),
  }));

  const HeadlineMain = styled(Typography)(({ theme }) => ({
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "inherit",
    lineHeight: "120%",
  }));

  const HeadlineSub = styled(Typography)(({ theme }) => ({
    fontSize: "1.3rem",
    fontWeight: 400,
    color: "inherit",
    lineHeight: "120%",
    marginTop: 10,
  }));

  interface DemoGifProps {
    id: string; //id from Google Drive
  }

  const [openIds, setOpenIds] = useState<string[]>([
    "1QgqS5PMO_GnJ7PyNeGQQzxVoKt9090Lc",
  ]);

  const DemoGif: React.FC<DemoGifProps> = ({ id }) => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          mt: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
          }}
        >
          <Chip
            startDecorator={openIds.includes(id) ? <CloseRounded /> : null}
            variant="solid"
            onClick={() => {
              if (openIds.includes(id)) {
                setOpenIds(openIds.filter((i) => i !== id));
              } else {
                setOpenIds((ids) => [...ids, id]);
              }
            }}
            sx={{ cursor: "pointer" }}
          >
            {openIds.includes(id)
              ? t("new_business:collapse_demo")
              : t("new_business:see_it_in_action")}
          </Chip>
        </Box>
        {openIds.includes(id) && (
          <Image
            duration={10}
            sx={{ borderRadius: 1, width: "100%" }}
            showLoading={<Skeleton height={400} width={300} />}
            width={300}
            src={"https://drive.google.com/uc?export=view&id=" + id}
            alt="Demo"
          />
        )}
      </Box>
    );
  };

  const ActionButton = () => {
    return (
      <Box
        sx={{
          position: "fixed",
          height: "20vh",
          width: "100vw",
          alignItems: "center",
          justifyContent: "center",
          bottom: 0,
          zIndex: 1000,
          display: "flex",
        }}
      >
        <Chip
          sx={{
            borderRadius: 10,
            bgcolor: "header.main",
            p: 2,
            cursor: "pointer",
            boxShadow: "0px 0px 10px 1px rgba(0,0,0,0.2)",
            transform: "scale(1)",
            transition: ".2s ease-in-out",
            "&:hover": {
              transform: "scale(1.1)",
              transition: ".2s ease-in-out",
              boxShadow: "2px 2px 10px 4px rgba(0,0,0,0.2)",
            },
            display: inViewport.isVisible ? "none" : "flex",
          }}
          onClick={() => {
            if (signUpRef && signUpRef.current) {
              signUpRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "inherit",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              {t("new_business:action")}
            </Typography>
            <Typography
              sx={{
                color: "inherit",
                fontWeight: "normal",
                fontSize: "0.8rem",
                mt: -0.5,
              }}
            >
              {t("new_business:action_sub")}
            </Typography>
          </Box>
        </Chip>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <PageSpacer>
        <Stack spacing={4}>
          <HeadlineBox ref={signUpRef}>
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Box>
                <HeadlineMain sx={{ fontSize: "2.3rem" }}>
                  {t("new_business:action_lowercase")}
                </HeadlineMain>
                <HeadlineSub sx={{ marginTop: 0 }}>
                  {t("new_business:start_sub")}
                </HeadlineSub>
              </Box>
              <Box
                sx={{
                  border: "1px solid",
                  p: 2,
                  borderRadius: 2,
                }}
              >
                <EmailSignIn
                  newUser
                  onComplete={() => navigate(APP_ROUTES.BUSINESS_HOME)}
                />
              </Box>
              <DemoGif id="1QgqS5PMO_GnJ7PyNeGQQzxVoKt9090Lc" />
            </Stack>
          </HeadlineBox>
          <HeadlineBox>
            <Stack>
              <HeadlineMain>{t("new_business:job_seekers")}</HeadlineMain>
              <HeadlineSub>{t("new_business:job_seekers_sub")}</HeadlineSub>
            </Stack>
          </HeadlineBox>
          <HeadlineBox>
            <Stack>
              <HeadlineMain>{t("new_business:qualified")}</HeadlineMain>
              <HeadlineSub>{t("new_business:qualified_sub")}</HeadlineSub>
            </Stack>
          </HeadlineBox>
          <HeadlineBox>
            <Stack>
              <HeadlineMain>{t("new_business:resumes")}</HeadlineMain>
              <HeadlineSub>{t("new_business:resumes_sub")}</HeadlineSub>
            </Stack>
          </HeadlineBox>
          <HeadlineBox>
            <Stack>
              <HeadlineMain>{t("new_business:schedule")}</HeadlineMain>
              <HeadlineSub>{t("new_business:schedule_sub")}</HeadlineSub>
              <HeadlineMain sx={{ mt: 2 }}>
                {t("new_business:no_shows")}
              </HeadlineMain>
              <HeadlineSub>{t("new_business:no_shows_sub")}</HeadlineSub>
            </Stack>
          </HeadlineBox>
        </Stack>
      </PageSpacer>
      {/* <ActionButton /> */}
    </React.Fragment>
  );
};

export default NewBusiness;
