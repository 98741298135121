import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import {
  APP_ROUTES,
  CARD_OVERFLOW_SX,
  DIVIDER_HEADER_SX_NO_Y,
  HEADER_TYPES,
} from "../../Utils/Constants";
import AppBarContext from "../../Context/AppBarContext";
import {
  Typography,
  Stack,
  Divider,
  Card,
  IconButton,
  CardOverflow,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
  ArrowForwardIosRounded,
  FavoriteRounded,
  KeyboardArrowRightRounded,
  SaveRounded,
} from "@mui/icons-material";

const SavedRolesCard: React.FC = () => {
  const { t } = useTranslation(["job_seeker"]);
  const navigate = useNavigate();
  const appBarContext = useContext(AppBarContext);

  return (
    <Card>
      <CardOverflow
        sx={CARD_OVERFLOW_SX}
        onClick={() => {
          // change header accordingly
          appBarContext?.setBackKey("back_to_account");

          // navigate to profile page
          navigate(APP_ROUTES.SAVED_ROLES);
        }}
      >
        <Typography level="cardHeader" startDecorator={<FavoriteRounded />}>
          {t("saved_roles", { ns: "job_seeker" })}
        </Typography>

        <IconButton>
          <ArrowForwardIosRounded />
        </IconButton>
      </CardOverflow>
      {/* <Divider sx={DIVIDER_HEADER_SX_NO_Y} />
      <Stack spacing={2} sx={{ mt: 2 }}>
        <Typography level="body2" noWrap>
          {t("profile_description", { ns: "job_seeker" })}
        </Typography>
        <Typography
          endDecorator={<KeyboardArrowRightRounded />}
          onClick={() => {
            // change header accordingly
            appBarContext?.setBackKey("back_to_account");

            // navigate to saved role page
            navigate(APP_ROUTES.SAVED_ROLES);
          }}
          level="innerCardLink"
        >
          8 saved roles
        </Typography>
      </Stack> */}
    </Card>
  );
};

export default SavedRolesCard;
