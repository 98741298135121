import {
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  IconButton,
  Box,
  Divider,
  Chip,
  Modal,
  Alert,
} from "@mui/joy";
import {
  AlarmRounded,
  CheckRounded,
  Email,
  LockClockRounded,
  MoveDownRounded,
  MoveUpRounded,
  PersonAddDisabledRounded,
  PersonAddRounded,
  PersonRounded,
  Phone,
  PhoneRounded,
  PlaceRounded,
  PunchClockRounded,
} from "@mui/icons-material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
} from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useInterviewScheduler from "../../Utils/useText";
import { Applications, Item } from "../../Services/Db/types";
import useKeyToDict from "../../Utils/useKeyToDict";
import { ApplicationsService } from "../../Services/Db/service";
import useConfirm from "../../Utils/useConfirm";
import useText from "../../Utils/useText";
import { useBusinessAuth } from "../../Context/AuthContext";
import { CreateProfile } from "../../UserProfile/CreateProfile";
import { FullBusinessService } from "../../Services/Db/business";
import useToast from "../../Utils/useToast";
import { DocumentReference } from "firebase/firestore";
import { BusinessService } from "../../Services/Db/service";

interface CandidateProps {
  candidate: Applications & { role: Item; requirements: string[] };
  phone: string;
  setLoaded: (loaded: boolean) => void;
  headerOnly?: boolean;
}

const Candiate: React.FC<CandidateProps> = ({
  candidate,
  phone,
  setLoaded,
  headerOnly,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["business", "common"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const confirm = useConfirm();
  const { textInviteToInterview } = useText();
  const { business } = useBusinessAuth();
  const toast = useToast();

  // const { scheduleInterview } = useInterviewScheduler();

  const setStatus = (status: string) => {
    ApplicationsService.update(candidate.id!, { status: status });
    setLoaded(false);
  };

  function handleInviteToInterview() {
    FullBusinessService.getBusinessInterviewAvailabilities(
      candidate.businessRef.id
    )
      .then((availabilities) => {
        if (!availabilities || availabilities.length === 0) {
          confirm(
            "interview",
            "no_interview_availabilities",
            "no_interview_availabilities_sub",
            "go_to_interviews",
            () =>
              navigate(APP_ROUTES.BUSINESS_INTERVIEWS, { state: { index: 1 } }),
            []
          );
        } else {
          confirm(
            "common",
            "confirm_move_to_interview",
            "confirm_move_to_interview_subtitle",
            "confirm",
            async () => {
              setStatus("interviewing");
              const business = await BusinessService.getResolvedReference(
                candidate.businessRef
              );

              textInviteToInterview(business, phone);
            },
            []
          );
        }
      })
      .catch((_err) => {
        toast("common", "failed_to_get_interviews", "", () => {});
      });
  }

  return (
    <Card>
      <Box>
        <Button
          onClick={() =>
            navigate(APP_ROUTES.CANDIDATE_PROFILE, {
              state: {
                uid: candidate.userRef.id,
                reqs: candidate.requirements,
                name: candidate.name,
              },
            })
          }
          sx={{ px: 0.5, mb: 0.5 }}
          variant="plain"
          color="neutral"
        >
          <Typography level="cardHeader">{candidate.name}</Typography>
        </Button>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: 0.5,
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <Chip
              startDecorator={<PersonRounded />}
              variant="outlined"
              color="neutral"
            >
              {getRoleNameFromRole(candidate.role)}
            </Chip>
          </Box>
          <Box>
            <Chip
              startDecorator={<AlarmRounded />}
              variant="outlined"
              color="neutral"
            >
              {t("applied", { ns: ["business"] })}{" "}
              {dayjs.unix(candidate.submitted.seconds).format("MMM D, YYYY")}
            </Chip>
          </Box>
          <Box>
            <Chip
              variant="soft"
              color="neutral"
              onClick={() => {
                window.open(`tel:${candidate}`);
              }}
              startDecorator={<PhoneRounded />}
            >
              {t("common:call_name", { name: candidate.name.split(" ")[0] })}
            </Chip>
          </Box>
          <Box>
            {candidate.status === "interviewing" && (
              <Chip
                variant="soft"
                color={Boolean(candidate.interviewRef) ? "success" : "warning"}
              >
                {Boolean(candidate.interviewRef)
                  ? t("common:scheduled")
                  : t("common:pending_scheduling")}
              </Chip>
            )}
          </Box>
        </Box>
      </Box>

      {!headerOnly && (
        <Fragment>
          <Divider sx={DIVIDER_HEADER_SX_MY} />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 0.5,
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <Button
              onClick={() =>
                navigate(APP_ROUTES.CANDIDATE_PROFILE, {
                  state: {
                    uid: candidate.userRef.id,
                    reqs: candidate.requirements,
                    name: candidate.name,
                    custom_answers: candidate.custom_answers,
                  },
                })
              }
              fullWidth
              startDecorator={<PersonRounded />}
            >
              {t("common:view")}
            </Button>
            {candidate.status === "submitted" && (
              <Button
                variant="soft"
                color="info"
                fullWidth
                startDecorator={<PersonAddRounded />}
                onClick={() => handleInviteToInterview()}
              >
                {t("interview", { ns: ["common"] })}
              </Button>
            )}
            {(candidate.status === "interviewing" ||
              candidate.status === "submitted") && (
              <React.Fragment>
                <Button
                  variant="soft"
                  color="success"
                  fullWidth
                  startDecorator={<PersonAddRounded />}
                  onClick={() =>
                    confirm(
                      "common",
                      "confirm_move_to_hired",
                      "confirm_move_to_hired_subtitle",
                      "confirm",
                      () => setStatus("hired"),
                      []
                    )
                  }
                >
                  {t("hire", { ns: ["common"] })}
                </Button>

                <Button
                  variant="outlined"
                  fullWidth
                  startDecorator={<PersonAddDisabledRounded />}
                  onClick={() =>
                    confirm(
                      "common",
                      "confirm_move_to_rejected",
                      "confirm_move_to_rejected_subtitle",
                      "confirm",
                      () => setStatus("rejected"),
                      []
                    )
                  }
                >
                  {t("decline", { ns: ["common"] })}
                </Button>
              </React.Fragment>
            )}
            {candidate.status === "rejected" && (
              <Button
                variant="outlined"
                fullWidth
                startDecorator={<MoveUpRounded />}
                onClick={() =>
                  confirm(
                    "common",
                    "confirm_move_to_applied",
                    "confirm_move_to_applied_subtitle",
                    "confirm",
                    () => setStatus("submitted"),
                    []
                  )
                }
              >
                {t("move_to_applied", { ns: ["common"] })}
              </Button>
            )}
            {candidate.status === "hired" && (
              <Button
                variant="outlined"
                fullWidth
                startDecorator={<MoveDownRounded />}
                onClick={() =>
                  confirm(
                    "common",
                    "confirm_move_to_rejected",
                    "confirm_move_to_rejected_subtitle",
                    "confirm",
                    () => setStatus("rejected"),
                    []
                  )
                }
              >
                {t("move_to_declined", { ns: ["common"] })}
              </Button>
            )}
          </Box>
        </Fragment>
      )}
    </Card>
  );
};

export default Candiate;
