import React, { Provider } from "react";
import { number } from "yup";
import { ProviderType } from "./types";
import { Item } from "../Services/Db/types";
import { DEFAULT_MAP_PARAMS } from "../Utils/Constants";

export interface MapContextType {
  wageRange: number[];
  setWageRange: (w: number[]) => void;
  roles: Item[];
  setRoles: (r: Item[]) => void;
  excludeRoles: Item[];
  setExcludeRoles: (r: Item[]) => void;
  placeId: string | undefined;
  setPlaceId: (p: string | undefined) => void;
  skills: Item[] | undefined;
  setSkills: (s: Item[] | undefined) => void;
  schedules: Item[] | undefined;
  setSchedules: (s: Item[] | undefined) => void;
  benefits: Item[] | undefined;
  setBenefits: (b: Item[] | undefined) => void;
  currentBounds: google.maps.LatLngBounds | undefined;
  setCurrentBounds: (b: google.maps.LatLngBounds | undefined) => void;
  markerIds: string[];
  setMarkerIds: (markers: string[]) => void;
}

// Context for setting the correct app bar
const MapContext = React.createContext<MapContextType | null>(null);

const MapContextProvider: React.FC<ProviderType> = ({ children }) => {
  const [wageRange, setWageRange] = React.useState<number[]>([0, 100]);
  const [roles, setRoles] = React.useState<Item[]>([]);
  const [excludeRoles, setExcludeRoles] = React.useState<Item[]>([]);
  const [placeId, setPlaceId] = React.useState<string>();
  const [skills, setSkills] = React.useState<Item[]>();
  const [schedules, setSchedules] = React.useState<Item[]>();
  const [benefits, setBenefits] = React.useState<Item[]>();
  const [currentBounds, setCurrentBounds] =
    React.useState<google.maps.LatLngBounds>();
  const [markerIds, setMarkerIds] = React.useState<string[]>([]);

  return (
    <MapContext.Provider
      value={{
        wageRange: wageRange,
        setWageRange: setWageRange,
        roles: roles,
        setRoles: setRoles,
        excludeRoles: excludeRoles,
        setExcludeRoles: setExcludeRoles,
        placeId: placeId,
        setPlaceId: setPlaceId,
        skills: skills,
        setSkills: setSkills,
        schedules: schedules,
        setSchedules: setSchedules,
        benefits: benefits,
        setBenefits: setBenefits,
        currentBounds: currentBounds,
        setCurrentBounds: setCurrentBounds,
        markerIds: markerIds,
        setMarkerIds: setMarkerIds,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export { MapContextProvider, MapContext };
