import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Container, Divider, Popover } from "@mui/material";
import {
  Menu,
  Button,
  Box,
  IconButton,
  Typography,
  List,
  Badge,
  ListItemButton,
} from "@mui/joy";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { APP_ROUTES, DIVIDER_HEADER_SX_NO_Y } from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import useInterviewLogicFirebase from "src/Pages_Business/Interviews/useInterviewLogicFirebase";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const navigate = useNavigate();

  const { t } = useTranslation(["header"]);
  const { loadInterviews } = useInterviewLogicFirebase();
  const { business } = useBusinessAuth();

  const [upcomingInterviews, setUpcomingInterviews] = useState<any[]>([]);
  const [candidates, setCandidates] = useState<any[]>([]);

  const loadCandidates = async (bid: string) => {
    const loadedCandidates =
      await FullBusinessService.getFullBusinessApplications(bid);
    setCandidates(loadedCandidates.filter((c: any) => c.status !== "rejected"));
  };

  useEffect(() => {
    if (business) {
      loadInterviews(business.email!).then((interviews) => {
        setUpcomingInterviews(interviews || []);
      });

      loadCandidates(business.email!);
    }
  }, [business]);

  const pages = [
    { title: t("roles", { ns: ["header"] }), href: APP_ROUTES.ROLES, count: 0 },
    {
      title: t("candidates", { ns: ["header"] }),
      href: APP_ROUTES.CANDIDATES,
      count: candidates.length,
    },
    {
      title: t("interviews", { ns: ["header"] }),
      href: APP_ROUTES.BUSINESS_INTERVIEWS,
      count: upcomingInterviews.length,
    },
  ];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorElNav !== null) {
      setAnchorElNav(null);
    } else {
      setAnchorElNav(event.currentTarget);
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const headTo = (href: string) => {
    navigate(href);
    handleCloseNavMenu();
  };

  // const handleCloseAllMenus = () => {
  //   setAnchorElNav(null);
  //   setAnchorElUser(null);
  // };

  return (
    // <ClickAwayListener onClickAway={() => handleCloseAllMenus}>

    <AppBar
      position="sticky"
      sx={{
        bgcolor: "header.bg",
        color: "header.main",
        mb: { xs: 3, md: 10 },
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        top: 0,
      }}
      elevation={0}
    >
      <Container
        sx={{ width: { xs: "100%", sm: "90%", md: "80%", lg: "70%" } }}
      >
        <Toolbar sx={{ height: "64px" }} disableGutters>
          <Typography
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              // this is what makes the logo disappear on mobile
              display: { xs: "none", md: "flex" },
              fontWeight: 500,
              color: "primary.main",
              ":hover": {
                color: "primary.main",
              },
              textDecoration: "none",
              fontSize: "1.5rem",
              fontStyle: "italic",
            }}
          >
            Hourlead
          </Typography>

          <Box
            sx={{
              flexGrow: 1,
              //this is what makes the menu appear on mobile
              display: { xs: "flex", md: "none" },
            }}
          >
            <IconButton onClick={handleOpenNavMenu}>
              <MenuIcon sx={{ color: "primary.main" }} />
            </IconButton>
            <Popover
              anchorEl={anchorElNav}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                mt: 4,
              }}
            >
              <List>
                {pages.map((page) => (
                  <ListItemButton
                    key={page.title}
                    onClick={() => headTo(page.href)}
                  >
                    <Badge badgeContent={page.count} size="lg" sx={{ mr: 2 }}>
                      <Typography
                        textAlign="center"
                        sx={{ fontWeight: 600, fontSize: "1.55rem", pr: 1.5 }}
                      >
                        {page.title}
                      </Typography>
                    </Badge>
                  </ListItemButton>
                ))}
              </List>
            </Popover>
          </Box>
          <Typography
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontWeight: 600,
              color: "primary.main",
              ":hover": {
                color: "primary.main",
              },
              textDecoration: "none",
              fontStyle: "italic",
              fontSize: "1.5rem",
            }}
          >
            Hourlead
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page.title}
                onClick={() => headTo(page.href)}
                sx={{
                  px: 1,
                  py: 0,
                  my: 0,
                  // mx: 0.5,
                  color: "header.main",
                  "&:hover": {
                    color: "header.mainHover",
                    bgcolor: "transparent",
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textUnderlineOffset: "5px",
                  },
                }}
                variant="plain"
                size="lg"
              >
                <Badge badgeContent={page.count} size="sm">
                  <Typography
                    sx={{
                      fontSize: "1.3em",
                      fontWeight: "inherit",
                      color: "inherit",
                      // py: 0.5,
                      mx: 0.75,
                    }}
                  >
                    {page.title}
                  </Typography>
                </Badge>
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton onClick={() => navigate(APP_ROUTES.BUSINESS_HOME)}>
              <AccountCircle sx={{ color: "header.main", fontSize: "2rem" }} />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      <Divider sx={DIVIDER_HEADER_SX_NO_Y} />
    </AppBar>
  );
};
export default ResponsiveAppBar;
