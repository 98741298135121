import { Box, Stack, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BusinessService } from "src/Services/Db/service";
import ConfirmInput from "../Inputs/ConfirmInput";

const EditBusinessCode = ({
  codeStart,
  placeId,
  setCodeParent,
}: {
  codeStart: string | undefined;
  placeId: string;
  setCodeParent?: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [allCodes, setAllCodes] = useState<string[]>([]);
  const [invalidMsg, setInvalidMsg] = useState<string>("business_code_invalid");

  const { t } = useTranslation("business");

  const [code, setCode] = useState<string>();

  useEffect(() => {
    setCode(codeStart);
  }, [codeStart]);

  useEffect(() => {
    BusinessService.getMany().then((res) => {
      if (res) {
        setAllCodes(
          res.map((b) => b.code).filter((c) => c !== undefined) as string[]
        );
      }
    });
  }, []);

  function handleComplete(e: string) {
    BusinessService.update(placeId, { code: e });
    if (setCodeParent) {
      setCodeParent(e);
    }
  }

  function validateCode(e: string): boolean {
    if (allCodes) {
      if (allCodes.includes(e)) {
        return false;
      }
      return true;
    } else {
      setInvalidMsg("failed_loading_codes");
      return false;
    }
  }

  return (
    <Stack>
      <Typography level="body2">{t("business_code_w_max")}</Typography>
      <ConfirmInput
        content={code && code.length > 0 ? code : t("business_code_empty")}
        setContent={setCode}
        onComplete={handleComplete}
        slotProps={{ input: { maxLength: 7 } }}
        validate={validateCode}
        invalidMsg={t(invalidMsg)!}
      />
    </Stack>
  );
};

export default EditBusinessCode;
