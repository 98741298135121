import { Slider, sliderClasses, Typography } from "@mui/joy";
import { TFunction } from "i18next";
import { useTranslation } from "react-i18next";

interface ExperenceSliderProps {
  setValue: (n: number) => void;
  defaultVal?: number;
}

function mapNumberToExperienceLabel(value: number, t: TFunction) {
  if (value === 0) {
    return t("no_experience");
  } else if (value === 1) {
    return t("experience_months", { months: 3 });
  } else if (value === 2) {
    return t("experience_months", { months: 6 });
  } else if (value > 2) {
    return t("experience_years", { years: value - 2 });
  }
}

const ExperienceSlider: React.FC<ExperenceSliderProps> = ({
  setValue,
  defaultVal,
}) => {
  const { t } = useTranslation("business");
  return (
    <Slider
      defaultValue={defaultVal ?? 0}
      min={0}
      max={12}
      onChange={(e, val) => setValue(val instanceof Array ? val[0] : val)}
      valueLabelFormat={(value) => (
        <Typography fontSize="1.25rem" sx={{ color: "#FFF" }}>
          {mapNumberToExperienceLabel(value, t)}
        </Typography>
      )}
      valueLabelDisplay="on"
      sx={{
        // Need both of the selectors to make it works on the server-side and client-side
        [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
          [`&.${sliderClasses.markLabel}`]: {
            transform: "none",
          },
          [`& .${sliderClasses.valueLabel}`]: {
            left: "calc(var(--Slider-thumb-size) / 2)",
            borderBottomLeftRadius: 0,
            "&::before": {
              left: 0,
              transform: "translateY(100%)",
              borderLeftColor: "currentColor",
            },
          },
        },
        [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
          [`&.${sliderClasses.markLabel}`]: {
            transform: "translateX(-100%)",
          },
          [`& .${sliderClasses.valueLabel}`]: {
            right: "calc(var(--Slider-thumb-size) / 2)",
            borderBottomRightRadius: 0,
            "&::before": {
              left: "initial",
              right: 0,
              transform: "translateY(100%)",
              borderRightColor: "currentColor",
            },
          },
        },
      }}
    />
  );
};

export default ExperienceSlider;
