import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
  Input,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import { useTranslation } from "react-i18next";
import {
  Add,
  CheckCircleRounded,
  Edit,
  HighlightOffRounded,
  NewReleasesRounded,
  RadioButtonUnchecked,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import DateModal from "../Components/Inputs/DateModal";

import { SectionProps } from "./sectionProps";

import { FullUserService } from "../Services/Db/user";
import { useAuth } from "../Context/AuthContext";
import { Timestamp } from "firebase/firestore";
import { start } from "repl";
import SelectInterviewAvailabilityDetails from "src/Components/Inputs/SelectInterviewAvailabilityDetails";

const AvailabilitySection: React.FC<SectionProps> = ({ setModalOpen }) => {
  const { t } = useTranslation(["availability_builder"]);

  const { user } = useAuth();

  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [dateModalOpen, setDateModalOpen] = useState(false);

  const [availabilityIsSet, setAvailabilityIsSet] = useState(false);

  useEffect(() => {
    if (user) {
      const loadStart = async (uid: string) => {
        const start: Timestamp | null = await FullUserService.loadUserStart(
          uid
        );
        if (start) {
          setStartDate(dayjs(start.toDate()));
        }
      };

      const loadAvailability = async (uid: string) => {
        const availability: string[] | null =
          await FullUserService.loadUserAvailability(uid);
        if (availability && availability.length > 0) {
          setAvailabilityIsSet(true);
        }
      };

      loadStart(user.phoneNumber!);
      loadAvailability(user.phoneNumber!);
    }
  }, [user]);

  useEffect(() => {
    if (startDate) {
      FullUserService.createUserStart(
        Timestamp.fromDate(startDate.toDate()),
        user?.phoneNumber!
      );
    }
  }, [startDate]);

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={
            startDate ? (
              <CheckCircleRounded sx={{ color: "success.solidBg" }} />
            ) : (
              <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
            )
          }
          level="h4"
          sx={{ fontWeight: 600 }}
        >
          {t("start_date", { ns: ["create_profile"] })}
        </Typography>
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />

      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, pt: 2 }}>
        <Button
          variant={startDate ? "soft" : "solid"}
          color={startDate ? "success" : "primary"}
          fullWidth
          onClick={() => setDateModalOpen(true)}
          startDecorator={
            startDate ? <CheckCircleRounded /> : <HighlightOffRounded />
          }
        >
          {startDate ? t("start_set") : t("set_start")}
        </Button>
        <Button
          variant={availabilityIsSet ? "soft" : "solid"}
          fullWidth
          onClick={() => setModalOpen(true)}
          color={availabilityIsSet ? "success" : "primary"}
          startDecorator={
            availabilityIsSet ? <CheckCircleRounded /> : <HighlightOffRounded />
          }
        >
          {availabilityIsSet ? t("availability_set") : t("set_availability")}
        </Button>
      </Box>

      <DateModal
        open={dateModalOpen}
        setOpen={setDateModalOpen}
        date={startDate}
        setDate={setStartDate}
      />
    </Card>
  );
};

export default AvailabilitySection;
