// FirebaseAuthContext.tsx
import * as React from "react";
import { User, getAuth, onAuthStateChanged } from "firebase/auth";
import app, { auth } from "../firebase";
import { ProviderType } from "./types";
import { FullUserService } from "../Services/Db/user";
import { FullBusinessService } from "../Services/Db/business";

type ContextState = { user: User | null; loading: boolean };

const AuthContext = React.createContext<ContextState | undefined>(undefined);

const AuthProvider: React.FC<ProviderType> = ({ children }) => {
  const [user, setUser] = React.useState<User | null>(auth.currentUser);
  const [loading, setLoading] = React.useState(true);
  const value = { user, loading };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.email) {
        setLoading(true);
        FullBusinessService.getProxyUser(user.email)
          .then((proxy) => {
            if (proxy) {
              setUser({ ...user, email: proxy });
            } else {
              setUser(user);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setUser(user);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [auth]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  const user = context.user;
  if (user && user.phoneNumber) {
    FullUserService.createUser(user.phoneNumber);
    return { user: user, loading: false };
  } else if (context.loading) {
    return { user: null, loading: true };
  }
  return { user: null, loading: context.loading };
}

function useBusinessAuth() {
  //const [proxy, setProxy] = React.useState<string | null>();
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  const user = context.user;
  if (user && user.email) {
    FullBusinessService.createBusiness(user.email);
    return { business: user, loading: false };
  } else if (context.loading) {
    return { business: null, loading: true };
  }
  return { business: null, loading: context.loading };
}

export { AuthProvider, useAuth, useBusinessAuth };
