import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/joy";
import {
  CopyAllRounded,
  EmailRounded,
  FacebookRounded,
  MailRounded,
  TextsmsRounded,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import useToast from "src/Utils/useToast";

interface ShareSheetProps {
  text: string;
  url: string;
  subject: string;
}

const ShareSheet: React.FC<ShareSheetProps> = ({ text, url, subject }) => {
  const toast = useToast();

  //   const handleText = () => {
  //     window.open(`sms:&body=${text} ${url}`);
  //     window.open(`sms:?body=${text} ${url}`);
  //   };

  const handleEmail = () => {
    window.open(`mailto:?subject=${subject}&body=${text} ${url}`);
  };

  const handleCopy = () => {
    toast("common", "copied_to_clipboard", "", () => {});
    navigator.clipboard.writeText(`${text} ${url}`);
  };

  const handleFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=#${url}`);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        gap: 2,
      }}
    >
      <IconButton
        variant="soft"
        color="neutral"
        component="a"
        href={`sms:?&body=${text} ${url}`}
      >
        <TextsmsRounded />
      </IconButton>
      <IconButton variant="soft" color="neutral" onClick={() => handleEmail()}>
        <EmailRounded />
      </IconButton>
      <IconButton variant="soft" color="neutral" onClick={() => handleCopy()}>
        <CopyAllRounded />
      </IconButton>
      <IconButton
        variant="soft"
        color="neutral"
        onClick={() => handleFacebook()}
      >
        <FacebookRounded />
      </IconButton>
    </Box>
  );
};

export default ShareSheet;
