import React from "react";
import { Education, Experiences, Item } from "src/Services/Db/types";

interface WageSurveyContextType {
  active: "intro" | "job" | "wage" | "more" | "result" | "resultSignedIn";
  setActive: (
    active: "intro" | "job" | "wage" | "more" | "result" | "resultSignedIn"
  ) => void;
  job: Item[] | null;
  setJob: (job: Item[] | null) => void;
  wage: number | null;
  setWage: (wage: number | null) => void;
  tips: number | null;
  setTips: (tips: number | null) => void;

  placeId: string | null;
  setPlaceId: (placeId: string | null) => void;

  experiences?: Experiences[];
  setExperiences: (experiences: Experiences[]) => void;
  education?: Education;
  setEducation: (education: Education) => void;
}

// Context for setting the correct app bar
const WageSurveyContext = React.createContext<WageSurveyContextType | null>(
  null
);

export default WageSurveyContext;
