import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
  Chip,
  Input,
  Modal,
} from "@mui/joy";

import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX } from "src/Utils/Constants";

import { TextField } from "@mui/material";

import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckRounded, CloseRounded } from "@mui/icons-material";
import { ModalDialog } from "react-bootstrap";
import dayjs, { Dayjs } from "dayjs";

interface DateModalProps {
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
  open: boolean;
  setOpen: (open: boolean) => void;
}

const DateModal: React.FC<DateModalProps> = ({
  date,
  setDate,
  open,
  setOpen,
}) => {
  const { t, i18n } = useTranslation(["availability_builder"]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          maxWidth: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },

          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
            lineHeight: "180%",
          }}
        >
          <Typography level="cardHeader">{t("when_can_you_start")}</Typography>
          <IconButton
            onClick={() => setOpen(false)}
            color={!!date ? "success" : "primary"}
          >
            {!!date ? <CheckRounded /> : <CloseRounded />}
          </IconButton>
        </Box>

        <Divider sx={DIVIDER_HEADER_SX} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 2,
            pt: 2,
          }}
        >
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            locale={i18n.language}
          >
            <Chip variant="outlined">
              {date
                ? t("availability_builder:can_start_on", {
                    start: date
                      .locale(i18n.language)
                      .format("dddd, MMMM D, YYYY"),
                  })
                : t("date_not_set_yet")}
            </Chip>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              value={date}
              onChange={(newValue) => {
                setDate(dayjs(newValue));
              }}
              renderInput={(params) => <TextField {...params} />}
              minDate={dayjs().toDate()}
              maxDate={dayjs().add(1, "year").toDate()}
              orientation="landscape"
            />
          </LocalizationProvider>
        </Box>
      </Card>
    </Modal>
  );
};

export default DateModal;
