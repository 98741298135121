import { Box, Checkbox, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import React from "react";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import LocationListItem from "./LocationListItem";

interface LocationCheckboxProps {
  placeId: string;
  selected: string[];
  setSelected: (s: string[]) => void;
  disabled?: boolean;
}

const LocationCheckbox: React.FC<LocationCheckboxProps> = ({
  placeId,
  selected,
  setSelected,
  disabled,
}) => {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setSelected([...selected, placeId]);
    } else {
      setSelected(selected.filter((id) => id !== placeId));
    }
  }

  const placeDetails = usePlaceDetails(placeId, ["name", "formatted_address"]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
      }}
    >
      <Checkbox
        size="lg"
        sx={{ margin: 2 }}
        onChange={(e) => handleChange(e)}
        overlay
        defaultChecked
        disabled={disabled}
      />
      <LocationListItem placeId={placeId} />
    </Box>
  );
};

export default LocationCheckbox;
