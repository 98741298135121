import {
  Stack,
  Typography,
  Box,
  Divider,
  Modal,
  Card,
  Input,
  IconButton,
  Button,
  Checkbox,
} from "@mui/joy";
import React, { useEffect, useState, useContext } from "react";
import SelectGroupSingle from "../Inputs/SelectGroupSingle";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import dayjs, { Dayjs } from "dayjs";
import MonthYearPopover from "../Inputs/MonthYearPopover";
import { useTranslation } from "react-i18next";
import { Close, CloseRounded, SetMeal } from "@mui/icons-material";

import useKeyToDict from "../../Utils/useKeyToDict";
import useConfirm from "../../Utils/useConfirm";
import InlineBusinessSelectMap from "../Maps/InlineBusinessSelectMap";
import { ExperiencesService, UserService } from "../../Services/Db/service";
import { Experiences, UserData } from "../../Services/Db/types";
import { doc, Timestamp } from "firebase/firestore";
import useToast from "../../Utils/useToast";
import { FullUserService } from "../../Services/Db/user";
import { useAuth } from "../../Context/AuthContext";

import { Item, Users } from "../../Services/Db/types";
import NewItemSelector from "../Inputs/NewItemSelector";
import { TABLES } from "../../Services/Db/consts";
import { analytics, db } from "../../firebase";

import WageSurveyContext from "src/Marketing/WageComparison/WageSurveyContext";
import { logEvent } from "firebase/analytics";

interface ExperienceBuilderProps {
  experienceID: string | null; //if there is an experience ID (or something?), we are editing this experience
  open: boolean;
  setOpen: (type: boolean) => void;
}

const ExperienceBuilder: React.FC<ExperienceBuilderProps> = ({
  experienceID,
  open,
  setOpen,
}) => {
  const { t, i18n } = useTranslation(["common", "experience_builder", "lists"]);
  const {
    keyToObjArray: keyToDict,
    keyToValueInList,
    getRoleNameFromRole,
  } = useKeyToDict("lists");
  const confirm = useConfirm();

  const [role, setRole] = useState<Item[] | null>(null);
  const [employmentType, setEmploymentType] = useState<string | null>(null);
  const [employmentStartMonth, setEmploymentStartMonth] =
    useState<Dayjs | null>(null);
  const [employmentStartYear, setEmploymentStartYear] = useState<Dayjs | null>(
    null
  );
  const [currentlyWorkHere, setCurrentlyWorkHere] = useState<boolean>(false);
  const [employmentEndMonth, setEmploymentEndMonth] = useState<Dayjs | null>(
    null
  );
  const [employmentEndYear, setEmploymentEndYear] = useState<Dayjs | null>(
    null
  );
  const [departureReason, setDepartureReason] = useState<string | null>(null);
  const [placeId, setPlaceId] = useState<string>();

  var employment_types_dict = keyToDict("job_types");

  const toast = useToast();
  const { user } = useAuth();
  const [userData, setUserData] = useState<UserData | null>(null);

  const handleDeleteConfirmed = () => {
    setOpen(false);
    FullUserService.deleteUserExperience(user?.phoneNumber!, experienceID!);
  };

  const handleClose = () => {
    logEvent(analytics, "add_experience_close", {});
    if (
      role ||
      employmentType ||
      employmentStartMonth ||
      employmentStartYear ||
      employmentEndMonth ||
      employmentEndYear
    ) {
      //if there's progress
      confirm(
        "experience_builder",
        "scrub_experience_message",
        "scrub_experience_subtitle",
        "scrub",
        () => {
          setOpen(false); //close modal if scrubbed
        },
        [getRoleNameFromRole(role ? role[0] : null)]
      );
    } else {
      setOpen(false);
    }
  };

  const handleFirstJob = () => {
    console.log("first job", user?.phoneNumber);
    UserService.update(user?.phoneNumber!, { noExperience: true });
    setOpen(false);
    console.log("first job:", userData?.noExperience);
  };

  const surveyContext = useContext(WageSurveyContext);
  const handleSave = async () => {
    logEvent(analytics, "add_experience_save", {});
    //validation
    const startDate: Date = new Date(
      employmentStartYear!.year(),
      employmentStartMonth!.month()
    );
    var endDate: Date | undefined;

    if (!currentlyWorkHere) {
      endDate = new Date(
        employmentEndYear!.year(),
        employmentEndMonth!.month()
      );
      if (endDate < startDate) {
        toast("create_profile", "end_before_start", "", () => {});
        return;
      }
    }

    if (!user) {
      const experience = {
        role: role ? role[0] : null,
        employmentType: employmentType!,
        startDate: Timestamp.fromDate(startDate),
        endDate: endDate ? Timestamp.fromDate(endDate) : null,
        departureReason: departureReason,
        businessRef: doc(db, TABLES.BUSINESSES, placeId!),
      };

      console.log("experience", experience);

      surveyContext?.setExperiences([
        ...(surveyContext?.experiences || []),
        experience,
      ]);

      setOpen(false);
      return;
    }

    //user has experience now
    if (userData?.noExperience) {
      await UserService.update(user?.phoneNumber!, { noExperience: false });
    }

    FullUserService.createUserExperience(
      {
        role: role ? role[0] : null,
        employmentType: employmentType!,
        startDate: Timestamp.fromDate(startDate),
        endDate: endDate ? Timestamp.fromDate(endDate) : null,
        departureReason: departureReason,
        businessRef: doc(db, TABLES.BUSINESSES, placeId!),
      },
      user.phoneNumber!,
      experienceID || undefined
    );
    setOpen(false);
  };

  const loadUser = async () => {
    if (!user) {
      return;
    }
    const u = await FullUserService.loadFullUser(user?.phoneNumber!);
    if (u) {
      setUserData(u);
      console.log("LOADED:", u);
    }
  };

  useEffect(() => {
    loadUser();

    // wipe previous searches on open
    if (experienceID) {
      const loadUserExperiences = async (eid: string) => {
        const experience: Experiences | null = await ExperiencesService.getOne(
          eid
        );
        if (experience) {
          setRole([experience.role!]);
          setEmploymentType(experience.employmentType);
          const startDate = experience.startDate.toDate();
          setEmploymentStartMonth(dayjs().month(startDate.getMonth()));
          setEmploymentStartYear(dayjs().year(startDate.getFullYear()));
          if (experience.endDate) {
            const endDate = experience.endDate.toDate();
            setEmploymentEndMonth(dayjs().month(endDate.getMonth()));
            setEmploymentEndYear(dayjs().year(endDate.getFullYear()));
          } else {
            setCurrentlyWorkHere(true);
          }
          setDepartureReason(experience.departureReason || null);
          setPlaceId(experience.businessRef.id);
        }
      };

      loadUserExperiences(experienceID);
    } else {
      //reset all variables to null if not editing
      setRole(null);
      setEmploymentType(null);
      setEmploymentStartMonth(null);
      setEmploymentStartYear(null);
      setEmploymentEndMonth(null);
      setEmploymentEndYear(null);
      setDepartureReason(null);
      setCurrentlyWorkHere(false);
    }
  }, [open]);

  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  useEffect(() => {
    // FIXME looks like sometimes map will persist on location but context could be clear
    if (
      role &&
      employmentType &&
      employmentStartMonth &&
      employmentStartYear &&
      placeId &&
      ((employmentEndMonth && employmentEndYear) || currentlyWorkHere)
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [
    role,
    employmentType,
    employmentStartMonth,
    employmentEndMonth,
    employmentStartYear,
    employmentEndYear,
    currentlyWorkHere,
    placeId,
  ]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={handleClose}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            outline: "none",
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ fontWeight: 600 }}>
              {t("header", { ns: ["experience_builder"] })}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { display: "none" },
              pb: 10,
            }}
          >
            <Stack spacing={4} divider={<Divider />} sx={{ mt: 2 }}>
              <Box>
                <NewItemSelector
                  dictKey={"roles"}
                  dictHeaderKey={"roles_headers"}
                  title={t("role", { ns: "experience_builder" })}
                  selectedItems={role}
                  setSelectedItems={setRole}
                />
                {/* //only allow user to select no experience if //a) they haven't
                already selected no experience //b) they have no experience */}
                {!userData?.noExperience &&
                  userData?.experiences?.length === 0 && (
                    <Typography
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      level="body2"
                      onClick={() => handleFirstJob()}
                    >
                      {t("experience_builder:first_job")}
                    </Typography>
                  )}
              </Box>

              <SelectGroupSingle
                selected={employmentType}
                setSelected={setEmploymentType}
                title={t("employment_type", { ns: ["experience_builder"] })}
                items={employment_types_dict}
              />

              <Stack>
                <Typography level="selectLabel">
                  {t("select_workplace", { ns: ["experience_builder"] })}
                </Typography>
                <InlineBusinessSelectMap
                  placeId={placeId}
                  setPlaceId={setPlaceId}
                />
              </Stack>

              <Stack>
                <Typography level="selectLabel">
                  {t("dates", { ns: ["experience_builder"] })}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "8px", //same as SelectGroup
                  }}
                >
                  <MonthYearPopover
                    placeholder={
                      t("start_date", { ns: ["experience_builder"] }) ||
                      "Start Date"
                    }
                    month={employmentStartMonth}
                    setMonth={setEmploymentStartMonth}
                    year={employmentStartYear}
                    setYear={setEmploymentStartYear}
                  />
                  <Typography level="body2" sx={{ px: 2 }}>
                    {t("to", { ns: ["experience_builder"] })}
                  </Typography>
                  <MonthYearPopover
                    placeholder={
                      t("end_date", { ns: ["experience_builder"] }) ||
                      "End Date"
                    }
                    month={employmentEndMonth}
                    setMonth={setEmploymentEndMonth}
                    year={employmentEndYear}
                    setYear={setEmploymentEndYear}
                    disabled={currentlyWorkHere}
                  />
                </Box>
                <Checkbox
                  checked={currentlyWorkHere}
                  onChange={() =>
                    setCurrentlyWorkHere((prevState) => !prevState)
                  }
                  label={
                    t("currently_work_here", {
                      ns: ["experience_builder"],
                    }) || ""
                  }
                  sx={{ my: 3 }}
                />
              </Stack>

              <Stack>
                <Typography level="selectLabel">
                  {t("reason_for_leaving", { ns: ["experience_builder"] })}
                </Typography>
                <Input
                  variant="outlined"
                  placeholder={
                    t("reason_for_leaving_placeholder", {
                      ns: ["experience_builder"],
                    }) || ""
                  }
                  disabled={currentlyWorkHere}
                  onChange={(e) => setDepartureReason(e.target.value)}
                />
              </Stack>
              <Stack spacing={2}>
                <Button onClick={handleSave} disabled={!canSubmit}>
                  {t("save", {
                    ns: ["common"],
                  })}
                </Button>
                {experienceID && (
                  <Button
                    variant="plain"
                    onClick={() => {
                      confirm(
                        "experience_builder",
                        "delete_experience_message",
                        "delete_experience_subtitle",
                        "delete",
                        handleDeleteConfirmed,
                        [getRoleNameFromRole(role ? role[0] : null)]
                      );
                    }}
                  >
                    {t("delete", {
                      ns: ["common"],
                    })}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default ExperienceBuilder;
