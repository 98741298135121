import React from "react";
import { Box, Divider, Typography, Stack, Grid, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../Utils/Constants";
import { useAuth, useBusinessAuth } from "../Context/AuthContext";
import useConfirm from "../Utils/useConfirm";
import { FullBusinessService } from "../Services/Db/business";
import { FullUserService } from "../Services/Db/user";
import PageSpacer from "src/Components/Utilities/PageSpacer";
import {
  ArticleRounded,
  LanguageRounded,
  PrivacyTipRounded,
  TextFieldsRounded,
} from "@mui/icons-material";

const Footer = () => {
  const { t, i18n } = useTranslation(["common"]);
  const navigate = useNavigate();

  // for account deletion
  const location = useLocation();
  const confirm = useConfirm();

  return (
    <Box
      id="footer"
      sx={{
        width: "100vw",
        position: "sticky",
        zIndex: 0,
      }}
    >
      <Grid
        container
        sx={{
          bgcolor: "primary.main",
          px: { xs: "5vw", md: "20vw" },
          pt: { xs: 2, md: 6 },
          pb: 20,
        }}
      >
        <Grid xs={12} md={3}>
          <Typography
            level="h2"
            fontStyle="italic"
            sx={{ color: "common.white", mb: 2 }}
          >
            Hourlead
          </Typography>
        </Grid>
        <Grid md={2} />
        <Grid xs={12} md={7}>
          <Stack spacing={1}>
            <Typography
              sx={{
                cursor: "pointer",
                fontWeight: "bold",
                color: "common.white",
              }}
              startDecorator={<LanguageRounded />}
              onClick={() => {
                console.log("clicked");
                const newLanuage = i18n.language === "en" ? "es" : "en";
                i18n.changeLanguage(newLanuage, (err, t) => {
                  if (err)
                    return console.log("something went wrong loading", err);
                });
              }}
            >
              {t("change_language")}
            </Typography>

            <Typography
              onClick={() => navigate(APP_ROUTES.PRIVACY_POLICY)}
              sx={{
                color: "common.white",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              startDecorator={<PrivacyTipRounded />}
            >
              {t("privacy_policy")}
            </Typography>
            <Typography
              onClick={() => navigate(APP_ROUTES.TERMS_OF_SERVICE)}
              sx={{
                color: "common.white",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              startDecorator={<ArticleRounded />}
            >
              {t("terms_of_service")}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;

// {
//   location.pathname === APP_ROUTES.PAGE_MAKER && (
//     <Typography
//       onClick={() =>
//         confirm(
//           "common",
//           "delete_account_business",
//           "delete_account_business_desc",
//           "delete",
//           () => FullBusinessService.removeCurrentBusiness(),
//           undefined,
//           "business"
//         )
//       }
//       sx={{
//         color: "rgba(255,255,255,.5)",
//         cursor: "pointer",
//       }}
//     >
//       {t("delete_account_business")}
//     </Typography>
//   );
// }
// {
//   location.pathname === APP_ROUTES.CREATE_PROFILE && (
//     <Typography
//       onClick={() =>
//         confirm(
//           "common",
//           "delete_account_user",
//           "delete_account_user_desc",
//           "delete",
//           () => FullUserService.removeCurrentUser(),
//           undefined,
//           "user"
//         )
//       }
//       sx={{ color: "rgba(255,255,255,.5)", cursor: "pointer" }}
//     >
//       {t("delete_account_user")}
//     </Typography>
//   );
// }
