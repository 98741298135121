import React from "react";
import { Chip, Box, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

interface WageChipProps {
  wage: number;
  tips: number;
}

const WageChip: React.FC<WageChipProps> = ({ wage, tips }) => {
  const { t } = useTranslation("common");

  return (
    <Chip variant="soft" sx={{ py: 1, borderRadius: 2.25 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.4,
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            ${wage}
          </Typography>
          <Typography
            sx={{
              color: "primary.main",
              mt: -1.3,
              fontSize: "0.6rem",
            }}
          >
            {t("/hr", { ns: ["common"] })}
          </Typography>
        </Box>
        {tips > 0 && (
          <React.Fragment>
            <Typography
              sx={{
                mr: -0.5,
                mt: -1.3,
                color: "primary.main",
              }}
            >
              +
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 0.4,
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 600,
                  fontSize: "1.2rem",
                }}
              >
                {tips}
              </Typography>
              <Typography
                sx={{
                  color: "primary.main",
                  mt: -1.3,
                  fontSize: "0.6rem",
                }}
              >
                {t("tips_hr", { ns: ["common"] })}
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Chip>
  );
};

export default WageChip;
