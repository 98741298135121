import { CheckRounded, CloseRounded, EditRounded } from "@mui/icons-material";
import {
  Box,
  IconButton,
  Input,
  InputProps,
  Stack,
  Typography,
  Chip,
  Divider,
  Button,
} from "@mui/joy";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericModal from "../Common/GenericModal";

interface ConfirmInputProps extends InputProps {
  content: string;
  setContent: (s: string) => void;
  onComplete?: (s: string) => void;
  validate?: (s: string) => boolean;
  invalidMsg?: string;
}

const ConfirmInput = ({
  content,
  setContent,
  onComplete,
  validate,
  invalidMsg,
  ...options
}: ConfirmInputProps) => {
  const [edit, setEdit] = useState(false);
  const [newContent, setNewContent] = useState<string>();
  const [displayInvalid, setDisplayInvalid] = useState(false);

  const { t } = useTranslation("common");

  const originalContent = useRef(content);

  function handleComplete() {
    if (newContent && newContent !== originalContent.current) {
      if (validate) {
        if (!validate(newContent)) {
          setDisplayInvalid(true);
          return;
        }
      }
      setContent(newContent);
      setEdit(false);
      setDisplayInvalid(false);
      if (onComplete) {
        onComplete(newContent);
      }
    }
  }

  return (
    <Stack>
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Chip
          variant="outlined"
          color="neutral"
          size="md"
          onClick={() => setEdit(true)}
          endDecorator={<EditRounded fontSize="small" />}
        >
          {content}
        </Chip>
      </Box>

      <GenericModal
        header={"Edit"}
        bodyComponent={
          <Stack spacing={1}>
            <Input
              onChange={(e) => setNewContent(e.target.value)}
              defaultValue={content}
              autoFocus
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                sx={{ flexGrow: 1 }}
                onClick={() => {
                  setEdit(false);
                  setDisplayInvalid(false);
                }}
                variant="soft"
              >
                {t("common:cancel")}
              </Button>

              <Button
                onClick={handleComplete}
                sx={{ flexGrow: 1 }}
                disabled={!newContent || newContent === originalContent.current}
              >
                {t("common:save")}
              </Button>
            </Box>
            <Typography color="warning" level="body2" hidden={!displayInvalid}>
              {invalidMsg}
            </Typography>
          </Stack>
        }
        show={edit}
        setShow={setEdit}
      />
    </Stack>
  );
};
export default ConfirmInput;
