import { Box, Button, IconButton, Stack, Typography } from "@mui/joy";
import { Listings } from "src/Services/Db/types";
import WageChip from "../Common/WageChip";
import { ArrowForwardRounded, SendRounded } from "@mui/icons-material";
import useKeyToDict from "src/Utils/useKeyToDict";
import { useTranslation } from "react-i18next";
import { usePlaceDetails } from "../Maps/PlaceDetails";

const RoleItem = ({
  listing,
  onClick,
  displayName,
  onlyTips,
}: {
  listing: Listings;
  setActive?: (n: number) => void;
  setSelectedListing?: (l: Listings) => void;
  onClick: () => void;
  displayName?: boolean;
  onlyTips?: boolean;
}) => {
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const { t } = useTranslation("common");

  const placeDetails = usePlaceDetails(listing.businessRef!.id, ["name"]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 2,
        }}
      >
        {onlyTips ? (
          <WageChip wage={listing.wage + listing.tips} tips={0} />
        ) : (
          <WageChip wage={listing.wage} tips={listing.tips} />
        )}
        <Stack spacing={0}>
          <Typography
            level="h5"
            fontWeight="bold"
            sx={{
              wordWrap: "break-word",
              maxWidth: 200,
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            id="#role_name"
          >
            {getRoleNameFromRole({
              category: listing.category,
              item: listing.role,
            })}
          </Typography>
          {displayName && (
            <Typography
              level="body1"
              sx={{
                wordWrap: "break-word",
              }}
              id="#role_name"
            >
              {placeDetails?.name ?? "Loading..."}
            </Typography>
          )}
        </Stack>
      </Box>
      <IconButton>
        <ArrowForwardRounded />
      </IconButton>
    </Box>
  );
};

export default RoleItem;
