import {
  useTheme,
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListSubheader,
  ListItemButton,
  Modal,
  Divider,
  IconButton,
  Card,
  Button,
  Alert,
  Chip,
  CardOverflow,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState, useRef, useContext } from "react";

import { Timestamp } from "firebase/firestore";
import { Skeleton } from "@mui/material";
import PageSpacer from "src/Components/Utilities/PageSpacer";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "../../../src/animations.css";
import { Education, Experiences, Item } from "src/Services/Db/types";

import Intro from "./Intro";
import Job from "./Job";
import Wage from "./Wage";
import More from "./More";
import Result from "./Result";
import WageSurveyContext from "./WageSurveyContext";

import AppBarContext from "src/Context/AppBarContext";
import { HEADER_TYPES } from "src/Utils/Constants";
import { useAuth } from "src/Context/AuthContext";
import ResultSignedIn from "./ResultSignedIn";

const WageComparison = () => {
  const [active, setActive] = useState<
    "intro" | "job" | "wage" | "more" | "result" | "resultSignedIn"
  >(
    localStorage.getItem("completedSurvey") === "true"
      ? "resultSignedIn"
      : "intro"
  );
  const [job, setJob] = useState<Item[] | null>(null);
  const [wage, setWage] = useState<number | null>(null);
  const [tips, setTips] = useState<number | null>(null);

  const [placeId, setPlaceId] = useState<string | null>(null);

  const [experiences, setExperiences] = useState<Experiences[]>();
  const [education, setEducation] = useState<Education>();

  const nodeRef = useRef(null);

  const page = () => {
    switch (active) {
      case "intro":
        return <Intro />;
      case "job":
        return <Job />;
      case "wage":
        return <Wage />;
      case "more":
        return <More />;
      case "result":
        return <Result />;
      case "resultSignedIn":
        return <ResultSignedIn />;
    }
  };

  const appBarContext = useContext(AppBarContext);
  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.NONE);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [active]);

  return (
    <WageSurveyContext.Provider
      value={{
        active: active,
        setActive: setActive,
        job: job,
        setJob: setJob,
        wage: wage,
        setWage: setWage,
        tips: tips,
        setTips: setTips,
        experiences: experiences,
        setExperiences: setExperiences,
        education: education,
        setEducation: setEducation,
        placeId: placeId,
        setPlaceId: setPlaceId,
      }}
    >
      <SwitchTransition mode={"out-in"}>
        <CSSTransition
          key={active}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >
          <PageSpacer>
            <Box ref={nodeRef} sx={{ mt: { xs: "3vh", md: "10vh" } }}>
              {page()}
            </Box>
          </PageSpacer>
        </CSSTransition>
      </SwitchTransition>
    </WageSurveyContext.Provider>
  );
};

export default WageComparison;
