import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Popover, Toolbar, AppBar, Divider } from "@mui/material";
import {
  Button,
  Stack,
  Typography,
  Grid,
  Box,
  IconButton,
  Card,
  Modal,
  Badge,
} from "@mui/joy";
import {
  AccountCircleRounded,
  AttachMoneyOutlined,
  Tune,
  FavoriteRounded,
  GroupsRounded,
} from "@mui/icons-material";
import AppBarContext from "../Context/AppBarContext";
import LogInModal from "../Components/Common/LogInModal";
import { APP_ROUTES, USER_TYPES } from "../Utils/Constants";
import { WageAlert, BusinessAlert } from "./Alerts";
import { useTranslation } from "react-i18next";
import HeaderLocationSearch from "../Components/Maps/HeaderLocationSearch";
import { NewUserFlow } from "../Pages_JobSeeker/NewUserFlow";
import WagePopover from "./WagePopover";
import FilterPopover from "./FilterPopover";
import Slide from "@mui/material/Slide";
import { useAuth, useBusinessAuth } from "../Context/AuthContext";
import { MapContext } from "../Context/MapContext";
import { Item } from "../Services/Db/types";
import { FullUserService } from "../Services/Db/user";

interface TrioProps {
  signedIn: boolean;
}

const Trio: React.FC<TrioProps> = ({ signedIn }) => {
  const { t } = useTranslation(["header", "common"]);
  const navigate = useNavigate();
  const appBarContext = useContext(AppBarContext);
  const { user } = useAuth();
  const { business } = useBusinessAuth();
  const [savedRolesCount, setSavedRolesCount] = useState(0);
  const context = useContext(AppBarContext);

  const [loginPopoverAnchorEl, setLoginPopoverAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleLoginClick = (event: React.MouseEvent<HTMLElement>) => {
    if (user) {
      context?.setBackKey("back_to_map");
      navigate(APP_ROUTES.JOB_SEEKER_HOME);
    } else if (business) {
      navigate(APP_ROUTES.BUSINESS_HOME);
    } else {
      console.log("setting anchor");
      setLoginPopoverAnchorEl(event.currentTarget);
    }
  };

  useEffect(() => {
    if (user) {
      const loadSavedRolesCount = async (phoneNumber: string) => {
        const savedRoles = await FullUserService.loadUserSavedListingIds(
          phoneNumber
        );
        setSavedRolesCount(savedRoles.length);
      };
      loadSavedRolesCount(user.phoneNumber || "");
    }
  }, [user]);

  if (!business && !user) {
    return (
      <React.Fragment>
        <Button
          size="sm"
          onClick={(e) => {
            handleLoginClick(e);
          }}
          startDecorator={<AccountCircleRounded />}
          sx={{ my: { xs: 0.5, md: 0 }, mr: { xs: 0, md: 1 } }}
        >
          {t("common:sign_in_short")}
        </Button>
        <LogInModal
          anchorEl={loginPopoverAnchorEl}
          setAnchorEl={setLoginPopoverAnchorEl}
        />
      </React.Fragment>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        gap: { xs: 1, md: 0 },
        mr: -1,
      }}
    >
      <IconButton
        onClick={(e) => {
          if (signedIn) {
            appBarContext?.setBackKey("back_to_map");
            navigate(APP_ROUTES.APPLICATIONS);
          } else {
            handleLoginClick(e);
          }
        }}
      >
        <Badge badgeContent={0} size="sm">
          {/* TODO: put real number (is the number of interviews or actions the person needs to take) */}
          <GroupsRounded sx={{ color: "header.main", fontSize: "2rem" }} />
        </Badge>
      </IconButton>

      <IconButton
        onClick={(e) => {
          if (signedIn) {
            appBarContext?.setBackKey("back_to_map");
            navigate(APP_ROUTES.SAVED_ROLES);
          } else {
            handleLoginClick(e);
          }
        }}
      >
        <Badge badgeContent={savedRolesCount} size="sm">
          <FavoriteRounded sx={{ color: "header.main", fontSize: "2rem" }} />
        </Badge>
      </IconButton>

      <IconButton onClick={handleLoginClick}>
        <AccountCircleRounded sx={{ color: "header.main", fontSize: "2rem" }} />
      </IconButton>
    </Box>
  );
};

export default Trio;
