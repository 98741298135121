import { useState } from "react";
import {
  Card,
  Box,
  CardOverflow,
  Typography,
  Stack,
  AspectRatio,
  Divider,
  Button,
  LinearProgress,
  Alert,
  Grid,
} from "@mui/joy";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useParams } from "react-router-dom";
import { doc, DocumentReference } from "firebase/firestore";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";
import { useEffect, useContext } from "react";
import AppBarContext from "../Context/AppBarContext";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Businesses, Item, Listings, PlaceDetails } from "../Services/Db/types";
import {
  BusinessAccService,
  BusinessService,
  ListingsService,
} from "../Services/Db/service";
import { Skeleton } from "@mui/material";
import { usePlaceDetails } from "../Components/Maps/PlaceDetails";
import { DEFAULT_MARKER_VALS } from "../Utils/Constants";
import { getBusinessRef } from "../Services/Db/utils";
import { where } from "@firebase/firestore";
import RoleGeneric from "../Components/Roles/RoleGeneric";
import { TABLES } from "../Services/Db/consts";
import { db } from "../firebase";
import { FullBusinessService } from "../Services/Db/business";
import LocationDropdown from "../Components/Inputs/LocationDropdown";
import ExampleRole from "../Components/Roles/ExampleRole";
import { useBusinessAuth } from "src/Context/AuthContext";
import BusinessPageHeader from "src/Components/BusinessPage/BusinessPageHeader";
import BusinessPageBody from "src/Components/BusinessPage/BusinessPageBody";

const GroupPage = () => {
  const [groupId, setGroupId] = useState<string | null>(
    useParams().groupId ?? null
  );

  const navigate = useNavigate();

  const [placeIds, setPlaceIds] = useState<string[]>([]);
  const [loadedPlaceIds, setLoadedPlaceIds] = useState(false);

  const { t } = useTranslation();

  //get all placeIds associated with this groupId
  useEffect(() => {
    if (groupId) {
      BusinessAccService.getMany(
        where("groupCode", "==", groupId.toUpperCase())
      )
        .then((accs) => {
          if (accs && accs.length > 0 && accs[0].locations) {
            setPlaceIds(
              accs[0].locations
                .map((place) => place.id)
                .filter((id) => id !== undefined) as string[]
            );
          }
        })
        .finally(() => {
          setLoadedPlaceIds(true);
        });
    } else if (groupId === null) {
      setPlaceIds([]);
    }
  }, [groupId]);

  return (
    <PageSpacer>
      <Grid container sx={{ mt: "5vh", alignItems: "stretch" }} spacing={2}>
        {!loadedPlaceIds &&
          [1, 2, 3].map((i) => (
            <Grid key={i} xs={12} sm={6}>
              <Skeleton variant="rounded" width="100%" height={200} />
            </Grid>
          ))}
        {loadedPlaceIds &&
          placeIds.length !== 0 &&
          placeIds.map((placeId) => (
            <Grid key={placeId} xs={12} sm={6}>
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.25)",
                    transform: "translateY(-2px)",
                    transition: "all 0.2s ease-in-out",
                  },
                  transition: "all 0.1s ease-in-out",
                  borderRadius: 3,
                }}
                onClick={() => {
                  navigate(
                    APP_ROUTES.BUSINESS_PAGE.replace(":businessId", placeId)
                  );
                }}
              >
                <BusinessPageHeader placeId={placeId} />
              </Box>
            </Grid>
          ))}
        {loadedPlaceIds && placeIds.length === 0 && (
          <Alert>No businesses found</Alert>
        )}
      </Grid>
    </PageSpacer>
  );
};

export default GroupPage;
