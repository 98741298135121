import React from "react";
import {
  Interviews,
  InterviewLocation,
  Applications,
  Item,
} from "../../Services/Db/types";

interface InterviewContextType {
  //placeId
  placeId: string;
  setPlaceId: (placeId: string) => void;

  //upcoming interviews
  upcomingInterviews: (Applications & {
    role: Item;
    interview?: Interviews;
    business: string;
  })[];
  setUpcomingInterviews: (
    interviews: (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  ) => void;
  loadedUpcomingInterviews: boolean;
  setLoadedUpcomingInterviews: (loaded: boolean) => void;

  //availability
  availabilityDiscrepancy: boolean;
  setAvailabilityDiscrepancy: (discrepancy: boolean) => void;
  loadingAvailability: boolean;
  setLoadedAvailability: (loading: boolean) => void;
  savingInProgress: boolean;
  setSavingInProgress: (saving: boolean) => void;

  //interview prefs
  loadingPrefs: boolean;
  setLoadingPrefs: (loading: boolean) => void;
  canSavePrefs: boolean;
  setCanSavePrefs: (canSave: boolean) => void;
  prefDiscrepancy: boolean;
  setPrefDiscrepancy: (discrepancy: boolean) => void;

  //interview time details
  interviewDuration: number;
  setInterviewDuration: (duration: number) => void;
  startTime: number;
  setStartTime: (start: number) => void;
  endTime: number;
  setEndTime: (end: number) => void;
  dayOffset: number;
  setDayOffset: (offset: number) => void;
  changeType: "adding" | "removing"; //adding or removing the selected times
  setChangeType: (type: "adding" | "removing") => void;
  changed: boolean; //track if there's been changes to show apply and cancel accordingly
  setChanged: (changed: boolean) => void;

  //other interview details
  interviewTypes: InterviewLocation[];
  setInterviewTypes: (types: InterviewLocation[]) => void;
  videoLink: string;
  setVideoLink: (link: string) => void;
  reminderMsg: string;
  setReminderMsg: (msg: string) => void;

  //track recurring v one time
  recurring: boolean;
  setRecurring: (recurring: boolean) => void;

  //collisions
  collisions: (Applications & {
    role: Item;
    interview?: Interviews;
    business: string;
  })[];
  setCollisions: (
    collisions: (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  ) => void;
  collisionsOpen: boolean;
  setCollisionsOpen: (open: boolean) => void;

  //track recurring
  recurringAvailability: Date[];
  setRecurringAvailability: (recurringAvailability: Date[]) => void;
  removeRecurringAvailability: Date[];
  setRemoveRecurringAvailability: (recurringAvailability: Date[]) => void;

  //track one-time
  oneTimeAvailability: Date[];
  setOneTimeAvailability: (oneTimeAvailability: Date[]) => void;

  //track holding
  holding: Date[];
  setHolding: (holding: Date[]) => void;
}

// Context for confirmation modal
const InterviewContext = React.createContext<InterviewContextType | null>(null);

export default InterviewContext;
