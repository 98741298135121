import { Businesses } from "../../Services/Db/types";
import { isMarkerInConstraints } from "./Utils";
import { getWagesForBounds } from "../../Services/Db/utils";
import { useContext, useEffect, useRef, useState } from "react";
import Supercluster, { AnyProps } from "supercluster";
import { MapContext } from "../../Context/MapContext";
import AppBarContext from "../../Context/AppBarContext";
import { number } from "yup";

const useMarkers = (
  zoom: number,
  center: google.maps.LatLng,
  bounds?: google.maps.LatLngBounds
) => {
  const [markers, setMarkers] = useState<
    (Businesses & { cluster?: boolean; ignore?: boolean })[]
  >([]);
  const [maxWage, setMaxWage] = useState<number>(100);
  const superclusterRef = useRef<Supercluster<AnyProps, AnyProps>>();
  const appBarContext = useContext(AppBarContext);

  useEffect(() => {
    if (bounds !== undefined) {
      appBarContext?.setLoading(true);
      // FIXME we have to query/cache smarter
      const loadNewMarkers = async () => {
        let businesses: (Businesses & {
          cluster?: boolean;
          ignore?: boolean;
        })[] = await getWagesForBounds(bounds);
        // filter by filters from mapcontext
        businesses = businesses.map((b) => ({
          ...b,
          ignore:
            b.listingWageMap === undefined ||
            Object.keys(b.listingWageMap).length === 0,
        }));
        /*
        businesses = businesses.map((b) => ({
          ...b,
          ignore: !isMarkerInConstraints(
            b,
            context?.wageRange,
            context?.roles,
            context?.excludeRoles,
            context?.benefits,
            context?.skills,
            context?.schedules
          ),
        }));
        */

        // check if all ids in businesses are already in markers
        const markerIds = new Set<string>();
        markers.forEach((m) => markerIds.add(m.id));
        //const newMarkers = businesses.filter((m) => !markerIds.has(m.id));
        //if (newMarkers.length > 0) {
        // if there are new markers and have map params, update them
        const clusters = new Set<string>();
        if (zoom !== undefined) {
          superclusterRef.current = new Supercluster({
            radius: 30,
            maxZoom: 20,
          });
          superclusterRef.current.load(
            businesses
              .filter((b) => !b.ignore)
              .map((m) => ({
                type: "Feature",
                properties: {
                  markerId: m.id,
                  cluster: false,
                  ...m,
                },
                geometry: {
                  type: "Point",
                  coordinates: [m.latlong.longitude, m.latlong.latitude],
                },
              }))
          );
          const sw = bounds.getSouthWest();
          const ne = bounds.getNorthEast();
          superclusterRef.current
            .getClusters([sw.lng(), sw.lat(), ne.lng(), ne.lat()], zoom)
            .filter((cluster) => cluster.properties?.cluster !== true)
            .forEach((c) => clusters.add(c.properties.markerId));
        }
        businesses.sort((a, b) => {
          // sort by clusters has id first then by wage
          if (clusters.has(a.id) && !clusters.has(b.id)) {
            return -1;
          } else if (!clusters.has(a.id) && clusters.has(b.id)) {
            return 1;
          } else {
            return (
              Math.max(...Object.values(a.listingWageMap!)) -
              Math.max(...Object.values(b.listingWageMap!))
            );
          }
        });
        // include cluster attr in businesses
        businesses = businesses.map((m) => {
          return {
            ...m,
            cluster: !clusters.has(m.id),
          };
        });

        const newMarkers = [
          ...markers.map((m) => {
            const businessMatch = businesses.find((b) => b.id === m.id);
            if (businessMatch) {
              return businessMatch;
            }
            return m;
          }),
          ...businesses.filter((b) => !markerIds.has(b.id)),
        ];

        setMarkers([...newMarkers]);

        setMaxWage(
          businesses.reduce((prev, curr) => {
            if (curr.ignore) return prev;
            const max = Math.max(...Object.values(curr.listingWageMap!));
            return max > prev ? max : prev;
          }, 0)
        );
        appBarContext?.setLoading(false);
      };
      loadNewMarkers();
    }
  }, [bounds]);

  return { markers, maxWage };
};

export default useMarkers;
