import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DocumentReference, Timestamp } from "firebase/firestore";
import {
  Button,
  FormControl,
  FormLabel,
  Card,
  Chip,
  FormHelperText,
  Typography,
  Checkbox,
  Textarea,
  Box,
  Divider,
  Stack,
  List,
  ListItem,
  LinearProgress,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { KeyboardBackspace } from "@mui/icons-material";
import SelectGroup from "../Components/Inputs/SelectGroup";
import AppBarContext from "../Context/AppBarContext";
import {
  APP_ROUTES,
  HEADER_TYPES,
  DIVIDER_HEADER_SX_MY,
} from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import useKeyToDict from "../Utils/useKeyToDict";
import WageSlider from "../Components/Inputs/WageSlider";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import SelectGroupSingle from "../Components/Inputs/SelectGroupSingle";
import { Item } from "../Services/Db/types";
import { addListing } from "../Services/Db/utils";
import NewItemSelector from "../Components/Inputs/NewItemSelector";
import { ListingsService } from "../Services/Db/service";
import ExperienceSlider from "../Components/Inputs/ExperienceSlider";
import useConfirm from "../Utils/useConfirm";
import { useBusinessAuth } from "../Context/AuthContext";
import { doc } from "firebase/firestore";
import { TABLES } from "../Services/Db/consts";
import { db } from "../firebase";
import InlineBusinessSelectMap from "../Components/Maps/InlineBusinessSelectMap";
import { loadFullBusiness } from "../Components/Maps/Utils";
import { usePlaceDetails } from "../Components/Maps/PlaceDetails";
import { FullBusinessService } from "../Services/Db/business";
import LocationCheckbox from "../Components/Business/LocationCheckbox";
import { replace } from "formik";

interface RoleMakerPageProps {
  adminPower?: boolean;
}

const RoleMakerPage: React.FC<RoleMakerPageProps> = ({ adminPower }) => {
  const navigate = useNavigate();
  const context = useContext(AppBarContext);
  const location = useLocation();
  const { business } = useBusinessAuth();
  const confirm = useConfirm();
  const { t } = useTranslation(["business", "apply", "common"]);
  const [loading, setLoading] = useState(false);

  const [listingId, setListingId] = useState<string | null>(
    location.state ? location.state.listingId : null
  );
  const [locations, setLocations] = useState<string[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  //ONLY FOR ADMIN ADDING ROLE
  const [placeId, setPlaceId] = useState<string | undefined>();
  const placeDetails = usePlaceDetails(placeId, [
    "name",
    "formatted_address",
    "geometry",
    "place_id",
  ]);
  /****************************/

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const [wage, setWage] = useState(0);
  const wagePrev = useRef(0);
  const wageDefault = useRef(0);
  const businessRef = useRef<DocumentReference>();
  const rolePrev = useRef<Item>();
  const [tipsCommission, setTipsCommission] = useState<number | undefined>(
    undefined
  );
  const tipsDefault = useRef<number | undefined>(undefined);
  const [role, setRole] = useState<Item[] | null>(null);
  const [scheduleTypesSelected, setScheduleSelected] = useState<Item[] | null>(
    []
  );
  const [responsibilitiesSelected, setResponsibilitiesSelected] = useState<
    Item[] | null
  >([]);
  const [benefitsSelected, setBenefitsSelected] = React.useState<Item[] | null>(
    []
  );

  const [yearsOfExperienceSelected, setYearsOfExperienceSelected] =
    useState<number>(0);
  const yearsOfExperienceDefault = useRef(0);

  const [description, setDescription] = useState<string | null>(null);

  const [skills, setSkills] = useState<Item[] | null>([]);

  const [customQuestions, setCustomQuestions] = useState<string[]>([]);

  useEffect(() => {
    console.log("ROLE CHANGED");
  }, [role]);

  useEffect(() => {
    if (location.state && listingId) {
      const loadListingFields = async (lid: string) => {
        const listing = await ListingsService.getOne(lid);
        if (listing) {
          setRole([{ item: listing.role, category: listing.category }]);
          rolePrev.current = { item: listing.role, category: listing.category };
          setScheduleSelected(listing.schedule);
          setResponsibilitiesSelected(listing.skills);
          setBenefitsSelected(listing.perks);

          setYearsOfExperienceSelected(listing.years_of_experience);

          setWage(listing.wage);
          wagePrev.current = listing.wage + listing.tips ?? 0;
          setTipsCommission(listing.tips || undefined);
          setDescription(listing.description ?? null);
          setCustomQuestions(listing.custom_questions ?? []);
          setRequirements(listing.application_requirements ?? []);
          businessRef.current = listing.businessRef;

          // set defaults for sliders
          wageDefault.current = listing.wage;
          tipsDefault.current = listing.tips || undefined;
          yearsOfExperienceDefault.current = listing.years_of_experience;
        }
      };
      loadListingFields(listingId);
    }
  }, [listingId]);

  useEffect(() => {
    if (business) {
      const loadLocations = async (bid: string) => {
        const businessProfile = await FullBusinessService.getBusinessProfile(
          bid
        );
        if (businessProfile) {
          setLocations(businessProfile.locations!.map((l) => l.id!));
          setSelectedLocations(businessProfile.locations!.map((l) => l.id!));
        }
      };
      loadLocations(business.email!);
    }
  }, [business]);

  async function handleRemoveListing(): Promise<void> {
    if (listingId) {
      setLoading(true);
      FullBusinessService.removeLocationListing(
        business?.email!,
        listingId
        //businessRef.current!,
        //wagePrev.current,
        //rolePrev.current!
      ).then(() => {
        setLoading(false);
        navigate(APP_ROUTES.ROLES, {
          state: { listingIds: [listingId], change: "remove" },
        });
      });
    } else {
      navigate(APP_ROUTES.ROLES);
    }
  }

  function handleEditListing(): void {
    setLoading(true);
    if (listingId) {
      FullBusinessService.updateLocationListing(
        {
          id: listingId,
          role: role ? role[0].item : "",
          category: role ? role[0].category : "",
          schedule: scheduleTypesSelected ?? [],
          skills: responsibilitiesSelected ?? [],
          perks: benefitsSelected ?? [],
          years_of_experience: yearsOfExperienceSelected,
          wage: wage,
          tips: tipsCommission ?? 0,
          description: description,
          status: "active",
          application_requirements: requirements,
          businessRef: businessRef.current,
          posted_at: Timestamp.fromDate(new Date()),
          custom_questions: customQuestions.filter((q) => q !== ""),
        },
        ""
        //wagePrev.current
      ).then(() => {
        setLoading(false);
        navigate(APP_ROUTES.ROLES);
      });
    } else {
      navigate(APP_ROUTES.ROLES);
    }
  }

  async function handleSubmitListing(): Promise<void> {
    setLoading(true);
    Promise.all(
      locations.map(
        async (l: string) =>
          await FullBusinessService.createBusinessListing(business?.email!, {
            role: role ? role[0].item : "",
            category: role ? role[0].category : "",
            schedule: scheduleTypesSelected ?? [],
            skills: responsibilitiesSelected ?? [],
            perks: benefitsSelected ?? [],
            years_of_experience: yearsOfExperienceSelected,
            wage: wage,
            tips: tipsCommission ?? 0,
            description: description,
            status: "active",
            application_requirements: requirements,
            businessRef: doc(db, TABLES.BUSINESSES, l),
            posted_at: Timestamp.fromDate(new Date()),
            custom_questions: customQuestions.filter((q) => q !== ""),
          })
      )
    )
      .then((listingIds) => {
        setLoading(false);
        navigate(APP_ROUTES.ROLES, {
          state: { listingIds: listingIds, change: "add" },
        });
      })
      .catch((err) => console.log(err));
  }

  function handleDisregardEdits(): void {
    navigate(APP_ROUTES.ROLES);
  }

  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    setCanSubmit(
      wage !== 0 &&
        role !== null &&
        role.length !== 0 &&
        Boolean(business && business.email) &&
        selectedLocations.length > 0
    );
  }, [role, wage, canSubmit, selectedLocations]);

  let availableRequirements = [
    { id: "experiences", title: t("apply:experiences") },
    { id: "languages", title: t("apply:languages_spoken") },
    { id: "education", title: t("apply:education") },
    { id: "references", title: t("apply:references") },
    { id: "availability", title: t("apply:availability") },
  ];

  const [requirements, setRequirements] = useState<string[]>([]);

  return (
    <React.Fragment>
      <PageSpacer>
        <Stack spacing={4}>
          {adminPower && (
            <Box>
              <Button
                onClick={() => {
                  console.log({
                    role: role ? role[0].item : "",
                    category: role ? role[0].category : "",
                    wage: wage,
                    tips: tipsCommission || 0,
                    perks: benefitsSelected || [],
                    schedule: scheduleTypesSelected || [],
                    years_of_experience: yearsOfExperienceSelected,
                    skills: responsibilitiesSelected || [],
                    description: description,
                    custom_questions: customQuestions.filter((q) => q !== ""),
                    posted_at: Timestamp.fromDate(new Date()),
                    status: "active",
                    application_requirements: requirements,
                  });
                  if (placeDetails && role) {
                    addListing(loadFullBusiness(placeDetails), {
                      role: role ? role[0].item : "",
                      category: role ? role[0].category : "",
                      wage: wage,
                      tips: tipsCommission || 0,
                      perks: benefitsSelected || [],
                      schedule: scheduleTypesSelected || [],
                      years_of_experience: yearsOfExperienceSelected,
                      skills: responsibilitiesSelected || [],
                      description: description,
                      custom_questions: customQuestions.filter((q) => q !== ""),
                      posted_at: Timestamp.fromDate(new Date()),
                      status: "active",
                      application_requirements: requirements,
                    });
                    console.log("added listing");
                  }
                }}
              >
                ADD LISTING
              </Button>
              <InlineBusinessSelectMap
                setPlaceId={setPlaceId}
                placeId={placeId}
              />
            </Box>
          )}
          <Box>
            <Button
              variant="outlined"
              color="primary"
              startDecorator={<KeyboardBackspace />}
              onClick={() => navigate(APP_ROUTES.ROLES)}
            >
              {t("back_to_all_roles", { ns: ["business"] })}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {location.state && location.state.edit ? (
              <React.Fragment>
                <Typography level="sectionHeader">
                  {t("edit_role", { ns: ["business"] })}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 1,
                    width: { xs: "100%", md: "auto" },
                  }}
                >
                  <Button
                    variant="soft"
                    fullWidth
                    onClick={handleDisregardEdits}
                  >
                    {t("disregard_edits", { ns: ["business"] })}
                  </Button>
                  <Button
                    variant="solid"
                    fullWidth
                    onClick={() =>
                      confirm(
                        "business",
                        "edit_listing",
                        "confirm_listing_desc",
                        "confirm",
                        handleEditListing
                      )
                    }
                    disabled={!canSubmit}
                  >
                    {t("confirm_edits", { ns: ["business"] })}
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography level="sectionHeader">
                  {t("new_role", { ns: ["business"] })}
                </Typography>
              </React.Fragment>
            )}
          </Box>

          <Card variant="outlined">
            <Typography level="cardHeader">
              {t("about_this_role", { ns: ["business"] })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Stack spacing={3} divider={<Divider />}>
              <NewItemSelector
                dictKey={"roles"}
                dictHeaderKey={"roles_headers"}
                title={t("role", { ns: ["business"] })}
                selectedItems={role}
                setSelectedItems={setRole}
              />

              {/* Wage */}
              <Stack>
                <Typography level="selectLabel">
                  {t("hourly_wage", { ns: ["business"] })}
                </Typography>
                <Box sx={{ py: 2, px: 1, width: "100%" }}>
                  <WageSlider
                    defaultVal={wageDefault.current + 1}
                    setWage={setWage}
                  />
                </Box>
                <Box sx={{ py: 2 }}>
                  <Chip
                    variant="outlined"
                    color="neutral"
                    onClick={() =>
                      setTipsCommission((prev) => {
                        if (prev === undefined) {
                          return 0;
                        }
                        return undefined;
                      })
                    }
                    startDecorator={
                      tipsCommission === undefined ? (
                        <CheckBoxOutlineBlank />
                      ) : (
                        <CheckBox />
                      )
                    }
                  >
                    {t("tips_commission", { ns: ["business"] }) || ""}
                  </Chip>
                </Box>
                {tipsCommission !== undefined && (
                  <React.Fragment>
                    <Typography level="selectLabel" sx={{ mt: 2 }}>
                      {t("tips_commission_label", { ns: ["business"] })}
                    </Typography>
                    <Box sx={{ py: 2, px: 1, width: "100%" }}>
                      <WageSlider
                        defaultVal={
                          tipsDefault.current ? tipsDefault.current + 1 : 0
                        }
                        setWage={setTipsCommission}
                      />
                    </Box>
                  </React.Fragment>
                )}
              </Stack>

              <NewItemSelector
                selectMultiple
                dictKey={"schedules"}
                dictHeaderKey={"schedules_headers"}
                title={t("schedule_types", { ns: ["business"] })}
                selectedItems={scheduleTypesSelected}
                setSelectedItems={setScheduleSelected}
              />

              <NewItemSelector
                selectMultiple
                title={t("benefits", { ns: ["business"] })}
                selectedItems={benefitsSelected}
                setSelectedItems={setBenefitsSelected}
                dictKey={"perks"}
                dictHeaderKey={"perks_headers"}
              />

              <Box>
                <Chip
                  variant="outlined"
                  color="neutral"
                  onClick={() =>
                    setDescription((prev) => {
                      if (prev === null) {
                        return "";
                      }
                      return null;
                    })
                  }
                  startDecorator={
                    description === null ? (
                      <CheckBoxOutlineBlank />
                    ) : (
                      <CheckBox />
                    )
                  }
                >
                  {t("add_optional_info", { ns: ["business"] })}
                </Chip>

                {description !== null && (
                  <FormControl sx={{ mt: 2 }}>
                    <FormLabel>
                      {t("role_description", { ns: ["business"] })}
                    </FormLabel>
                    <Textarea
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder={
                        t("role_description", { ns: ["business"] }) || ""
                      }
                    />
                    <FormHelperText>
                      150 {t("word_max", { ns: ["common"] })}
                    </FormHelperText>
                  </FormControl>
                )}
              </Box>
            </Stack>
          </Card>

          <Card>
            <Typography level="cardHeader">
              {t("who_im_looking_for", { ns: ["business"] })}
            </Typography>
            <Typography level="body1">
              {t("who_im_looking_for_desc", { ns: ["business"] })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Stack spacing={2} divider={<Divider />}>
              <Box>
                <Typography
                  level="selectLabel"
                  sx={{ pb: 3, wordWrap: "break-word" }}
                >
                  {t("years_of_experience", { ns: ["business"] })}
                </Typography>
                <ExperienceSlider setValue={setYearsOfExperienceSelected} />
              </Box>
              <NewItemSelector
                selectMultiple
                title={t("requirements", { ns: ["business"] })}
                selectedItems={skills}
                setSelectedItems={setSkills}
                dictKey={"skills"}
                dictHeaderKey={"skills_headers"}
              />
            </Stack>
          </Card>

          <Card>
            <Typography level="cardHeader">
              {t("application_requirements", { ns: ["business"] })}
            </Typography>

            <Typography level="body2">
              {t("application_requirements_desc", { ns: ["business"] })}
            </Typography>

            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <List>
              <ListItem key={"name"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Checkbox checked={true} disabled />
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("common:name")}
                  </Typography>
                </Box>
              </ListItem>
              <ListItem key={"phone"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Checkbox checked={true} disabled />
                  <Typography sx={{ fontWeight: 500 }}>
                    {t("common:phone_number")}
                  </Typography>
                </Box>
              </ListItem>

              {availableRequirements.map((req) => {
                return (
                  <ListItem key={req.id}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (requirements.includes(req.id)) {
                          setRequirements(
                            requirements.filter((r) => r !== req.id)
                          );
                        } else {
                          setRequirements([...requirements, req.id]);
                        }
                      }}
                    >
                      <Checkbox checked={requirements.includes(req.id)} />
                      <Typography sx={{ fontWeight: 500 }}>
                        {req.title}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Card>

          <Card>
            <Typography level="cardHeader">
              {t("additional_questions", { ns: ["business"] })}
            </Typography>

            <Typography level="body2">
              {t("additional_questions_desc", { ns: ["business"] })}
            </Typography>

            <Divider sx={DIVIDER_HEADER_SX_MY} />

            <Stack spacing={2}>
              {[1, 2, 3].map((i) => {
                return (
                  <FormControl key={i}>
                    <FormLabel>
                      {t("question_num", { ns: ["business"], num: i })}
                    </FormLabel>
                    <Textarea
                      placeholder={
                        t(`additional_questions_placeholder_${i}`, {
                          ns: ["business"],
                        }) || ""
                      }
                      onChange={(e) => {
                        const val = e.target.value;

                        setCustomQuestions((prev) => {
                          const newQuestions = [...prev];
                          newQuestions[i - 1] = val;
                          return newQuestions;
                        });
                      }}
                      value={customQuestions[i - 1] || ""}
                    />
                  </FormControl>
                );
              })}
            </Stack>
          </Card>

          {locations.length > 1 && (
            <Card>
              <Typography level="cardHeader">
                {t("select_location", { ns: ["business"] })}
              </Typography>

              <Typography level="body2">
                {t("select_location_desc", { ns: ["business"] })}
              </Typography>

              <Divider sx={DIVIDER_HEADER_SX_MY} />

              {locations.map((location, i) => (
                <Box sx={{ mb: 1 }} key={i}>
                  <LocationCheckbox
                    placeId={location}
                    selected={selectedLocations}
                    setSelected={setSelectedLocations}
                  />
                </Box>
              ))}
            </Card>
          )}

          <Stack spacing={1}>
            {location.state && location.state.edit ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <Button variant="soft" fullWidth onClick={handleDisregardEdits}>
                  {t("disregard_edits", { ns: ["business"] })}
                </Button>
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() =>
                    confirm(
                      "business",
                      "edit_listing",
                      "confirm_listing_desc",
                      "confirm",
                      handleEditListing
                    )
                  }
                  disabled={!canSubmit}
                >
                  {t("confirm_edits", { ns: ["business"] })}
                </Button>
              </Box>
            ) : (
              <Button
                variant="solid"
                disabled={!canSubmit}
                fullWidth
                onClick={() =>
                  confirm(
                    "business",
                    "post_role",
                    "confirm_listing_desc",
                    "confirm",
                    handleSubmitListing
                  )
                }
              >
                {t("post_role", { ns: ["business"] })}
              </Button>
            )}
            {location.state && location.state.edit && (
              <Button
                sx={{ mt: 0 }}
                variant="plain"
                fullWidth
                onClick={() =>
                  confirm(
                    "business",
                    "delete_listing",
                    "delete_listing_desc",
                    "delete",
                    handleRemoveListing
                  )
                }
              >
                {t("common:delete")}
              </Button>
            )}
          </Stack>
        </Stack>
      </PageSpacer>
      <Modal open={loading}>
        <ModalDialog>
          <LinearProgress />
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
};

export default RoleMakerPage;
