import { useContext } from "react";
import ToastContext from "../Context/ToastContext";

function useToast() {
  const toastContext = useContext(ToastContext);

  const toast = (
    namespace: string,
    messageKey: string,
    actionKey: string,
    toDo: () => void,
    toInsertInMessage?: string[] | undefined
  ) => {
    toastContext?.setNamespace(namespace);
    toastContext?.setMessageKey(messageKey);
    toastContext?.setActionKey(actionKey);
    toastContext?.setToDo(() => toDo);
    toastContext?.setToInsertInMessage(toInsertInMessage);
    toastContext?.setShow(true);
  };

  return toast;
}

export default useToast;
