import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, InputBase, Toolbar, AppBar } from "@mui/material";
import { Button, Typography, Grid, Box } from "@mui/joy";
import { AccountCircleOutlined, AccountCircle } from "@mui/icons-material";
import AppBarContext from "../Context/AppBarContext";
import LogInModal from "../Components/Common/LogInModal";
import { APP_ROUTES } from "../Utils/Constants";
import { WageAlert, BusinessAlert } from "./Alerts";
import { KeyboardBackspace } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function UserAppBar() {
  const navigate = useNavigate();
  const appBarContext = React.useContext(AppBarContext);

  const { t } = useTranslation(["header", "common"]);

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          bgcolor: "header.bg",
          color: "header.main",
          mb: { xs: 3, md: 10 },
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "64px",
          }}
        >
          <Grid
            container
            sx={{
              width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            }}
          >
            <Grid xs={4}>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  startDecorator={<KeyboardBackspace />}
                  onClick={() => {
                    navigate(-1);
                  }}
                  sx={{ ml: -1 }}
                >
                  {t(appBarContext?.backKey || "back", {
                    ns: ["header", "common"],
                  })}
                </Button>
              </Box>
            </Grid>

            <Grid
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                level="h5"
                noWrap
                sx={{
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "header.main",
                  fontStyle: "italic",
                }}
                onClick={() => navigate(APP_ROUTES.HOME)}
              >
                Hourlead
              </Typography>
            </Grid>

            <Grid xs={4}></Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}
