import "./App.css";

import React, { useState, useEffect, useContext, useRef } from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Box } from "@mui/joy";

// APP BARS
import { HEADER_TYPES } from "./Utils/Constants";
import BusinessAppBar from "./App Bars/BusinessAppBar";
import SearchAppBar from "./App Bars/SearchAppBar";
import BasicAppBar from "./App Bars/BasicAppBar";
import UserAppBar from "./App Bars/UserAppBar";
import GPTAppBar from "./App Bars/GPTAppBar";

// PUBLIC PAGES
import Home from "./Pages_Public/Home";
import Footer from "./Pages_Public/Footer";
import WelcomeCard from "./Pages_Public/WelcomeCard";
import PrivacyPolicy from "./Pages_Public/PrivacyPolicy";
import WageDisclaimer from "./Pages_Public/WageDisclaimer";
import BusinessPage from "./Pages_Public/BusinessPage";
import GroupPage from "./Pages_Public/GroupPage";

// MARKETING PAGES
import Demo from "./Marketing/Demo";
import ScheduleCall from "./Marketing/ScheduleCall";
import WageComparison from "./Marketing/WageComparison/WageComparison";

// BUSINESS PAGES
import Candidates from "./Pages_Business/Candidates";
import Roles from "./Pages_Business/Roles";
import FullRole from "./Pages_Business/FullRole";
import CandidateProfile from "./Pages_Business/CandidateProfile";
import NewBusiness from "./Pages_Business/NewBusiness";
import BuisnessInterviews from "./Pages_Business/Interviews/BusinessInterviews";
import BusinessHome from "./Pages_Business/BusinessHome";
import PageMaker from "./Pages_Business/PageMaker";
import SignMaker from "./Pages_Business/SignMaker";

// JOB-SEEKER PAGES
import { NewUserFlowFullPage } from "./Pages_JobSeeker/NewUserFlow";
import { CreateProfilePage } from "./UserProfile/CreateProfile";
import JobSeekerHome from "./Pages_JobSeeker/JobSeekerHome";
import SavedRoles from "./Pages_JobSeeker/SavedRoles";
import Applications from "./Pages_JobSeeker/ApplicationsPage";
import Apply from "./Components/Apply/Apply";

import { CssVarsProvider } from "@mui/joy";
import theme from "./Utils/Theme";

import { APP_ROUTES } from "./Utils/Constants";
import RequireAuth from "./Components/Authentication/RequireAuth";

import { MapContextProvider } from "./Context/MapContext";
import { AuthProvider, useAuth } from "./Context/AuthContext";
import AppBarContext from "./Context/AppBarContext";

//CONFIRMATION MODAL AND TOAST
import ConfirmationModalContext from "./Context/ConfirmationModalContext";
import ConfirmationModal from "./Components/Utilities/ConfirmationModal";
import ToastContext from "./Context/ToastContext";
import Toast from "./Components/Utilities/Toast";

import GoogleMapsLoader from "./Components/Maps/GoogleMapsLoader";
import SignUp from "./Components/Authentication/SignUp";
import AddRole from "./AddRole";
import LocationSelector from "./Components/Maps/LocationSelector";
import DaySelect from "./Components/Inputs/DaySelect";
import TermsOfService from "./Pages_Public/TermsOfService";
import Reporter from "./Components/Inputs/Reporter";
import ExampleRoleFull from "./Components/Roles/ExampleRoleFull";
import InterviewConfirmation from "./Pages_JobSeeker/InterviewConfirmation";
import EditRolePage from "./Pages_Business/EditRolePage";
import Invitation from "./Pages_Public/Invitation";
import ScrollToTop from "./Utils/ScrollToTop";

const header = (headerType: HEADER_TYPES) => {
  switch (headerType) {
    case HEADER_TYPES.GPT:
      return <GPTAppBar />;
    case HEADER_TYPES.BUSINESS:
      return <BusinessAppBar />;
    case HEADER_TYPES.SEARCH:
      return <SearchAppBar />;
    case HEADER_TYPES.USER:
      return <UserAppBar />;
    case HEADER_TYPES.BASIC:
      return <BasicAppBar />;
    case HEADER_TYPES.NONE:
      return <></>;
    default:
      return <p>error</p>;
  }
};

const App = () => {
  const [headerType, setHeaderType] = useState(HEADER_TYPES.NONE);
  const [backKey, setBackKey] = useState<string | undefined>(undefined);

  const [showHeader, setShowHeader] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showAlerts, setShowAlerts] = useState<string[]>([
    // "business",
    // "addWage",
  ]);

  const prevShowAlerts = useRef(showAlerts);
  useEffect(() => {
    var justHidden = "";

    //find the difference
    justHidden = prevShowAlerts.current.filter(
      (x) => !showAlerts.includes(x)
    )[0];
    prevShowAlerts.current = showAlerts;

    var hideAlerts = justHidden ? [justHidden] : [];

    const local = localStorage.getItem("hideAlerts");

    //add local storage to hide alerts
    if (local !== null) {
      hideAlerts = [
        ...hideAlerts,
        ...JSON.parse(localStorage.getItem("hideAlerts")!),
      ];
    }

    setShowAlerts(showAlerts.filter((alert) => !hideAlerts.includes(alert)));

    localStorage.setItem("hideAlerts", JSON.stringify(hideAlerts));
  }, [showAlerts.join(" ")]);

  const [showWelcome, setShowWelcome] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);
  const [namespaceConfirm, setNamespaceConfirm] = useState("");
  const [mainMessageKey, setMainMessageKey] = useState("");
  const [subtitleKey, setSubtitleKey] = useState("");
  const [actionKeyConfirm, setActionKeyConfirm] = useState("");
  const [toDoConfirm, setToDoConfirm] = useState(() => () => {});
  const [toInsertInSubtitleConfirm, setToInsertInSubtitleConfirm] = useState<
    string[] | undefined
  >(undefined);
  const [requiresReauth, setRequiresReauth] = useState<"business" | "user">();

  const [showToast, setShowToast] = useState(false);
  const [namespaceToast, setNamespaceToast] = useState("");
  const [messageKeyToast, setMessageKeyToast] = useState("");
  const [toInsertInMessageToast, setToInsertInMessageToast] = useState<
    string[] | undefined
  >(undefined);
  const [actionKeyToast, setActionKeyToast] = useState("");
  const [toDoToast, setToDoToast] = useState(() => () => {});
  const [alertOffset, setAlertOffset] = useState(0);

  return (
    <AuthProvider>
      <AppBarContext.Provider
        value={{
          setHeaderType: setHeaderType,
          showAlerts: showAlerts,
          setShowAlerts: setShowAlerts,
          backKey: backKey,
          setBackKey: setBackKey,
          showHeader: showHeader,
          setShowHeader: setShowHeader,
          alertOffset: alertOffset,
          setAlertOffset: setAlertOffset,
          loading: loading,
          setLoading: setLoading,
        }}
      >
        <ConfirmationModalContext.Provider
          value={{
            show: showConfirm,
            setShow: setShowConfirm,
            namespace: namespaceConfirm,
            setNamespace: setNamespaceConfirm,
            mainMessageKey: mainMessageKey,
            setMainMessageKey: setMainMessageKey,
            subtitleKey: subtitleKey,
            setSubtitleKey: setSubtitleKey,
            actionKey: actionKeyConfirm,
            setActionKey: setActionKeyConfirm,
            toDo: toDoConfirm,
            setToDo: setToDoConfirm,
            toInsertInSubtitle: toInsertInSubtitleConfirm,
            setToInsertInSubtitle: setToInsertInSubtitleConfirm,
            requiresReauth: requiresReauth,
            setRequiresReauth: setRequiresReauth,
          }}
        >
          <ToastContext.Provider
            value={{
              show: showToast,
              setShow: setShowToast,
              namespace: namespaceToast,
              setNamespace: setNamespaceToast,
              messageKey: messageKeyToast,
              setMessageKey: setMessageKeyToast,
              toInsertInMessage: toInsertInMessageToast,
              setToInsertInMessage: setToInsertInMessageToast,
              actionKey: actionKeyToast,
              setActionKey: setActionKeyToast,
              toDo: toDoToast,
              setToDo: setToDoToast,
            }}
          >
            <MapContextProvider>
              <GoogleMapsLoader>
                <Box className="App">
                  <Router>
                    <ScrollToTop />
                    <CssVarsProvider theme={theme}>
                      {header(headerType)}
                      <Box
                        sx={{
                          minHeight: "-webkit-fill-available",
                        }}
                      >
                        <Routes>
                          {/* TEST TO DELETE ROUTES */}
                          <Route
                            path={APP_ROUTES.TEST_ADD_BUSINESS}
                            element={<LocationSelector />}
                          />
                          <Route
                            path={APP_ROUTES.TEST}
                            element={<DaySelect />}
                          />
                          <Route
                            path={APP_ROUTES.EXAMPLE_ROLE}
                            element={<ExampleRoleFull />}
                          />

                          {/* PUBLIC ROUTES */}
                          <Route
                            path={APP_ROUTES.INVITATION}
                            element={<Invitation />}
                          />
                          <Route
                            path={APP_ROUTES.INTERVIEW_CONFIRMATION}
                            element={<InterviewConfirmation />}
                          />
                          <Route
                            path={APP_ROUTES.HOME}
                            element={
                              <Home
                                showWelcome={showWelcome}
                                setShowWelcome={setShowWelcome}
                              />
                            }
                          />
                          <Route
                            path={APP_ROUTES.PRIVACY_POLICY}
                            element={<PrivacyPolicy />}
                          />

                          <Route
                            path={APP_ROUTES.WAGE_DISCLAIMER}
                            element={<WageDisclaimer />}
                          />

                          <Route
                            path={APP_ROUTES.TERMS_OF_SERVICE}
                            element={<TermsOfService />}
                          />

                          <Route path={APP_ROUTES.DEMO} element={<Demo />} />
                          <Route
                            path={APP_ROUTES.WAGE_COMPARISON}
                            element={<WageComparison />}
                          />
                          <Route
                            path={APP_ROUTES.SCHEDULE_CALL}
                            element={<ScheduleCall />}
                          />

                          {/* BUSINESS ROUTES */}
                          <Route path={APP_ROUTES.ROLES} element={<Roles />} />
                          <Route
                            path={APP_ROUTES.CANDIDATES}
                            element={
                              <RequireAuth business>
                                <Candidates />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.ROLE_MAKER}
                            element={
                              <RequireAuth business>
                                <EditRolePage />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.ROLE}
                            element={<FullRole />}
                          />
                          <Route
                            path={APP_ROUTES.CANDIDATE_PROFILE}
                            element={
                              <RequireAuth business>
                                <CandidateProfile />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.BUSINESS_PAGE}
                            element={<BusinessPage />}
                          />
                          <Route
                            path={APP_ROUTES.GROUP_PAGE}
                            element={<GroupPage />}
                          />
                          <Route
                            path={APP_ROUTES.PAGE_MAKER}
                            element={
                              <RequireAuth business>
                                <PageMaker />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.SIGN_MAKER}
                            element={
                              <RequireAuth business>
                                <SignMaker />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.ADD_ROLE}
                            element={<AddRole />}
                          />
                          <Route
                            path={APP_ROUTES.NEW_BUSINESS}
                            element={<NewBusiness />}
                          />
                          <Route
                            path={APP_ROUTES.BUSINESS_INTERVIEWS}
                            element={
                              <RequireAuth business>
                                <BuisnessInterviews />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.BUSINESS_HOME}
                            element={
                              <RequireAuth business>
                                <BusinessHome />
                              </RequireAuth>
                            }
                          />

                          {/* USER ROUTES */}
                          <Route
                            path={APP_ROUTES.NEW_USER_FLOW}
                            element={<NewUserFlowFullPage />}
                          />
                          <Route
                            path={APP_ROUTES.CREATE_PROFILE}
                            element={
                              <RequireAuth>
                                <CreateProfilePage />
                              </RequireAuth>
                            }
                          />
                          <Route path={APP_ROUTES.APPLY} element={<Apply />} />
                          <Route
                            path={APP_ROUTES.JOB_SEEKER_HOME}
                            element={
                              <RequireAuth>
                                <JobSeekerHome />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.SAVED_ROLES}
                            element={
                              <RequireAuth>
                                <SavedRoles />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.APPLICATIONS}
                            element={
                              <RequireAuth>
                                <Applications />
                              </RequireAuth>
                            }
                          />
                          <Route
                            path={APP_ROUTES.SIGN_UP}
                            element={
                              <RequireAuth>
                                <SignUp />
                              </RequireAuth>
                            }
                          />
                        </Routes>
                      </Box>

                      <Reporter />

                      <Footer />

                      <Toast />

                      <WelcomeCard
                        show={showWelcome}
                        setShow={setShowWelcome}
                      />
                      <ConfirmationModal
                        show={showConfirm}
                        setShow={setShowConfirm}
                      />
                    </CssVarsProvider>
                  </Router>
                </Box>
              </GoogleMapsLoader>
            </MapContextProvider>
          </ToastContext.Provider>
        </ConfirmationModalContext.Provider>
      </AppBarContext.Provider>
    </AuthProvider>
  );
};

export default App;
