import { Businesses, Item } from "../../Services/Db/types";
import { Stack, Typography, Box } from "@mui/joy";
import dayjs, { Dayjs } from "dayjs";
import { DocumentReference } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BusinessService } from "../../Services/Db/service";
import useKeyToDict from "../../Utils/useKeyToDict";

interface ExperienceItemProps {
  role: Item;
  businessRef: DocumentReference;
  job_type: string;
  reason_for_leaving?: string;
  start_date: Dayjs;
  end_date?: Dayjs;
}

const ExperienceItem: React.FC<ExperienceItemProps> = ({
  role,
  businessRef,
  job_type,
  reason_for_leaving,
  start_date,
  end_date,
}) => {
  const { t } = useTranslation(["experience_builder", "common"]);
  let duration = (end_date || dayjs()).diff(start_date, "month");

  let years = Math.floor(duration / 12);
  let months = duration % 12;
  let yearsString = t("years", { count: years });
  let monthsString = t("months", { count: months });
  let durationMessage;
  if (years === 0) {
    //months must always be > 0
    durationMessage = monthsString;
  } else {
    if (months === 0) {
      durationMessage = yearsString;
    } else {
      durationMessage = `${yearsString} ${t("common:and")} ${monthsString}`;
    }
  }

  const [business, setBusiness] = useState<Businesses>();

  useEffect(() => {
    const loadBusiness = async (businessRef: DocumentReference) => {
      const loadedBusiness: Businesses | null =
        await BusinessService.getResolvedReference(businessRef);
      if (loadedBusiness) {
        setBusiness(loadedBusiness);
      }
    };
    loadBusiness(businessRef);
  }, [businessRef]);

  const { getRoleNameFromRole, keyToValueInList } = useKeyToDict("lists");

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography level="h5">
          {getRoleNameFromRole(role)}{" "}
          <Typography sx={{ color: "text.tertiary" }}>
            {t("for", { ns: ["common"] })} {durationMessage}
          </Typography>
        </Typography>
        <Typography level="body2">
          {business?.name}
          <Typography sx={{ color: "text.tertiary" }}>
            {" "}
            - {keyToValueInList(job_type, "job_types")}
          </Typography>
        </Typography>
        <Typography level="body2">
          {reason_for_leaving &&
            t("left_because", { ns: ["experience_builder"] }) +
              ": " +
              reason_for_leaving}
        </Typography>
        <Box sx={{ display: { xs: "inline", md: "none" } }}>
          <Typography level="body2">
            {start_date.format("MMM YYYY")} -{" "}
            {end_date
              ? end_date.format("MMM YYYY")
              : t("present", { ns: ["common"] })}
          </Typography>
          <Typography level="body3">{business?.address}</Typography>
        </Box>
      </Stack>
      <Stack
        alignItems={"flex-end"}
        sx={{ display: { xs: "none", md: "inline" } }}
      >
        <Typography level="body2" textAlign={"right"}>
          {start_date.format("MMM YYYY")} -{" "}
          {end_date
            ? end_date.format("MMM YYYY")
            : t("present", { ns: ["common"] })}
        </Typography>
        <Typography level="body3" textAlign={"right"}>
          {business?.address}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ExperienceItem;
