import { createCustomEqual } from "fast-equals";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import * as React from "react";
import { Businesses, Item, Listings } from "../../Services/Db/types";
import { GeoPoint, where } from "firebase/firestore";
import { geohashForLocation } from "geofire-common";
import { BusinessService, ListingsService } from "../../Services/Db/service";
import { Business } from "@mui/icons-material";

export function calculateColor(w: number) {
  const minColor = [255, 235, 87]; //[255, 221, 217];
  const maxColor = [215, 81, 67];
  return [
    minColor[0] * (1 - w) + maxColor[0] * w,
    minColor[1] * (1 - w) + maxColor[1] * w,
    minColor[2] * (1 - w) + maxColor[2] * w,
  ];
}

// CUSTOM DEEP EQUALS FOR useEffect Maps
const deepCompareEqualsForMaps = createCustomEqual(
  // @ts-expect-error
  (deepEqual) => (a: any, b: any) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof google.maps.LatLng
    ) {
      return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
    }

    // TODO extend to other types
    // @ts-expect-error use fast-equals for other objects
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

export function formatWages(wage: number) {
  return `$${Math.round(wage)}`;
}

export function loadFullBusiness(
  placeResult: google.maps.places.PlaceResult
): Businesses {
  // TODO note that this is a weird intersection of the google maps api and firebase, so it's here
  if (
    !placeResult.place_id ||
    !placeResult.formatted_address ||
    !placeResult.geometry?.location ||
    !placeResult.name
  ) {
    // FIXME maybe we should have a dialogue to add this info yourself
    throw new Error("Insufficient information to load business");
  }
  const latlong = new GeoPoint(
    placeResult.geometry.location.lat(),
    placeResult.geometry.location.lng()
  );
  return {
    id: placeResult.place_id,
    name: placeResult.name,
    latlong: latlong,
    geohash: geohashForLocation([latlong.latitude, latlong.longitude]),
    address: placeResult.formatted_address,
  };
}

export function getMaxValueKey(roleWageMap: { [key: string]: number }): number {
  return Math.max(...Object.values(roleWageMap));
}

function formatItem(item: Item) {
  return `${item.item}:${item.category}`;
}

export function isMarkerInConstraints(
  business: Businesses,
  wageRange?: number[],
  roles?: Item[],
  excludeRoles?: Item[],
  perks?: Item[],
  skills?: Item[],
  scheduleTypes?: Item[]
): boolean {
  // if have no listings shouldnt appear as marker on map
  if (
    business.listingWageMap === undefined ||
    Object.keys(business.listingWageMap).length === 0
  ) {
    return false;
  }

  /*
  if (wageRange) {
    const wageInRange = Object.values(business.listingWageMap).some((wage) => {
      return wage >= wageRange[0] && wage <= wageRange[1];
    });
    if (!wageInRange) return false;
  }

  if (roles && roles.length > 0) {
    const roleInRoles = Object.keys(business.listingWageMap).some((role) => {
      return roles.some(
        (r) => r.item === role || r.item.toLowerCase() === "all"
      );
    });
    if (!roleInRoles) return false;
  }

  if (excludeRoles && excludeRoles.length > 0) {
    const roleInExcludeRoles = Object.keys(business.listingWageMap).some(
      (role) => {
        return excludeRoles.some(
          (r) => r.item === role || r.item.toLowerCase() === "all"
        );
      }
    );
    if (roleInExcludeRoles) return false;
  }

  if (perks && perks.length > 0) {
    const perkInPerks = perks.some((perk) => {
      return business.allPerks?.includes(formatItem(perk));
    });
    if (!perkInPerks) return false;
  }

  if (skills && skills.length > 0) {
    const skillInSkills = skills.some((skill) => {
      return business.allSkills?.includes(formatItem(skill));
    });
    if (!skillInSkills) return false;
  }

  if (scheduleTypes && scheduleTypes.length > 0) {
    const scheduleTypeInScheduleTypes = scheduleTypes.some((scheduleType) => {
      return business.allScheduleTypes?.includes(formatItem(scheduleType));
    });

    if (!scheduleTypeInScheduleTypes) return false;
  }
  */

  return true;
}
