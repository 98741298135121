import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX } from "src/Utils/Constants";
import InlineBusinessSelectMap from "../Maps/InlineBusinessSelectMap";

interface ModalProps {
  open: boolean;
  setOpen: (b: boolean) => void;
  placeId?: string;
  setPlaceId: (p?: string) => void;
  onComplete: (p?: string) => void;
}

const AddLocationModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  placeId,
  setPlaceId,
  onComplete,
}) => {
  const { t } = useTranslation("business");

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          outline: "none",
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="cardHeader" sx={{ fontWeight: 600 }}>
            {t("add_location")}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Stack spacing={2} sx={{ mb: 2 }}>
          <InlineBusinessSelectMap placeId={placeId} setPlaceId={setPlaceId} />
          <Button
            disabled={!placeId}
            onClick={() => {
              setOpen(false);
              onComplete(placeId);
            }}
          >
            {t("add_location")}
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
};

export default AddLocationModal;
