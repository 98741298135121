import { InfoRounded } from "@mui/icons-material";
import { Alert, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const SelectLocationAlert = ({ alert }: { alert?: string }) => {
  const { t } = useTranslation("common");
  return (
    <Alert color="neutral" sx={{ mt: 2 }}>
      <Stack spacing={1}>
        <Typography startDecorator={<InfoRounded />} level="h4">
          {t("select_location")}
        </Typography>
        <Typography level="body1">
          {alert ?? t("select_location_interview")}
        </Typography>
      </Stack>
    </Alert>
  );
};

export default SelectLocationAlert;
