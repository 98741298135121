interface ScriptProps {
  src: string;
  callback: () => void;
  id: string;
}

const loadScript = ({ src, callback, id }: ScriptProps) => {
  const existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
};
export default loadScript;
