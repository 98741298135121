import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import EducationItem from "../Components/Common/EducationItem";

import { SectionProps } from "./sectionProps";

import { useTranslation } from "react-i18next";
import {
  Add,
  CheckCircleRounded,
  Edit,
  HighlightOffRounded,
  NewReleasesRounded,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";

const EducationSection: React.FC<SectionProps> = ({
  setModalOpen,
  setSelectedId,
  education,
  none,
}) => {
  const { t } = useTranslation(["create_profile"]);

  const Education = () => {
    //DISPLAY EXPERIENCES
    if (!!education) {
      return (
        <Box
          onClick={() => {
            setModalOpen(true);
            if (setSelectedId) setSelectedId(education.id!);
          }}
          sx={{ cursor: "pointer" }}
        >
          <EducationItem
            studied={education.studied}
            school_type={education.type}
            start_date={dayjs(education.startDate.toDate())}
            end_date={
              education.graduationDate
                ? dayjs(education.graduationDate.toDate())
                : null
            }
            credits={education.credits ? education.credits : undefined}
          />
        </Box>
      );
    }
    // //USER HAS SELECTED THEY HAVE NO EDUCATION -- not an option
    // else if (none) {
    //   return <Alert>{t("education_builder:no_education")}</Alert>;
    // }
    //ADD EDUCATION
    else {
      return (
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            if (setSelectedId) setSelectedId(null);
            setModalOpen(true);
          }}
        >
          {t("create_profile:add_education")}
        </Button>
      );
    }
  };

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={
            education ? (
              <CheckCircleRounded sx={{ color: "success.solidBg" }} />
            ) : (
              <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
            )
          }
          level="h4"
          sx={{ fontWeight: 600 }}
        >
          {t("education", { ns: ["create_profile"] })}
        </Typography>
        {!education && (
          <IconButton
            onClick={() => {
              setModalOpen(true);
              if (setSelectedId) setSelectedId(null);
            }}
          >
            <Add />
          </IconButton>
        )}
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />
      <Stack spacing={3} divider={<Divider />} sx={{ mt: 2 }}>
        <Education />
      </Stack>
    </Card>
  );
};

export default EducationSection;
