import { AspectRatio, Card, CardOverflow, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { DocumentReference } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AppBarContext from "src/Context/AppBarContext";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { HEADER_TYPES } from "src/Utils/Constants";
import LocationDropdown from "../Inputs/LocationDropdown";
import { usePlaceDetails } from "../Maps/PlaceDetails";

const BusinessPageHeader = ({
  placeId,
  setPlaceId,
}: {
  placeId: string;
  setPlaceId?: (p: string | null) => void;
}) => {
  const { business } = useBusinessAuth();
  const { t } = useTranslation(["common"]);
  const placeDetails = usePlaceDetails(placeId, ["name", "photo"]);
  const [img, setImg] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [locations, setLocations] = useState<DocumentReference[]>([]);

  const [loadingDetails, setLoadingDetails] = useState(true);
  const [loadingBusiness, setLoadingBusiness] = useState(true);
  const [isOwnedBusiness, setIsOwnedBusiness] = useState(false);

  const appBarContext = useContext(AppBarContext);

  useEffect(() => {
    if (isOwnedBusiness) {
      appBarContext?.setHeaderType(HEADER_TYPES.BUSINESS);
    } else {
      appBarContext?.setHeaderType(HEADER_TYPES.BASIC);
    }
  }, [isOwnedBusiness, appBarContext]);

  useEffect(() => {
    if (business && business.email) {
      FullBusinessService.getBusinessProfile(business.email)
        .then((profile) => {
          if (profile && profile.locations) {
            setLocations(profile.locations);
            if (profile.locations.some((l) => l.id === placeId)) {
              setIsOwnedBusiness(true);
            }
          }
        })
        .finally(() => setLoadingBusiness(false));
    } else {
      setIsOwnedBusiness(false);
      setLoadingBusiness(false);
    }
  }, [business, placeId]);

  useEffect(() => {
    if (placeDetails) {
      setImg(placeDetails.photos ? placeDetails.photos[0].getUrl() : "");
      setName(placeDetails.name!);
      setLoadingDetails(false);
    } else if (placeId === null) {
      setLoadingDetails(false);
      setImg("");
    }
  }, [placeId, placeDetails]);

  if (loadingBusiness || loadingDetails) {
    return (
      <Card>
        <CardOverflow>
          <AspectRatio ratio={3}>
            <Skeleton variant="rounded" />
          </AspectRatio>
        </CardOverflow>
        <Box sx={{ p: 2 }}>
          <Skeleton variant="text" />
        </Box>
      </Card>
    );
  }

  return (
    <Card>
      {(img !== "" || loadingDetails) && (
        <CardOverflow>
          <AspectRatio
            ratio={3}
            sx={{
              borderTopLeftRadius: "19px",
              borderTopRightRadius: "19px",
              mb: 1,
            }}
          >
            <img
              src={img}
              loading="lazy"
              alt=""
              id="overflow-img"
              width={"100%"}
            />
          </AspectRatio>
        </CardOverflow>
      )}
      <Box>
        {isOwnedBusiness ? (
          <LocationDropdown
            locations={locations}
            setSelected={(s) => {
              if (setPlaceId) setPlaceId(s);
            }}
            defaultVal={t("common:select_location") ?? "Select a location"}
            selected={placeId}
          />
        ) : (
          <Typography
            sx={{ mt: 1, px: 1, wordWrap: "break-word", lineHeight: "100%" }}
            level="sectionHeader"
          >
            {name}
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default BusinessPageHeader;
