import * as React from "react";
import { Box } from "@mui/joy";
import { useDeepCompareEffectForMaps } from "./Utils";
import { DEFAULT_MAP_PARAMS } from "../../Utils/Constants";
import { MapContext } from "../../Context/MapContext";
import useBackoff from "src/Utils/useBackoff";

interface MapEventHandler {
  event_name: string;
  callback: (map: google.maps.Map, ...args: any) => void;
}

interface MapProps extends google.maps.MapOptions {
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode | React.ReactNode[];
  events?: MapEventHandler[];
  inline?: boolean;
  bounds?: google.maps.LatLngBounds;
}

const Map: React.FC<MapProps> = ({
  onIdle,
  inline,
  children,
  events,
  bounds,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  const [loadFailed, setLoadFailed] = React.useState(false);
  /*
  useBackoff(
    () => {
      console.log(map?.getRenderingType());
      return Boolean(map && map.getRenderingType() === "UNINITIALIZED");
    },
    () => {
      ref.current = null;
      setMap(undefined);
    },
    (n: number) => {
      return n * 2;
    },
    () => {
      setLoadFailed(true);
    },
    3,
    4
  );
  */

  const mapContext = React.useContext(MapContext);

  React.useEffect(() => {
    if (ref.current && !map) {
      const map: google.maps.Map = new window.google.maps.Map(ref.current, {
        mapId: process.env.REACT_APP_GOOGLE_MAPS_ID,
      });
      setMap(map);
    }
  }, [ref, map]);

  React.useEffect(() => {
    if (options.zoom && options.center && map) {
      map.setZoom(options.zoom);
      map.setCenter(options.center);
    }
  }, [options.zoom, options.center]);

  useDeepCompareEffectForMaps(() => {
    if (map && events) {
      events.forEach((e) => {
        google.maps.event.clearListeners(map, e.event_name);
        map.addListener(e.event_name, () => e.callback(map));
      });
    }
    if (map) {
      // default map positioning
      if (!options.center) {
        options.center = new google.maps.LatLng(
          DEFAULT_MAP_PARAMS.lat,
          DEFAULT_MAP_PARAMS.lng
        );
      }
      if (!options.zoom) {
        options.zoom = DEFAULT_MAP_PARAMS.zoom;
      }
      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions({ ...options, gestureHandling: "greedy" });
    }
  }, [map, options]);

  React.useEffect(() => {
    if (bounds) {
      map?.fitBounds(bounds);
    }
  }, [bounds]);

  // const [height, setHeight] = React.useState(0);

  // const headerHeight = React.useCallback(() => {
  //   const header = document.getElementById("search-app-bar");
  //   return header ? header.clientHeight : 0;
  // }, []);

  // React.useEffect(() => {
  //   const handleResize = () => {
  //     setHeight(window.innerHeight - headerHeight());
  //   };

  //   window.addEventListener("resize", handleResize);
  // }, []);

  // RENDER:
  return (
    <Box
      ref={ref}
      sx={{
        // height: `${height}px`,
        height: inline ? "100%" : "inherit",
        width: "100%",
        paddingTop: 0,
        marginTop: 0,
        overflow: "hidden",
      }}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, { map });
        }
      })}
    </Box>
  );
};

export default Map;
