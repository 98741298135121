import {
  Stack,
  Typography,
  Box,
  Divider,
  Modal,
  Card,
  Input,
  IconButton,
  Button,
  Checkbox,
} from "@mui/joy";
import React, { useEffect, useState, useContext } from "react";
import SelectGroupSingle from "../Inputs/SelectGroupSingle";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import { Close, CloseRounded } from "@mui/icons-material";

import useKeyToDict from "../../Utils/useKeyToDict";
import useConfirm from "../../Utils/useConfirm";
import { ReferencesService } from "../../Services/Db/service";
import { References } from "../../Services/Db/types";
import { FullUserService } from "../../Services/Db/user";
import { useAuth } from "../../Context/AuthContext";

interface ReferenceBuilderProps {
  referenceID: string | null; //if there is an reference ID (or something?), we are editing this reference
  open: boolean;
  setOpen: (type: boolean) => void;
}

const ReferenceBuilder: React.FC<ReferenceBuilderProps> = ({
  referenceID,
  open,
  setOpen,
}) => {
  const { t, i18n } = useTranslation(["common", "reference_builder", "lists"]);
  const { keyToObjArray: keyToDict } = useKeyToDict("lists");
  const confirm = useConfirm();

  //This should be used for editing too... load in from firebase and prepopulate the below states:
  const [referenceName, setReferenceName] = useState<string>("");
  const [referenceType, setReferenceType] = useState<string>("");
  const [referencePhone, setReferencePhone] = useState<string | null>(null);
  const [referenceEmail, setReferenceEmail] = useState<string | null>(null);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const { user } = useAuth();

  const handleDeleteConfirmed = () => {
    if (referenceID) {
      FullUserService.deleteUserReference(user?.phoneNumber!, referenceID);
    }
    setOpen(false);
  };

  const handleClose = () => {
    if (referenceName || referenceType || referencePhone || referenceEmail) {
      //if there's progress
      confirm(
        "reference_builder",
        "scrub_reference_message",
        "scrub_reference_subtitle",
        "scrub",
        () => {
          setOpen(false);
        }
      );
    } else {
      setOpen(false);
    }
  };

  const handleSave = () => {
    FullUserService.createUserReference(
      {
        name: referenceName,
        relationship: referenceType,
        phone: referencePhone,
        email: referenceEmail,
      },
      user?.phoneNumber!,
      referenceID || undefined
    );
    setOpen(false);
  };

  useEffect(() => {
    if (referenceID) {
      //load in from firebase
      const loadReference = async () => {
        const loadedReference: References | null =
          await ReferencesService.getOne(referenceID);
        if (loadedReference) {
          setReferenceName(loadedReference.name);
          setReferenceType(loadedReference.relationship);
          setReferencePhone(loadedReference.phone);
          setReferenceEmail(loadedReference.email);
        }
      };
      loadReference();
    } else {
      //reset all variables to null if not editing
      setReferenceName("");
      setReferenceType("");
      setReferencePhone(null);
      setReferenceEmail(null);
    }
  }, [open]);

  useEffect(() => {
    if (referenceName && referenceType && (referencePhone || referenceEmail)) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [referenceName, referenceType, referencePhone, referenceEmail]);

  const reference_options_dict = keyToDict("reference_types");

  return (
    <React.Fragment>
      <Modal
        open={open}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={handleClose}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ fontWeight: 600 }}>
              {t("header", { ns: ["reference_builder"] })}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              overflow: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              pb: 10,
            }}
          >
            <Stack spacing={4} divider={<Divider />} sx={{ mt: 2 }}>
              <SelectGroupSingle
                selected={referenceType}
                setSelected={setReferenceType}
                title={t("relationship", { ns: ["reference_builder"] })}
                items={reference_options_dict}
              />

              <Stack>
                <Typography level="selectLabel">
                  {t("name", { ns: ["reference_builder"] })}
                </Typography>
                <Input
                  value={referenceName}
                  onChange={(e) => setReferenceName(e.target.value)}
                />
              </Stack>

              <Stack>
                <Typography level="selectLabel">
                  {t("phone", { ns: ["reference_builder"] })}
                </Typography>
                <Input
                  value={referencePhone || ""}
                  onChange={(e) => setReferencePhone(e.target.value)}
                />
              </Stack>

              <Stack>
                <Typography level="selectLabel">
                  {t("email", { ns: ["reference_builder"] })}
                </Typography>
                <Input
                  value={referenceEmail || ""}
                  onChange={(e) => setReferenceEmail(e.target.value)}
                />
              </Stack>

              <Stack spacing={2}>
                <Button onClick={handleSave} disabled={!canSubmit}>
                  {t("save", {
                    ns: ["common"],
                  })}
                </Button>
                {referenceID && (
                  <Button
                    variant="plain"
                    onClick={() => {
                      confirm(
                        "reference_builder",
                        "delete_reference_message",
                        "delete_reference_subtitle",
                        "delete",
                        handleDeleteConfirmed,
                        [referenceName]
                      );
                    }}
                  >
                    {t("delete", {
                      ns: ["common"],
                    })}
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default ReferenceBuilder;
