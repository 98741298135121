import {
  useTheme,
  Typography,
  Stack,
  Box,
  Divider,
  Button,
  LinearProgress,
  Card,
  Alert,
} from "@mui/joy";
import ScheduleSelector from "react-schedule-selector";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import React, { useEffect, useState, useContext, Fragment } from "react";
import {
  Interviews,
  InterviewAvailabilities,
  Applications,
  Item,
  InterviewLocation,
  InterviewPrefs,
} from "../../Services/Db/types";

import SelectInterviewAvailabilityDetails from "../../Components/Inputs/SelectInterviewAvailabilityDetails";
import WeekToggle from "../../Components/Inputs/WeekToggle";
import RecurringSwitch from "../../Components/Inputs/RecurringSwitch";
import CollisionsPopup from "./CollisionsPopup";

import useMediaQuery from "@mui/material/useMediaQuery";
import {
  UpdateRounded,
  CheckCircleRounded,
  CancelRounded,
  EventRounded,
  InfoRounded,
  ErrorRounded,
} from "@mui/icons-material";
import { useBusinessAuth } from "../../Context/AuthContext";
import { FullBusinessService } from "../../Services/Db/business";
import { Timestamp } from "firebase/firestore";
import { Skeleton } from "@mui/material";
import SelectLocationAlert from "../../Components/Business/SelectLocationAlert";
import useConfirm from "src/Utils/useConfirm";
import InterviewContext from "./InterviewContext";
import useInterviewLogicFirebase from "./useInterviewLogicFirebase";
import useInterviewLogicTime from "./useInterviewLogicTime";

require("dayjs/locale/es");

const InterviewAvailability = () => {
  const { t, i18n } = useTranslation(["interview", "create_profile"]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const context = useContext(InterviewContext);
  const confirm = useConfirm();
  const {
    loadAvailability,
    restoreDefaultPrefs,
    handleSavePrefs,
    setInterviewPrefs,
    clearAllAvailability,
    handleSaveAvailability,
  } = useInterviewLogicFirebase();
  const {
    getRecurringAvailabilityForThisWeek,
    getCalculatedAvailability,
    handleChange,
    confirmChanges,
  } = useInterviewLogicTime();

  //set can save prefs if prefs are loaded
  useEffect(() => {
    if (!context?.loadingPrefs) {
      context?.setCanSavePrefs(true);
    }
  }, [
    context?.interviewDuration,
    context?.reminderMsg,
    context?.loadingPrefs,
    context?.placeId,
    context?.startTime,
    context?.endTime,
  ]);

  // //set interview prefs on load
  // useEffect(() => {

  // }, [context?.placeId]);

  useEffect(() => {
    loadAvailability();
    setInterviewPrefs();
  }, [context?.placeId]);

  const [
    recurringAvailabilityForThisWeek,
    setRecurringAvailabilityForThisWeek,
  ] = useState<Date[]>([]);

  useEffect(() => {
    const thisWeek = getRecurringAvailabilityForThisWeek();
    setRecurringAvailabilityForThisWeek(thisWeek);
    console.log(thisWeek);
  }, [
    context?.removeRecurringAvailability,
    context?.recurringAvailability,
    context?.dayOffset,
  ]);

  const slotColor = (x: Date, selected: boolean) => {
    if (selected) {
      if (context!.holding.some((val) => val.getTime() === x.getTime())) {
        if (context!.recurring) {
          if (context!.changeType === "adding") {
            return "primary.solidHoverBg";
          } else {
            return "#AAA";
          }
        } else {
          if (context!.changeType === "adding") {
            return "#C0463FAA";
          } else {
            return "#AAA";
          }
        }
      }

      if (
        recurringAvailabilityForThisWeek.some(
          (val) => val.getTime() === x.getTime()
        )
      ) {
        return "primary.solidHoverBg";
      }

      if (
        context!.oneTimeAvailability.some(
          (val) => val.getTime() === x.getTime()
        )
      ) {
        return "#C0463FAA";
      }

      if (context!.recurring) {
        return "primary.solidHoverBg";
      } else {
        return "#C0463FAA";
      }
    } else {
      if (
        recurringAvailabilityForThisWeek.some(
          (val) => val.getTime() === x.getTime()
        )
      ) {
        return "primary.solidHoverBg";
      }

      if (
        context!.oneTimeAvailability.some(
          (val) => val.getTime() === x.getTime()
        )
      ) {
        return "#C0463FAA";
      }

      return "#DDD";
    }
  };

  const recurringSymbol = (x: Date) => {
    if (context?.holding.some((val) => val.getTime() === x.getTime())) {
      if (context!.recurring) {
        return <UpdateRounded fontSize="inherit" />;
      }
    }

    if (
      recurringAvailabilityForThisWeek.some(
        (val) => val.getTime() === x.getTime()
      )
    ) {
      return <UpdateRounded fontSize="inherit" />;
    }
  };

  const interviewSymbol = (x: Date) => {
    if (
      context!.upcomingInterviews.some(
        (interview) =>
          interview.interview?.scheduledAt.toDate().getTime() === x.getTime()
      )
    ) {
      return <EventRounded fontSize="inherit" />;
    }
  };

  return (
    <Box id={"interview_availability_box"}>
      {context?.prefDiscrepancy ? (
        <Alert color="warning" sx={{ my: 2 }} startDecorator={<ErrorRounded />}>
          <Stack spacing={1}>
            {t("interview:pref_discrepancy")}
            <Button
              fullWidth
              size="sm"
              variant="soft"
              sx={{ mt: 1 }}
              onClick={() =>
                confirm(
                  "interview",
                  "restore_default",
                  "restore_default_prefs_desc",
                  "confirm",
                  restoreDefaultPrefs
                )
              }
            >
              {t("interview:restore_default")}
            </Button>
          </Stack>
        </Alert>
      ) : (
        <Fragment>
          <SelectInterviewAvailabilityDetails />
          <Button
            fullWidth
            startDecorator={<CheckCircleRounded />}
            size="sm"
            onClick={handleSavePrefs}
            disabled={!context?.canSavePrefs}
            sx={{ mt: 2 }}
          >
            {t("interview:save_prefs")}
          </Button>
        </Fragment>
      )}

      <Divider sx={{ my: 2 }} />

      <Stack>
        {context?.loadingAvailability ? (
          context!.availabilityDiscrepancy ? (
            <Alert
              color="warning"
              sx={{ my: 2 }}
              startDecorator={<ErrorRounded />}
            >
              <Stack spacing={1}>
                {t("interview:avail_discrepancy")}
                <Button
                  fullWidth
                  size="sm"
                  variant="soft"
                  sx={{ mt: 1 }}
                  onClick={() =>
                    confirm(
                      "interview",
                      "clear_all",
                      "clear_all_avails_desc",
                      "confirm",
                      clearAllAvailability
                    )
                  }
                >
                  {t("interview:clear_all")}
                </Button>
              </Stack>
            </Alert>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: 2,
                gap: 1,
              }}
            >
              <Button
                fullWidth
                startDecorator={<CancelRounded />}
                variant="outlined"
                size="sm"
                disabled={!context!.changed}
                onClick={() => {
                  loadAvailability();
                }}
              >
                {t("common:cancel")}
              </Button>
              <Button
                fullWidth
                startDecorator={<CheckCircleRounded />}
                size="sm"
                onClick={confirmChanges}
                disabled={!context!.changed}
              >
                {t("common:apply")}
              </Button>
            </Box>
          )
        ) : (
          <Box display="flex" justifyContent={"space-between"}>
            <Skeleton variant="rounded" width={"50%"} />
            <Skeleton variant="rounded" width={"50%"} />
          </Box>
        )}

        <WeekToggle
          daysShown={mobile ? 3 : 7}
          dayOffset={context?.dayOffset}
          setDayOffset={context?.setDayOffset}
        />
      </Stack>

      <ScheduleSelector
        selection={[
          ...getCalculatedAvailability(),
          ...(context?.holding ?? []),
        ]} //what we've confirmed plus what we're working with right now
        onChange={(newAvailability: Date[]) => {
          handleChange(newAvailability);
        }}
        startDate={dayjs()
          .add(context?.dayOffset || 0, "day")
          .toDate()}
        numDays={mobile ? 3 : 7}
        minTime={context?.startTime || 8}
        maxTime={context?.endTime || 20}
        hourlyChunks={60 / (context?.interviewDuration || 30)}
        selectionScheme={"square"} // "linear" to select between consecutive dates
        renderDateLabel={(date: any) => {
          return (
            <Stack>
              <Typography textAlign={"left"} sx={{ color: "text.tertiary" }}>
                {dayjs(date).locale(i18n.language).format("M/D")}
              </Typography>
              <Typography textAlign={"left"}>
                {dayjs(date).locale(i18n.language).format("ddd")}
              </Typography>
            </Stack>
          );
        }}
        renderTimeLabel={(time: any) => {
          return (
            <Typography textAlign={"right"}>
              {dayjs(time).locale(i18n.language).format("h:mma")}
            </Typography>
          );
        }}
        renderDateCell={(
          datetime: Date,
          selected: boolean,
          refSetter: (dateCell: HTMLElement | null) => void
        ) => {
          return (
            <React.Fragment>
              <Box
                ref={refSetter}
                id={"#" + datetime.toString()}
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  backgroundColor: slotColor(datetime, selected),
                  "&:hover": {
                    backgroundColor:
                      selected && context!.recurring
                        ? "primary.solidHoverBg"
                        : "#C0463FAA",
                  },
                  color: "common.white",
                  fontSize: "0.8rem",
                  p: 0.25,
                }}
              >
                {recurringSymbol(datetime)}
                {interviewSymbol(datetime)}
              </Box>
            </React.Fragment>
          );
        }}
      />

      <RecurringSwitch />

      <CollisionsPopup />
    </Box>
  );
};

export default InterviewAvailability;
