import { InfoRounded } from "@mui/icons-material";
import { Box, Typography, Stack } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { BusinessAccService } from "src/Services/Db/service";
import ConfirmInput from "../Inputs/ConfirmInput";

const EditGroupCode = () => {
  const { t } = useTranslation("business");
  const { business } = useBusinessAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [groupCode, setGroupCode] = useState<string>();
  const [display, setDisplay] = useState<boolean>(true);
  const [allCodes, setAllCodes] = useState<string[]>([]);
  const [invalidMsg, setInvalidMsg] = useState<string>("business_code_invalid");

  useEffect(() => {
    if (business?.email) {
      FullBusinessService.getBusinessProfile(business.email).then((res) => {
        if (res && res.locations) {
          if (res.locations.length > 1) {
            setGroupCode(res.groupCode);
            setLoading(false);
          } else {
            setDisplay(false);
          }
        }
      });
    }
  }, [business]);

  useEffect(() => {
    BusinessAccService.getMany().then((res) => {
      if (res) {
        setAllCodes(
          res.map((b) => b.groupCode).filter((c) => c !== undefined) as string[]
        );
      }
    });
  }, []);

  function handleConfirm(code: string) {
    if (business?.email && code) {
      FullBusinessService.updateGroupCode(business.email, code);
    }
  }

  function validateCode(e: string): boolean {
    if (allCodes) {
      if (allCodes.includes(e)) {
        return false;
      }
      return true;
    } else {
      setInvalidMsg("failed_loading_codes");
      return false;
    }
  }

  if (!display) {
    return <></>;
  }
  if (loading) {
    return <Skeleton variant="text" width={200} />;
  } else {
    return (
      <Stack spacing={1}>
        <ConfirmInput
          content={groupCode ?? "e.g. HOURLEAD"}
          setContent={setGroupCode}
          onComplete={handleConfirm}
          validate={validateCode}
          invalidMsg={t(invalidMsg)!}
        />
        <Typography startDecorator={<InfoRounded />} level="body2">
          {t("group_code_desc")} hourlead.com/group/{groupCode || "HOURLEAD"}
        </Typography>
      </Stack>
    );
  }
};

export default EditGroupCode;
