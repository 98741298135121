import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Typography,
  Box,
  Stack,
  LinearProgress,
  Chip,
} from "@mui/joy";
import WageChip from "../Common/WageChip";
import { APP_ROUTES } from "../../Utils/Constants";

import { Businesses, Listings } from "../../Services/Db/types";
import useKeyToDict from "../../Utils/useKeyToDict";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface RoleHeaderProps {
  listing: Listings | null;
  business: Businesses | null | undefined;
  status?: "submitted" | "hired" | "rejected" | "interviewing" | "withdrawn";
}

const RoleHeader: React.FC<RoleHeaderProps> = ({
  listing,
  business,
  status,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const { getRoleNameFromRole } = useKeyToDict("lists");

  return (
    <React.Fragment>
      {listing ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 1,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(
                generatePath(APP_ROUTES.ROLE, {
                  listingId: listing.id ?? null,
                })
              );
            }}
          >
            <WageChip wage={listing.wage} tips={listing.tips} />
            <Stack sx={{ pl: 2 }}>
              <Typography level="h6" sx={{ fontWeight: "bold" }}>
                {getRoleNameFromRole({
                  category: listing.category,
                  item: listing.role,
                })}
              </Typography>
              {business && (
                <Typography level="body1" sx={{ mt: -0.5, pt: 0 }}>
                  {business.name}
                </Typography>
              )}
            </Stack>
          </Box>
          {(status === "hired" || status === "rejected") && (
            <Chip
              variant="soft"
              color={status === "hired" ? "success" : "warning"}
              sx={{ py: 1, backgroundColor: "success" }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Typography color={status === "hired" ? "success" : "warning"}>
                  {t(status)}
                </Typography>
              </Box>
            </Chip>
          )}
        </Box>
      ) : (
        <Skeleton variant="rectangular" />
      )}
      {!(status === "rejected" || status === "hired") && (
        <Divider sx={{ mx: -2, opacity: 1, mt: 1, mb: 2 }} />
      )}
    </React.Fragment>
  );
};

export default RoleHeader;
