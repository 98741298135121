import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Typography,
  Box,
  Stack,
  LinearProgress,
  Chip,
  Button,
  Divider,
  Modal,
  IconButton,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import useToast from "../../Utils/useToast";
import {
  Applications,
  Businesses,
  Interviews,
  Listings,
} from "../../Services/Db/types";
import {
  ApplicationsService,
  BusinessService,
  InterviewPrefsService,
  InterviewsService,
  ListingsService,
} from "../../Services/Db/service";
import { useAuth } from "../../Context/AuthContext";
import { DocumentReference } from "firebase/firestore";
import dayjs from "dayjs";
import useConfirm from "../../Utils/useConfirm";
import RoleHeader from "./RoleHeader";
import {
  AlarmRounded,
  CloseRounded,
  ContentPaste,
  ContentPasteRounded,
  LocationOnRounded,
  PersonRounded,
  VideocamRounded,
} from "@mui/icons-material";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  SelectInterviewSlot,
  SelectInterviewSlotModal,
} from "../Inputs/SelectInterviewSlot";
import { minHeight } from "@mui/system";

interface RoleAppliedProps {
  listingId: string;
  application?: Applications;

  update?: boolean;
  setUpdate?: (u: boolean) => void;
  displayingFull?: boolean;
}

function getStatusKeyFromStatus(status: string) {
  switch (status) {
    case "submitted":
      return "pending";
    case "accepted":
      return "accepted";
    case "hired":
      return "hired";
    case "rejected":
      return "rejected";
    case "interviewing":
      return "interviewing";
    case "withdrawn":
      return "withdrawn";
    default:
      return "pending";
  }
}

function getStatusColorFromStatus(status: string) {
  switch (status) {
    case "submitted":
      return "info";
    case "accepted":
      return "success";
    case "rejected":
      return "warning";
    case "interviewing":
      return "warning";
    case "withdrawn":
      return "warning";
    case "hired":
      return "success";
    default:
      return "info";
  }
}

const RoleApplied: React.FC<RoleAppliedProps> = ({
  listingId,
  application,
  setUpdate,
  update,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "interview"]);
  const toast = useToast();
  const [listing, setListing] = React.useState<Listings | null>(null);
  const loadedListing = React.useRef(false);
  const [business, setBusiness] = React.useState<Businesses | null>();
  const [interview, setInterview] = React.useState<Interviews | null>();
  const [address, setAddress] = React.useState<string>();
  const [videoLink, setVideoLink] = React.useState<string>();
  const loadedBusiness = React.useRef(false);
  const [showScheduleInterviewModal, setShowScheduleInterviewModal] =
    React.useState(false);
  const { user } = useAuth();
  const confirm = useConfirm();

  React.useEffect(() => {
    if (!loadedListing.current) {
      const loadListing = async (id: string) => {
        await ListingsService.getOne(id)
          .then((listing) => {
            setListing(listing);
            loadedListing.current = true;
          })
          .catch((e) => {
            console.log("didnt fine listing");
          });
      };

      loadListing(listingId);
    }
  }, [listingId]);

  React.useEffect(() => {
    if (application && application.interviewRef) {
      const loadInterview = async (interviewRef: DocumentReference) => {
        await InterviewsService.getResolvedReference(interviewRef).then(
          (interview) => {
            if (interview?.mode === "in-person") {
              BusinessService.getResolvedReference(interview.businessRef).then(
                (business) => {
                  if (business) {
                    setAddress(business.address);
                  }
                }
              );
            } else if (interview?.mode === "video") {
              BusinessService.getResolvedReference(interview.businessRef).then(
                (business) => {
                  if (business && business.interviewPrefs)
                    InterviewPrefsService.getResolvedReference(
                      business?.interviewPrefs
                    ).then((interviewPrefs) => {
                      if (interviewPrefs && interviewPrefs.video) {
                        setVideoLink(interviewPrefs.video);
                      }
                    });
                }
              );
            }
            setInterview(interview);
          }
        );
      };
      loadInterview(application.interviewRef);
    }
  }, [application]);

  React.useEffect(() => {
    if (listing && !loadedBusiness.current) {
      const getBusinessInfo = async (businessRef: DocumentReference) => {
        await BusinessService.getResolvedReference(businessRef).then(
          (business) => {
            if (business) {
              setBusiness(business);
              loadedBusiness.current = true;
            }
          }
        );
      };
      getBusinessInfo(listing.businessRef!);
    }
  }, [listing]);

  const handleRemoveApplication = () => {
    if (application) {
      ApplicationsService.update(application.id!, { status: "withdrawn" });
      if (update !== undefined && setUpdate) setUpdate(!update);
      toast(
        "business",
        "toast_remove_role",
        "undo",
        () => {
          ApplicationsService.update(application.id!, { status: "submitted" });
          if (update !== undefined && setUpdate) setUpdate(!update);
        },
        [application.name]
      );
    }
  };

  if (listing)
    return (
      <React.Fragment key={listingId}>
        <Card>
          <RoleHeader
            listing={listing}
            business={business}
            status={application?.status}
          />
          <Stack spacing={2}>
            {application &&
              application.status !== "rejected" &&
              application.status !== "hired" && (
                <React.Fragment>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                    {application.status === "interviewing" &&
                      application.interviewRef &&
                      interview && (
                        <Chip
                          variant="outlined"
                          color="neutral"
                          startDecorator={<PersonRounded />}
                        >
                          {t(`interview:${interview.mode}`)}
                        </Chip>
                      )}
                    {application.status === "interviewing" &&
                      application.interviewRef &&
                      interview && (
                        <Chip
                          variant="outlined"
                          color="neutral"
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                          }}
                        >
                          {dayjs
                            .unix(interview.scheduledAt.seconds)
                            .format("h:mm A, ddd MMM D")}
                        </Chip>
                      )}
                    {interview && interview.mode === "in-person" && (
                      <Chip
                        variant="outlined"
                        color="neutral"
                        startDecorator={<LocationOnRounded />}
                        sx={{ maxWidth: "100%" }}
                      >
                        {address}
                      </Chip>
                    )}
                    {interview && interview.mode === "video" && (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          startDecorator={<ContentPasteRounded />}
                          size="sm"
                          variant="plain"
                          sx={{ justifyContent: "flex-start" }}
                          onClick={() => {
                            navigator.clipboard.writeText(videoLink!);
                            toast("interview", "toast_copied", "", () => {}, [
                              videoLink!,
                            ]);
                          }}
                          fullWidth
                        >
                          <Typography level="body2">
                            {t("interview:copy_link")}
                          </Typography>
                        </Button>
                      </Box>
                    )}
                    {interview && interview.mode === "phone" && (
                      <Typography level="body2">
                        {t("interview:phone_location")}
                      </Typography>
                    )}

                    <Chip
                      variant="outlined"
                      onClick={handleRemoveApplication}
                      sx={{ flexGrow: 1, textAlign: "center" }}
                    >
                      {t("interview:withdraw")}
                    </Chip>
                    {application.status === "interviewing" &&
                      !application.interviewRef && (
                        <Chip
                          variant="solid"
                          sx={{
                            flexGrow: 4,

                            textAlign: "center",
                          }}
                          onClick={() => setShowScheduleInterviewModal(true)}
                        >
                          {t("interview:schedule_interview")}
                        </Chip>
                      )}
                    {application.status === "submitted" && (
                      <Chip
                        variant="outlined"
                        color="neutral"
                        startDecorator={<AlarmRounded />}
                        sx={{
                          backgroundColor: "success",
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        {t("applied", { ns: ["business"] })}{" "}
                        {dayjs
                          .unix(application.submitted.seconds)
                          .format("MMM D, YYYY")}
                      </Chip>
                    )}
                  </Box>
                </React.Fragment>
              )}
          </Stack>
        </Card>
        <SelectInterviewSlotModal
          show={showScheduleInterviewModal}
          setShow={setShowScheduleInterviewModal}
          placeId={listing.businessRef?.id!}
          role={{ item: listing.role, category: listing.category }}
          applicationId={application?.id!}
          uid={user?.phoneNumber!}
          update={update}
          setUpdate={setUpdate}
        />
      </React.Fragment>
    );
  else return null;
};

export default RoleApplied;
