import React from "react";
import { useContext, useEffect } from "react";
import { Stack } from "@mui/material";
import Select, { selectClasses } from "@mui/joy/Select";
import { Option, Card } from "@mui/joy";
import {
  KeyboardArrowDown,
  WarningRounded,
  Close,
  CloseRounded,
  PeopleAltRounded,
} from "@mui/icons-material";
import Candidate from "../Components/Common/Candidate";
import TabsPage from "../Components/Inputs/TabsPage";
import AppBarContext from "../Context/AppBarContext";
import { APP_ROUTES, HEADER_TYPES } from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { Button, Alert, IconButton, Typography, Box } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { Applications, Item } from "../Services/Db/types";
import dayjs from "dayjs";
import { useBusinessAuth } from "../Context/AuthContext";
import { FullBusinessService } from "../Services/Db/business";
import useKeyToDict from "../Utils/useKeyToDict";
import { useNavigate } from "react-router-dom";

const statuses = ["submitted", "interviewing", "hired", "rejected"]; // from Applications, only statuses we actually care

const Candidates = () => {
  const context = useContext(AppBarContext);
  const { business } = useBusinessAuth();

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const { t } = useTranslation(["business", "common"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const [showAlert, setShowAlert] = React.useState(false);

  const [activeRole, setActiveRole] = React.useState<string | null>(null);
  const [roles, setRoles] = React.useState<Item[]>([]);
  const [sortIndex, setSortIndex] = React.useState(0);
  const [candidates, setCandidates] = React.useState<
    (Applications & { role: Item; requirements: string[] })[]
  >([]);
  const [candidatesLoaded, setCandidatesLoaded] = React.useState(false);

  const updateIndex = (i: number) => {
    setSortIndex(i);
  };

  const sortOptions = statuses.map((status) => ({
    title: t(`common:${status === "submitted" ? "applied" : status}`),
    value: candidates.filter(
      (candidate) =>
        candidate.status === status &&
        (activeRole === null || candidate.role.item === activeRole)
    ).length,
  }));

  React.useEffect(() => {
    if (!candidatesLoaded && business) {
      const loadCandidates = async (bid: string) => {
        const loadedCandidates =
          await FullBusinessService.getFullBusinessApplications(bid);
        setCandidates(loadedCandidates);
        setCandidatesLoaded(true);
        setRoles(
          loadedCandidates
            .map((candidate) => candidate.role)
            // remove duplicates of roles
            .filter(
              (role, index, self) =>
                index === self.findIndex((r) => r.item === role.item)
            )
        );
        console.log("loaded candidates", loadedCandidates);
      };
      loadCandidates(business.email!);
    }
  }, [candidatesLoaded, business]);

  const candidatesForThisHeader = candidates.filter(
    (candidate) =>
      (activeRole === null || candidate.role.item === activeRole) &&
      candidate.status === statuses[sortIndex]
  );

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <PageSpacer>
        <Stack spacing={1}>
          <Stack direction="row" sx={{ justifyContent: "flex-start" }}>
            <Select
              indicator={<KeyboardArrowDown />}
              value={activeRole}
              onChange={(e, newValue) =>
                setActiveRole(newValue?.toString() || null)
              }
              sx={{
                [`& .${selectClasses.indicator}`]: {
                  transition: "0.2s",
                  [`&.${selectClasses.expanded}`]: {
                    transform: "rotate(-180deg)",
                  },
                },
                typography: "h2",

                border: 0,
                backgroundColor: "transparent",
              }}
            >
              <Option value={null} key={"all_roles"}>
                {t("common:all_candidates")}
              </Option>
              {roles.map((option) => (
                <Option value={option.item} key={option.item}>
                  {getRoleNameFromRole(option)}
                </Option>
              ))}
            </Select>
          </Stack>

          <TabsPage
            index={sortIndex}
            updateIndex={updateIndex}
            options={sortOptions}
          />

          {sortIndex === 1 && (
            <Button
              startDecorator={<PeopleAltRounded />}
              variant="soft"
              onClick={() => navigate(APP_ROUTES.BUSINESS_INTERVIEWS)}
            >
              {t("interview:all_interviews")}
            </Button>
          )}

          <Stack spacing={3}>
            {candidatesForThisHeader.length === 0 ? (
              <Card>
                <Typography level="cardHeader">
                  {t("business:no_candidates_in_this_category", {
                    category: t(`common:${statuses[sortIndex]}`),
                  })}
                </Typography>
              </Card>
            ) : (
              candidatesForThisHeader.map((candidate) => (
                <Candidate
                  key={candidate.id}
                  candidate={candidate}
                  phone={candidate.userRef.id}
                  setLoaded={setCandidatesLoaded}
                />
              ))
            )}
          </Stack>
        </Stack>
      </PageSpacer>

      <Alert
        sx={{
          display: showAlert ? "flex" : "none",
          position: "absolute",
          bottom: 10,
          left: 10,
        }}
        startDecorator={<WarningRounded sx={{ mx: 0.5 }} />}
        variant="soft"
        endDecorator={
          <React.Fragment>
            <Button variant="plain" color="primary" sx={{ ml: 4, mr: 1 }}>
              {t("undo", { ns: "common" })}
            </Button>
            <IconButton variant="plain" size="sm" color="primary">
              <CloseRounded />
            </IconButton>
          </React.Fragment>
        }
      >
        <Typography color="warning" fontWeight="md">
          {t("candidate_declined", { ns: "business" })}
        </Typography>
      </Alert>
    </React.Fragment>
  );
};

export default Candidates;
