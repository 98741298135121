import { APP_ROUTES, DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  CheckRounded,
  InfoRounded,
  LooksOneOutlined,
  LooksOneRounded,
  LooksOneSharp,
  LooksTwoRounded,
  OneK,
  OneKkOutlined,
  CheckCircle,
} from "@mui/icons-material";
import { Alert, Box, Button, Divider, Stack, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Step, StepContent, StepLabel, Stepper } from "@mui/material";

type SetupGuideProps = {
  isInterviewCompleted: boolean;
  isPostingCompleted: boolean;
};

const SetupGuide = ({
  isInterviewCompleted,
  isPostingCompleted,
}: SetupGuideProps) => {
  const { t } = useTranslation(["business"]);
  const navigate = useNavigate();

  return (
    <Alert color="warning">
      <Stack>
        <Typography level="cardHeader">
          {t("business:complete_setup")}
        </Typography>
        <Stepper orientation="vertical">
          <Step active completed={isPostingCompleted}>
            <StepLabel>{t("business:setup_roles")}</StepLabel>
            <StepContent>
              <Typography level="body1">
                {t("business:setup_roles_desc")}
              </Typography>
              <Button
                color="warning"
                onClick={() => navigate(APP_ROUTES.ROLES)}
                fullWidth
                sx={{ mt: 2 }}
              >
                {t("business:go_to_roles")}
              </Button>
            </StepContent>
          </Step>
          <Step active completed={isInterviewCompleted}>
            <StepLabel>{t("business:setup_interviews")}</StepLabel>
            <StepContent>
              <Typography level="body1">
                {t("business:setup_interviews_desc")}
              </Typography>
              <Button
                color="warning"
                onClick={() =>
                  navigate(APP_ROUTES.BUSINESS_INTERVIEWS, {
                    state: { index: 1 },
                  })
                }
                fullWidth
                sx={{ mt: 2 }}
              >
                {t("business:go_to_interviews")}
              </Button>
            </StepContent>
          </Step>
          <Step active completed>
            <StepLabel>Hire your first employee!</StepLabel>
          </Step>
        </Stepper>
      </Stack>
    </Alert>
  );
};

export default SetupGuide;
