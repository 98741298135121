import { Add, AddRounded, Delete } from "@mui/icons-material";
import { Box, Divider, Button, IconButton, Stack, Typography } from "@mui/joy";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import useConfirm from "src/Utils/useConfirm";
import AddLocationModal from "./AddLocationModal";
import BusinessLocationInfo from "./BusinessLocationInfo";
import LocationListItem from "./LocationListItem";

const BusinessLocationsInfo = () => {
  const { business } = useBusinessAuth();
  const { t } = useTranslation("business");

  const [openAddModal, setOpenAddModal] = useState(false);
  const [newPlaceId, setNewPlaceId] = useState<string>();
  const [locations, setLocations] = useState<string[]>([]);

  useEffect(() => {
    if (business) {
      FullBusinessService.getBusinessProfile(business.email!).then((res) => {
        if (res?.locations) {
          setLocations(res.locations.map((l) => l.id));
        }
      });
    }
  }, [business]);

  return (
    <Stack sx={{ mb: 1 }} spacing={1}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ ml: -1 }} level="h5">
          {t("locations")}
        </Typography>
        <IconButton variant="plain" onClick={() => setOpenAddModal(true)}>
          <AddRounded />
        </IconButton>
      </Box>
      {locations.map((lid) => (
        <BusinessLocationInfo
          placeId={lid}
          locations={locations}
          setLocations={setLocations}
        />
      ))}

      <AddLocationModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        placeId={newPlaceId}
        setPlaceId={setNewPlaceId}
        onComplete={(pid?: string) => {
          if (pid) {
            FullBusinessService.updateLocations(business!.email!, pid).then(
              () => setLocations([...locations, pid])
            );
          }
        }}
      />
    </Stack>
  );
};

export default BusinessLocationsInfo;
