import { AppBar, Toolbar, Typography, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../Utils/Constants";
import { useTranslation } from "react-i18next";

const BasicAppBar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["header"]);

  return (
    <AppBar
      elevation={0}
      position="sticky"
      sx={{
        bgcolor: "header.bg",
        color: "header.main",
        height: { xs: "32px", sm: "64px" },
        mb: { xs: 3, md: 10 },
      }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Typography
          variant="h5"
          noWrap
          onClick={() => navigate(APP_ROUTES.HOME)}
          sx={{
            cursor: "pointer",
            fontWeight: "bold",
            "&:hover": { textDecoration: "underline" },
            color: "header.main",
            fontStyle: "italic",
          }}
        >
          Hourlead
        </Typography>
        <Divider sx={{ m: 0 }} />
      </Toolbar>
    </AppBar>
  );
};

export default BasicAppBar;
