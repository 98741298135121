import { KeyboardArrowDown } from "@mui/icons-material";
import { Select, selectClasses, Stack } from "@mui/joy";
import { DocumentReference } from "firebase/firestore";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Option from "@mui/joy/Option";
import LocationListItem from "../Business/LocationListItem";

interface LocationDropdownProps {
  locations: DocumentReference[];
  setSelected: (locationId: string | null) => void;
  defaultVal?: string;
  selected?: string | null;
}

export const LocationDropdown: React.FC<LocationDropdownProps> = ({
  locations,
  setSelected,
  defaultVal,
  selected,
}) => {
  const [activeLocation, setActiveLocation] = useState<string | null>(
    selected ?? null
  );
  const { t } = useTranslation(["business", "common"]);
  return (
    <Stack>
      <Select
        indicator={<KeyboardArrowDown />}
        value={activeLocation}
        defaultValue={selected}
        onChange={(e, newValue) => {
          setActiveLocation(newValue?.toString() || null);
          setSelected(newValue?.toString() || null);
        }}
        sx={{
          [`& .${selectClasses.indicator}`]: {
            transition: "0.2s",
            [`&.${selectClasses.expanded}`]: {
              transform: "rotate(-180deg)",
            },
          },
          typography: "h2",

          border: 0,
          backgroundColor: "transparent",
        }}
      >
        <Option value={null} key={"all_locations"}>
          {t(defaultVal ?? "common:all_locations")}
        </Option>
        {locations.map((locationRef) => (
          <Option value={locationRef.id} key={locationRef.id}>
            <LocationListItem placeId={locationRef.id} />
          </Option>
        ))}
      </Select>
    </Stack>
  );
};

export default LocationDropdown;
