import React, { useContext } from "react";
import InterviewContext from "./InterviewContext";
import {
  InterviewPrefs,
  Applications,
  Interviews,
  Item,
} from "src/Services/Db/types";
import { FullBusinessService } from "src/Services/Db/business";
import useConfirm from "src/Utils/useConfirm";
import { useBusinessAuth } from "src/Context/AuthContext";
import dayjs from "dayjs";

function useInterviewLogicCollisions() {
  const context = useContext(InterviewContext);

  const findCollisionsPrefs = () => {
    //interviews fall before startTime or after endTime
    const outOfBounds = context!.upcomingInterviews.filter(
      (x) =>
        x.interview?.scheduledAt !== undefined &&
        (dayjs(x.interview?.scheduledAt.toDate()).isBefore(
          dayjs(context!.startTime)
        ) ||
          dayjs(x.interview?.scheduledAt.toDate()).isAfter(
            dayjs(context!.endTime)
          ))
    );
    if (outOfBounds.length > 0) {
      return outOfBounds;
    }
    return [];
  };

  const findCollisions = () => {
    //no upcoming interviews, no collisions
    if (context!.upcomingInterviews.length === 0) {
      return [];
    }

    //no upcoming scheduled interviews, no collisions
    if (
      context!.upcomingInterviews.filter(
        (x) => x.interview?.scheduledAt !== undefined
      ).length === 0
    ) {
      return [];
    }

    //find the last scheduled interview
    const lastScheduledInterview = context!.upcomingInterviews.sort((a, b) => {
      return dayjs(a.interview?.scheduledAt.toDate()).diff(
        dayjs(b.interview?.scheduledAt.toDate())
      );
    })[0];

    //so that we can find how many weeks of recurring we need to calculate
    const weeksToLastInterview = dayjs(
      lastScheduledInterview.interview?.scheduledAt.toDate()
    )
      .startOf("week")
      .diff(dayjs().startOf("week"), "week");

    //calculate recurring availability for all upcoming interviews
    const recurringUntilLastScheduledInterview = Array(
      weeksToLastInterview
    ).reduce((acc: Date[], i: number) => {
      return acc.concat(
        context!.recurringAvailability.map((x: Date) => {
          return dayjs(x)
            .add(i + 1, "week")
            .toDate();
        })
      );
    }, []);

    return context!.upcomingInterviews.filter(
      (
        interview: Applications & {
          role: Item;
          interview?: Interviews;
          business: string;
        }
      ) => {
        //if interview isn't in either recurring or add, it's a collision
        return !(
          recurringUntilLastScheduledInterview.includes(
            interview.interview?.scheduledAt.toDate()
          ) ||
          context!.oneTimeAvailability.includes(
            (interview.interview?.scheduledAt.toDate() as Date) || Date()
          )
        );
      }
    );
  };

  return { findCollisions, findCollisionsPrefs };
}

export default useInterviewLogicCollisions;
