import {
  AddRounded,
  AssistantRounded,
  AutoAwesomeRounded,
  Info,
  SearchRounded,
  TryRounded,
} from "@mui/icons-material";
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  useTheme,
  Input,
  FormControl,
  Typography,
  Stack,
  Divider,
  Modal,
  Card,
  Alert,
} from "@mui/joy";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import GPTContext from "./GPTContext";
import useCycle from "./useCycle";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import "../../src/animations.css";
import CyclePrompts from "./CyclePrompts";

import { Amplify, API } from "aws-amplify";
import awsExports from "../../src/aws-exports";
import useToast from "src/Utils/useToast";
import useFilters from "./UseFilters";
import AppBarContext from "src/Context/AppBarContext";
import {
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
  DIVIDER_HEADER_SX_NO_Y,
} from "src/Utils/Constants";
import { useAuth } from "src/Context/AuthContext";
import GenericModal from "src/Components/Common/GenericModal";
import PhoneSignIn from "src/Components/Authentication/PhoneSignIn";
import Messages from "./Messages";
import { MapContext } from "src/Context/MapContext";
import {
  businessesToListingShortString,
  getBusinessesOnMap,
} from "src/Services/Db/utils";
import useKeyToDict from "src/Utils/useKeyToDict";
import { analytics } from "src/firebase";
import { logEvent } from "firebase/analytics";
Amplify.configure(awsExports);

let apiName = "server";
interface InputBoxProps {
  isFocused: boolean;
  setIsFocused: (isFocused: boolean) => void;
}

const InputBox: React.FC<InputBoxProps> = ({ isFocused, setIsFocused }) => {
  const { t } = useTranslation(["gpt"]);
  const { user } = useAuth();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const toast = useToast();

  const [currMessage, setCurrMessage] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const { keyToObjArray } = useKeyToDict("gpt");
  const suggestions = keyToObjArray("suggestions").map((s) => s[1]);

  const cyclePrompts = useCycle(
    Object.values(t("header_prompts", { returnObjects: true }) as string[])
  );

  const context = useContext(GPTContext);
  const appBarContext = useContext(AppBarContext);
  const mapContext = useContext(MapContext);

  const handleMessage = async (e: any, suggestion?: string) => {
    e.preventDefault();

    if (!user) {
      setShowModal(true);
      return;
    }

    if ((!currMessage && !suggestion) || context?.loading) return;

    setIsFocused(false);

    const msg = suggestion ? suggestion : currMessage;

    const listingStr = await businessesToListingShortString(
      mapContext?.markerIds
    );

    if (msg.length + listingStr.length > 15000) {
      toast("gpt", "message_too_long", "", () => {});
      return;
    }

    logEvent(analytics, "search", {
      search_term: msg,
    });

    context?.setLoading(true);
    appBarContext?.setLoading(true);
    context?.setMessages([
      ...context?.messages,
      {
        role: "user",
        content: msg,
      },
    ]);

    API.post(apiName, "/gptsearch", {
      body: {
        message: msg,
        listingStr: listingStr,
        gpt3: true,
      },
    })
      .then((response: string[]) => {
        context?.setMessages([
          ...context?.messages,
          {
            role: "user",
            content: msg,
          },
        ]);
        context?.setRecommendations(response);
        context?.setNoResults(response.length === 0);
        context?.setShowRecommendations(true);
      })
      .catch((_error: string) => {
        toast("gpt", "response_failed", "", () => {});
      })
      .finally(() => {
        context?.setLoading(false);
        appBarContext?.setLoading(false);
      });

    setCurrMessage("");
  };

  return (
    <form onSubmit={(e: any) => handleMessage(e)}>
      <FormControl>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            color: "text.tertiary",
            ml: 1,
          }}
        >
          <AutoAwesomeRounded
            sx={{
              color: isFocused && currMessage ? "black" : "colors.tertiary",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              autoFocus={!mobile}
              value={currMessage}
              enterKeyHint="search"
              placeholder={
                isFocused || (context?.messages && context?.messages.length > 0)
                  ? context?.messages && context?.messages.length > 0
                    ? context.messages[context?.messages.length - 1].content
                    : cyclePrompts
                  : ""
              }
              onChange={(e: any) => setCurrMessage(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              style={{
                border: "none",
                paddingTop: "4px",
                paddingBottom: "4px",
                paddingLeft: "0.5rem",
                backgroundColor: "transparent",
                width: "100%",
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                outline: "none",
                caretColor: "#D75143",
              }}
            />

            {currMessage && (
              <IconButton
                variant="plain"
                type="submit"
                sx={{
                  fontSize: "1.1rem",
                  py: 0,
                  my: -1,
                  bgcolor: "transparent",
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }}
              >
                <TryRounded />
              </IconButton>
            )}
          </Box>

          {!isFocused && !currMessage && context?.messages.length === 0 && (
            <CyclePrompts />
          )}
        </Box>
        {/* <Divider
          sx={{
            ...DIVIDER_HEADER_SX_NO_Y,
            maxWidth: isFocused ? "100vw" : 0,
            transition: "max-width 0.3s ease-in-out",
          }}
        /> */}
        <Stack
          sx={{
            maxHeight: isFocused ? "100vh" : 0,
            overflow: "hidden",
            transition: "max-height 0.3s ease-in-out, border 0.3s ease-in-out",
            position: "absolute",
            top: 0,
            mt: 6,
            // left: { xs: "-2vw", md: 0 },
            width: "100%",
            bgcolor: "common.white",
            borderRadius: { xs: 1, md: 0.5 },
            border: "0px solid #DDD",
            borderWidth: isFocused ? "1px" : "none",
          }}
          spacing={1}
          divider={<Divider sx={{ opacity: 1 }} />}
        >
          {suggestions.map((suggestion, index) => (
            <Typography
              key={index}
              sx={{
                px: 1,
                py: 0.5,
                pt: index === 0 ? 0.75 : 0,
                pb: index === suggestions.length - 1 ? 0.75 : 0,
                "&:hover": {
                  cursor: "pointer",
                  textDecoration: "underline",
                },
                color: "text.secondary",
              }}
              level="body1"
              onClick={() => {
                handleMessage({ preventDefault: () => {} }, suggestion);
              }}
            >
              {suggestion}
            </Typography>
          ))}
        </Stack>
        <GenericModal
          show={showModal}
          setShow={setShowModal}
          header={"Sign in"}
          bodyComponent={
            <>
              <Alert startDecorator={<Info />} sx={{ mb: 1 }} color="neutral">
                <Typography level="body1">
                  Sign in with your phone number to find your perfect job with
                  ChatGPT today!
                </Typography>
              </Alert>
              <PhoneSignIn
                onComplete={() => {
                  setShowModal(false);
                  logEvent(analytics, "sign_up", {
                    method: "gpt",
                  });
                }}
              />
            </>
          }
        />
      </FormControl>
    </form>
  );
};

export default InputBox;
