import {
  DocumentReference,
  endAt,
  orderBy,
  startAt,
  where,
} from "@firebase/firestore";
import {
  geohashQueryBounds,
  Geopoint,
  distanceBetween,
  geohashForLocation,
} from "geofire-common";
import DatabaseService, {
  ApplicationsService,
  BusinessService,
  InterviewAvailabilitiesService,
  InterviewPrefsService,
  InterviewsService,
  ListingsService,
  UserService,
  WageService,
} from "./service";
import {
  BusinessAccount,
  Businesses,
  InterviewAvailabilities,
  InterviewPrefs,
  Interviews,
  Item,
  Listings,
  MapField,
  UserData,
  Wages,
} from "./types";
import { doc, GeoPoint, Timestamp } from "firebase/firestore";
import { db } from "../../firebase";
import { FullUserService } from "./user";
import {
  EmployedSignUpData,
  StudentSignUpData,
} from "../../Components/Authentication/SignUp";
import DatabaseError from "./errors";
import { TABLES } from "./consts";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

async function queryServiceByGeohash<Type>(
  center: Geopoint,
  radius: number,
  service: DatabaseService<Type>,
  coll: string = "geohash"
): Promise<Type[]> {
  const bounds = geohashQueryBounds(center, radius);
  const docs: Type[] = [];

  for (var b of bounds) {
    docs.push(
      ...(await service.getMany(
        where(coll, ">=", b[0]),
        where(coll, "<=", b[1])
      ))
    );
  }

  return docs;
}

function maxMapRadius(bounds: google.maps.LatLngBounds): number {
  if (!bounds) {
    return 1000;
  }
  const swCoords = bounds.getSouthWest();
  const neCoords = bounds.getNorthEast();
  const center = bounds.getCenter();
  return (
    Math.max(
      distanceBetween(
        [swCoords.lat(), swCoords.lng()],
        [center.lat(), center.lng()]
      ),
      distanceBetween(
        [neCoords.lat(), neCoords.lng()],
        [center.lat(), center.lng()]
      )
    ) * 500
  ); // distance in km * 1000. / 2 to make meters and radius
}

export async function getWagesForBounds(
  bounds: google.maps.LatLngBounds
): Promise<Businesses[]> {
  const radius: number = maxMapRadius(bounds);
  const center: google.maps.LatLng = bounds.getCenter();
  return await queryServiceByGeohash<Businesses>(
    [center.lat(), center.lng()],
    radius,
    BusinessService
  );
  // FIXME so like compound queries dont seem to work even with compound indices in firebase??
}

export function getBusinessesOnMap(
  businesses: Businesses[],
  bounds?: google.maps.LatLngBounds
): string[] {
  if (!bounds) return businesses.map((business) => business.id);
  const radius: number = maxMapRadius(bounds);
  const center: google.maps.LatLng = bounds.getCenter();
  return businesses
    .filter(
      (business) =>
        distanceBetween(
          [center.lat(), center.lng()],
          [business.latlong.latitude, business.latlong.longitude]
        ) *
          1000 <=
        radius
    )
    .map((business) => business.id);
}

export function getBusinessRef(id: string): DocumentReference {
  return doc(db, TABLES.BUSINESSES, id);
}

export async function studentSignUp(
  uid: string,
  studentData: StudentSignUpData
) {}

export async function employedSignUp(
  uid: string,
  employedData: EmployedSignUpData
): Promise<void> {
  const businessRef = getBusinessRef(employedData.business.id);
  const wageData: Wages = {
    wage: employedData.wage,
    tips: employedData.tips,
    // FIXME
    role: { item: employedData.role, category: "role" },
    business: businessRef,
  };
  return FullUserService.createUserWithData({
    uid: uid,
    wage: wageData,
  });
}

export function updateWageMap(
  role: string,
  wage: number,
  tips: number,
  wageMap?: { [key: string]: number },
  countMap?: { [key: string]: number }
): [{ [key: string]: number }, { [key: string]: number }] {
  if (!wageMap || !countMap) {
    return [{ role: wage }, { role: 1 }];
  }
  wageMap[role] =
    (wageMap[role] * countMap[role] + wage + tips) / (countMap[role] + 1);
  countMap[role] = countMap[role] + 1;
  return [wageMap, countMap];
}

// FIXME to delete
export async function addListing(business: Businesses, listing: Listings) {
  const loadedBusiness = await BusinessService.getOne(business.id);

  var listingWageMap: MapField = {};
  var listingCtMap: MapField = {};
  if (
    loadedBusiness &&
    loadedBusiness.listingCtMap &&
    loadedBusiness.listingWageMap
  ) {
    listingWageMap = { ...loadedBusiness.listingWageMap };
    console.log("LISTING WAGE MAP", listingWageMap);
    listingCtMap = { ...loadedBusiness.listingCtMap };
    const avg = loadedBusiness.listingWageMap[listing.role] || 0;
    const ct = loadedBusiness.listingCtMap[listing.role] || 0;
    listingWageMap[listing.role] =
      (listing.wage + listing.tips + avg * ct) / (ct + 1);
    listingCtMap[listing.role] = ct + 1;
  } else {
    listingWageMap[listing.role] = listing.wage + listing.tips;
    listingCtMap[listing.role] = 1;
  }
  const { id, ...businessData } = business;
  const businessRef = await BusinessService.create(
    {
      ...businessData,
      listingWageMap: listingWageMap,
      listingCtMap: listingCtMap,
    },
    id
  );
  ListingsService.create({ ...listing, businessRef: businessRef });
}

function createBusinessWithDetails(
  place: google.maps.places.PlaceResult | null,
  status: google.maps.places.PlacesServiceStatus
) {
  if (status == google.maps.places.PlacesServiceStatus.OK && place) {
    const latlong = new GeoPoint(
      place.geometry!.location!.lat(),
      place.geometry!.location!.lng()
    );

    BusinessService.create(
      {
        name: place.name,
        address: place.formatted_address,
        geohash: geohashForLocation([latlong.latitude, latlong.longitude]),
        latlong: latlong,
        listingWageMap: {},
        listingCtMap: {},
      },
      place.place_id
    );
    console.log(place.place_id);
  } else {
    throw new DatabaseError("Failed to load and store new business");
  }
}

export async function ensureBusinessExists(placeId: string): Promise<void> {
  const business: Businesses | null = await BusinessService.getOne(placeId);
  if (business) return;

  // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlacesService
  const placesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );
  placesService.getDetails(
    {
      placeId: placeId,
      fields: ["name", "place_id", "formatted_address", "geometry"],
    },
    createBusinessWithDetails
  );
}

export async function loadSelectedMarkerMapByPid(
  placeId: string
): Promise<MapField> {
  const markerMap: MapField | undefined = (
    await BusinessService.getOne(placeId)
  )?.listingWageMap;
  return markerMap || {};
}

export async function getAllLocationsByPid(placeId: string): Promise<string[]> {
  await ensureBusinessExists(placeId);
  const business: Businesses | null = await BusinessService.getOne(placeId);
  if (!business) return [];
  const businesses: Businesses[] = await BusinessService.getMany(
    where("name", "==", business.name)
  );
  return businesses.map((b) => b.id);
}

export async function getInterviewSlotsByPid(placeId: string): Promise<
  | (InterviewPrefs & {
      recurringAvailability: Timestamp[];
      oneTimeAvailability: Timestamp[];
      removeAvailability: Timestamp[];
    })
  | undefined
> {
  const business: Businesses | null = await BusinessService.getOne(placeId);
  // if business hasn't set time, then that's on them
  if (!business) return undefined;
  if (!business.interviewAvailabilities) return undefined;
  if (!business.interviewPrefs) return undefined;

  const interviewPrefs: InterviewPrefs | null =
    await InterviewPrefsService.getResolvedReference(business.interviewPrefs);
  const availabilities: InterviewAvailabilities[] = await Promise.all(
    business.interviewAvailabilities
      .map(
        async (a) =>
          await InterviewAvailabilitiesService.getResolvedReference(a)
      )
      .filter((a) => a !== null) as Promise<InterviewAvailabilities>[]
  );
  if (!interviewPrefs) return undefined;

  return {
    ...interviewPrefs,
    recurringAvailability:
      availabilities
        .filter((a) => a?.type === "recurring")
        ?.map((a) => a?.time) || [],
    oneTimeAvailability:
      availabilities
        .filter((a) => a?.type === "one-time")
        ?.map((a) => a?.time) || [],
    removeAvailability:
      availabilities.filter((a) => a?.type === "remove")?.map((a) => a?.time) ||
      [],
  };
}

export async function createInterview(
  applicationId: string,
  time: Date,
  mode: "phone" | "video" | "in-person",
  uid: string,
  placeId: string,
  status: string
) {
  const application = await ApplicationsService.getOne(applicationId);
  const business = await BusinessService.getOne(placeId);
  if (!business) return;
  if (!business.interviewPrefs) return;
  if (!application) return;

  const interviewPrefs = await InterviewPrefsService.getResolvedReference(
    business.interviewPrefs
  );
  if (!interviewPrefs) return;
  const duration = interviewPrefs.duration;

  const interviewRef = await InterviewsService.create({
    userRef: doc(db, TABLES.USERS, uid),
    businessRef: doc(db, TABLES.BUSINESSES, placeId),
    applicationRef: doc(db, TABLES.APPLICATIONS, applicationId),
    scheduledAt: Timestamp.fromDate(time),
    duration: duration,
    mode: mode,
    status: status,
  });
  console.log(interviewRef, "interviewRef");

  await ApplicationsService.update(applicationId, {
    interviewRef: interviewRef,
  });
}

export async function getInterviewInfo(
  intId: string
): Promise<{ name?: string; role?: string; when?: Timestamp }> {
  const interview = await InterviewsService.getOne(intId);
  const business = await BusinessService.getResolvedReference(
    interview?.businessRef!
  );
  const application = await ApplicationsService.getResolvedReference(
    interview?.applicationRef!
  );
  const listing = await ListingsService.getResolvedReference(
    application?.listingRef!
  );

  return {
    name: business?.name,
    role: listing?.role,
    when: interview?.scheduledAt,
  };
}

export async function getApplicationInfo(
  appId: string
): Promise<{ businessName: string; role: Item; reminderMsg?: string }> {
  const application = await ApplicationsService.getOne(appId);
  const business = await BusinessService.getResolvedReference(
    application?.businessRef!
  );
  const interviewPrefs = await InterviewPrefsService.getResolvedReference(
    business?.interviewPrefs!
  );
  const listing = await ListingsService.getResolvedReference(
    application?.listingRef!
  );

  return {
    businessName: business?.name || "",
    role: { item: listing?.role || "", category: listing?.category || "" },
    reminderMsg: interviewPrefs?.reminderMsg,
  };
}

export async function filterDuplicateListings(
  listingIds: string[]
): Promise<string[]> {
  const listings = await Promise.all(
    listingIds.map(async (id) => await ListingsService.getOne(id))
  );
  // filter out listings that include this listing id in linkedListings and appear later in the array
  const filteredListings = listings.filter((listing, index) => {
    // if listing appears in linkedListings for listings beyond the current index, return false
    if (listing === null) return false;
    return !listings
      .slice(index + 1)
      .some((l) => l?.linkedListings?.some((ll) => ll.id === listing?.id));
  });
  return filteredListings.map((listing) => listing?.id || "");
}

export async function businessesToListingShortString(businessIds?: string[]) {
  if (!businessIds) return "";
  const listings = await Promise.all(
    businessIds.map(async (id) => {
      const listings = await ListingsService.getMany(
        where("businessRef", "==", doc(db, TABLES.BUSINESSES, id))
      );
      return listings;
    })
  );
  const listingStrs = listings
    .filter((l) => l.length > 0)
    .map((l) =>
      l
        .map((l) => listingToShortString(l))
        .join("\n")
        .trim()
    );
  return listingStrs.join("\n").trim();
}

export function listingToShortString(listing: Listings): string {
  let ret = "";

  ret += "ID:" + listing.id + ":";

  let infos: string[] = [];
  infos.push(`${listing.category}-${listing.role}`);
  infos.push(`$${listing.wage + listing.tips}/hr`);
  infos.push(`${listing.years_of_experience} experience`);

  if (listing.schedule && listing.schedule.length > 0) {
    infos.push("schedule:" + listing.schedule.map((s) => s.item).join(","));
  }
  if (listing.skills && listing.skills.length > 0) {
    infos.push("skills:" + listing.skills.map((s) => s.item).join(","));
  }
  if (listing.perks && listing.perks.length > 0) {
    infos.push("perks:" + listing.perks.map((s) => s.item).join(","));
  }

  return ret + infos.join("|");
}

export function listingToString(listing: Listings): string {
  // returns a string representation of a listing including all defined keys and values
  const indexableListing: { [key: string]: any } = listing;
  const keys = Object.keys(indexableListing);
  const values = Object.values(indexableListing);
  const stringifiedValues = values.map((value) => {
    if (typeof value === "object") {
      if (value instanceof Timestamp) {
        return "";
      } else if (value === null || value === undefined) {
        return "";
      } else if (value instanceof DocumentReference) {
        return value.id;
      } else if (value instanceof Array && value.length === 0) {
        return "";
      } else if (
        value instanceof Object &&
        value.hasOwnProperty("item") &&
        value.hasOwnProperty("category")
      ) {
        return `${value.item}/${value.category}`;
      } else if (value instanceof Array) {
        if (
          value[0].hasOwnProperty("item") &&
          value[0].hasOwnProperty("category")
        ) {
          return value.map((v) => `${v.item}/${v.category}`).join(",");
        }
      } else {
        return JSON.stringify(value);
      }
    } else {
      return value.toString();
    }
  });
  const stringifiedListing = keys
    .map((key, index) => {
      if (
        stringifiedValues[index] === "" ||
        key === "custom_questions" ||
        key === "application_requirements" ||
        key === "status" ||
        key === "linkedListings"
      ) {
        return "";
      }
      return `${key}:${stringifiedValues[index]}`;
    })
    .filter((s) => s !== "")
    .join("|");
  return stringifiedListing;
}
