import PageSpacer from "../Components/Utilities/PageSpacer";
import { CheckCircle } from "@mui/icons-material";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/joy";
import { Fragment, useContext, useEffect, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { HEADER_TYPES } from "../Utils/Constants";
import { useParams } from "react-router-dom";
import { getInterviewInfo } from "../Services/Db/utils";
import { InterviewsService } from "../Services/Db/service";
import { Timestamp } from "firebase/firestore";
import dayjs from "dayjs";

const InterviewConfirmation = () => {
  const context = useContext(AppBarContext);
  context?.setHeaderType(HEADER_TYPES.BASIC);
  const [loading, setLoading] = useState(true);
  const interviewId = useParams().interviewId;
  const [interviewInfo, setInterviewInfo] = useState<{
    name?: string;
    role?: string;
    when?: Timestamp;
  }>();

  useEffect(() => {
    if (interviewId) {
      getInterviewInfo(interviewId)
        .then((interview) => {
          setInterviewInfo(interview);
        })
        .finally(() => {
          InterviewsService.update(interviewId, { status: "confirmed" }).then(
            () => {
              setLoading(false);
            }
          );
        });
    }
  }, [interviewId]);

  return (
    <PageSpacer>
      <Stack spacing={2} alignItems="center">
        {loading ? (
          <CircularProgress size="lg" />
        ) : (
          <Fragment>
            <CheckCircle sx={{ fontSize: 75 }} color="success" />
            <Typography level="cardHeader">Interview Confirmed</Typography>
            <Typography level="body1">
              Your interview with {interviewInfo?.name} for at{" "}
              {dayjs
                .unix(interviewInfo?.when?.seconds!)
                .format("dddd, MMM D, h:mm a")}{" "}
              has been confirmed.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 1,
                flexWrap: "nowrap",
              }}
            >
              <Button
                fullWidth
                variant="outlined"
                onClick={() => setLoading(false)}
              >
                Cancel Interview
              </Button>
              <Button variant="soft" fullWidth>
                Reschedule Interview
              </Button>
            </Box>
          </Fragment>
        )}
      </Stack>
    </PageSpacer>
  );
};

export default InterviewConfirmation;
