import {
  Box,
  Card,
  Checkbox,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/joy";
import Collapse from "@mui/material/Collapse";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import EditRoleHeader from "src/Components/Business/EditRoleHeader";
import { Item } from "src/Services/Db/types";
import { DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import ExperienceSlider from "../ExperienceSlider";
import NewItemSelector from "../NewItemSelector";
import { RoleEditType } from "./RoleAbout";

interface RoleReqsType extends RoleEditType {}

const RoleReqs = ({ listing, setListing, collapsable }: RoleReqsType) => {
  const { t } = useTranslation("business");

  const [expand1, setExpand1] = useState(false);
  const [expand2, setExpand2] = useState(false);

  function setSkills(skills: Item[] | null) {
    if (skills) {
      setListing({ ...listing, skills: skills });
    }
  }

  function setYearsOfExperienceSelected(yearsOfExperience: number) {
    setListing({ ...listing, years_of_experience: yearsOfExperience });
  }

  function setRequirements(reqId: string) {
    if (listing.application_requirements.includes(reqId)) {
      setListing({
        ...listing,
        application_requirements: [
          ...listing.application_requirements.filter((r) => r !== reqId),
        ],
      });
    } else {
      setListing({
        ...listing,
        application_requirements: [...listing.application_requirements, reqId],
      });
    }
  }

  const availableRequirements = [
    { id: "experiences", title: t("apply:experiences") },
    { id: "languages", title: t("apply:languages_spoken") },
    { id: "education", title: t("apply:education") },
    { id: "references", title: t("apply:references") },
    { id: "availability", title: t("apply:availability") },
  ];

  return (
    <Fragment>
      <Card>
        <EditRoleHeader
          headerKey="who_im_looking_for"
          collapsable={collapsable}
          expand={expand1}
          setExpand={setExpand1}
          desc="who_im_looking_for_desc"
        />
        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Collapse in={expand1 || !collapsable} timeout="auto">
          <Stack spacing={2} divider={<Divider />}>
            <Box>
              <Typography
                level="selectLabel"
                sx={{ pb: 3, wordWrap: "break-word" }}
              >
                {t("years_of_experience", { ns: ["business"] })}
              </Typography>
              <ExperienceSlider
                setValue={setYearsOfExperienceSelected}
                defaultVal={listing.years_of_experience}
              />
            </Box>
            <NewItemSelector
              selectMultiple
              title={t("requirements", { ns: ["business"] })}
              selectedItems={listing.skills}
              setSelectedItems={setSkills}
              dictKey={"skills"}
              dictHeaderKey={"skills_headers"}
            />
          </Stack>
        </Collapse>
      </Card>
      <Card>
        <EditRoleHeader
          headerKey="application_requirements"
          expand={expand2}
          setExpand={setExpand2}
          collapsable={collapsable}
          desc="application_requirements_desc"
        />

        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Collapse in={expand2 || !collapsable} timeout="auto">
          <List>
            <ListItem key={"name"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Checkbox checked={true} disabled />
                <Typography sx={{ fontWeight: 500 }}>
                  {t("common:name")}
                </Typography>
              </Box>
            </ListItem>
            <ListItem key={"phone"}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Checkbox checked={true} disabled />
                <Typography sx={{ fontWeight: 500 }}>
                  {t("common:phone_number")}
                </Typography>
              </Box>
            </ListItem>
            {availableRequirements.map((req) => {
              return (
                <ListItem key={req.id}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRequirements(req.id);
                    }}
                  >
                    <Checkbox
                      checked={listing.application_requirements.includes(
                        req.id
                      )}
                    />
                    <Typography sx={{ fontWeight: 500 }}>
                      {req.title}
                    </Typography>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </Card>
    </Fragment>
  );
};

export default RoleReqs;
