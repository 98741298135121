import { Box, Card, Divider, Typography } from "@mui/joy";
import { Collapse, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import EditRoleHeader from "src/Components/Business/EditRoleHeader";
import LocationCheckbox from "src/Components/Business/LocationCheckbox";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { ListingsService } from "src/Services/Db/service";
import { DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import { RoleEditType } from "./RoleAbout";

interface RoleLocationType extends RoleEditType {
  selectedLocations: string[];
  setSelectedLocations: (locations: string[]) => void;
}

const RoleLocations = ({
  listing,
  setListing,
  selectedLocations,
  setSelectedLocations,
  collapsable,
}: RoleLocationType) => {
  const { t } = useTranslation("business");
  const [locations, setLocations] = useState<string[]>([]);
  const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
  const [expand, setExpand] = useState<boolean>(true);
  const { business } = useBusinessAuth();
  const location = useLocation();

  useEffect(() => {
    if (loadingLocations) {
      if (location.state && location.state.edit && business) {
        if (listing.linkedListings) {
          Promise.all(
            listing.linkedListings.map((linkedListing) =>
              ListingsService.getResolvedReference(linkedListing)
            )
          ).then((links) => {
            setLocations(
              [
                listing.businessRef?.id!,
                ...(links
                  .filter((id) => id !== null && id !== undefined)
                  .map((link) => link?.businessRef?.id) as string[]),
              ] || []
            );
            setSelectedLocations(
              [
                listing.businessRef?.id!,
                ...(links
                  .filter((id) => id !== null && id !== undefined)
                  .map((link) => link?.businessRef?.id) as string[]),
              ] || []
            );
            setLoadingLocations(false);
          });
        } else {
          setLocations([listing.businessRef?.id!]);
          setSelectedLocations([listing.businessRef?.id!]);
          setLoadingLocations(false);
        }
      } else {
        if (business) {
          FullBusinessService.getBusinessProfile(business.email!)
            .then((res) => {
              if (res) {
                setLocations(res.locations?.map((loc) => loc.id || "") || []);
                setSelectedLocations(
                  res.locations?.map((loc) => loc.id || "") || []
                );
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setLoadingLocations(false);
            });
        }
      }
    }
  }, [business, listing]);

  if (loadingLocations) {
    return <Skeleton />;
  }

  if (locations.length > 1) {
    return (
      <Card>
        <EditRoleHeader
          headerKey="select_location"
          expand={expand}
          setExpand={setExpand}
          collapsable={collapsable}
          desc={"select_location_desc"}
        />

        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Collapse in={expand || !collapsable} timeout="auto">
          {locations.map((loc, i) => (
            <Box sx={{ mb: 1 }} key={i}>
              <LocationCheckbox
                placeId={loc}
                selected={selectedLocations}
                setSelected={setSelectedLocations}
                disabled={location.state && location.state.edit}
              />
            </Box>
          ))}
        </Collapse>
      </Card>
    );
  }

  return <></>;
};

export default RoleLocations;
