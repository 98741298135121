import {
  useTheme,
  Typography,
  Stack,
  Select,
  Option,
  Box,
  Checkbox,
  Input,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import React, { useContext } from "react";
import SelectGroup from "./SelectGroup";
import InterviewLocationSelect from "./InterviewLocationSelect";
import NumberField from "./NumberField";
import { NumberFieldBox } from "../Authentication/PhoneSignIn";
import { InterviewLocation } from "../../Services/Db/types";
import { Skeleton } from "@mui/material";
import InterviewContext from "../../Pages_Business/Interviews/InterviewContext";

const SelectInterviewAvailabilityDetails = () => {
  const { t } = useTranslation(["interview"]);
  const context = useContext(InterviewContext);

  if (context?.loadingPrefs) {
    return (
      <React.Fragment>
        <Skeleton variant="text" width="100%" sx={{ marginTop: 2 }} />
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="rounded" width="100%" height={40} />
        <Skeleton variant="text" width="50%" />
        <Skeleton variant="rounded" width="100%" height={40} />
        <Skeleton variant="text" width="50%" />
        <Box display="flex">
          <Skeleton variant="rounded" width={"30%"} height={40} />
          <Skeleton
            variant="rounded"
            width={"30%"}
            height={40}
            sx={{ marginLeft: 2 }}
          />
          <Skeleton
            variant="rounded"
            width={"30%"}
            height={40}
            sx={{ marginLeft: 2 }}
          />
        </Box>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          my: 2,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography level="body1">
          {t("interviews_are", { ns: "interview" })}
        </Typography>

        <Select
          value={context?.interviewDuration}
          onChange={(e, val) => {
            context?.setInterviewDuration(val as number);
          }}
        >
          {[10, 15, 20, 30, 60].map((mins) => (
            <Option value={mins} key={mins}>
              {mins}
            </Option>
          ))}
        </Select>

        <Typography level="body1">
          {t("minutes_long", { ns: "interview" })}
        </Typography>
      </Stack>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 1,
          my: 2,
        }}
      >
        <Typography level="body1">
          {t("availability_starts_at", { ns: "interview" })}
        </Typography>

        {/* TODO, load default start time from firebase */}
        <Select
          defaultValue={9}
          onChange={(e, val) => {
            if ((val as number) < context!.endTime) {
              context!.setStartTime(val as number);
            }
          }}
          sx={{ width: { xs: "100%", md: "20%" } }}
          value={context!.startTime}
        >
          {[...Array(24)].map((_, hour) => (
            <Option value={hour} key={hour}>
              {dayjs().startOf("day").hour(hour).format("h:mm a")}
            </Option>
          ))}
        </Select>

        <Typography level="body1">
          {t("interviews_end_at", { ns: "interview" })}
        </Typography>

        <Select
          defaultValue={9}
          onChange={(e, val) => {
            if ((val as number) > context!.startTime) {
              context!.setEndTime(val as number);
            }
          }}
          sx={{ width: { xs: "100%", md: "20%" } }}
          value={context!.endTime}
        >
          {[...Array(24)].map((_, hour) => (
            <Option value={hour}>
              {dayjs().startOf("day").hour(hour).format("h:mm a")}
            </Option>
          ))}
        </Select>
      </Box>
      <Stack
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <Typography level="body1">
          {t("interview_options", { ns: "interview" })}
        </Typography>
        <InterviewLocationSelect
          selected={context!.interviewTypes}
          setSelected={context!.setInterviewTypes}
        />
        {context!.interviewTypes.includes("video") && (
          <Input
            placeholder="Video link (e.g. zoom.com/...)"
            defaultValue={context!.videoLink}
            onChange={(e) => context!.setVideoLink(e.target.value)}
            fullWidth
          />
        )}
        <Typography level="body1">
          {t("reminder_message", { ns: "interview" })}
        </Typography>
        <Input
          placeholder="E.g. 'When you arrive, come to the back and ask for Alex.'"
          onChange={(e) => context!.setReminderMsg(e.target.value)}
          defaultValue={context!.reminderMsg}
          fullWidth
        />
      </Stack>
    </React.Fragment>
  );
};

export default SelectInterviewAvailabilityDetails;
