import { useState } from "react";
import {
  Card,
  Divider,
  Input,
  Textarea,
  Typography,
  Stack,
  Button,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import useConfirm from "../../Utils/useConfirm";
import { Item } from "../../Services/Db/types";

interface SectionCreatorProps {
  sections: Item[];
  setSections: (sections: Item[]) => void;
  setSave: (save: boolean) => void;
}

const SectionCreator: React.FC<SectionCreatorProps> = ({
  sections,
  setSections,
  setSave,
}) => {
  const { t } = useTranslation(["business", "common"]);
  const confirm = useConfirm();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  return (
    <Stack>
      <Card>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder={t("section_title") || ""}
          variant="plain"
          sx={{ fontSize: "1.5rem", fontWeight: 600 }}
          endDecorator={
            <Button
              onClick={() => {
                setSections([
                  ...sections,
                  { category: title, item: description },
                ]);
                setTitle("");
                setDescription("");
                setSave(true);
              }}
            >
              {t("add_section")}
            </Button>
          }
        />
        <Divider sx={DIVIDER_HEADER_SX} />
        <Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder={
            t("section_description", { header: title || "title" }) || ""
          }
          variant="plain"
          sx={{ mt: 2 }}
        />
      </Card>
    </Stack>
  );
};

export default SectionCreator;
