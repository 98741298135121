import {
  useTheme,
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListSubheader,
  ListItemButton,
  Modal,
  Divider,
  IconButton,
  Card,
  Button,
  Chip,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState } from "react";
import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  CheckCircleOutlineRounded,
  Close,
  CloseRounded,
} from "@mui/icons-material";
import { Timestamp } from "firebase/firestore";
import {
  createInterview,
  getInterviewSlotsByPid,
} from "../../Services/Db/utils";
import { InterviewLocation, Item } from "../../Services/Db/types";
import useKeyToDict from "../../Utils/useKeyToDict";
import {
  ApplicationsService,
  BusinessService,
  InterviewPrefsService,
} from "../../Services/Db/service";
import InterviewLocationSelect from "./InterviewLocationSelect";
import { Skeleton } from "@mui/material";
import useText from "../../Utils/useText";

require("dayjs/locale/es");
var isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

interface SelectInterviewSlotProps {
  placeId: string;
  setSelected: (slot: Date | null) => void;
}

const calculatedAvailabilityFor3Weeks = (
  recurringAvailability: Timestamp[],
  addAvailability: Timestamp[],
  removeAvailability: Timestamp[],
  perHour: number
) => {
  const recurringAvailabilityFor3Weeks: Dayjs[] = [];

  for (let i = 0; i < 3; i++) {
    recurringAvailability.map((t: Timestamp) => {
      const dayinjs = dayjs.unix(t.seconds);
      const day = dayinjs.day();
      const hour = dayinjs.hour();
      const mins = dayinjs.minute();
      const newDate = dayjs()
        .day(day)
        .hour(hour)
        .minute(mins)
        .second(0)
        .millisecond(0)
        .add(i, "week");
      recurringAvailabilityFor3Weeks.push(newDate);
    });
  }

  const addAvailabilityFor3Weeks = addAvailability
    .filter((t: Timestamp) => dayjs.unix(t.seconds).diff(dayjs(), "week") <= 3)
    .map((t) => dayjs.unix(t.seconds));

  console.log(
    "addAvailabilityFor3Weeks",
    addAvailabilityFor3Weeks.map((d) => d.format("dddd, MMM DD YY, h:mm a"))
  );

  const calculatedAvailabilityFor3Weeks: Dayjs[] = [
    ...recurringAvailabilityFor3Weeks,
    ...addAvailabilityFor3Weeks,
  ].filter(
    (d: Dayjs) =>
      !removeAvailability.some((t: Timestamp) => t.seconds === d.unix()) &&
      !d.isBefore(dayjs().add(1, "day"), "day") //filter any times before tomorrow
  ); //the difference between recurring, add, and remove

  return calculatedAvailabilityFor3Weeks.reduce(
    (acc: { [key: string]: Date[] }, curr: Dayjs) => {
      const day = curr.format("dddd, MMM DD YY");
      if (!acc[day]) {
        acc[day] = []; //create new array for a day if it doesn't exist
      }

      acc[day] = [...acc[day], curr.toDate()];
      acc[day].sort((a, b) => a.getTime() - b.getTime());
      return acc;
    },
    {} as { [key: string]: Date[] }
  );
};

const SelectInterviewSlot: React.FC<SelectInterviewSlotProps> = ({
  placeId,
  setSelected,
}) => {
  const { i18n } = useTranslation(["job_seeker"]);

  const handleSelection = (s: Date) => {
    setSelected(s);
  };

  const [next3Weeks, setNext3Weeks] = useState<{ [key: string]: Date[] }>({});
  const [next3WeeksOrderedKeys, setNext3WeeksOrderedKeys] = useState<string[]>(
    []
  );

  useEffect(() => {
    const loadAvailability = async (pid: string) => {
      const interviewInfo = await getInterviewSlotsByPid(pid);
      if (interviewInfo) {
        const recurring = interviewInfo.recurringAvailability;
        const add = interviewInfo.oneTimeAvailability;
        const remove = interviewInfo.removeAvailability;
        const perHour = 60 / interviewInfo.duration;

        const calculated = calculatedAvailabilityFor3Weeks(
          recurring,
          add,
          remove,
          perHour
        );

        console.log(
          "recurring",
          recurring.map((s) => dayjs.unix(s.seconds).format())
        );
        console.log("calculated", calculated);

        setNext3Weeks(calculated);
        setNext3WeeksOrderedKeys(
          Object.keys(calculated).sort(
            (a: any, b: any) => dayjs(a).diff(dayjs(b), "day") //sort by day
          )
        );
      }
    };
    loadAvailability(placeId);
    console.log("3 weeks", calculatedAvailabilityFor3Weeks);
  }, [placeId]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <List sx={{ p: 0, m: 0 }}>
        {next3WeeksOrderedKeys.length > 0
          ? next3WeeksOrderedKeys.map((key, headerIndex) => (
              <ListItem nested key={headerIndex}>
                <ListSubheader sticky sx={{ bgcolor: "header.bg", pb: 0 }}>
                  <Box sx={{ width: "100%" }}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "1.25rem",
                        textTransform: "none",
                        letterSpacing: "normal",
                        pb: 1,
                        ml: -1.5,
                      }}
                    >
                      {dayjs(key)
                        .locale(i18n.language)
                        .format("dddd, MMM D, YYYY")}
                    </Typography>
                  </Box>
                </ListSubheader>
                <List>
                  {next3Weeks[key].map((slot, index) => (
                    <ListItem key={index} sx={{ ml: -1 }}>
                      <ListItemButton
                        onClick={() => handleSelection(slot)}
                        sx={{ "&:hover": { bgcolor: "transparent" } }}
                      >
                        <Card
                          sx={{
                            width: "100%",
                            "&:hover": { bgcolor: "#EEEEEE" },
                            boxShadow: "0 0 0 0",
                            borderWidth: 1,
                            py: 1.5,
                            fontWeight: "500",
                          }}
                        >
                          {dayjs(slot)
                            .locale(i18n.language)
                            .format("dddd h:mm A")}
                        </Card>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </ListItem>
            ))
          : Array.from({ length: 10 }, (v, i) => i).map((i) => (
              <ListItem nested key={i} sx={{ my: 1 }}>
                <Skeleton variant="rounded" height="40px" />
              </ListItem>
            ))}
      </List>
    </Box>
  );
};

interface SelectInterviewModeProps {
  placeId: string;
  selectedMode: InterviewLocation[];
  setSelectedMode: (mode: InterviewLocation[]) => void;
}

const SelectInterviewMode: React.FC<SelectInterviewModeProps> = ({
  placeId,
  selectedMode,
  setSelectedMode,
}) => {
  const [modes, setModes] = useState<InterviewLocation[]>([]);
  const { t } = useTranslation(["interview", "common"]);

  useEffect(() => {
    const loadModes = async (pid: string) => {
      const loadedBusiness = await BusinessService.getOne(pid);
      if (loadedBusiness && loadedBusiness.interviewPrefs) {
        const loadedPrefs = await InterviewPrefsService.getOne(
          loadedBusiness.interviewPrefs.id
        );
        setModes(loadedPrefs?.types || ["in-person", "video", "phone"]);
      } else {
        setModes(["in-person", "video", "phone"]);
      }
    };
    loadModes(placeId);
  }, [placeId]);

  return (
    <Stack sx={{ alignItems: "center", my: 2 }}>
      <Typography level="h6" sx={{ fontWeight: 600 }}>
        {t("interview:interview_options")}
      </Typography>
      <Typography level="body1" sx={{ mb: 1 }}>
        {t("interview:interview_options_desc")}
      </Typography>
      <InterviewLocationSelect
        single
        selected={selectedMode}
        setSelected={setSelectedMode}
        values={modes}
      />
    </Stack>
  );
};

interface SelectInterviewSlotModalProps {
  show: boolean;
  setShow: (open: boolean) => void;
  placeId: string;
  role: Item;
  applicationId: string;
  uid: string;
  update?: boolean;
  setUpdate?: (update: boolean) => void;
}

const SelectInterviewSlotModal: React.FC<SelectInterviewSlotModalProps> = ({
  show,
  setShow,
  placeId,
  role,
  applicationId,
  uid,
  update,
  setUpdate,
}) => {
  const { t, i18n } = useTranslation(["interview", "common"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const [selected, setSelected] = useState<Date | null>();
  const [selectedMode, setSelectedMode] = useState<InterviewLocation[]>([]);
  const [locationStr, setLocationStr] = useState<string>("");
  const { textConfirmInterview } = useText();

  const handleClose = () => setShow(false);
  const handleSubmit = () => {
    if (selected && selectedMode.length > 0) {
      if (applicationId) {
        createInterview(
          applicationId,
          selected as Date,
          selectedMode[0],
          uid,
          placeId,
          "unconfirmed"
        );
        if (setUpdate) setUpdate(!update);
      }
    }
    setPage("reciept");
    textConfirmInterview(
      applicationId,
      dayjs(selected).locale(i18n.language).format("dddd, MMM D, h:mm a")
    );
  };

  const [page, setPage] = useState<"select" | "confirm" | "mode" | "reciept">(
    "select"
  );

  useEffect(() => {
    if (selected) {
      setPage("mode");
    }
  }, [selected]);

  useEffect(() => {
    if (selectedMode.length > 0) {
      setPage("confirm");
    }
  }, [selectedMode]);

  useEffect(() => {
    if (page === "reciept") {
      if (selectedMode[0] === "in-person") {
        if (placeId) {
          BusinessService.getOne(placeId).then((business) => {
            setLocationStr(
              t("interview:in_person_location", {
                address: business?.address,
              }) ?? ""
            );
          });
        }
      } else if (selectedMode[0] === "video") {
        if (placeId) {
          BusinessService.getOne(placeId).then((business) => {
            InterviewPrefsService.getResolvedReference(
              business?.interviewPrefs!
            ).then((prefs) => {
              setLocationStr(
                t("interview:video_location", {
                  link: prefs?.video,
                }) ?? ""
              );
            });
          });
        }
        setLocationStr(t("interview:video_location") ?? "");
      } else if (selectedMode[0] === "phone") {
        setLocationStr(t("interview:phone_location") ?? "");
      }
    }
  }, [page]);

  return (
    <Modal
      open={show}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 4,
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },

          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="cardHeader">{t("schedule_interview")}</Typography>
          <IconButton onClick={() => setShow(false)}>
            <CloseRounded />
          </IconButton>
        </Box>

        <Divider sx={DIVIDER_HEADER_SX} />

        {page === "select" && (
          <SelectInterviewSlot setSelected={setSelected} placeId={placeId} />
        )}

        {page === "mode" && (
          <SelectInterviewMode
            placeId={placeId}
            selectedMode={selectedMode}
            setSelectedMode={setSelectedMode}
          />
        )}

        {page === "confirm" && (
          <Stack>
            <Box
              sx={{
                px: "10vw",
                height: "60vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Stack>
                <Typography level="h5" textAlign="center">
                  {t("confirm_interview", {
                    mode: t(`interview:${selectedMode[0]}`),
                    role: getRoleNameFromRole(role), //TODO: get role
                  })}
                </Typography>

                <Typography level="h3" textAlign="center">
                  {dayjs(selected)
                    .locale(i18n.language)
                    .format("dddd, MMM D, h:mm a")}
                </Typography>
              </Stack>

              <Stack spacing={1}>
                <Button
                  size="lg"
                  // sx={{ fontSize: "1.25rem" }}
                  onClick={handleSubmit}
                >
                  {t("schedule_it")}
                </Button>
                <Button
                  variant="plain"
                  onClick={() => {
                    setPage("select");
                    setSelectedMode([]);
                  }}
                >
                  {t("new_time")}
                </Button>
              </Stack>
            </Box>
          </Stack>
        )}

        {page === "reciept" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: 3,
              py: 5,
            }}
          >
            <CheckCircleOutlineRounded
              sx={{ fontSize: "4rem", color: "primary.main" }}
            />
            <Typography level="h3" textAlign={"center"}>
              {t("interview_scheduled")}
            </Typography>

            <Typography
              level="h5"
              sx={{ color: "text.secondary" }}
              textAlign={"center"}
            >
              {t("interview_scheduled_sub", {
                time: dayjs(selected)
                  .locale(i18n.language)
                  .format("dddd, MMM D, h:mm A"),
              })}
            </Typography>
            <Chip variant="outlined" color="neutral" sx={{ maxWidth: "100%" }}>
              {locationStr}
            </Chip>

            <Button onClick={() => setShow(false)}>
              {t("done", { ns: "common" })}
            </Button>
          </Box>
        )}
      </Card>
    </Modal>
  );
};

export { SelectInterviewSlot, SelectInterviewSlotModal };
