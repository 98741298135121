import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Button,
  Chip,
  Stack,
  Box,
  Divider,
  IconButton,
  FormControl,
  Modal,
} from "@mui/joy";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { MapContext } from "../Context/MapContext";
import { useTranslation } from "react-i18next";
import GPTContext from "./GPTContext";

import InputBox from "./InputBox";
import useCycle from "./useCycle";
import "../../src/animations.css";
import Suggestions from "./Suggestions";
import Messages from "./Messages";
import { ClickAwayListener } from "@mui/material";

const GPTBox: React.FC = () => {
  const { t } = useTranslation(["gpt"]);

  const context = useContext(GPTContext);

  const [currMessage, setCurrMessage] = useState<string>("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    //open if focused on input or there are messages
    if (isFocused || (context && context.messages.length > 0)) {
      context?.setOpen(true);
    }
  }, [isFocused]);

  return (
    <Box>
      <Stack
        sx={{
          borderRadius: 1,
          border: "1px solid #DDD",
          width: "100%",
        }}
      >
        {/*
        <Stack
          sx={{
            overflowY: "scroll",
            pt: context?.messages.length === 0 && context?.open ? 1 : 0,
            "&::-webkit-scrollbar": { display: "none" },
            maxHeight: context?.open ? "25vh" : "0vh",
            transition: "max-height 0.5s ease-in-out",
          }}
        >
          <Messages />
          <Suggestions />
        </Stack>
        {context?.open && <Divider sx={{ opacity: 1 }} />}*/}

        <InputBox isFocused={isFocused} setIsFocused={setIsFocused} />
      </Stack>
    </Box>
  );
};

export default GPTBox;
