import * as React from "react";
import { Listings } from "../../Services/Db/types";
import ListItems from "../../Components/Common/ListItems";
import { Stack, Divider, Typography } from "@mui/joy";
import useKeyToDict from "../../Utils/useKeyToDict";
import { useTranslation } from "react-i18next";

interface ListingDetailsProps {
  listing: Listings;
}

const ListingDetails: React.FC<ListingDetailsProps> = ({ listing }) => {
  const { t } = useTranslation("common");

  return (
    <Stack sx={{}} spacing={2} divider={<Divider />}>
      {listing.perks.length > 0 && (
        <ListItems
          titleKey="perks"
          ns="common"
          items={listing.perks}
          parentKey="perks"
        />
      )}
      {listing.schedule.length > 0 && (
        <ListItems
          titleKey="schedule"
          ns="common"
          items={listing.schedule}
          parentKey="schedules"
        />
      )}

      {listing.skills.length > 0 && (
        <ListItems
          titleKey="requirements"
          ns="common"
          items={listing.skills}
          parentKey="skills"
        />
      )}
      {listing.description && listing.description !== "" && (
        <React.Fragment>
          <Typography level="h6" fontWeight="bold" sx={{ mb: 1 }}>
            {t("description")}
          </Typography>
          <Typography level="body1">{listing.description}</Typography>
        </React.Fragment>
      )}
    </Stack>
  );
};

export default ListingDetails;
