import { DIVIDER_HEADER_SX } from "../../Utils/Constants";
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/joy";
import { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import LocationCheckbox from "./LocationCheckbox";
import { useBusinessAuth } from "../../Context/AuthContext";
import { FullBusinessService } from "../../Services/Db/business";
import {
  AddAlertRounded,
  AddBox,
  AddRounded,
  CloseRounded,
  ErrorRounded,
  HelpCenterRounded,
} from "@mui/icons-material";
import useText from "../../Utils/useText";
import InlineBusinessSelectMap from "../Maps/InlineBusinessSelectMap";
import AddLocationModal from "./AddLocationModal";

interface LocationListProps {
  locations: string[];
  setIsSetup?: (b: boolean) => void;
  unverified: boolean;
  canAdd?: boolean;
  setLocations?: (l: string[]) => void;
}

export const LocationList: React.FC<LocationListProps> = ({
  locations,
  setIsSetup,
  unverified,
  canAdd,
  setLocations,
}) => {
  const { t } = useTranslation(["business", "common"]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([
    ...locations,
  ]);
  const { business } = useBusinessAuth();
  const { textAdminsAlert } = useText();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [newPlaceId, setNewPlaceId] = useState<string>();

  async function handleSubmit() {
    if (unverified) {
      await FullBusinessService.createBusinessLocations(
        business?.email!,
        selectedLocations,
        locations[0]
      );
      textAdminsAlert(
        `Unverified business with email ${business?.email} and PID ${locations[0]} just signed up!`
      );
      console.log("texting admins");
    } else {
      await FullBusinessService.createBusinessLocations(
        business?.email!,
        selectedLocations
      );
    }

    if (setIsSetup) setIsSetup(true);
  }

  return (
    <Fragment>
      {unverified && (
        <Alert color="warning">
          <Stack spacing={1}>
            <Typography startDecorator={<ErrorRounded />} level="h4">
              {t("unverified_location")}
            </Typography>
            <Typography level="body1">
              {t("unverified_location_desc")}
            </Typography>
          </Stack>
        </Alert>
      )}
      <Typography level="body1" sx={{ mt: 2 }}>
        {t("confirm_locations_description")}
      </Typography>
      {locations.map((location, i) => (
        <Box sx={{ mt: 2 }} key={i}>
          <LocationCheckbox
            placeId={location}
            selected={selectedLocations}
            setSelected={setSelectedLocations}
          />
          {i !== locations.length - 1 && <Divider sx={DIVIDER_HEADER_SX} />}
        </Box>
      ))}

      {canAdd && (
        <Fragment>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.preventDefault();
              setOpenAddModal(true);
            }}
          >
            <Checkbox
              size="lg"
              sx={{ margin: 2 }}
              // prevent the checkbox from being checked
              checked={false}
              overlay
              uncheckedIcon={<AddRounded />}
            />
            <Typography level="h6">{t("add_location")}</Typography>
          </Box>
        </Fragment>
      )}

      {setIsSetup && (
        <Button
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit}
          disabled={selectedLocations.length === 0 && locations.length !== 0}
        >
          {t("common:confirm")}
        </Button>
      )}

      <AddLocationModal
        open={openAddModal}
        setOpen={setOpenAddModal}
        placeId={newPlaceId}
        setPlaceId={setNewPlaceId}
        onComplete={(pid?: string) => {
          if (pid) {
            setLocations!([...locations, pid]);
            setSelectedLocations([...selectedLocations, pid]);
          }
        }}
      />
    </Fragment>
  );
};
