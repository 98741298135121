import {
  Add,
  Delete,
  EditRounded,
  KeyboardArrowDown,
  Person,
  PersonRounded,
  PrintRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";
import { Collapse } from "@mui/material";
import { sendEmailVerification } from "firebase/auth";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
} from "src/Utils/Constants";
import useConfirm from "src/Utils/useConfirm";
import AddLocationModal from "./AddLocationModal";
import BusinessAccountInfo from "./BusinessAccountInfo";
import BusinessLocationsInfo from "./BusinessLocationsInfo";
import EditEmail from "./EditEmail";
import LocationListItem from "./LocationListItem";

const BusinessProfile = () => {
  const { business } = useBusinessAuth();
  const { t } = useTranslation("business");

  const [expand, setExpand] = useState(true);

  const navigate = useNavigate();

  return (
    <Stack spacing={1}>
      <Button
        variant="soft"
        startDecorator={<PrintRounded />}
        onClick={() => navigate(APP_ROUTES.SIGN_MAKER)}
      >
        {t("sign_maker.print_sign_start_hiring")}
      </Button>
      <Card>
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          onClick={() => setExpand(!expand)}
        >
          <Typography startDecorator={<PersonRounded />} level="cardHeader">
            {t("profile")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
            }}
          >
            {!business?.emailVerified && (
              <Chip
                variant="soft"
                sx={{ fontWeight: "bold" }}
                color="warning"
                size="sm"
              >
                {t("email_unverified").toUpperCase()}
              </Chip>
            )}
            <IconButton variant="plain">
              <KeyboardArrowDown
                fontSize="medium"
                sx={{
                  transition: "0.3s",
                  zIndex: 2,
                  pointerEvents: "none",
                  transform: expand ? "rotate(180deg)" : "rotate(360deg)",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Collapse in={expand}>
          <Stack spacing={2} padding={2}>
            <BusinessLocationsInfo />
            <BusinessAccountInfo />
          </Stack>
        </Collapse>
      </Card>
    </Stack>
  );
};

export default BusinessProfile;
