import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Typography,
  ListSubheader,
  Divider,
  Chip,
  Checkbox,
  Stack,
  Card,
  List,
  Button,
  ListItem,
  IconButton,
  CardOverflow,
  ListItemContent,
  ListItemDecorator,
  ListItemButton,
  ListDivider,
  ChipDelete,
} from "@mui/joy";
import useKeyToDict from "../../Utils/useKeyToDict";
import {
  ArrowRightRounded,
  CheckRounded,
  ClearAllRounded,
  ClearRounded,
  Done,
  HighlightOffRounded,
  KeyboardArrowDown,
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
  KeyboardBackspace,
  KeyboardBackspaceRounded,
} from "@mui/icons-material";
import { Item } from "src/Services/Db/types";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "../../../src/animations.css";

interface NewItemSelectorProps {
  selectMultiple?: boolean;
  title?: string | null;

  selectedItems: any[] | null;
  setSelectedItems: (value: any[] | null) => void;

  dictKey: string;
  dictHeaderKey: string;

  isRequired?: boolean;
}

const NewItemSelector: React.FC<NewItemSelectorProps> = ({
  selectMultiple,
  title,
  selectedItems,
  setSelectedItems,
  dictKey,
  dictHeaderKey,
  isRequired,
}) => {
  const { keyToObjArray, keyToValueInList, getItemValueFromItemAndHeader } =
    useKeyToDict("lists");

  const item_dict = keyToObjArray(dictKey);
  const categories = item_dict.map((item) => item[0]);
  const [openCategory, setOpenCategory] = useState<string | null>(null);

  const select = (item: Item) => {
    console.log(item);
    if (selectMultiple) {
      //just for ts
      if (selectedItems instanceof Array) {
        //check if the item is already selected
        const selectedMinusNew = selectedItems.filter(
          (x) => !(x.item === item && x.category === item.category) //if the item is already selected, remove it
        );

        //if item is already selected, remove it
        if (selectedMinusNew.length !== selectedItems.length) {
          setSelectedItems(selectedMinusNew);
        } else {
          //if it isn't, add it
          setSelectedItems([...selectedItems, item]);
        }
      }
    } else {
      setSelectedItems([item]);
    }
  };

  const items = !!openCategory ? keyToObjArray(dictKey, openCategory) : [];

  return (
    <Stack sx={{ overflowX: "hidden", mb: 2 }}>
      {title && <Typography level="selectLabel">{title}</Typography>}

      <Card sx={{ borderRadius: 1.25 }}>
        <CardOverflow>
          {openCategory ? (
            <Stack>
              <Box>
                <Button
                  color="neutral"
                  variant="plain"
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    px: 0.25,
                    textAlign: "left",
                    my: 1,
                    ml: -1,
                  }}
                  onClick={() => {
                    setOpenCategory(null);
                  }}
                  startDecorator={<KeyboardArrowLeftRounded />}
                >
                  {keyToValueInList(openCategory, dictHeaderKey)}
                </Button>
              </Box>

              <ListDivider />

              <List sx={{}}>
                {items.map((item: any) => (
                  <Fragment>
                    <ListItem
                      key={item.item}
                      sx={{
                        // py: 0,
                        // my: 0,
                        mx: -2,
                      }}
                    >
                      <ListItemButton
                        sx={{
                          py: 2,
                          my: -1.5,
                          borderBottomLeftRadius:
                            items.indexOf(item) === items.length - 1 ? 12 : 0,
                          borderBottomRightRadius:
                            items.indexOf(item) === items.length - 1 ? 12 : 0,
                          "&:hover": {
                            bgcolor: selectedItems?.some((x: any) => {
                              return (
                                x.item === item[0] &&
                                x.category === openCategory
                              );
                            })
                              ? "primary.solidBg"
                              : "auto",
                          },
                          bgcolor: selectedItems?.some((x: any) => {
                            return (
                              x.item === item[0] && x.category === openCategory
                            );
                          })
                            ? "primary.solidBg"
                            : "auto",
                        }}
                        onClick={() =>
                          select({
                            item: item[0],
                            category: openCategory,
                          })
                        }
                      >
                        {selectedItems?.some((x: any) => {
                          return (
                            x.item === item[0] && x.category === openCategory
                          );
                        }) && (
                          <ListItemDecorator>
                            <CheckRounded sx={{ color: "common.white" }} />
                          </ListItemDecorator>
                        )}
                        <ListItemContent
                          sx={
                            selectedItems?.some((x: any) => {
                              return (
                                x.item === item[0] &&
                                x.category === openCategory
                              );
                            })
                              ? {
                                  color: "common.white",
                                  fontWeight: "bold",
                                }
                              : { color: "text.primary" }
                          }
                        >
                          {item[1]}
                        </ListItemContent>
                      </ListItemButton>
                    </ListItem>
                    {items.indexOf(item) !== items.length - 1 && (
                      <ListDivider />
                    )}
                  </Fragment>
                ))}
              </List>
            </Stack>
          ) : (
            <Fragment>
              <Box
                sx={{
                  pt: 2,
                  pb: 0.5,
                  display:
                    selectedItems && selectedItems.length > 0 ? "flex" : "none",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", flexWrap: "wrap", gap: 1, ml: -0.25 }}
                >
                  {selectedItems?.map((x: Item) => {
                    return (
                      <Chip
                        endDecorator={
                          isRequired ? null : (
                            <ChipDelete
                              onDelete={() =>
                                setSelectedItems(
                                  selectedItems?.filter(
                                    (y) => y.item !== x.item
                                  )
                                )
                              }
                            />
                          )
                        }
                      >
                        {getItemValueFromItemAndHeader(x, dictKey)}
                      </Chip>
                    );
                  })}
                </Box>
                {/* {!isRequired && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      console.log("clear");
                      setSelectedItems([]);
                      console.log(selectedItems);
                    }}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    <ClearRounded />
                  </IconButton>
                )} */}
              </Box>

              <List>
                {categories.map((category, i) => (
                  <Fragment>
                    {i === 0 && selectedItems && selectedItems.length > 0 && (
                      <ListDivider />
                    )}
                    <ListItem
                      key={category}
                      sx={{
                        // py: 0,
                        // my: 0,
                        mx: -2,
                      }}
                      onClick={() => setOpenCategory(category)}
                    >
                      <ListItemButton
                        sx={{
                          py: 2,
                          my: -1.5,
                          borderTopLeftRadius:
                            i === 0 &&
                            selectedItems &&
                            selectedItems.length === 0
                              ? 12
                              : 0,
                          borderTopRightRadius:
                            i === 0 &&
                            selectedItems &&
                            selectedItems.length === 0
                              ? 12
                              : 0,
                          borderBottomLeftRadius:
                            i === categories.length - 1 ? 12 : 0,
                          borderBottomRightRadius:
                            i === categories.length - 1 ? 12 : 0,
                        }}
                      >
                        <ListItemContent>
                          {keyToValueInList(category, dictHeaderKey)}
                        </ListItemContent>
                        <ListItemDecorator sx={{ mr: -2 }}>
                          <KeyboardArrowRightRounded />
                        </ListItemDecorator>
                      </ListItemButton>
                    </ListItem>
                    {categories.indexOf(category) !== categories.length - 1 && (
                      <ListDivider />
                    )}
                  </Fragment>
                ))}
              </List>
            </Fragment>
          )}
        </CardOverflow>
      </Card>
    </Stack>
  );
};

export default NewItemSelector;
