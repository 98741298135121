import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Stack,
  Typography,
  Grid,
  IconButton,
  Card,
  Modal,
  Chip,
} from "@mui/joy";
import {
  AccountCircleRounded,
  AttachMoneyOutlined,
  Tune,
  FavoriteRounded,
  GroupsRounded,
} from "@mui/icons-material";
import AppBarContext from "../Context/AppBarContext";
import LogInModal from "../Components/Common/LogInModal";
import { APP_ROUTES, USER_TYPES } from "../Utils/Constants";
import { WageAlert, BusinessAlert } from "./Alerts";
import { useTranslation } from "react-i18next";
import HeaderLocationSearch from "../Components/Maps/HeaderLocationSearch";
import { NewUserFlow } from "../Pages_JobSeeker/NewUserFlow";
import WagePopover from "./WagePopover";
import FilterPopover from "./FilterPopover";
import Slide from "@mui/material/Slide";
import { useAuth, useBusinessAuth } from "../Context/AuthContext";
import { MapContext } from "../Context/MapContext";
import { Item } from "../Services/Db/types";
import { FullUserService } from "../Services/Db/user";
import Trio from "./Trio";
import GPTBox from "../GPT/GPTBox";
import GPTContext from "src/GPT/GPTContext";
import { GPTMessage } from "src/Services/Db/types";
import Recommendations from "src/GPT/Recommendations";
import { ClickAwayListener, Box } from "@mui/material";
import theme from "src/Utils/Theme";

export default function GPTAppBar() {
  const navigate = useNavigate();
  const context = React.useContext(AppBarContext);
  const mapContext = React.useContext(MapContext);

  const { t } = useTranslation(["header", "common"]);

  const { user } = useAuth();
  const { business } = useBusinessAuth();

  const alertOffset = context?.showAlerts.reduce((acc, alert) => {
    const a = document.getElementById("#" + alert) || null;

    if (a) {
      return acc + a.offsetHeight;
    }
    return acc;
  }, 0);

  useEffect(() => {
    context?.setAlertOffset(alertOffset || 0);
  }, [alertOffset]);

  const gptContext = React.useContext(GPTContext);

  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<GPTMessage[]>([]);
  const [suggestions, setSuggestions] = useState<string[]>([
    "What are some great paying jobs in my area?",
    "I want an exciting new job",
    "I need a job that can accommodate my schedule",
  ]);
  const [loading, setLoading] = useState<boolean>(false);
  const [recommendations, setRecommendations] = useState<string[]>([]);
  const [showRecommendations, setShowRecommendations] = useState<boolean>(true);
  const [noResults, setNoResults] = useState<boolean>(false);

  function handleClickAway() {
    if (open) {
      setOpen(false);
    }
  }

  return (
    <GPTContext.Provider
      value={{
        messages,
        setMessages,
        suggestions,
        setSuggestions,
        open,
        setOpen,
        loading,
        setLoading,
        recommendations,
        setRecommendations,
        showRecommendations,
        setShowRecommendations,
        noResults,
        setNoResults,
      }}
    >
      <Box id="search-app-bar">
        <Stack>
          {context?.showAlerts!.includes("business") && <BusinessAlert />}
          {context?.showAlerts!.includes("addWage") && <WageAlert />}
        </Stack>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Slide
            direction="down"
            in={context?.showHeader}
            appear={false}
            // mountOnEnter
            // unmountOnExit
            timeout={400}
          >
            <Box
              component={Grid}
              container
              id="#search-app-bar"
              spacing={1}
              sx={{
                position: "fixed",
                left: { xs: "4vw", md: "5vw" },
                zIndex: 1000,
                top: context?.alertOffset ? context?.alertOffset + 10 : "3vh",
                width: { xs: "94vw", md: "90vw" },
                backgroundColor: "common.white",
                borderRadius: 1.75,
                border: "1px solid #D8D8DE",
                boxShadow: "0px 2px 10px 1px rgba(0,0,0,0.1)",
                display: "flex",
                px: 0.5,
                pl: 1,
                height: "auto",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: gptContext?.open ? "25vh" : "auto", //for mobile
                transition: "maxHeight 0.3s ease-in-out",
              }}
            >
              <Grid xs={6} md={2}>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    cursor: "pointer",
                    color: "header.main",
                    alignSelf: "center",
                    fontSize: "1.4rem",
                    fontStyle: "italic",
                  }}
                  onClick={() => navigate(APP_ROUTES.HOME)}
                >
                  Hourlead
                </Typography>
              </Grid>

              <Grid
                xs={6}
                md={2}
                display={{ xs: "flex", md: "none" }}
                sx={{ justifyContent: "flex-end" }}
              >
                <Trio signedIn={user !== undefined} />
              </Grid>

              <Grid
                md={8}
                sx={{
                  width: "100%",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Box>
                  <GPTBox />
                </Box>
              </Grid>

              <Grid
                xs={12}
                sx={{
                  display: { xs: "block", md: "none" },
                  mt: -0.75,
                  mb: 0.25,
                }}
              >
                <Box>
                  <GPTBox />
                </Box>
              </Grid>

              <Grid
                md={2}
                display={{ xs: "none", md: "flex" }}
                sx={{ justifyContent: "flex-end" }}
              >
                <Trio signedIn={user !== undefined} />
              </Grid>
            </Box>
          </Slide>
        </ClickAwayListener>
      </Box>
      <Recommendations />
    </GPTContext.Provider>
  );
}
