import {
  CheckRounded,
  CloseRounded,
  EditRounded,
  EmailRounded,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Input,
  Stack,
  Typography,
  Button,
  Chip,
} from "@mui/joy";
import { Skeleton } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import useConfirm from "src/Utils/useConfirm";
import { sendEmailVerification } from "firebase/auth";
import ConfirmInput from "../Inputs/ConfirmInput";
import { useTranslation } from "react-i18next";

const EditEmail = () => {
  const { business } = useBusinessAuth();
  const confirm = useConfirm();
  const [email, setEmail] = useState(business?.email);

  function handleComplete(e: string) {
    if (business?.email) {
      confirm(
        "business",
        "confirm_email_change",
        "confirm_email_change_desc",
        "confirm",
        () => {
          FullBusinessService.updateBusinessEmail(business?.email!, e);
        },
        [e],
        "business"
      );
    }
  }

  useEffect(() => {
    if (business?.email) {
      setEmail(business.email);
    }
  }, [business]);

  const [verificationSent, setVerificationSent] = useState(false);

  function resendVerificationEmail() {
    if (business) {
      sendEmailVerification(business).then(() => setVerificationSent(true));
    }
  }

  const { t } = useTranslation("business");

  if (email) {
    return (
      <Stack spacing={1}>
        <Typography level="h6">{t("common:email")}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ConfirmInput
            content={email}
            setContent={setEmail}
            onComplete={handleComplete}
          />
          {business?.emailVerified ? (
            <Box>
              <Chip
                color="success"
                size="sm"
                variant="soft"
                sx={{ fontWeight: "bold" }}
              >
                {t("verified").toUpperCase()}
              </Chip>
            </Box>
          ) : (
            <Button
              color="warning"
              size="sm"
              variant="soft"
              onClick={resendVerificationEmail}
              startDecorator={<EmailRounded />}
            >
              {verificationSent
                ? t("verification_sent")
                : t("resend_verification")}
            </Button>
          )}
        </Box>
      </Stack>
    );
  } else {
    return <Skeleton variant="text" width={200} />;
  }
};
export default EditEmail;
