import { Search } from "@mui/icons-material";
import { useTheme, Box, Stack } from "@mui/joy";
import {
  DIVIDER_HEADER_SX_MY,
  DIVIDER_HEADER_SX,
  DEFAULT_MAP_PARAMS,
} from "../../Utils/Constants";
import * as React from "react";

import Map from "./Map";
import Marker from "./LocationMarkerBasic";
import Autocomplete from "./Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";
import { usePlaceDetails } from "./PlaceDetails";
import { Typography } from "@mui/material";
import { SearchBox } from "./HeaderLocationSearch";

interface InlineBusinessSelectProps {
  setPlaceId: (p: string) => void;
  placeId?: string;
}

const InlineBusinessSelectMap: React.FC<InlineBusinessSelectProps> = ({
  setPlaceId,
  placeId,
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("common");

  const [bounds, setBounds] = React.useState<google.maps.LatLngBounds>();
  const [center, setCenter] = React.useState<google.maps.LatLng>(
    new google.maps.LatLng(DEFAULT_MAP_PARAMS.lat, DEFAULT_MAP_PARAMS.lng)
  );
  const [zoom, setZoom] = React.useState<number>(DEFAULT_MAP_PARAMS.zoom);

  const placeDetails = usePlaceDetails(placeId, [
    "geometry",
    "formatted_address",
    "name",
  ]);

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
        }}
      >
        <SearchBox
          sx={{
            "&:hover": {
              backgroundColor: "grey.50",
            },
            height: "2.5rem",
            padding: 1,
            mb: -1,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Search sx={{ color: "text.secondary", pr: 0.5 }} />
          <Autocomplete
            value={
              placeDetails
                ? placeDetails?.name + ", " + placeDetails?.formatted_address
                : undefined
            }
            types={["establishment"]}
            setBounds={setBounds}
            setZoom={setZoom}
            setCenter={setCenter}
            setPlaceId={setPlaceId}
          />
        </SearchBox>
      </Box>

      <Box
        sx={{
          height: 300,
          borderRadius: 2,
        }}
      >
        <Map
          bounds={bounds || placeDetails?.geometry?.viewport}
          disableDefaultUI={true}
          disableDoubleClickZoom={true}
          draggable={false}
          scrollwheel={false}
          panControl={false}
          inline
        >
          {placeDetails && (
            <Marker position={placeDetails.geometry?.location || center} />
          )}
        </Map>
      </Box>
    </Stack>
  );
};

export default InlineBusinessSelectMap;
