import { Box } from "@mui/joy";
import { useEffect, useState } from "react";

const LoadingCircle = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (index === 3) {
      setIndex(0);
    }
    if (index < 3) {
      timeout = setTimeout(() => setIndex(index + 1), 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [index]);

  // return 3 circles within each other
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 30,
        height: 30,
        maxWidth: index === 2 ? 0 : 30,
        maxHeight: index === 2 ? 0 : 30,
        borderRadius: "50%",
        bgcolor: "primary.main",
        transition: "all 0.5s ease-out",
        opacity: index === 2 ? 1 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 30,
          height: 30,
          borderRadius: "50%",
          bgcolor: "primary.main",
          transition: "all 0.5s ease-out",
          opacity: index === 1 ? 1 : 0,
          maxWidth: index === 1 ? 0 : 30,
          maxHeight: index === 1 ? 0 : 30,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 30,
            height: 30,
            maxWidth: index === 0 ? 0 : 30,
            maxHeight: index === 0 ? 0 : 30,
            borderRadius: "50%",
            bgcolor: "primary.main",
            opacity: index === 0 ? 1 : 0,
            transition: "all 0.5s ease-out",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default LoadingCircle;
