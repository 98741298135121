import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
} from "@mui/joy";
import React, { useEffect, useContext, useState, Fragment } from "react";
import AppBarContext from "../Context/AppBarContext";
import {
  DIVIDER_HEADER_SX,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";

import ExperienceItem from "../Components/Common/ExperienceItem";

import { useTranslation } from "react-i18next";
import {
  Add,
  CheckCircleRounded,
  Edit,
  HighlightOffRounded,
  NewReleasesRounded,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";

import { SectionProps } from "./sectionProps";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";

const ExperienceSection: React.FC<SectionProps> = ({
  setModalOpen,
  setSelectedId,
  experiences,
  none, //track if user has selected they have no experience/education
}) => {
  const { t } = useTranslation(["create_profile", "lists"]);

  const Experiences = () => {
    if (experiences === undefined) return null;

    //DISPLAY EXPERIENCES
    if (experiences?.length > 0) {
      return (
        <Stack divider={<Divider sx={DIVIDER_HEADER_SX_MY} />}>
          {experiences.map((exp, index) => (
            <Box
              onClick={() => {
                setModalOpen(true);
                if (exp.id && setSelectedId) setSelectedId(exp.id);
              }}
              sx={{ cursor: "pointer" }}
              key={index}
            >
              <ExperienceItem
                role={exp.role!}
                businessRef={exp.businessRef}
                job_type={exp.employmentType}
                start_date={dayjs(exp.startDate.toDate())}
                end_date={exp.endDate ? dayjs(exp.endDate.toDate()) : undefined}
                reason_for_leaving={exp.departureReason || undefined}
              />
            </Box>
          ))}
        </Stack>
      );
    }
    //USER HAS SELECTED THEY HAVE NO EXPERIENCE
    else if (none) {
      return (
        <Alert
          color="success"
          size="lg"
          endDecorator={
            <Button
              variant="solid"
              color="success"
              onClick={() => {
                if (setSelectedId) setSelectedId(null);
                setModalOpen(true);
              }}
            >
              {t("add_experience", { ns: ["create_profile"] })}
            </Button>
          }
        >
          {t("experience_builder:no_experience")}
        </Alert>
      );
    }
    //ADD EXPERIENCE
    else {
      return (
        <Button
          variant="outlined"
          color="neutral"
          onClick={() => {
            if (setSelectedId) setSelectedId(null);
            setModalOpen(true);
            logEvent(analytics, "add_experience_opened", {});
          }}
        >
          {t("add_experience", { ns: ["create_profile"] })}
        </Button>
      );
    }
  };

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={
            (experiences && experiences.length > 0) || none ? (
              <CheckCircleRounded sx={{ color: "success.solidBg" }} />
            ) : (
              <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
            )
          }
          level="h4"
          sx={{ fontWeight: 600 }}
        >
          {t("experience", { ns: ["create_profile"] })}
        </Typography>
        <IconButton
          onClick={() => {
            setModalOpen(true);
            if (setSelectedId) setSelectedId(null);
            logEvent(analytics, "add_experience_opened", {});
          }}
        >
          <Add />
        </IconButton>
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />
      <Stack spacing={2} divider={<Divider />} sx={{ mt: 2 }}>
        <Experiences />
      </Stack>
    </Card>
  );
};

export default ExperienceSection;
