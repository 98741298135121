import { Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../Utils/Constants";

interface TermsAndPrivacySubProps {
  noWageDisclaimer?: boolean;
  email?: boolean;
}

const TermsAndPrivacySub: React.FC<TermsAndPrivacySubProps> = ({
  noWageDisclaimer,
  email,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation(["terms", "common"]);

  return (
    <Typography level="body4">
      {email ? t("terms:by_signing_up_email") : t("terms:by_signing_up")}{" "}
      <Typography
        onClick={() => navigate(APP_ROUTES.TERMS_OF_SERVICE)}
        component={"a"}
        sx={{ cursor: "pointer" }}
      >
        {t("terms:terms_of_service")}
      </Typography>{" "}
      {t("common:and")}{" "}
      <Typography
        onClick={() => navigate(APP_ROUTES.PRIVACY_POLICY)}
        component={"a"}
        sx={{ cursor: "pointer" }}
      >
        {t("terms:privacy_policy")}
      </Typography>
      .
      {!noWageDisclaimer && (
        <Typography>
          {t("terms:wages_disclaimer")}
          <Typography
            onClick={() => navigate(APP_ROUTES.WAGE_DISCLAIMER)}
            component={"a"}
            sx={{ cursor: "pointer" }}
          >
            {t("terms:read_more_about_wages")}
          </Typography>
        </Typography>
      )}
    </Typography>
  );
};

export default TermsAndPrivacySub;
