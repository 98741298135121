import {
  Button,
  FormControl,
  FormHelperText,
  Input,
  LinearProgress,
  Stack,
} from "@mui/joy";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import useToast from "src/Utils/useToast";
import { auth } from "src/firebase";

const ForgotPassword = ({
  setForgotPassword,
}: {
  setForgotPassword: (b: boolean) => void;
}) => {
  const [email, setEmail] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [sent, setSent] = React.useState<boolean>(false);

  const toast = useToast();

  function handleSubmit(e: React.MouseEvent<HTMLAnchorElement>) {
    setLoading(true);
    sendPasswordResetEmail(auth, email, {
      url: "https://www.hourlead.com/",
    })
      .then(() => {
        toast("common", "reset_password_email_sent", "", () => {});
        setSent(true);
      })
      .catch((error) => {
        toast("user_flow", error.code, "", () => {});
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const { t } = useTranslation(["common"]);
  return (
    <Stack spacing={2}>
      <FormControl required>
        <Input
          placeholder={t("common:email") || ""}
          type="email"
          onChange={(e) => setEmail(e.target.value.toLowerCase())}
        />
        <FormHelperText sx={{ ml: 1, color: "text.secondayr" }}>
          {t("common:forgot_password_desc")}
        </FormHelperText>
      </FormControl>

      <Button
        type="submit"
        fullWidth
        variant="outlined"
        onClick={(e) => handleSubmit(e)}
      >
        {loading ? (
          <LinearProgress />
        ) : sent ? (
          t("common:reset_email_sent")
        ) : (
          t("common:reset_password")
        )}
      </Button>
      <Button
        type="submit"
        fullWidth
        variant="plain"
        size="sm"
        sx={{
          my: 0,
          color: "text.secondary",
          textDecoration: "underline",
        }}
        disabled={sent}
        onClick={(e) => setForgotPassword(false)}
      >
        {t("common:sign_in")}
      </Button>
    </Stack>
  );
};

export default ForgotPassword;
