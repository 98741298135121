import { Button, Stack, Typography } from "@mui/joy";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "src/Context/AuthContext";
import BusinessUserManagement from "./BusinessUserManagement";
import EditEmail from "./EditEmail";
import EditGroupCode from "./EditGroupCode";

import { Amplify, API } from "aws-amplify";
import awsExports from "../../../src/aws-exports";
import BusinessShareGroup from "./BusinessShareGroup";
Amplify.configure(awsExports);

let apiName = "server";

export const BusinessAccountInfo = () => {
  const { t } = useTranslation("business");
  const { business } = useBusinessAuth();

  return (
    <Stack spacing={2}>
      <Typography sx={{ ml: -1 }} level="h5">
        {t("account_info")}
      </Typography>

      <BusinessUserManagement />

      <BusinessShareGroup />

      <EditEmail />
    </Stack>
  );
};
export default BusinessAccountInfo;
