import { Stack, Typography, Box } from "@mui/joy";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import useKeyToDict from "../../Utils/useKeyToDict";

interface EducationItemProps {
  studied: string | null;
  school_type: string;
  start_date: Dayjs;
  end_date: Dayjs | null;
  credits?: number; //no credits listed means graduated
}

const EducationItem: React.FC<EducationItemProps> = ({
  studied,
  school_type,
  start_date,
  end_date,
  credits,
}) => {
  const { t } = useTranslation(["education_builder", "common"]);
  const { keyToValueInList } = useKeyToDict("lists");

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Typography level="h5">
          {keyToValueInList(school_type, "education_types")}
        </Typography>
        <Typography level="body2">{studied}</Typography>
        <Box sx={{ display: { xs: "inline", md: "none" } }}>
          <Typography level="body2">
            {start_date.format("MMM YYYY")} -{" "}
            {end_date
              ? end_date.format("MMM YYYY")
              : t("present", { ns: ["common"] })}
          </Typography>
          <Typography level="body2">
            {credits
              ? t("education_builder:credits_count", { credits: credits })
              : t("education_builder:graduated")}
          </Typography>
        </Box>
      </Stack>
      <Stack
        alignItems={"flex-end"}
        sx={{ display: { xs: "none", md: "inline" } }}
      >
        <Typography level="body2" textAlign="right">
          {start_date.format("MMM YYYY")} -{" "}
          {end_date
            ? end_date.format("MMM YYYY")
            : t("present", { ns: ["common"] })}
        </Typography>
        <Typography level="body2" textAlign="right">
          {credits
            ? t("education_builder:credits_count", { credits: credits })
            : t("education_builder:graduated")}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default EducationItem;
