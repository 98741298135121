import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";
import RoleSaved from "../Components/Roles/RoleSaved";

import { useTranslation } from "react-i18next";
import { Add, Edit } from "@mui/icons-material";
import dayjs from "dayjs";
import { useAuth } from "../Context/AuthContext";
import { FullUserService } from "../Services/Db/user";
import { Listings } from "../Services/Db/types";
import { ListingsService } from "../Services/Db/service";

const SavedRoles = () => {
  const appBarContext = useContext(AppBarContext);
  const { t, i18n } = useTranslation(["create_profile"]);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.USER);
  }, []);

  const { user } = useAuth();
  const [savedRoleIds, setSavedRoleIds] = useState<string[]>([]);

  useEffect(() => {
    if (user) {
      const loadSavedRoles = async (phoneNumber: string) => {
        const loadedSavedRoles = await FullUserService.loadUserSavedListingIds(
          phoneNumber
        );
        setSavedRoleIds(loadedSavedRoles);
      };
      loadSavedRoles(user.phoneNumber!);
    }
  }, [user]);

  return (
    <PageSpacer>
      <Stack spacing={2}>
        <Typography level="sectionHeader">
          {t("saved_roles", { ns: "job_seeker" })}
        </Typography>
        {savedRoleIds.length === 0 && (
          <Card>
            <Typography level="h3">{t("job_seeker:no_saved_roles")}</Typography>
            <Typography level="body1">
              {t("job_seeker:no_saved_roles_sub")}
            </Typography>
          </Card>
        )}
        {savedRoleIds.map((listingId) => (
          <React.Fragment key={listingId}>
            <RoleSaved
              listingId={listingId}
              roles={savedRoleIds}
              setRoles={setSavedRoleIds}
            />
          </React.Fragment>
        ))}
      </Stack>
    </PageSpacer>
  );
};

export default SavedRoles;
