import React, { useState, useEffect } from "react";
import useKeyToDict from "../../Utils/useKeyToDict";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import {
  Stack,
  Button,
  Input,
  Typography,
  FormControl,
  FormLabel,
  Textarea,
  Card,
  Box,
  IconButton,
  Modal,
  Divider,
  Chip,
  Alert,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import { CheckRounded, NewReleasesRounded } from "@mui/icons-material";
import { CreateProfile } from "../../UserProfile/CreateProfile";

import { Listings } from "../../Services/Db/types";

interface ProfileModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  listing: Listings;
}

const ProfileModal: React.FC<ProfileModalProps> = ({
  open,
  setOpen,
  listing,
}) => {
  const { t } = useTranslation(["apply"]);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          outline: "none",
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          pb: 0,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="h4" sx={{ fontWeight: 600 }}>
            {t("profile")}
          </Typography>

          {/* <Chip
            size="sm"
            variant="outlined"
            color="primary"
            sx={{ fontWeight: "bold", borderColor: "black" }}
            onClick={() => setOpen(false)}
            startDecorator={<CheckRounded />}
          >
            {t("common:save").toUpperCase()}
          </Chip> */}

          <IconButton onClick={() => setOpen(false)} color="success">
            <CheckRounded />
          </IconButton>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX} />
        <Box
          sx={{
            overflowY: "scroll",
            "&::-webkit-scrollbar": { display: "none" },
            pb: 10,
            pt: 2,
          }}
        >
          <Alert
            sx={{ mb: 2 }}
            color="neutral"
            startDecorator={<NewReleasesRounded />}
          >
            {t("youll_only_enter_once")}
          </Alert>
          <CreateProfile
            reqs={
              listing.application_requirements || [
                "education",
                "experiences",
                "references",
                "availability",
              ]
            }
          />
        </Box>
      </Card>
    </Modal>
  );
};

export default ProfileModal;
