import { KeyboardBackspace } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Modal,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import RoleAbout from "src/Components/Inputs/RoleEditing/RoleAbout";
import RoleCustomQs from "src/Components/Inputs/RoleEditing/RoleCustomQs";
import RoleLocations from "src/Components/Inputs/RoleEditing/RoleLocations";
import RoleReqs from "src/Components/Inputs/RoleEditing/RoleReqs";
import PageSpacer from "src/Components/Utilities/PageSpacer";
import AppBarContext from "src/Context/AppBarContext";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { ListingsService } from "src/Services/Db/service";
import { Listings } from "src/Services/Db/types";
import { APP_ROUTES, DEFAULT_LISTING, HEADER_TYPES } from "src/Utils/Constants";
import useConfirm from "src/Utils/useConfirm";
import useToast from "src/Utils/useToast";

const EditRolePage = () => {
  const navigate = useNavigate();
  const context = useContext(AppBarContext);
  const location = useLocation();
  const { business } = useBusinessAuth();
  const confirm = useConfirm();
  const toast = useToast();
  const { t } = useTranslation(["business", "apply", "common"]);
  const [loading, setLoading] = useState<boolean>(
    location.state && location.state.edit && location.state.listingId
  );
  const [listing, setListing] = useState<Listings>(DEFAULT_LISTING);
  const [blockingLoad, setBlockingLoad] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  useEffect(() => {
    if (loading) {
      if (business) {
        ListingsService.getOne(location.state.listingId)
          .then((listing) => {
            if (listing) {
              setListing(listing);
            } else {
              toast("business", "error", "no_listing", () => {});
            }
          })
          .catch((_err) => {
            toast("business", "error", "no_listing", () => {});
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [business, loading]);

  useEffect(() => {
    console.log(selectedLocations);
    setCanSubmit(
      listing.wage !== 0 &&
        listing.role !== "" &&
        Boolean(business && business.email) &&
        selectedLocations.length > 0
    );
  }, [listing, selectedLocations, business]);

  async function handleRemoveListing(): Promise<void> {
    if (location.state && location.state.listingId) {
      setBlockingLoad(true);
      FullBusinessService.removeLocationListing(
        business?.email!,
        location.state.listingId
      ).then(() => {
        setBlockingLoad(false);
        navigate(APP_ROUTES.ROLES, {
          state: { listingIds: [location.state.listingId], change: "remove" },
        });
      });
    } else {
      navigate(APP_ROUTES.ROLES);
    }
  }

  async function handleEditListing(): Promise<void> {
    if (
      location.state &&
      location.state.edit &&
      location.state.listingId &&
      listing
    ) {
      setBlockingLoad(true);
      FullBusinessService.updateLocationListing(
        listing,
        location.state.listingId
      ).then(() => {
        setBlockingLoad(false);
        navigate(APP_ROUTES.ROLES);
      });
    }
  }

  async function handleSubmitListing(): Promise<void> {
    if (listing && selectedLocations.length > 0) {
      setBlockingLoad(true);
      FullBusinessService.createBusinessListingAllLocations(
        business!.email!,
        listing,
        selectedLocations
      ).then((listingIds) => {
        setBlockingLoad(false);
        navigate(APP_ROUTES.ROLES, {
          state: { listingIds: listingIds, change: "add" },
        });
      });
    }
  }

  function handleDisregardEdits(): void {
    navigate(APP_ROUTES.ROLES);
  }

  return (
    <PageSpacer>
      <Stack spacing={4}>
        {/* box for edit/new role buttons */}
        <Box>
          <Button
            variant="outlined"
            color="primary"
            startDecorator={<KeyboardBackspace />}
            onClick={() => navigate(APP_ROUTES.ROLES)}
          >
            {t("back_to_all_roles", { ns: ["business"] })}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 2,
          }}
        >
          {location.state && location.state.edit ? (
            <React.Fragment>
              <Typography level="sectionHeader">
                {t("edit_role", { ns: ["business"] })}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                  width: { xs: "100%", md: "auto" },
                }}
              >
                <Button variant="soft" fullWidth onClick={handleDisregardEdits}>
                  {t("disregard_edits", { ns: ["business"] })}
                </Button>
                <Button
                  variant="solid"
                  fullWidth
                  onClick={() =>
                    confirm(
                      "business",
                      "edit_listing",
                      "confirm_listing_desc",
                      "confirm",
                      handleEditListing
                    )
                  }
                  disabled={!canSubmit}
                >
                  {t("confirm_edits", { ns: ["business"] })}
                </Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography level="sectionHeader">
                {t("new_role", { ns: ["business"] })}
              </Typography>
            </React.Fragment>
          )}
        </Box>
        {/* each compoenet of form */}
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <React.Fragment>
            <RoleAbout listing={listing} setListing={setListing} />
            <RoleReqs listing={listing} setListing={setListing} />
            <RoleCustomQs listing={listing} setListing={setListing} />
            <RoleLocations
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
              listing={listing}
              setListing={setListing}
            />
          </React.Fragment>
        )}
        {/*submit button */}
        <Stack spacing={1}>
          {location.state && location.state.edit ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: 1,
                width: { xs: "100%", md: "auto" },
              }}
            >
              <Button variant="soft" fullWidth onClick={handleDisregardEdits}>
                {t("disregard_edits", { ns: ["business"] })}
              </Button>
              <Button
                variant="solid"
                fullWidth
                onClick={() =>
                  confirm(
                    "business",
                    "edit_listing",
                    "confirm_listing_desc",
                    "confirm",
                    handleEditListing
                  )
                }
                disabled={!canSubmit}
              >
                {t("confirm_edits", { ns: ["business"] })}
              </Button>
            </Box>
          ) : (
            <Button
              variant="solid"
              disabled={!canSubmit}
              fullWidth
              onClick={() =>
                confirm(
                  "business",
                  "post_role",
                  "confirm_listing_desc",
                  "confirm",
                  handleSubmitListing
                )
              }
            >
              {t("post_role", { ns: ["business"] })}
            </Button>
          )}
          {location.state && location.state.edit && (
            <Button
              sx={{ mt: 0 }}
              variant="plain"
              fullWidth
              onClick={() =>
                confirm(
                  "business",
                  "delete_listing",
                  "delete_listing_desc",
                  "delete",
                  handleRemoveListing
                )
              }
            >
              {t("common:delete")}
            </Button>
          )}
        </Stack>
        <Modal open={blockingLoad}>
          <ModalDialog>
            <LinearProgress />
          </ModalDialog>
        </Modal>
      </Stack>
    </PageSpacer>
  );
};

export default EditRolePage;
