import React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import AppBarContext from "../Context/AppBarContext";
import { HEADER_TYPES } from "../Utils/Constants";
import Loader from "../Components/Maps/GoogleMapsLoader";
import WageMap from "../Components/Maps/WageMap";
import { height } from "@mui/system";

interface HomeProps {
  showWelcome: boolean;
  setShowWelcome: (showWelcome: boolean) => void;
}

const Home: React.FC<HomeProps> = ({ showWelcome, setShowWelcome }) => {
  const navigate = useNavigate();

  const { businessId } = useParams();

  useEffect(() => {
    if (businessId?.length === "ChIJNbQgo6zDrIkRwVGsUfwPqz4".length) {
      navigate(`/business/${businessId}`);
    }
  }, [businessId]);

  const context = useContext(AppBarContext);
  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.GPT);
    context?.setShowHeader(true);
    console.log("setting header in home");
  }, []);

  useEffect(() => {
    const local = localStorage.getItem("hideWelcome");
    if (
      local === null &&
      businessId?.length !== "ChIJNbQgo6zDrIkRwVGsUfwPqz4".length
    ) {
      setShowWelcome(true);
      localStorage.setItem("hideWelcome", JSON.stringify(false));
    } else {
      localStorage.setItem("hideWelcome", JSON.stringify(true));
    }
  }, [showWelcome]);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100vw",
        display: "flex",
        "&::-webkit-scrollbar": { display: "none" },
        zIndex: 1,
      }}
    >
      <WageMap />
    </Box>
  );
};

export default Home;
