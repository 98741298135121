import { useState, useEffect } from "react";
import useKeyToDict from "../../Utils/useKeyToDict";
import { Item, PlaceDetails, Listings } from "../../Services/Db/types";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import {
  Stack,
  Button,
  Box,
  AspectRatio,
  Typography,
  Divider,
  Chip,
  Modal,
  IconButton,
  Card,
} from "@mui/joy";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import {
  ArrowForwardRounded,
  CloseRounded,
  FavoriteBorderOutlined,
  FavoriteRounded,
  SendRounded,
} from "@mui/icons-material";

import { DocumentReference } from "firebase/firestore";
import { BusinessService } from "../../Services/Db/service";
import ListItems from "../Common/ListItems";
import { useAuth } from "../../Context/AuthContext";
import { FullUserService } from "../../Services/Db/user";
import useToast from "../../Utils/useToast";
import PhoneSignIn from "../Authentication/PhoneSignIn";
import ListingDetails from "../Listings/ListingDetails";
import { logEvent } from "firebase/analytics";
import { analytics } from "src/firebase";

interface InfoStepProps {
  setActive: (active: number) => void;
  listing: Listings;
}

const getBusiness = async (businessRef: DocumentReference) => {
  const loadedBusiness = await BusinessService.getResolvedReference(
    businessRef
  );
  return loadedBusiness;
};

const InfoStep: React.FC<InfoStepProps> = ({ setActive, listing }) => {
  const { t } = useTranslation(["apply", "common"]);
  const { getRoleNameFromRole, getCategory } = useKeyToDict("lists");

  const [businessName, setBusinessName] = useState("");
  const [businessDescription, setBusinessDescription] = useState("");

  const { user } = useAuth();
  const [savedListing, setSavedListing] = useState(false);

  // modal for signing in to save listing
  const [modal, setModal] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      const loadSavedListing = async (listingId: string) => {
        const savedListings = await FullUserService.loadUserSavedListingIds(
          user.phoneNumber!
        );
        setSavedListing(savedListings.includes(listingId));
      };
      loadSavedListing(listing.id || "");
    }
  }, [user, listing]);

  useEffect(() => {
    if (listing.businessRef) {
      getBusiness(listing.businessRef).then((business) => {
        setBusinessName(business?.name || "");
        setBusinessDescription(business?.description || "");
      });
    }
  }, [listing.businessRef]);

  const toast = useToast();
  function handleSaveListing(id: string | undefined): void {
    if (id) {
      if (user) {
        if (savedListing) {
          FullUserService.removeSavedListing(user.phoneNumber!, id);
        } else {
          FullUserService.addSavedListing(user.phoneNumber!, id);
        }
        setSavedListing(!savedListing);
      } else {
        // FIXME show login modal
        toast("user_flow", "login_to_save_listing", "sign_in", () => {
          setModal(true);
        });
      }
    }
  }

  return (
    <Stack>
      <Stack
        sx={{
          maxHeight: "40vh",
          overflowY: "scroll",

          pb: 2,
          pt: 1,
        }}
        spacing={2}
      >
        <Box>
          {listing.category && (
            <Chip
              variant="solid"
              color="warning"
              size="sm"
              sx={{ fontWeight: 700 }}
            >
              {getCategory("roles_headers", listing.category).toUpperCase()}
            </Chip>
          )}
        </Box>

        <ListingDetails listing={listing} />
      </Stack>

      <Divider sx={{ mx: -2, opacity: 1 }} />
      <Box sx={{ display: "flex", width: "100%", gap: 1 }}>
        <Button
          fullWidth
          variant="soft"
          sx={{ my: 2 }}
          onClick={() => handleSaveListing(listing.id)}
          startDecorator={
            savedListing ? <FavoriteRounded /> : <FavoriteBorderOutlined />
          }
        >
          {savedListing ? t("common:saved") : t("common:save")}
        </Button>
        <Divider orientation="vertical" sx={{ opacity: 1, mx: 1 }} />
        <Button
          fullWidth
          sx={{ my: 2 }}
          onClick={() => {
            setActive(2);
            logEvent(analytics, "view_apply", { listing_id: listing.id });
          }}
          endDecorator={<SendRounded />}
        >
          {t("common:apply")}
        </Button>
      </Box>

      <Modal
        open={modal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClose={() => {
          setModal(false);
        }}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            outline: "none",
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography level="h4" sx={{ fontWeight: 600 }}>
              {t("sign_in", { ns: "common" })}
            </Typography>
            <IconButton onClick={() => setModal(false)}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Divider sx={DIVIDER_HEADER_SX} />
          <Box
            sx={{
              overflow: "auto",
              "&::-webkit-scrollbar": { mr: 2 },
              pb: 2,
              pt: 2,
            }}
          >
            <PhoneSignIn
              onComplete={() => {
                setModal(false);
                logEvent(analytics, "sign_up", {
                  method: "save_role",
                });
              }}
            />
          </Box>
        </Card>
      </Modal>
    </Stack>
  );
};

export default InfoStep;
