import {
  AddRounded,
  Delete,
  InfoRounded,
  KeyboardArrowDown,
  PersonAdd,
  PersonAddRounded,
  Refresh,
} from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/joy";
import { Collapse, Skeleton } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import useEmail from "src/Utils/useEmail";
import InviteToAccountModal from "./InviteToAccountModal";
import useToast from "src/Utils/useToast";
import { useTranslation } from "react-i18next";
import { isNull } from "lodash";
import EditGroupCode from "./EditGroupCode";

const BusinessShareGroup = () => {
  const { business } = useBusinessAuth();
  const { sendEmail } = useEmail();
  const toast = useToast();

  const [addedUsers, setAddedUsers] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (business?.email) {
      FullBusinessService.getBusinessProfile(business.email)
        .then((biz) => {
          if (biz?.addedUsers) {
            setAddedUsers(biz.addedUsers);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [business]);

  const { t } = useTranslation("business");

  return (
    <Stack spacing={1}>
      <Typography level="h6">{t("group_code")}</Typography>
      <EditGroupCode />
    </Stack>
  );
};

export default BusinessShareGroup;
