// MUI material compatibility
import { deepmerge } from "@mui/utils";
import { experimental_extendTheme as extendMuiTheme } from "@mui/material/styles";
import colors from "@mui/joy/colors";
import { extendTheme, useColorScheme } from "@mui/joy/styles";
import "../App.css";
import { NoEncryption } from "@mui/icons-material";

const font = "'Archivo', sans-serif";

const muiTheme = extendMuiTheme({
  // This is required to point to `var(--joy-*)` because we are using
  // `CssVarsProvider` from Joy UI.
  cssVarPrefix: "joy",
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#D74531",
        },
        grey: colors.grey,
        error: {
          main: colors.red[500],
        },
        info: {
          main: colors.purple[500],
        },
        success: {
          main: colors.green[500],
        },
        warning: {
          main: colors.yellow[200],
        },
        common: {
          white: "#FFF",
          black: "#09090D",
          background: "#F7F7F7",
        },
        divider: colors.grey[200],
        text: {
          primary: colors.grey[800],
          secondary: colors.grey[600],
        },
        // https://plainenglish.io/blog/extend-material-ui-theme-in-typescript-a462e207131f
        header: {
          main: "#D74531",
          mainHover: "#D74531",
          bg: "#FFF",
        },
      },
    },
  },
  shape: {
    borderRadius: 10,
  },
  typography: {
    fontFamily: font,
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "0px",
        },
      },
    },
  },
});

const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        text: {
          primary: "#121212",
        },
        background: {
          surface: "#FFF",
          level1: "#D74531",
        },
        common: {
          white: "#FFF",
          black: "#09090D",
        },
        primary: {
          plainColor: "#000",
          plainBg: "",
          plainHoverColor: "#D74531",
          plainHoverBg: "#D7514322",
          plainActiveColor: "#D74531",
          plainActiveBg: "#D7514322",
          plainDisabledColor: "#CCC",

          solidColor: "#FFF",
          solidBg: "#D74531",
          solidHoverColor: "#FFF",
          solidHoverBg: "#C0463F",
          solidActiveColor: "#FFF",
          solidActiveBg: "#C02020",
          solidDisabledBg: "#D7514322",

          softColor: "#D74531",
          softBg: "#D7514326",
          softHoverColor: "#D74531",
          softHoverBg: "#C0463F66",
          softActiveColor: "#D74531",
          softActiveBg: "#C0463F",

          outlinedColor: "#D74531",
          outlinedBorder: "#D74531",
          outlinedHoverBorder: "#C0463F",
          outlinedActiveBorder: "#C0463F",
          outlinedHoverBg: "#D7514322",
          outlinedActiveBg: "#D7514355",
          outlinedDisabledBorder: "#DDD",
          outlinedDisabledColor: "#DDD",
        },
        // primary: {

        // plainBg: "none",
        // plainColor: "#394c64",
        // plainHoverBg: "yellow",
        // plainHoverColor: "#D74531",
        // plainActiveBg: "green",
        // plainActiveColor: "#D74531",
        // plainDisabledColor: "#DDD",
        // solidBg: "#344d66",
        // solidColor: "#FFF",
        // solidHoverBg: "#C0463F",
        // solidHoverColor: "#FFF",
        // solidActiveBg: "#C02020",
        // solidActiveColor: "#FFF",
        // solidDisabledBg: "#D7514322",
        // softBg: "#D7514326",
        // softColor: "#D74531",
        // softHoverBg: "#C0463F66",
        // softHoverColor: "#D74531",
        // softActiveBg: "#C0463F",
        // softActiveColor: "#D74531",
        // softDisabledBg: "#D7514322",
        // outlinedBorder: "#D74531",
        // outlinedColor: "#D74531",
        // outlinedHoverBorder: "#C0463F",
        // outlinedActiveBorder: "#C0463F",
        // outlinedHoverBg: "#D7514322",
        // outlinedActiveBg: "#D7514355",
        // outlinedDisabledBorder: "#DDD",
        // outlinedDisabledColor: "#DDD",
        // },

        neutral: {
          plainColor: "#000",
        },
        warning: {},
        success: {},
      },
    },
  },

  fontFamily: {
    body: font,
    display: font,
  },
  //add to createPalette.d.ts to add more variables
  typography: {
    sectionHeader: {
      fontSize: "2.75rem",
      fontWeight: 600,
      lineHeight: "3rem",
    },
    selectLabel: {
      fontSize: "1rem",
      color: "black",
      paddingBottom: "1rem",
      fontWeight: 600,
    },
    cardHeader: {
      fontSize: "1.65rem",
      fontWeight: 600,
      lineHeight: "2rem",
    },
    innerCardLink: {
      fontSize: "1.1rem",
      fontWeight: 600,
      "&:hover": {
        textDecoration: "underline",
        color: "#D74531",
      },
    },
  },
  components: {
    JoyCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.vars.radius.lg,
          boxShadow: "none",
          backgroundColor: theme.vars.palette.background.surface,
        }),
      },
    },
    JoyDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          bgColor: "black",
        }),
      },
    },

    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.vars.radius.sm,
        }),
      },
    },
    JoyIconButton: {
      defaultProps: {
        variant: "plain",
      },
    },
    JoyChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          borderRadius: theme.vars.radius.sm,
        }),
      },
    },
  },
});

// You can use your own `deepmerge` function.
// joyTheme will deeply merge to muiTheme.
const theme = deepmerge(muiTheme, joyTheme);

export default theme;
