import React, { useState, useEffect } from "react";

import { usePlaceDetails } from "../Maps/PlaceDetails";
import {
  Stack,
  Button,
  Input,
  Typography,
  FormControl,
  FormLabel,
  Textarea,
  Card,
  Box,
  IconButton,
  Modal,
  Divider,
  Chip,
  Alert,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import {
  CheckCircleRounded,
  HighlightOffRounded,
  LockPersonRounded,
  SendRounded,
  CloseRounded,
  NewReleasesRounded,
  SelectAllRounded,
  CheckRounded,
} from "@mui/icons-material";
import useText from "../../Utils/useText";
import useEmail from "../../Utils/useEmail";
import { useAuth } from "../../Context/AuthContext";
import { FullUserService } from "../../Services/Db/user";
import NumberField from "../Inputs/NumberField";
import { application } from "express";
import PhoneSignIn from "../Authentication/PhoneSignIn";
import ExperienceBuilder from "../Profile/ExperienceBuilder";

import { ApplicationsService } from "../../Services/Db/service";
import { TABLES } from "../../Services/Db/consts";
import { doc, Timestamp } from "firebase/firestore";
import { analytics, db } from "../../firebase";
import { Listings } from "../../Services/Db/types";
import QuestionModal from "./QuestionModal";
import ProfileModal from "./ProfileModal";
import dayjs from "dayjs";
import { logEvent } from "firebase/analytics";

interface ApplicationAndNameProps {
  listing: Listings;
  businessName: string;
  setActive?: (active: number) => void;
}

const ApplicationAndName: React.FC<ApplicationAndNameProps> = ({
  listing,
  setActive,
  businessName,
}) => {
  const { t } = useTranslation(["apply", "common"]);

  const { user } = useAuth();

  const [completedReqs, setCompletedReqs] = useState<string[]>([]);
  const [canSubmit, setCanSubmit] = useState(false);
  const [appReqModal, setAppReqModal] = useState<boolean>(false);

  const [customQuestionModal, setCustomQuestionModal] =
    useState<boolean>(false);
  const [customAnswers, setCustomAnswers] = useState<string[]>([]);
  const [applicationRequirementsMet, setApplicationRequirementsMet] =
    useState<boolean>(false);

  //load everything that's already on user's profile
  useEffect(() => {
    if (user) {
      const loadUserRequirements = async () => {
        const loadedUser = await FullUserService.loadBasicUser(
          user.phoneNumber!
        );
        var reqs: string[] = [];
        if (
          (loadedUser?.experiences && loadedUser.experiences.length > 0) ||
          loadedUser?.noExperience
        ) {
          console.log("loadedUser.experiences", loadedUser.experiences);
          reqs.push("experiences");
        }
        if (loadedUser?.education) {
          reqs.push("education");
        }
        if (loadedUser?.references && loadedUser.references.length > 0) {
          reqs.push("references");
        }
        if (
          loadedUser?.start &&
          dayjs(loadedUser.start.toDate()).isAfter(dayjs())
        ) {
          reqs.push("start");
        }
        if (loadedUser?.languages && loadedUser.languages.length > 0) {
          reqs.push("languages");
        }
        if (loadedUser?.availability && loadedUser.availability.length > 0) {
          reqs.push("availability");
        }

        setCompletedReqs(reqs);
      };
      loadUserRequirements();
    }
  }, [user, appReqModal]);

  useEffect(() => {
    const appReqsMet = listing.application_requirements
      ? listing.application_requirements.reduce((acc, curr) => {
          return acc && completedReqs.includes(curr);
        }, true)
      : true;

    const customQuestionsMet = customAnswers.reduce((acc, curr) => {
      return acc && curr.length > 0;
    }, true);

    setCanSubmit(appReqsMet && customQuestionsMet);
    setApplicationRequirementsMet(appReqsMet);
  }, [completedReqs, customAnswers, listing]);

  const [name, setName] = useState("");

  const { textConfirmApplication } = useText();
  const { sendNewApplicationEmail } = useEmail();

  function handleApply() {
    logEvent(analytics, "apply", {
      listing_id: listing.id,
    });
    FullUserService.addUserApplication(user?.phoneNumber!, {
      listingRef: doc(db, TABLES.LISTINGS, listing.id!),
      userRef: doc(db, TABLES.USERS, user?.phoneNumber!),
      businessRef: listing.businessRef!,
      name: name,
      status: "submitted",
      submitted: Timestamp.now(),
      custom_answers: listing.custom_questions.map((q, i) => {
        return { category: q, item: customAnswers[i] };
      }),
    }).then(() => {
      if (setActive) setActive(3);

      if (listing) {
        textConfirmApplication(listing);
        sendNewApplicationEmail(listing, name);
      }
    });
  }

  if (user) {
    return (
      <Stack
        spacing={2}
        sx={{
          maxHeight: setActive ? "55vh" : "none",
          overflowY: "scroll",
          py: 1,
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {listing.application_requirements.length > 0 && (
          <React.Fragment>
            <Typography level="h6" sx={{ fontWeight: "bold" }}>
              {t("apply:application_requirements")}
            </Typography>

            <Card
              variant="outlined"
              sx={{ cursor: "pointer", boxShadow: "none" }}
              onClick={() => {
                setAppReqModal(true);
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography level="h6" sx={{ fontWeight: "bold", my: -0.5 }}>
                  {t("apply:profile")}
                </Typography>
                <Chip
                  variant="soft"
                  size="sm"
                  sx={{ fontWeight: "bold" }}
                  color={applicationRequirementsMet ? "success" : "warning"}
                >
                  {applicationRequirementsMet
                    ? t("profile_complete").toUpperCase()
                    : t("profile_incomplete").toUpperCase()}
                </Chip>
              </Box>
              <Divider sx={DIVIDER_HEADER_SX_MY} />
              <Stack spacing={1}>
                {listing.application_requirements.map((req) => {
                  // icon for each requirement colored by if they appear in requirements array
                  return (
                    <Typography
                      startDecorator={
                        completedReqs.includes(req) ? (
                          <CheckCircleRounded
                            sx={{ color: "success.solidBg" }}
                          />
                        ) : (
                          <HighlightOffRounded
                            sx={{ color: "primary.solidBg" }}
                          />
                        )
                      }
                      sx={{
                        textDecoration: completedReqs.includes(req)
                          ? "none"
                          : "underline",
                      }}
                      level="h6"
                    >
                      {t(req, { ns: ["apply"] })}{" "}
                    </Typography>
                  );
                })}
              </Stack>
            </Card>
            <Divider />
          </React.Fragment>
        )}

        {listing.custom_questions.length > 0 && (
          <React.Fragment>
            <Typography level="h6" sx={{ fontWeight: "bold" }}>
              {t("apply:custom_questions", {
                count: listing.custom_questions.length,
              })}
            </Typography>

            <Card
              variant="outlined"
              sx={{ cursor: "pointer", boxShadow: "none" }}
              onClick={() => {
                setCustomQuestionModal(true);
              }}
            >
              <Typography level="h6" sx={{ fontWeight: "bold" }}>
                {t("apply:custom_questions")}
              </Typography>
              <Divider sx={DIVIDER_HEADER_SX_MY} />
              <Stack spacing={1}>
                {listing.custom_questions.map((q, i) => {
                  // icon for each requirement colored by if they appear in requirements array
                  return (
                    <Typography
                      startDecorator={
                        customAnswers &&
                        customAnswers.length ===
                          listing.custom_questions.length &&
                        customAnswers[i].length > 0 ? (
                          <CheckCircleRounded
                            sx={{ color: "success.solidBg" }}
                          />
                        ) : (
                          <HighlightOffRounded
                            sx={{ color: "primary.solidBg" }}
                          />
                        )
                      }
                      sx={{
                        textDecoration:
                          customAnswers &&
                          customAnswers.length ===
                            listing.custom_questions.length &&
                          customAnswers[i].length > 0
                            ? "none"
                            : "underline",
                      }}
                      noWrap
                    >
                      {q}
                    </Typography>
                  );
                })}
              </Stack>
            </Card>
            <Divider />
          </React.Fragment>
        )}

        <Typography
          level="h6"
          sx={{
            fontWeight: "700",
            color: !canSubmit ? "grey.300" : "text",
          }}
        >
          {listing.application_requirements.length === 0 &&
          listing.custom_questions.length === 0
            ? t("enter_name_on_apply_just_name", {
                ns: "apply",
              })
            : t("enter_name_on_apply", {
                ns: "apply",
              })}
        </Typography>

        <FormControl sx={{ pb: 1 }}>
          <Input
            placeholder={t("name_on_application", { ns: "apply" }) || ""}
            onChange={(e) => {
              setName(e.target.value);
            }}
            color="neutral"
            startDecorator={<LockPersonRounded />}
            sx={{
              borderRadius: 1,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              height: "3rem",
            }}
          />
          <Button
            fullWidth
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              border: "1px solid #d8d8de",
              borderTop: "none",
            }}
            disabled={!Boolean(name) || !canSubmit}
            type="submit"
            color="primary"
            onClick={handleApply}
            startDecorator={<SendRounded />}
          >
            {t("apply", { ns: "common" })}
          </Button>
          <Typography level="body2" sx={{ color: "text.tertiary", m: 1 }}>
            {listing.application_requirements.length === 0 &&
            listing.custom_questions.length === 0
              ? t("never_store_name_no_profile", {
                  ns: "apply",
                  business: businessName,
                })
              : t("never_store_name_with_profile", {
                  ns: "apply",
                  business: businessName,
                })}
          </Typography>
        </FormControl>

        <ProfileModal
          open={appReqModal}
          setOpen={setAppReqModal}
          listing={listing}
        />

        <QuestionModal
          open={customQuestionModal}
          setOpen={setCustomQuestionModal}
          customAnswers={customAnswers}
          setCustomAnswers={setCustomAnswers}
          businessName={businessName}
          listing={listing}
        />
      </Stack>
    );
  }

  return (
    <Box sx={{ py: 2 }}>
      <Typography level="selectLabel">{t("enter_number_nouser")}</Typography>
      <PhoneSignIn
        //no need to do anything because once user is signed in, the other pane will render
        onComplete={() => {
          logEvent(analytics, "sign_up", {
            method: "apply",
          });
        }}
      />
    </Box>
  );
};

export default ApplicationAndName;
