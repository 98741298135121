import React, { useEffect, useState } from "react";
import { Stack, Box } from "@mui/joy";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { APP_ROUTES, DEFAULT_MARKER_VALS } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import useToast from "../../Utils/useToast";
import useKeyToDict from "../../Utils/useKeyToDict";
import { Item, PlaceDetails, Listings } from "../../Services/Db/types";

import RolesStep from "./RolesStep";
import ReceiptStep from "./ReceiptStep";
import InfoStep from "./InfoStep";
import ApplicationAndName from "./ApplicationAndName";

import StepNumber from "../NewUserFlow/StepNumber";

interface ApplyStepsProps {
  placeId: string;
  setHeader?: (header: string) => void;
  directApplyToListing?: Listings; //role's already been selected
}

const ApplySteps: React.FC<ApplyStepsProps> = ({
  placeId,
  setHeader,
  directApplyToListing,
}) => {
  // const [step, setStep] = useState<"roles" | "apply" | "receipt">("roles");
  const [active, setActive] = useState(
    directApplyToListing !== undefined ? 2 : 0
  );
  console.log("active", active);
  const [selectedListing, setSelectedListing] = useState<Listings | null>(
    directApplyToListing || null
  );

  const { t } = useTranslation(["apply", "common"]);
  const toast = useToast();
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const placeDetails = usePlaceDetails(placeId, [
    "photo",
    "website",
    "name",
    "rating",
  ]);

  //load marker popup details
  const [markerPopupDetails, setMarkerPopupDetails] =
    React.useState<PlaceDetails>();

  useEffect(() => {
    if (placeDetails) {
      const placeName: string = placeDetails.name || "Name not found";
      var photoUrl: string = DEFAULT_MARKER_VALS.photo;
      if (placeDetails.photos) {
        photoUrl = placeDetails.photos[0].getUrl();
      }
      const website: string =
        placeDetails.website || DEFAULT_MARKER_VALS.website;
      const rating: number = placeDetails.rating || -1;
      const types: string[] = placeDetails.types || [];
      setMarkerPopupDetails({
        img: photoUrl,
        website: website,
        name: placeName,
        rating: rating,
        types: types,
      });
    }
  }, [placeDetails]);

  //start on first pane each time a new place is selected
  useEffect(() => {
    if (!directApplyToListing) setActive(0);
  }, [placeId]);

  //set header appropriately
  useEffect(() => {
    if (setHeader) {
      switch (active) {
        case 0:
          setHeader(markerPopupDetails?.name || "Loading...");
          break;
        case 3:
          setHeader(t("apply:application_sent"));
          break;
        default:
          setHeader(
            getRoleNameFromRole({
              category: selectedListing?.category!,
              item: selectedListing?.role!,
            }) +
              " " +
              t("at", { ns: "common" }) +
              " " +
              markerPopupDetails?.name || "Loading..."
          );
          break;
      }
    }
  }, [active, markerPopupDetails]);

  const { keyToObjArray } = useKeyToDict("apply");
  const steps = keyToObjArray("apply_steps");

  const renderView = (index: number) => {
    switch (index) {
      case 0:
        return (
          <RolesStep
            markerPopupDetails={markerPopupDetails}
            placeId={placeId}
            setActive={setActive}
            setSelectedListing={setSelectedListing}
          />
        );
      case 1:
        return <InfoStep setActive={setActive} listing={selectedListing!} />;
      case 2:
        return (
          <ApplicationAndName
            listing={directApplyToListing || selectedListing!}
            setActive={setActive}
            businessName={markerPopupDetails?.name || ""}
          />
        );
      case 3:
        return (
          <ReceiptStep
            markerPopupDetails={markerPopupDetails}
            selectedListing={selectedListing}
          />
        );
      default:
        return null;
    }
  };

  const handleTouch = (step: number) => {
    //can't go back once you've submitted application
    if (step <= active && active !== steps.length - 1) {
      setActive(step);
    }
  };

  return (
    <Stack
      sx={{
        display: "flex",
        alignItems: "center",
        overflowY: "none",
        maxHeight: "70vh",
      }}
    >
      {active !== 0 && !directApplyToListing && (
        <Stack sx={{ width: "100%" }}>
          <Stepper
            activeStep={active}
            sx={{ mt: -1.5, mb: -1, mx: -4 }}
            alternativeLabel
            connector={<StepConnector sx={{ mt: 1.5, mr: 0.5 }} />}
          >
            {/* .slice(0, Math.max(3, active + 3)) to not show the step "done" until user applies */}
            {steps.slice(directApplyToListing ? 2 : 0).map((step, index) => (
              <Step key={index}>
                <Box sx={{ transform: "scale(0.8)" }}>
                  <StepNumber
                    stepNumber={index + 1}
                    title={step[1]}
                    completedTo={active}
                    handleTouch={() => handleTouch(index)}
                    horizontal
                  />
                </Box>
              </Step>
            ))}
          </Stepper>
          <Divider sx={{ width: "100%" + 2, opacity: 1, mx: -2, mt: 1 }} />
        </Stack>
      )}

      <Box sx={{ width: "100%" }}>{renderView(active)}</Box>
    </Stack>
  );
};

export default ApplySteps;
