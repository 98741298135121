import { BusinessAccService } from "../Services/Db/service";
import { DocumentReference, where } from "firebase/firestore";

import { Amplify, API } from "aws-amplify";
import awsExports from "../../src/aws-exports";
import { Listings } from "src/Services/Db/types";
import useKeyToDict from "./useKeyToDict";
import dayjs from "dayjs";
Amplify.configure(awsExports);

let apiName = "server";

export type Email = {
  to: DocumentReference | string | undefined;
  subject: string;
  text: string;
};

const getBusinessEmails = async (businessRef: DocumentReference) => {
  const results = await BusinessAccService.getMany(
    where("locations", "array-contains", businessRef)
  );

  return results;
};

function useEmail() {
  const { getRoleNameFromRole } = useKeyToDict("lists");

  const sendEmail = async (message: Email) => {
    if (!message.to) return; //no businessRef

    var toSend = [];
    if (message.to instanceof DocumentReference) {
      toSend = await getBusinessEmails(message.to);
    } else {
      toSend = [{ id: message.to }];
    }

    for (const business of toSend) {
      console.log("Sending email:", message);

      const data = {
        body: {
          to: business.id,
          from: "Alex <alex@hourlead.com>",
          subject: message.subject,
          text: message.text,
        },
      };

      await API.post(apiName, "/gmail", data)
        .then((response: string) => {
          console.log("SENT EMAIL with response", response);
        })
        .catch((error: string) => {
          console.log("ERROR SENDING EMAIL", error);
        });
    }
  };

  const sendNewApplicationEmail = async (listing: Listings, name: string) => {
    sendEmail({
      to: listing.businessRef,
      subject: `New Application from Hourlead: ${name}`,
      text: `You have a new application for ${getRoleNameFromRole({
        item: listing.role,
        category: listing.category,
      })}! Go to hourlead.com/candidates to view it.`,
    });
  };

  const emailPhoneConfirmation = async (
    email: string,
    phone: string,
    name: string,
    business: string,
    slot: Date
  ) => {
    const time = dayjs(slot).format("h:mm A, dddd, MM D");

    const message = `Hi there, ${name},\n\nThis is a confirmation that you've set up a call with me at ${time}.\n\nI'll call you at this number: ${phone}\n\nIf you need to reschedule, please reply to this email or text me at 3107457252.\n\nHere to help, and looking forward to getting ${business} set up,\n\nAlex\n\nCo-Founder, Hourlead
    `;

    sendEmail({
      to: email,
      subject: `Confirmation: Setting up Hourlead at ${time}.`,
      text: message,
    });
  };

  return { sendEmail, sendNewApplicationEmail, emailPhoneConfirmation };
}

export default useEmail;
