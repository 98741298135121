import { Box, IconButton, Button } from "@mui/joy";
import {
  ArrowCircleRightRounded,
  ArrowCircleLeftRounded,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import InterviewContext from "src/Pages_Business/Interviews/InterviewContext";
import React, { useContext } from "react";

interface WeekToggleProps {
  daysShown: number;
  dayOffset: number | undefined;
  setDayOffset: ((offset: number) => void) | undefined;
  maxOffset?: number;
}

const WeekToggle: React.FC<WeekToggleProps> = ({
  daysShown,
  dayOffset,
  setDayOffset,
  maxOffset = 100,
}) => {
  const { t } = useTranslation(["interview"]);

  if (setDayOffset === undefined) return null;
  if (dayOffset === undefined) return null;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: dayOffset === 0 ? "flex-end" : "space-between",
      }}
    >
      {dayOffset !== 0 ? (
        <Box sx={{ display: "flex" }}>
          <IconButton
            onClick={() => {
              setDayOffset(Math.max(dayOffset - daysShown, 0));
            }}
          >
            <ArrowCircleLeftRounded sx={{ fontSize: "2.5rem" }} />
          </IconButton>
          <Button
            variant="plain"
            sx={{ textDecoration: "underline" }}
            onClick={() => setDayOffset(0)}
          >
            {t("today")}
          </Button>
        </Box>
      ) : null}

      {dayOffset + daysShown < maxOffset ? (
        <IconButton
          onClick={() => {
            setDayOffset(Math.min(dayOffset + daysShown, maxOffset || 100));
          }}
        >
          <ArrowCircleRightRounded sx={{ fontSize: "2.5rem" }} />
        </IconButton>
      ) : null}
    </Box>
  );
};

export default WeekToggle;
