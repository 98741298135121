import {
  Stack,
  Typography,
  Box,
  Button,
  Input,
  Card,
  CardContent,
  CardOverflow,
  Alert,
  AspectRatio,
  Divider,
  Chip,
} from "@mui/joy";
import {
  CheckCircle,
  Edit,
  Paid,
  ArrowForwardRounded,
} from "@mui/icons-material";

import { Dispatch, useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Stepper, StepContent, Step } from "@mui/material";

// Types
import { DIVIDER_HEADER_SX_MY, USER_TYPES } from "../Utils/Constants";
import { Item } from "../Services/Db/types";

// Components
import FAQ from "../Components/NewUserFlow/FAQ";
import StepNumber from "../Components/NewUserFlow/StepNumber";
import useKeyToDict from "../Utils/useKeyToDict";
import WageSlider from "../Components/Inputs/WageSlider";
import { NewUserAction, SIGNUP_STEPS } from "./NewUserFlow";
import { MapContext } from "../Context/MapContext";
import { loadFullBusiness } from "../Components/Maps/Utils";
import useToast from "../Utils/useToast";
import { usePlaceDetails } from "../Components/Maps/PlaceDetails";
import NewItemSelector from "../Components/Inputs/NewItemSelector";
import InlineBusinessSelectMap from "src/Components/Maps/InlineBusinessSelectMap";

interface Props {
  dispatch: Dispatch<NewUserAction>;
  //  setUserType: (userType: USER_TYPES) => void;
  //  setStep: (step: "selectUserType" | "stepper" | "phone") => void;
}

const EmployedStepper: React.FC<Props> = ({ dispatch }) => {
  const { t } = useTranslation(["common", "user_flow", "lists"]);

  const wrongUserType = () => {
    dispatch({ type: SIGNUP_STEPS.START });
  };

  const [role, setRole] = useState<Item[] | null>(null);
  const toast = useToast();

  const [wage, setWage] = useState(0);
  const [tips, setTips] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const context = useContext(MapContext);
  const placeDetails = usePlaceDetails(context?.placeId, [
    "name",
    "formatted_address",
    "geometry",
    "place_id",
  ]);

  const [activeStep, setActiveStep] = useState(0);
  const [completedTo, setCompletedTo] = useState(1000); //disabled this for now... set to 0 if you want to prevent users from skipping steps

  useEffect(() => {
    if (wage && role && placeDetails) {
      setIsCompleted(true);
    }
  }, [wage, role, placeDetails]);

  const handleNext = () => {
    //if we're moving to the next step, we've completed the current step, so we set completedTo to the next step
    setCompletedTo((prevCompletedTo) =>
      Math.max(prevCompletedTo, activeStep + 1)
    );
    //move on to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleWage = (e: any) => {
    const regex = /^[0-9\b]*$/;
    if (regex.test(e.target.value)) {
      setWage(e.target.value);
    }
  };

  const handleTips = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setTips(e.target.value);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTouch = (step: number) => {
    if (step <= completedTo) {
      setActiveStep(step);
    }
  };

  const {
    keyToObjArray: keyToDict,
    keyToValueInList,
    getRoleNameFromRole,
  } = useKeyToDict("lists");

  return (
    <Stack sx={{ mt: { xs: -2, md: 0 } }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        <Step key={"role"} completed={Boolean(role)}>
          <StepNumber
            stepNumber={1}
            title={t("role", { ns: "user_flow" })}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />

          <StepContent>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <Typography level="cardHeader">
                  {t("what_do_you_do", {
                    ns: ["user_flow"],
                  })}
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  level="body2"
                  onClick={() => wrongUserType()}
                >
                  {t("not_employed", {
                    ns: ["user_flow"],
                  })}
                </Typography>
              </Box>

              <Divider sx={DIVIDER_HEADER_SX_MY} />

              <NewItemSelector
                dictKey={"roles"}
                dictHeaderKey={"roles_headers"}
                title={""}
                selectedItems={role}
                setSelectedItems={setRole}
              />
            </Card>

            <FAQ section="role" />
          </StepContent>
        </Step>

        <Step key={"wage"} completed={Boolean(wage)}>
          <StepNumber
            stepNumber={2}
            title={t("wage", { ns: "user_flow" })}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />
          <StepContent>
            <Card>
              <Typography level="cardHeader">
                {t("whats_your_hourly_wage", {
                  ns: ["user_flow"],
                })}{" "}
                <Typography
                  sx={{
                    fontWeight: 400,
                    color: "text.tertiary",
                  }}
                >
                  {t("wages_count", {
                    ns: ["user_flow"],
                    wage: wage,
                  })}
                </Typography>
                {tips > 0 && (
                  <Typography sx={{ fontWeight: 400, color: "text.tertiary" }}>
                    {t("tips_count", {
                      ns: ["user_flow"],
                      tips: tips,
                      total: wage + tips,
                    })}
                  </Typography>
                )}
              </Typography>

              <Divider sx={DIVIDER_HEADER_SX_MY} />

              <Stack spacing={4} sx={{ my: 2 }}>
                <Stack
                  direction="row"
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={2}
                >
                  <Typography
                    level="body2"
                    sx={{ fontWeight: "bold", width: "3rem" }}
                  >
                    {t("base_wage", { ns: ["user_flow"] })}
                  </Typography>
                  <WageSlider defaultVal={0} setWage={setWage} />
                </Stack>
                <Stack
                  direction="row"
                  sx={{ display: "flex", alignItems: "center" }}
                  spacing={2}
                >
                  <Typography
                    level="body2"
                    sx={{ fontWeight: "bold", width: "3rem" }}
                  >
                    {t("tips", { ns: ["user_flow"] })}
                  </Typography>
                  <WageSlider defaultVal={0} setWage={setTips} />
                </Stack>
              </Stack>
            </Card>

            <Alert
              sx={{ mt: 2 }}
              startDecorator={<Paid />}
              color={Boolean(wage) ? "success" : "neutral"}
            >
              <Typography textAlign={{ xs: "center", md: "right" }}>
                {t("you_could_make", {
                  ns: ["user_flow"],
                })}{" "}
                <Typography sx={{ fontWeight: "bold" }}>
                  {Boolean(wage) ? "$4" : "$X"}{" "}
                  {t("more_per_hour", {
                    ns: ["user_flow"],
                  })}
                </Typography>{" "}
                {t("as_a_role_in_location", {
                  ns: ["user_flow"],
                  role: getRoleNameFromRole(
                    role ? role[0] : { item: "", category: "" }
                  ),
                  location: "Durham", //TODO: get location
                })}
              </Typography>
            </Alert>
            <Button
              sx={{ mt: 2 }}
              endDecorator={<ArrowForwardRounded />}
              variant="solid"
              fullWidth
              disabled={!Boolean(wage)}
              color={Boolean(wage) ? "primary" : "neutral"}
              onClick={() => handleNext()}
            >
              {t("next", { ns: "common" })}
            </Button>

            <FAQ section="wage" />
          </StepContent>
        </Step>

        <Step key={"map"} completed={Boolean(context?.placeId)}>
          <StepNumber
            stepNumber={3}
            title={t("location", { ns: "user_flow" })}
            completedTo={4}
            handleTouch={handleTouch}
          />
          <StepContent>
            {/* TODO: save this location  */}
            <InlineBusinessSelectMap
              setPlaceId={function (p: string): void {
                throw new Error("Function not implemented.");
              }}
              //titleKey="select_workplace"
              //titleNs={"user_flow"}
            />

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                variant="solid"
                disabled={!isCompleted}
                onClick={() => {
                  if (!placeDetails) {
                    toast("user_flow", "select_business", "", () => {});
                  } else if (!role) {
                    toast("user_flow", "select_role", "", () => {});
                  } else if (!wage && !tips) {
                    toast("user_flow", "select_wage", "", () => {});
                  } else {
                    dispatch({
                      type: SIGNUP_STEPS.ADDED_USERINFO,
                      employedData: {
                        wage: wage,
                        tips: tips,
                        role: role![0].item,
                        business: loadFullBusiness(placeDetails),
                      },
                    });
                  }
                }}
                fullWidth
                endDecorator={<ArrowForwardRounded />}
              >
                {t("done", { ns: "user_flow" })}
              </Button>
            </Stack>

            <FAQ section="location" />
          </StepContent>
        </Step>
      </Stepper>
    </Stack>
  );
};

export default EmployedStepper;
