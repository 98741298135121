import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Stack,
  Typography,
  Card,
  Divider,
  Box,
  IconButton,
  Button,
  Alert,
} from "@mui/joy";
import React, { useEffect, useContext, useState } from "react";
import AppBarContext from "../Context/AppBarContext";
import { DIVIDER_HEADER_SX, HEADER_TYPES } from "../Utils/Constants";

import { useTranslation } from "react-i18next";
import {
  Add,
  CheckCircleRounded,
  Edit,
  HighlightOffRounded,
  NewReleasesRounded,
} from "@mui/icons-material";
import dayjs, { Dayjs } from "dayjs";
import SelectGroup from "../Components/Inputs/SelectGroup";
import useKeyToDict from "../Utils/useKeyToDict";
import MonthYearPopover from "../Components/Inputs/MonthYearPopover";

import { useAuth } from "../Context/AuthContext";
import { FullUserService } from "../Services/Db/user";

const LanguagesSection = () => {
  const { t } = useTranslation(["create_profile", "lists"]);
  const { keyToObjArray: keyToDict } = useKeyToDict("lists");
  const languages_dict = keyToDict("languages");

  const [languages, setLanguages] = useState<string[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const loadLanguages = async (uid: string) => {
        const languages = await FullUserService.loadUserLanguages(uid);
        setLanguages(languages);
      };
      loadLanguages(user.phoneNumber!);
    }
  }, []);

  useEffect(() => {
    if (languages.length)
      FullUserService.createUserLanguages(languages, user?.phoneNumber!);
  }, [languages]);

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          startDecorator={
            languages ? (
              <CheckCircleRounded sx={{ color: "success.solidBg" }} />
            ) : (
              <HighlightOffRounded sx={{ color: "primary.solidBg" }} />
            )
          }
          level="h4"
          sx={{ fontWeight: 600 }}
        >
          {t("languages_spoken", { ns: ["create_profile"] })}
        </Typography>
      </Box>
      <Divider sx={DIVIDER_HEADER_SX} />
      <Stack spacing={3} divider={<Divider />} sx={{ mt: 2 }}>
        <SelectGroup
          selected={languages}
          setSelected={setLanguages}
          items={languages_dict}
        />
      </Stack>
    </Card>
  );
};

export default LanguagesSection;
