import {
  Modal,
  Box,
  Divider,
  ModalDialog,
  Button,
  List,
  ListItem,
  Typography,
  IconButton,
  Stack,
} from "@mui/joy";

import { useContext } from "react";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";

import {
  CloseRounded,
  DangerousRounded,
  RunningWithErrorsRounded,
} from "@mui/icons-material";

import { Interviews, Applications, Item } from "../../Services/Db/types";
import useKeyToDict from "../../Utils/useKeyToDict";
import useText from "src/Utils/useText";
import {
  InterviewsService,
  ApplicationsService,
} from "src/Services/Db/service";

import InterviewContext from "./InterviewContext";

import useInterviewLogicFirebase from "./useInterviewLogicFirebase";

require("dayjs/locale/es");

const CollisionsPopup = () => {
  const { t } = useTranslation(["interview"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const { textRescheduleInterview } = useText();

  const context = useContext(InterviewContext);
  const { handleSaveAvailability, handleSavePrefs } =
    useInterviewLogicFirebase();

  const rescheduleInterviews = (
    collisions: (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[]
  ) => {
    collisions.forEach((collision) => {
      InterviewsService.remove(collision.interview!.id!);
      ApplicationsService.update(collision.interview!.applicationRef!.id, {
        interviewRef: null,
      });
      textRescheduleInterview(collision.business, collision.userRef.id);
    });
  };

  return (
    <Modal open={context?.collisionsOpen || false}>
      <ModalDialog variant="outlined" role="alertdialog">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="h6" startDecorator={<RunningWithErrorsRounded />}>
            {t("confirm_changes")}
          </Typography>
          <IconButton onClick={() => context?.setCollisionsOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Box>
          <Stack>
            <Typography level="selectLabel">
              {t("collisions_message", {
                count: context?.collisions ? context?.collisions.length : 0,
              })}
            </Typography>
            <Typography sx={{ mt: -2 }}>
              {t("collisions_submessage", {
                count: context?.collisions ? context?.collisions.length : 0,
              })}
            </Typography>
          </Stack>

          <List sx={{ ml: -1 }}>
            {context?.collisions.map((collision) => (
              <ListItem>
                <Box
                  sx={{ border: "1px solid #DFDFDF", borderRadius: 1, p: 1 }}
                >
                  {t("interview:collision_item", {
                    time: dayjs(
                      collision.interview?.scheduledAt.toDate()
                    ).format("dddd, MMM D, h:mm A"),
                    name: collision.name,
                    role: getRoleNameFromRole(collision.role),
                  })}
                </Box>
              </ListItem>
            ))}
          </List>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              onClick={() => {
                handleSaveAvailability();
                handleSavePrefs();
                rescheduleInterviews(context?.collisions || []);
                context?.setCollisionsOpen(false);
              }}
            >
              {t("common:confirm")}
            </Button>
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default CollisionsPopup;
