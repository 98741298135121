import {
  CheckBox,
  CheckBoxOutlineBlank,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Stack,
  Textarea,
  Typography,
} from "@mui/joy";
import { Collapse } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import EditRoleHeader from "src/Components/Business/EditRoleHeader";
import { Item, Listings } from "src/Services/Db/types";
import { DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import NewItemSelector from "../NewItemSelector";
import WageSlider from "../WageSlider";

export interface RoleEditType {
  listing: Listings;
  setListing: Dispatch<SetStateAction<Listings>>;
  collapsable?: boolean;
}

interface RoleAboutType extends RoleEditType {}

const RoleAbout = ({ listing, setListing, collapsable }: RoleAboutType) => {
  const { t } = useTranslation("business");
  const [showTips, setShowTips] = useState<boolean>(listing.tips > 0);
  const [showDescription, setShowDescription] = useState<boolean>(
    Boolean(listing.description)
  );
  const wageDefault = useRef(listing.wage);
  const tipsDefault = useRef(listing.tips);
  const [expand, setExpand] = useState(true);

  useEffect(() => {}, []);

  function setRole(roles: any[] | null) {
    if (roles) {
      setListing({
        ...listing,
        role: roles[0].item,
        category: roles[0].category,
      });
    }
  }

  function setWage(wage: number) {
    setListing({ ...listing, wage: wage });
  }

  function setTipsCommission(tipsCommission: number) {
    setListing({ ...listing, tips: tipsCommission });
  }

  function setScheduleSelected(schedule: Item[] | null) {
    if (schedule) {
      setListing({ ...listing, schedule: schedule });
    }
  }

  function setBenefitsSelected(benefits: Item[] | null) {
    if (benefits) {
      setListing({ ...listing, perks: benefits });
    }
  }

  function setDescription(description?: string) {
    setListing({ ...listing, description: description });
  }

  return (
    <Card variant="outlined">
      <EditRoleHeader
        headerKey="about_this_role"
        expand={expand}
        setExpand={setExpand}
        collapsable={collapsable}
        desc="about_this_role_desc"
      />
      <Divider sx={DIVIDER_HEADER_SX_MY} />
      <Collapse in={expand || !collapsable} timeout="auto">
        <Stack spacing={3} divider={<Divider />}>
          <NewItemSelector
            dictKey={"roles"}
            dictHeaderKey={"roles_headers"}
            title={t("role", { ns: ["business"] })}
            selectedItems={
              listing.role && listing.category
                ? [{ item: listing.role, category: listing.category }]
                : []
            }
            setSelectedItems={setRole}
            isRequired
          />

          {/* Wage */}
          <Stack>
            <Typography level="selectLabel">
              {t("hourly_wage", { ns: ["business"] })}
            </Typography>
            <Box sx={{ py: 2, px: 1, width: "100%" }}>
              <WageSlider defaultVal={wageDefault.current} setWage={setWage} />
            </Box>
            <Box sx={{ py: 2 }}>
              <Chip
                variant="outlined"
                color="neutral"
                onClick={() => {
                  if (showTips) {
                    setTipsCommission(0);
                  }
                  setShowTips((prev) => {
                    return !prev;
                  });
                }}
                startDecorator={
                  !showTips ? <CheckBoxOutlineBlank /> : <CheckBox />
                }
              >
                {t("tips_commission", { ns: ["business"] }) || ""}
              </Chip>
            </Box>
            {showTips && (
              <React.Fragment>
                <Typography level="selectLabel" sx={{ mt: 2 }}>
                  {t("tips_commission_label", { ns: ["business"] })}
                </Typography>
                <Box sx={{ py: 2, px: 1, width: "100%" }}>
                  <WageSlider
                    defaultVal={tipsDefault.current}
                    setWage={setTipsCommission}
                  />
                </Box>
              </React.Fragment>
            )}
          </Stack>

          <NewItemSelector
            selectMultiple
            dictKey={"schedules"}
            dictHeaderKey={"schedules_headers"}
            title={t("schedule_types", { ns: ["business"] })}
            selectedItems={listing.schedule}
            setSelectedItems={setScheduleSelected}
          />

          <NewItemSelector
            selectMultiple
            title={t("benefits", { ns: ["business"] })}
            selectedItems={listing.perks}
            setSelectedItems={setBenefitsSelected}
            dictKey={"perks"}
            dictHeaderKey={"perks_headers"}
          />

          <Box>
            <Chip
              variant="outlined"
              color="neutral"
              onClick={() => {
                if (showDescription) {
                  setDescription(undefined);
                }
                setShowDescription((prev) => {
                  return !prev;
                });
              }}
              startDecorator={
                !showDescription ? <CheckBoxOutlineBlank /> : <CheckBox />
              }
            >
              {t("add_optional_info", { ns: ["business"] })}
            </Chip>

            {showDescription && (
              <FormControl sx={{ mt: 2 }}>
                <FormLabel>
                  {t("role_description", { ns: ["business"] })}
                </FormLabel>
                <Textarea
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={
                    t("role_description", { ns: ["business"] }) || ""
                  }
                />
                <FormHelperText>
                  150 {t("word_max", { ns: ["common"] })}
                </FormHelperText>
              </FormControl>
            )}
          </Box>
        </Stack>
      </Collapse>
    </Card>
  );
};

export default RoleAbout;
