import React, { useEffect, useState } from "react";
import {
  MonthPicker,
  LocalizationProvider,
  YearPicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Popover, Typography } from "@mui/material"; //not sure why Material Typography changes text color, and Joy doesn't
import { Box, Chip } from "@mui/joy";
import { useTranslation } from "react-i18next";

interface MonthYearPopoverProps {
  placeholder?: string;
  month: Dayjs | null;
  setMonth: (month: Dayjs | null) => void;
  year: Dayjs | null;
  setYear: (year: Dayjs | null) => void;
  disabled?: boolean;
}

const MonthYearPopover: React.FC<MonthYearPopoverProps> = ({
  placeholder,
  month,
  setMonth,
  year,
  setYear,
  disabled,
}) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [step, setStep] = useState<"month" | "year" | "done">("month");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    //month will always show first, if user sets month to the same month
    //(i.e. they are here to change year), then leave popover open
    //until they set year
    handleClose();
  }, [year]);

  useEffect(() => {
    //set step to month as soon as popover opens
    setStep("month");
  }, [anchorEl]);

  useEffect(() => {
    //set step to year once month is entered
    setStep("year");
  }, [month]);

  return (
    <React.Fragment>
      <Chip
        onClick={handleClick}
        variant="outlined"
        color={month && year && !disabled ? "primary" : "neutral"}
        sx={{ py: 1, px: 1.75, borderRadius: 1 }} //trying to match SelectGroup padding
        disabled={disabled}
      >
        <Typography
          color={disabled ? "#AAAAAA" : month && year ? "primary" : "neutral"}
        >
          {month
            ? month.format("MMMM") + (year ? ", " + year.format("YYYY") : "")
            : placeholder || ""}
        </Typography>
      </Chip>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale={i18n.language}>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ zIndex: 10000 }}
          transitionDuration={0}
          //TO-DO: popover must appear above modal
        >
          <Box sx={{ maxWidth: { xs: "100vw", md: "30vw" } }}>
            {step === "year" ? (
              <YearPicker
                disableFuture
                minDate={dayjs().subtract(75, "year")}
                maxDate={dayjs().add(1, "year")}
                date={year}
                onChange={(year) => setYear(year)}
              />
            ) : (
              <MonthPicker date={month} onChange={(month) => setMonth(month)} />
            )}
          </Box>
        </Popover>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default MonthYearPopover;
