import {
  addDoc,
  collection,
  CollectionReference,
  deleteDoc,
  doc,
  DocumentReference,
  getDoc,
  getDocs,
  query,
  QueryConstraint,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { TABLES } from "./consts";
import DatabaseError from "./errors";
import {
  Users,
  Wages,
  Businesses,
  Education,
  Experiences,
  References,
  Listings,
  Applications,
  BusinessAccount,
  Interviews,
  InterviewAvailabilities,
  InterviewPrefs,
  BugAndFeature,
  LinkedUsers,
} from "./types";

class DatabaseService<Type> {
  private _collectionRef: CollectionReference;
  constructor(collectionPath: string) {
    this._collectionRef = collection(db, collectionPath);
  }

  getOne = async (id: string): Promise<Type | null> => {
    const queryDoc = doc(this._collectionRef, id);
    const snapshot = await getDoc(queryDoc);
    if (!snapshot.exists()) {
      return null;
    }
    return <Type>{
      id: snapshot.id,
      ...snapshot.data(),
    };
  };

  getResolvedReference = async (
    documentReference: DocumentReference
  ): Promise<Type | null> => {
    const snapshot = await getDoc(documentReference);
    if (!snapshot.exists()) {
      return null;
    }
    return <Type>{
      id: snapshot.id,
      ...snapshot.data(),
    };
  };

  getMany = async (...clauses: QueryConstraint[]): Promise<Type[]> => {
    const q = query(this._collectionRef, ...clauses);
    const snapshot = await getDocs(q);
    const ret = snapshot.docs.map((doc) => {
      return <Type>{
        id: doc.id,
        ...doc.data(),
      };
    });
    return ret;
  };

  update = async (id: string, data: any): Promise<void> => {
    await updateDoc(doc(this._collectionRef, id), data);
  };

  create = async (data: any, id?: string): Promise<DocumentReference> => {
    // NOTE if id is provided and document already exists, calls update instead
    if (id) {
      const documentReference = doc(this._collectionRef, id);
      const existingDoc = await getDoc(documentReference);
      if (existingDoc.exists()) {
        this.update(id, data);
        return documentReference;
      }
      setDoc(documentReference, data);
      return documentReference;
    }
    return addDoc(this._collectionRef, data);
  };

  remove = async (id: string): Promise<void> => {
    deleteDoc(doc(this._collectionRef, id));
  };
}

export const UserService = new DatabaseService<Users>(TABLES.USERS);
export const WageService = new DatabaseService<Wages>(TABLES.WAGES);
export const BusinessService = new DatabaseService<Businesses>(
  TABLES.BUSINESSES
);
export const EducationService = new DatabaseService<Education>(
  TABLES.EDUCATION
);
export const ExperiencesService = new DatabaseService<Experiences>(
  TABLES.EXPERIENCES
);
export const ReferencesService = new DatabaseService<References>(
  TABLES.REFERENCES
);
export const ApplicationsService = new DatabaseService<Applications>(
  TABLES.APPLICATIONS
);
export const ListingsService = new DatabaseService<Listings>(TABLES.LISTINGS);
export const BusinessAccService = new DatabaseService<BusinessAccount>(
  TABLES.BUSINESS_ACCOUNTS
);
export const InterviewsService = new DatabaseService<Interviews>(
  TABLES.INTERVIEWS
);
export const InterviewAvailabilitiesService =
  new DatabaseService<InterviewAvailabilities>(TABLES.INTERVIEW_AVAILABILITIES);
export const InterviewPrefsService = new DatabaseService<InterviewPrefs>(
  TABLES.INTERVIEW_PREFS
);
export const BugAndFeatureService = new DatabaseService<BugAndFeature>(
  TABLES.BUG_AND_FEATURE
);
export const MessagesService = new DatabaseService<any>(TABLES.MESSAGES);
export const LinkedUsersService = new DatabaseService<LinkedUsers>(
  TABLES.LINKED_USERS
);

export default DatabaseService;
