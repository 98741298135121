import StepLabel from "@mui/material/StepLabel";
import StepIcon from "./StepIcon";
import { Typography } from "@mui/joy";

interface StepNumberProps {
  stepNumber: number;
  title: string;
  completedTo: number;
  handleTouch: (step: number) => void;
  horizontal?: boolean;
}

const StepNumber: React.FC<StepNumberProps> = ({
  stepNumber,
  title,
  completedTo,
  handleTouch,
  horizontal,
}) => {
  return (
    <StepLabel
      StepIconComponent={StepIcon}
      onClick={() => handleTouch(stepNumber - 1)}
      //not sure why this doesn't work -- maybe something to do with mounting?
      sx={{
        cursor: completedTo >= stepNumber ? "pointer" : "default",
      }}
    >
      <Typography
        level="body1"
        sx={{
          ml: horizontal ? -1 : 0,
          mt: horizontal ? -1 : 0,
          fontWeight: horizontal ? 600 : 300,
          color: completedTo >= stepNumber - 1 ? "primary.main" : "grey.500",
        }}
      >
        {title}
      </Typography>
    </StepLabel>
  );
};

export default StepNumber;
