import {
  ArrowForwardRounded,
  ArrowRightRounded,
  GavelRounded,
  HailRounded,
  KeyboardArrowRightRounded,
  PaidRounded,
  VisibilityOffRounded,
} from "@mui/icons-material";
import {
  Stack,
  Typography,
  Box,
  ListItem,
  List,
  Button,
  Alert,
  FormControl,
  Input,
  Chip,
  Card,
} from "@mui/joy";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import InlineBusinessSelectMap from "src/Components/Maps/InlineBusinessSelectMap";
import { CreateProfile } from "src/UserProfile/CreateProfile";
import WageSurveyContext from "./WageSurveyContext";
import { analytics } from "src/firebase";
import { logEvent } from "firebase/analytics";

const More = () => {
  const { t } = useTranslation("wage_comparison");

  const context = useContext(WageSurveyContext);

  return (
    <Stack spacing={4}>
      <Stack
        sx={{
          borderRadius: 1,
          p: 5,
          bgcolor: "primary.softBg",
          color: "primary.softColor",
        }}
        spacing={2}
      >
        <Box>
          <Chip
            sx={{
              bgcolor: "primary.softColor",
              color: "white",
            }}
          >
            {t("more.optional")}
          </Chip>
        </Box>
        <Typography level="h1" sx={{ color: "primary.softColor" }}>
          {t("more.title")}
        </Typography>
        <Typography
          sx={{
            color: "primary.softColor",
            alignSelf: "flex-end",
            cursor: "pointer",
          }}
          endDecorator={<ArrowForwardRounded />}
          onClick={() => {
            context?.setActive("result");
            logEvent(analytics, "finish_survey", {});
          }}
        >
          {t("more.skip")}
        </Typography>
      </Stack>

      <Card>
        <Typography level="h6">{t("more.where_do_you_work")}</Typography>
        <InlineBusinessSelectMap
          placeId={context?.placeId || ""}
          setPlaceId={context?.setPlaceId || (() => {})}
        />
      </Card>

      <CreateProfile reqs={["experiences", "education"]} />
      <Button
        sx={{ fontSize: "1.5rem", p: 2 }}
        endDecorator={<ArrowForwardRounded />}
        onClick={() => {
          context?.setActive("result");
          logEvent(analytics, "finish_survey", {});
        }}
      >
        {t("more.next")}
      </Button>
    </Stack>
  );
};

export default More;
