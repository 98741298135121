import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useContext } from "react";
import {
  APP_ROUTES,
  CARD_OVERFLOW_SX,
  DIVIDER_HEADER_SX_NO_Y,
  HEADER_TYPES,
} from "../../Utils/Constants";
import AppBarContext from "../../Context/AppBarContext";
import {
  Typography,
  Stack,
  Divider,
  Card,
  Box,
  IconButton,
  CardOverflow,
  ListItemDecorator,
  List,
  ListItem,
  ListItemContent,
} from "@mui/joy";
import { useTranslation } from "react-i18next";

import {
  ArrowForwardIosRounded,
  SchoolRounded,
  SendRounded,
} from "@mui/icons-material";

const ApplicationsCard: React.FC = () => {
  const { t } = useTranslation(["job_seeker"]);
  const navigate = useNavigate();
  const appBarContext = useContext(AppBarContext);

  return (
    <Card>
      <CardOverflow
        sx={CARD_OVERFLOW_SX}
        onClick={() => {
          // change header accordingly
          appBarContext?.setBackKey("back_to_account");

          // navigate to profile page
          navigate(APP_ROUTES.APPLICATIONS);
        }}
      >
        <Typography level="cardHeader" startDecorator={<SendRounded />}>
          {t("applications", { ns: "job_seeker" })}
        </Typography>

        <IconButton>
          <ArrowForwardIosRounded />
        </IconButton>
      </CardOverflow>
      {/* <Divider sx={DIVIDER_HEADER_SX_NO_Y} />
      <Typography level="body2" sx={{ mt: 2 }}>
        {t("profile_description", { ns: "job_seeker" })}
      </Typography> */}
    </Card>
  );
};

export default ApplicationsCard;
