import { Typography, Stack, Box, CircularProgress } from "@mui/joy";
import { useState, useEffect, useContext, useReducer, Dispatch } from "react";
import AppBarContext from "../Context/AppBarContext";
import EmployedStepper from "./EmployedStepper";
import NotEmployedStepper from "./NotEmployedStepper";
import StudentStepper from "./StudentStepper";
import PhoneStep from "./PhoneStep";
import SelectUserType from "./SelectUserType";
import { APP_ROUTES, HEADER_TYPES, USER_TYPES } from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useAuth } from "../Context/AuthContext";
import { auth } from "../firebase";
import { Action } from "@remix-run/router";
import { usePlaceDetails } from "../Components/Maps/PlaceDetails";
import { loadFullBusiness } from "../Components/Maps/Utils";
import { Navigate } from "react-router-dom";
import useToast from "../Utils/useToast";
import {
  EmployedSignUpData,
  SignUpData,
  StudentSignUpData,
  UnemployedSignUpData,
} from "../Components/Authentication/SignUp";

interface StepperProps {
  userType?: USER_TYPES;
  dispatch: Dispatch<NewUserAction>;
  //setUserType: (userType: USER_TYPES) => void;
  //setStep: (step: "selectUserType" | "stepper" | "phone") => void;
}

const Stepper: React.FC<StepperProps> = ({ userType, dispatch }) => {
  switch (userType) {
    case USER_TYPES.EMPLOYED:
      return <EmployedStepper dispatch={dispatch} />; //setUserType={setUserType} setStep={setStep} />;
    case USER_TYPES.NOT_EMPLOYED:
      return <NotEmployedStepper dispatch={dispatch} />; //setUserType={setUserType} setStep={setStep} />;
    case USER_TYPES.STUDENT:
      return <StudentStepper dispatch={dispatch} />; //setUserType={setUserType} setStep={setStep} />;
    default:
      return <EmployedStepper dispatch={dispatch} />; //setUserType={setUserType} setStep={setStep} />;
  }
};

interface NewUserState {
  usertype?: USER_TYPES;
  step: SIGNUP_STEPS;
  employedData?: EmployedSignUpData;
  studentData?: StudentSignUpData;
  unemployedData?: UnemployedSignUpData;
}

export enum SIGNUP_STEPS {
  START,
  SELECTED_USERTYPE,
  ADDED_USERINFO,
  SIGNEDIN_PHONE,
  FINISHED,
}

export type NewUserAction =
  | { type: SIGNUP_STEPS.START }
  | { type: SIGNUP_STEPS.SELECTED_USERTYPE; usertype: USER_TYPES }
  | {
      type: SIGNUP_STEPS.ADDED_USERINFO;
      employedData?: EmployedSignUpData;
      studentData?: StudentSignUpData;
      unemployedData?: UnemployedSignUpData;
    }
  | { type: SIGNUP_STEPS.SIGNEDIN_PHONE }
  | { type: SIGNUP_STEPS.FINISHED };

function newUserReducer(
  state: NewUserState,
  action: NewUserAction
): NewUserState {
  switch (action.type) {
    case SIGNUP_STEPS.SELECTED_USERTYPE:
      return { ...state, usertype: action.usertype, step: action.type };
    case SIGNUP_STEPS.ADDED_USERINFO:
      return {
        ...state,
        employedData: action.employedData,
        unemployedData: action.unemployedData,
        studentData: action.studentData,
        step: action.type,
      };
    case SIGNUP_STEPS.SIGNEDIN_PHONE:
      return { ...state, step: action.type };
    case SIGNUP_STEPS.FINISHED:
      return { step: action.type };
    default:
      return { step: SIGNUP_STEPS.START };
  }
}

const NewUserFlow = () => {
  const [step, setStep] = useState<"selectUserType" | "stepper" | "phone">(
    "selectUserType"
  );
  const [state, dispatch] = useReducer(newUserReducer, {
    step: SIGNUP_STEPS.START,
  });
  const { user } = useAuth();
  const toast = useToast(); // FIXME this should be the new bigger toast banner

  //const [userType, setUserType] = useState<USER_TYPES>(USER_TYPES.NOT_SET);

  switch (state.step) {
    case SIGNUP_STEPS.START:
      return <SelectUserType dispatch={dispatch} />;
    case SIGNUP_STEPS.SELECTED_USERTYPE:
      return (
        <Stepper
          userType={state.usertype}
          //setUserType={setUserType}
          //setStep={setStep}
          dispatch={dispatch}
        />
      );
    case SIGNUP_STEPS.ADDED_USERINFO:
      return (
        <PhoneStep
          onComplete={() => dispatch({ type: SIGNUP_STEPS.SIGNEDIN_PHONE })}
        />
      );
    case SIGNUP_STEPS.SIGNEDIN_PHONE:
      // can only occur if user is valid
      // FIXME error handling
      if (!user || !user.phoneNumber) {
        toast("user_flow", "no_user_found", "", () => {});
      } else if (
        !state.employedData &&
        !state.studentData &&
        !state.unemployedData
      ) {
        toast("user_flow", "invalid_user_data", "", () => {});
      } else {
        const signUpData: SignUpData = {
          uid: user.phoneNumber,
          employedData: state.employedData,
          unemployedData: state.unemployedData,
          studentData: state.studentData,
        };
        return <Navigate to={APP_ROUTES.SIGN_UP} state={signUpData} />;
      }
      return null;
    default:
      return <SelectUserType dispatch={dispatch} />;
  }
};

const NewUserFlowFullPage = () => {
  const appBarContext = useContext(AppBarContext);

  useEffect(() => {
    appBarContext?.setHeaderType(HEADER_TYPES.BASIC);
  });

  return (
    <PageSpacer>
      <NewUserFlow />
    </PageSpacer>
  );
};

export { NewUserFlow, NewUserFlowFullPage };
