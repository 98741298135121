import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/joy";
import { useTranslation } from "react-i18next";

const EditRoleHeader = ({
  headerKey,
  expand,
  setExpand,
  collapsable,
  desc,
}: {
  headerKey: string;
  expand: boolean;
  setExpand: (b: boolean) => void;
  collapsable?: boolean;
  desc?: string;
}) => {
  const { t } = useTranslation("business");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography level="cardHeader">{t(headerKey)}</Typography>
        {desc && <Typography level="body2">{t(desc)}</Typography>}
      </Box>
      {collapsable && (
        <IconButton variant="plain" onClick={() => setExpand(!expand)}>
          <KeyboardArrowDown
            fontSize="medium"
            sx={{
              transition: "0.3s",
              zIndex: 2,
              pointerEvents: "none",
              transform: expand ? "rotate(180deg)" : "rotate(360deg)",
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default EditRoleHeader;
