import { CloseRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  Input,
  LinearProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/joy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "src/Utils/Constants";
import useToast from "src/Utils/useToast";

const InviteToAccountModal = ({
  open,
  setOpen,
  onComplete,
}: {
  open: boolean;
  setOpen: (b: boolean) => void;
  onComplete: (e: string) => Promise<void>;
}) => {
  const { t } = useTranslation("business");
  const toast = useToast();

  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Modal
      open={open}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card
        sx={{
          maxHeight: { xs: "80vh", sm: "70vh" },
          outline: "none",
          width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },
          pb: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography level="cardHeader" sx={{ fontWeight: 600 }}>
            {t("add_user_to_account")}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Stack spacing={2} sx={{ mb: 2 }}>
          <Typography level="body2" sx={{ fontWeight: 600 }}>
            {t("add_user_to_account_description")}
          </Typography>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            type="email"
          ></Input>
          <Button
            disabled={!email || loading}
            onClick={() => {
              setLoading(true);
              onComplete(email!).then(() => {
                setOpen(false);
                setLoading(false);
                toast("business", "user_invited", "", () => {}, [email!]);
              });
            }}
          >
            {loading ? <LinearProgress /> : t("invite_user")}
          </Button>
        </Stack>
      </Card>
    </Modal>
  );
};

export default InviteToAccountModal;
