import {
  CopyAll,
  Delete,
  Download,
  DownloadRounded,
  EditRounded,
  KeyboardArrowDown,
} from "@mui/icons-material";
import {
  AspectRatio,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/joy";
import { Collapse } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { BusinessService } from "src/Services/Db/service";
import useConfirm from "src/Utils/useConfirm";
import useToast from "src/Utils/useToast";
import EditBusinessCode from "./EditBusinessCode";
import EditBusinessLogo from "./EditBusinessLogo";
import LocationListItem from "./LocationListItem";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import { Navigate, useNavigate } from "react-router-dom";
import { APP_ROUTES } from "src/Utils/Constants";

const BusinessLocationInfo = ({
  placeId,
  locations,
  setLocations,
}: {
  placeId: string;
  locations: string[];
  setLocations: (s: string[]) => void;
}) => {
  const { business } = useBusinessAuth();
  const confirm = useConfirm();

  const [expand, setExpand] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [code, setCode] = useState<string>();

  const placeDetails = usePlaceDetails(placeId, ["name"]);

  const { t } = useTranslation("business");
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (placeId) {
      BusinessService.getOne(placeId).then((res) => {
        if (res && res.code) {
          setCode(res.code);
        }
      });
    }
  }, [placeId]);

  function handleDeleteLocation(pid: string) {
    if (business) {
      FullBusinessService.removeLocation(business.email!, pid).then((_res) => {
        setLocations(locations.filter((l) => l !== pid));
      });
    }
  }

  return (
    <Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setExpand(!expand)}
      >
        <LocationListItem placeId={placeId} />
        <Box sx={{ display: "flex", gap: 1 }}>
          <Chip
            variant="soft"
            color={code === undefined ? "warning" : "success"}
            sx={{ display: expand ? "none" : "flex" }}
            onClick={() => navigate(APP_ROUTES.SIGN_MAKER)}
          >
            {code === undefined
              ? t("business:incomplete")
              : t("business:claim_your_sign")}
          </Chip>
          <IconButton variant="plain">
            <KeyboardArrowDown
              fontSize="medium"
              sx={{
                transition: "0.3s",
                zIndex: 2,
                pointerEvents: "none",
                transform: expand ? "rotate(180deg)" : "rotate(360deg)",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expand}>
        <Stack
          direction={"column"}
          spacing={1}
          pl={1}
          borderLeft={2}
          borderColor="divider"
          pb={1}
        >
          <Typography level="body2">{t("business_link")}</Typography>

          <Typography
            variant="outlined"
            color="neutral"
            startDecorator={<CopyAll />}
            onClick={() => {
              navigator.clipboard.writeText(`https://hourlead.com/${placeId}`);
              toast("common", "copied_to_clipboard", "", () => {});
            }}
            sx={{ cursor: "pointer", borderRadius: 0.75 }}
            noWrap
          >
            hourlead.com/{placeId}
          </Typography>

          <Box>
            <Button
              startDecorator={<Delete />}
              size="sm"
              onClick={() =>
                confirm(
                  "business",
                  "delete_location",
                  "delete_location_desc",
                  "delete",
                  () => handleDeleteLocation(placeId)
                )
              }
              variant="soft"
              color="warning"
            >
              {t("delete_location")}
            </Button>
          </Box>
        </Stack>
      </Collapse>
      <Divider sx={{ my: 1 }} />
    </Fragment>
  );
};

export default BusinessLocationInfo;
