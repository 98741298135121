import {
  Modal,
  ModalDialog,
  Typography,
  Button,
  Box,
  IconButton,
  Stack,
  Card,
  Divider,
  Chip,
} from "@mui/joy";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  AnnouncementRounded,
  CloseRounded,
  LooksOne,
  DirectionsRunRounded,
  SendRounded,
  TryRounded,
  PaidRounded,
  RunCircleRounded,
  PersonRounded,
  BusinessRounded,
  StorefrontRounded,
  StorefrontOutlined,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../Utils/Constants";
import useText from "../Utils/useText";
import EmailSignIn from "../Components/Authentication/EmailSignIn";
import { borderRadius } from "@mui/system";

interface WelcomeCardProps {
  show: boolean;
  setShow: (type: boolean) => void;
}

const WelcomeCard: React.FC<WelcomeCardProps> = ({ show, setShow }) => {
  const { t, i18n } = useTranslation(["header", "common"]);

  const handleClose = () => {
    setShow(false);
  };

  const navigate = useNavigate();

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      sx={{
        overflowY: "scroll",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "70%" },
          ":focus": {
            outline: "none",
          },
          pb: "10vh",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            my: "10vh",
            ":focus": {
              outline: "none",
            },
            height: { xs: "fit-content", md: "80vh" },
            overflowY: "scroll",

            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <IconButton onClick={handleClose} sx={{ m: -1 }}>
            <CloseRounded />
          </IconButton>
          <Stack sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Box
                sx={{
                  mx: { xs: "0%", md: "5%" },
                  my: { xs: "1rem", md: "2rem" },
                  width: { xs: "100%", md: "40%" },
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: 3,
                }}
              >
                <Typography
                  level="h1"
                  sx={{ fontWeight: 700, lineHeight: "120%" }}
                  textAlign="center"
                >
                  {t("welcome_to_hourlead")}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "1.3rem",
                    fontWeight: 300,
                    mx: { xs: 0, md: "15%" },
                    lineHeight: 1.5,
                    whiteSpace: { xs: "", md: "pre-line" },
                  }}
                  textAlign="center"
                >
                  {t("welcome_to_hourlead_sub")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Button
                    size="lg"
                    sx={{ fontWeight: 800, width: "80%" }}
                    onClick={() => setShow(false)}
                    startDecorator={<PersonRounded />}
                  >
                    {t("start")}
                  </Button>
                  <Button
                    sx={{ fontSize: "1rem", fontWeight: 500, width: "80%" }}
                    variant="soft"
                    onClick={() => {
                      setShow(false);
                      navigate(APP_ROUTES.NEW_BUSINESS);
                    }}
                    startDecorator={<StorefrontOutlined />}
                  >
                    {t("are_you_a_business")}
                  </Button>
                </Box>
              </Box>

              <Divider />
              <Divider orientation="vertical" />

              <Box
                sx={{
                  mx: { xs: "5%", md: "5%" },
                  my: { xs: "1rem", md: "2rem" },
                  width: { xs: "90%", md: "40%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 4,
                  // border: "1px solid",
                  // borderColor: "primary.main",
                  // borderRadius: 2,
                  p: 2,
                }}
              >
                <Box>
                  <Chip
                    sx={{ fontWeight: "bold" }}
                    size="sm"
                    variant="outlined"
                  >
                    {t("how_it_works").toUpperCase()}
                  </Chip>
                </Box>
                <Typography
                  level="h5"
                  sx={{ fontWeight: "bold" }}
                  startDecorator={
                    // <Typography
                    //   sx={{
                    //     fontSize: "1.25rem",
                    //     bgcolor: "primary.main",
                    //     color: "#FFF",
                    //     borderRadius: "50%",
                    //     display: "flex",
                    //     justifyContent: "center",
                    //     alignItems: "center",
                    //     p: "0.2rem",
                    //     fontWeight: 500,
                    //     mr: 2,
                    //   }}
                    // >
                    //   <Typography sx={{ fontSize: "1rem" }}>$</Typography>
                    //   18
                    // </Typography>
                    <PaidRounded
                      sx={{ mr: 2, color: "primary.main", fontSize: "2.5rem" }}
                    />
                  }
                >
                  {t("step_1")}
                </Typography>
                <Typography
                  level="h5"
                  sx={{ fontWeight: "bold" }}
                  startDecorator={
                    <SendRounded
                      sx={{ mr: 2, color: "primary.main", fontSize: "2.5rem" }}
                    />
                  }
                >
                  {t("step_2")}
                </Typography>
                <Typography
                  level="h5"
                  sx={{ fontWeight: "bold" }}
                  startDecorator={
                    <TryRounded
                      sx={{ mr: 2, color: "primary.main", fontSize: "2.5rem" }}
                    />
                  }
                >
                  {t("step_3")}
                </Typography>
                <Typography
                  level="h5"
                  sx={{ fontWeight: "bold" }}
                  startDecorator={
                    <RunCircleRounded
                      sx={{ mr: 2, color: "primary.main", fontSize: "2.5rem" }}
                    />
                  }
                >
                  {t("step_4")}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                onClick={() => {
                  console.log("clicked");
                  const newLanuage = i18n.language === "en" ? "es" : "en";
                  i18n.changeLanguage(newLanuage, (err, t) => {
                    if (err)
                      return console.log("something went wrong loading", err);
                    console.log(t("delete")); // -> same as i18next.t
                  });
                }}
                sx={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {t("change_language", { ns: "common" })}
              </Typography>
            </Box>
          </Stack>
        </Card>
      </Box>
    </Modal>
  );
};

export default WelcomeCard;
