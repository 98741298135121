import React, { useCallback, useContext, useEffect } from "react";
import InterviewContext from "./InterviewContext";
import { InterviewPrefs } from "src/Services/Db/types";
import { FullBusinessService } from "src/Services/Db/business";
import useConfirm from "src/Utils/useConfirm";
import { useBusinessAuth } from "src/Context/AuthContext";
import dayjs from "dayjs";
import useInterviewLogicCollisions from "./useInterviewLogicCollisions";
import useInterviewLogicFirebase from "./useInterviewLogicFirebase";

function useInterviewLogicTime() {
  const context = useContext(InterviewContext);

  const { handleSaveAvailability } = useInterviewLogicFirebase();
  const { findCollisions } = useInterviewLogicCollisions();

  /*
  const [runApply, setRunApply] = React.useState(false);

  useEffect(() => {
    if (changeType === "adding") {
      applyAdd();
    } else {
      applyRemove();
    }
  }, [runApply]);
*/

  //bring recurring availability to this week and set appropriate week offset
  const getRecurringAvailabilityForThisWeek = () => {
    return (
      context?.recurringAvailability
        .map((x: Date) => {
          const day = dayjs(x).day(); //get the day of the recurring availability
          const hour = dayjs(x).hour();
          const mins = dayjs(x).minute();

          //create a new dayjs object with appropriate details
          const newDay = dayjs()
            .add(context!.dayOffset, "day") //add dayOffset to get the start day
            .day(day)
            .hour(hour)
            .minute(mins)
            .second(0)
            .millisecond(0);

          if (newDay.day() < dayjs().add(context!.dayOffset, "day").day()) {
            return newDay.add(7, "day").toDate();
          } else {
            return newDay.toDate();
          }
        })
        //filter out any dates that need to be removed
        .filter(
          (newDate) =>
            !context!.removeRecurringAvailability.some(
              (val) => val.getTime() === newDate.getTime()
            )
        ) ?? []
    );
  };

  const getCalculatedAvailability = () => {
    return [
      ...getRecurringAvailabilityForThisWeek(),
      ...(context?.oneTimeAvailability ?? []),
    ].filter((x: Date) => {
      //filter out any times that don't start at a multiple of the interview length
      const mins = dayjs(x).minute();
      const minsOK = mins % context!.interviewDuration === 0;

      const startOK = dayjs(x).hour() >= context!.startTime; //make sure it's after the start hour

      return minsOK && startOK;
    });
  };

  // const apply = () => {
  //   context!.setChanged(true);
  //   setRunApply(true);
  // };

  const clearHolding = () => {
    context!.setHolding([]);
  };

  const applyAdd = () => {
    if (context!.recurring) {
      //check if holdingAvailability is in removeRecurringAvailability
      //if it is, remove it from removeRecurringAvailability
      context!.setRemoveRecurringAvailability(
        context!.removeRecurringAvailability.filter(
          (x) => !context?.holding.some((y) => y.getTime() === x.getTime())
        )
      );

      //check if holding availability is in recurring already
      //if it is, filter overlap out
      const holdingFiltered =
        context?.holding.filter((x: Date) => {
          //get the day, hour, min of the currently held dates
          const dayX = dayjs(x).day();
          const hourX = dayjs(x).hour();
          const minsX = dayjs(x).minute();

          const overlap = context!.recurringAvailability.filter((y: Date) => {
            const dayY = dayjs(y).day(); //get the day of the existing recurring availability
            const hourY = dayjs(y).hour();
            const minsY = dayjs(y).minute();

            //if day, hour, and min are the same, this is overlap, and should not be included
            return dayY === dayX && hourX === hourY && minsX === minsY;
          });

          return overlap.length === 0;
        }) ?? [];

      console.log("HOLDING FILTERED:", holdingFiltered);

      //update recurring availability accordingly
      context!.setRecurringAvailability([
        ...context!.recurringAvailability,
        ...holdingFiltered,
      ]);
    }
    //if not recurring, it's one-time, and holding availability is all clear to be added
    //(you can't re-add one-time avail that's already selected by nature of the selector)
    else {
      context!.setOneTimeAvailability([
        ...context!.oneTimeAvailability,
        ...(context?.holding ?? []),
      ]);
    }

    //clear holding availability. recurring switch will disappear too
    console.log("CLEARING HOLDING...");
    context!.setHolding([]);
  };

  const applyRemove = () => {
    console.log("REMOVE");
    //check if we're removing one-time or changing recurring availability
    //(we only check the first item because you can't edit recurring and one-time concurrently. whatever the first one is will apply to the rest)

    //if oneTimeAvailability includes what we're removing, remove it from oneTimeAvailability
    if (context?.oneTimeAvailability.some((x) => x === context?.holding[0])) {
      //filter one time availability so it doesn't contain what should be removed
      context!.setOneTimeAvailability(
        context!.oneTimeAvailability.filter(
          (x) => !context?.holding.some((y) => y.getTime() === x.getTime())
        )
      );
    }

    //if not one-time, it's recurring.
    //we are either permanently editing recurring or making a one-time change,
    //depending on the status of the recurring switch
    else {
      //permanent recurring availability
      if (context!.recurring) {
        //we have to find the day and time to match with and edit permanent recurring availability
        context!.setRecurringAvailability(
          context!.recurringAvailability.filter((x: Date) => {
            const dayX = dayjs(x).day(); //get the day of the recurring availability
            const hourX = dayjs(x).hour();
            const minsX = dayjs(x).minute();

            //loop through each Date in toRemove, find day, hour, min
            //and find all overlap
            const overlap = context?.holding.filter((y: Date) => {
              const dayY = dayjs(y).day(); //get the day of the existing recurring availability
              const hourY = dayjs(y).hour();
              const minsY = dayjs(y).minute();

              //if day, hour, and min are the same, this is overlap, and should not be included
              return dayY === dayX && hourX === hourY && minsX === minsY;
            });

            //if this day, hour, min exists in toRemove, filter this out
            return overlap ? overlap.length === 0 : false;
          })
        );
      }
      //making a one-time change to recurring
      else {
        //these are specific Date objects, where we do care about the actual date, not just the day

        context!.setRemoveRecurringAvailability([
          ...context!.removeRecurringAvailability,
          ...(context?.holding ?? []),
        ]);
      }
    }

    console.log("CLEARING HOLDING... apply remove");
    context!.setHolding([]);
  };

  const handleChange = (newAvailability: Date[]) => {
    // console.log("CHANGE");
    if (context?.holding && context?.holding.length > 0) {
      return;
    }

    console.log("CALCULATED", getCalculatedAvailability());

    //add anything that's not currently in calculated availability (it was added)
    //or previous holding availability (it's about to be added, and we haven't updated holding yet)
    const toAdd = newAvailability.filter(
      (x: Date) =>
        !getCalculatedAvailability().some(
          (y) =>
            y.getTime() === x.getTime() &&
            !context?.holding.some((y) => y.getTime() === x.getTime())
        )
    );

    //remove anything that's in calculated availability but not in new (it was deleted)
    const toRemove = getCalculatedAvailability().filter((x: Date) => {
      return !newAvailability.some((y) => y.getTime() === x.getTime());
    });

    //hold all new changes until user presses save
    var holding: Date[] = [];

    console.log("TO ADD", toAdd);
    console.log("TO REMOVE", toRemove);

    if (toAdd.length > 0 && toRemove.length === 0) {
      context?.setChanged(true);
      holding = toAdd;
      context?.setChangeType("adding");
    } else if (toRemove.length > 0) {
      context?.setChanged(true);
      holding = toRemove;
      context?.setChangeType("removing");
    }

    if (holding.length > 0) {
      context?.setChanged(true);
      context?.setHolding(holding);
    }
  };

  const confirmChanges = () => {
    //if holding availability is not empty, apply it
    if (context?.holding.length !== 0) {
      applyAdd();
    }

    const collisions = findCollisions();

    console.log("COLLISIONS", collisions);

    //if there are collisions, show a modal
    if (collisions.length > 0) {
      context!.setCollisions(collisions);
      context!.setCollisionsOpen(true);
      return;
    }

    //if there are no collisions, we can save the changes
    handleSaveAvailability();
  };

  return {
    getRecurringAvailabilityForThisWeek,
    getCalculatedAvailability,
    handleChange,
    confirmChanges,
    applyAdd,
    applyRemove,
    clearHolding,
  };
}

export default useInterviewLogicTime;
