import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AspectRatio, Box, Typography, Button, Stack } from "@mui/joy";
import { useBusinessAuth } from "src/Context/AuthContext";
import { FullBusinessService } from "src/Services/Db/business";
import { BusinessService } from "src/Services/Db/service";
import { Businesses } from "src/Services/Db/types";
import { CircularProgress, Skeleton } from "@mui/material";
import EditBusinessCode from "./EditBusinessCode";
import { PrintRounded } from "@mui/icons-material";
import useToast from "src/Utils/useToast";
import { Amplify, API } from "aws-amplify";
import awsExports from "../../../src/aws-exports";
import { useReactToPrint } from "react-to-print";
import PrintButton from "../Inputs/PrintButton";

Amplify.configure(awsExports);

let apiName = "server";

interface SignComponentProps {
  placeId: string;
}

const SignComponent = ({ placeId }: SignComponentProps) => {
  const { t } = useTranslation(["business"]);
  const toast = useToast();

  const [business, setBusiness] = useState<Businesses | null>(null);
  const [code, setCode] = useState<string>();
  const [name, setName] = useState<string>();
  const [signLoaded, setSignLoaded] = useState(false);
  const [pdfResponse, setPdfResponse] = useState<string>();

  useEffect(() => {
    if (placeId) {
      BusinessService.getOne(placeId).then((res) => {
        setBusiness(res);
        setCode(res?.code);
        setName(res?.name);
      });
    }
  }, [placeId]);

  useEffect(() => {
    /*
    if (code === undefined) {
      toast("business", "business_code_undefined", "", () => {});
      return;
    }
    if (placeDetails?.name === undefined) {
      toast("business", "business_name_undefined", "", () => {});
      return;
    }
    */

    if (code === undefined || name === undefined) {
      return;
    }

    setSignLoaded(false);

    API.post(apiName, "/sign", {
      body: {
        code: code,
        businessName: name,
        placeId: placeId,
      },
    })
      .then((response: { pdf_url: string }) => {
        console.log("response: ", response);
        console.log("response.pdf_url: ", response.pdf_url);
        setPdfResponse(response.pdf_url);
        /*
        let pdfWindow = window.open("");
        pdfWindow?.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            response +
            "'></iframe>"
        );
        */
      })
      .finally(() => setSignLoaded(true));
  }, [code, name, placeId]);

  function handleClick() {
    window.open(pdfResponse, "_blank");
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 2,
        flexWrap: "wrap-reverse",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.level2",
          p: 2,
          borderRadius: 1,
          flexGrow: 1,
          gap: 1,
        }}
      >
        <Box>
          <Typography level="h4" noWrap>
            {business?.name}
          </Typography>
          <Typography level="body1" noWrap>
            {business?.address.split(",")[0]}
          </Typography>
        </Box>

        <EditBusinessCode
          codeStart={business?.code}
          placeId={placeId}
          setCodeParent={setCode}
        />
      </Box>
      <Box>
        <Stack spacing={1}>
          {signLoaded ? (
            <AspectRatio
              sx={{ borderRadius: 1, minWidth: 200 }}
              ratio={4 / 3}
              objectFit="fill"
            >
              <object
                id={`${placeId}-pdf`}
                data={`${pdfResponse}#view=FitH`}
                style={{ width: "100%", height: "100%" }}
              ></object>
            </AspectRatio>
          ) : (
            <Skeleton variant="rounded" width={200} height={150}>
              <CircularProgress />
            </Skeleton>
          )}
          <Button onClick={handleClick}>Print</Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default SignComponent;
