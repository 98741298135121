import {
  useTheme,
  Typography,
  Stack,
  Box,
  List,
  ListItem,
  ListSubheader,
  ListItemButton,
  Modal,
  Divider,
  IconButton,
  Card,
  Button,
  Alert,
  Chip,
  CardOverflow,
  Textarea,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useState, useRef } from "react";

import {
  CalendarMonthRounded,
  CheckCircleOutlineRounded,
  Close,
  CloseRounded,
  InfoRounded,
} from "@mui/icons-material";
import { Timestamp } from "firebase/firestore";

import { Skeleton } from "@mui/material";
import useText from "../Utils/useText";
import useEmail from "../Utils/useEmail";
import PageSpacer from "src/Components/Utilities/PageSpacer";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import "../../src/animations.css";

const ScheduleCall = () => {
  const { t, i18n } = useTranslation("marketing");

  var start = dayjs().hour(8).minute(0).second(0).millisecond(0);
  var end = dayjs().hour(20).minute(0).second(0).millisecond(0);
  let times: Dayjs[] = [];
  for (let day = 0; day < 7; day++) {
    for (let time = start; time.isBefore(end); time = time.add(15, "minute")) {
      times.push(time);
    }
    start = start.add(1, "day");
    end = end.add(1, "day");
  }

  const timesGrouped = times.reduce(
    (acc: { [key: string]: Date[] }, curr: Dayjs) => {
      const day = curr.format("dddd, MMM DD YY");
      if (!acc[day]) {
        acc[day] = []; //create new array for a day if it doesn't exist
      }

      acc[day] = [...acc[day], curr.toDate()];
      acc[day].sort((a, b) => a.getTime() - b.getTime());
      return acc;
    },
    {} as { [key: string]: Date[] }
  );

  const timesGroupedKeys = Object.keys(timesGrouped);

  const { textAlex } = useText();
  const { emailPhoneConfirmation } = useEmail();

  const [active, setActive] = useState<"time" | "info" | "confirm">("time");
  const [selected, setSelected] = useState<Date | null>(null);

  const handleSelection = (slot: Date) => {
    setSelected(slot);
    setActive("info");
  };

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");

  const nodeRef = useRef(null);

  const done = () => {
    setActive("confirm");
    emailPhoneConfirmation(email, phone, name, businessName, selected!);
    textAlex(email, phone, name, businessName, selected!);
  };

  const render = () => {
    if (active === "time") {
      return (
        <Box
          ref={nodeRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "> *": { width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" } },
            pb: 20,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography
              color="primary"
              level="h1"
              variant="solid"
              sx={{
                mt: {
                  xs: "3vh",
                  md: "10vh",
                },
                borderRadius: 1,
                p: 4,
                lineHeight: "120%",
                m: 0,
              }}
            >
              {t("hello")}
            </Typography>

            <Typography
              color="primary"
              level="h3"
              variant="soft"
              sx={{
                borderRadius: 1,
                p: 2,
                lineHeight: "120%",
                m: 0,
              }}
            >
              {t("schedule")}
            </Typography>

            <Typography
              color="info"
              level="h5"
              variant="soft"
              sx={{
                borderRadius: 1,
                p: 2,
                lineHeight: "120%",
                m: 0,
              }}
              startDecorator={<InfoRounded />}
            >
              {t("what_candidates_see")}
            </Typography>

            <Card
              sx={{
                maxHeight: "80vh",
              }}
            >
              <CardOverflow
                sx={{
                  overflowY: "auto",
                }}
              >
                <List
                  sx={{
                    p: 0,
                    m: 0,
                  }}
                >
                  {timesGroupedKeys.map((key, headerIndex) => (
                    <ListItem nested key={headerIndex}>
                      <ListSubheader
                        sticky
                        sx={{ bgcolor: "header.bg", pb: 0 }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "1.5rem",
                              textTransform: "none",
                              letterSpacing: "normal",
                              pb: 1.5,
                              pt: 1,
                              ml: -1.5,
                            }}
                          >
                            {dayjs(key)
                              .locale(i18n.language)
                              .format("dddd, MMM D, YYYY")}
                          </Typography>
                          <Divider sx={{ mx: -3.5, opacity: 1 }} />
                        </Box>
                      </ListSubheader>
                      <List>
                        {timesGrouped[key].map((slot, index) => (
                          <ListItem key={index} sx={{ ml: -1 }}>
                            <ListItemButton
                              onClick={() => handleSelection(slot)}
                              sx={{ "&:hover": { bgcolor: "transparent" } }}
                            >
                              <Card
                                sx={{
                                  width: "100%",
                                  "&:hover": { bgcolor: "#EEEEEE" },
                                  boxShadow: "0 0 0 0",
                                  borderWidth: 1,
                                  py: 1.5,
                                  fontWeight: "500",
                                }}
                              >
                                {dayjs(slot)
                                  .locale(i18n.language)
                                  .format("dddd h:mm A")}
                              </Card>
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </ListItem>
                  ))}
                </List>
              </CardOverflow>
            </Card>
          </Box>
        </Box>
      );
    } else if (active === "info") {
      return (
        <Box
          ref={nodeRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "> *": { width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" } },
            pb: 20,
          }}
        >
          <Stack spacing={2}>
            <Typography
              color="primary"
              level="h1"
              variant="solid"
              sx={{
                mt: {
                  xs: "3vh",
                  md: "10vh",
                },
                borderRadius: 1,
                p: 4,
                lineHeight: "120%",
                m: 0,
              }}
            >
              {dayjs(selected).format("H:mma, dddd, MMMM D")}
            </Typography>

            <Button onClick={() => setActive("time")} variant="plain">
              {t("change_time")}
            </Button>

            <Card>
              <Typography level="cardHeader">{t("enter_info")}</Typography>

              <Box
                sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box>
                  <FormLabel>{t("email")}</FormLabel>
                  <Textarea
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Box>

                <Box>
                  <FormLabel>{t("phone")}</FormLabel>
                  <Textarea
                    required
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Box>

                <Box>
                  <FormLabel>{t("name")}</FormLabel>
                  <Textarea
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>

                <Box>
                  <FormLabel>{t("business_name")}</FormLabel>
                  <Textarea
                    required
                    onChange={(e) => setBusinessName(e.target.value)}
                  />
                </Box>

                <Button onClick={() => done()}>{t("confirm")}</Button>
              </Box>
            </Card>
          </Stack>
        </Box>
      );
    } else if (active === "confirm") {
      return (
        <Box
          ref={nodeRef}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "> *": { width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" } },
            pb: 20,
          }}
        >
          <Stack spacing={2}>
            <Typography
              color="primary"
              level="h1"
              variant="solid"
              sx={{
                mt: {
                  xs: "3vh",
                  md: "10vh",
                },
                borderRadius: 1,
                p: 4,
                lineHeight: "120%",
              }}
            >
              {t("thanks_talk_soon")}
            </Typography>
          </Stack>
        </Box>
      );
    }
  };

  return (
    <SwitchTransition mode={"out-in"}>
      <CSSTransition
        key={active}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="fade"
      >
        {render()}
      </CSSTransition>
    </SwitchTransition>
  );
};

export default ScheduleCall;
