import { useEffect, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { KeyboardBackspace, Phone, Schedule } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Stack,
  Card,
  CardContent,
  Typography,
  Box,
  List,
  ListItem,
  Chip,
  Divider,
} from "@mui/joy";
import Candiate from "../Components/Common/Candidate";
import ExperienceItem from "../Components/Common/ExperienceItem";
import EducationItem from "../Components/Common/EducationItem";
import ReferenceItem from "../Components/Common/ReferenceItem";
import dayjs, { Dayjs } from "dayjs";

import AppBarContext from "../Context/AppBarContext";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX_MY,
  HEADER_TYPES,
} from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import PageSpacer from "../Components/Utilities/PageSpacer";
import {
  Education,
  Experiences,
  Item,
  References,
  UserData,
} from "../Services/Db/types";
import { FullUserService } from "../Services/Db/user";
import useKeyToDict from "src/Utils/useKeyToDict";
import ScheduleSelector from "react-schedule-selector";

const CandidateProfile = () => {
  const navigate = useNavigate();

  const context = useContext(AppBarContext);
  const { keyToDict } = useKeyToDict("lists");

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const location = useLocation();
  const uid = location.state.uid;
  const name = location.state.name;
  const reqs = location.state.reqs;
  const custom_answers = location.state.custom_answers ?? [];

  const [experiences, setExperiences] = useState<Experiences[] | null>(null);
  const [education, setEducation] = useState<Education>();
  const [references, setReferences] = useState<References[]>([]);
  const [languages, setLanguages] = useState<string[]>([]);
  const [availability, setAvailability] = useState<string[]>([]);
  const [start, setStart] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (uid) {
      const loadUserData = async () => {
        const userData = await FullUserService.loadFullUser(uid);
        if (reqs.includes("experiences") && userData.experiences) {
          setExperiences(userData.experiences);
        }
        if (reqs.includes("experiences") && userData.noExperience) {
          setExperiences(null);
        }
        if (reqs.includes("education") && userData.education) {
          setEducation(userData.education);
        }
        if (reqs.includes("references") && userData.references) {
          setReferences(userData.references);
        }
        if (reqs.includes("languages") && userData.languages) {
          setLanguages(userData.languages);
        }
        if (
          reqs.includes("availability") &&
          userData.availability &&
          userData.start
        ) {
          setAvailability(userData.availability);
          setStart(dayjs(userData.start.toDate()));
        }
      };
      loadUserData();
    }
  }, [uid]);

  const { t, i18n } = useTranslation(["candidate", "business", "common"]);
  const languages_dict = keyToDict("languages");

  return (
    <PageSpacer>
      <Stack spacing={4}>
        <Box>
          <Button
            variant="outlined"
            color="primary"
            startDecorator={<KeyboardBackspace />}
            onClick={() => navigate(APP_ROUTES.CANDIDATES)}
          >
            {t("common:back")}
          </Button>
        </Box>

        <Card>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              level="cardHeader"
              sx={{
                cursor: "pointer",
              }}
            >
              {name}
            </Typography>
            <IconButton
              variant="plain"
              sx={{ color: "text.primary" }}
              onClick={() => {
                window.open(`tel:${uid}`);
              }}
            >
              <Phone />
            </IconButton>
          </Box>
        </Card>

        {reqs.length === 0 && (
          <Card>
            <Typography level="cardHeader">
              {t("no_requirements", { ns: ["business"] })}
            </Typography>
          </Card>
        )}

        {experiences === null && (
          <Card>
            <Typography level="cardHeader">
              {t("no_experience", { ns: ["business"] })}
            </Typography>
          </Card>
        )}

        {!!experiences && experiences.length > 0 && (
          <Card>
            <Typography level="cardHeader">
              {t("experience", { ns: ["business"] })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Stack spacing={3} divider={<Divider />}>
              {experiences.map((exp) => (
                <ExperienceItem
                  role={exp.role!}
                  businessRef={exp.businessRef}
                  job_type={exp.employmentType}
                  start_date={dayjs.unix(exp.startDate.seconds)}
                  end_date={
                    exp.endDate ? dayjs.unix(exp.endDate.seconds) : undefined
                  }
                  reason_for_leaving={exp.departureReason ?? undefined}
                />
              ))}
            </Stack>
          </Card>
        )}

        {education && (
          <Card>
            <Typography level="cardHeader">
              {t("education", { ns: ["business"] })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />

            <EducationItem
              studied={education.studied}
              school_type={education.type}
              start_date={dayjs(education.startDate.toDate())}
              end_date={
                education.graduationDate
                  ? dayjs(education.graduationDate.toDate())
                  : null
              }
            />
          </Card>
        )}

        {custom_answers.map((item: Item) => (
          <Card>
            <Typography level="cardHeader">{item.category}</Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Typography level="body1">{item.item}</Typography>
          </Card>
        ))}

        {availability.length > 0 && start && (
          <Card>
            <Typography level="cardHeader">
              {t("availability_builder:header")}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box>
                <Chip variant="outlined" size="lg">
                  {t("availability_builder:can_start_on", {
                    start: start
                      .locale(i18n.language)
                      .format("dddd, MMMM D, YYYY"),
                  })}
                </Chip>
              </Box>

              <ScheduleSelector
                selection={availability.map((date) => {
                  const [day, hour] = date.split("-");
                  return dayjs()
                    .day(parseInt(day))
                    .hour(parseInt(hour))
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .toDate();
                })}
                startDate={dayjs().startOf("week").toDate()}
                disabled
                minTime={5}
                maxTime={29}
                hourlyChunks={1}
                renderDateLabel={(date: any) => {
                  return (
                    <Typography textAlign={"left"} sx={{ fontWeight: "500" }}>
                      {dayjs(date).locale(i18n.language).format("ddd")}
                    </Typography>
                  );
                }}
                renderTimeLabel={(time: any) => {
                  const t = dayjs(time).locale(i18n.language);

                  return (
                    <Typography textAlign={"right"} sx={{ fontWeight: "500" }}>
                      {t.format("h")}
                      <Typography level="body2">{t.format("a")}</Typography>
                    </Typography>
                  );
                }}
                renderDateCell={(
                  datetime: Date,
                  selected: boolean,
                  refSetter: (dateCell: HTMLElement | null) => void
                ) => {
                  return (
                    <Box
                      ref={refSetter}
                      sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        backgroundColor: selected ? "primary.main" : "#DDD",
                        color: "common.white",
                        fontSize: "0.8rem",
                        p: 0.25,
                        borderRadius: 0.25,
                      }}
                    />
                  );
                }}
              />
            </Box>
          </Card>
        )}

        {references.length > 0 && (
          <Card>
            <Typography level="cardHeader">
              {t("personal_references", { ns: ["business"] })}
            </Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />

            <Stack spacing={3} divider={<Divider />}>
              {references.map((ref) => (
                <ReferenceItem
                  name={ref.name}
                  referenceType={ref.relationship}
                  phone={ref.phone}
                  email={ref.email}
                />
              ))}
            </Stack>
          </Card>
        )}

        {languages.length > 0 && (
          <Card>
            <Typography level="cardHeader">{t("apply:languages")}</Typography>
            <Divider sx={DIVIDER_HEADER_SX_MY} />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {languages.map((lang) => (
                <Chip color="neutral" variant="soft" size="lg">
                  {languages_dict[lang]}
                </Chip>
              ))}
            </Box>
          </Card>
        )}
      </Stack>
    </PageSpacer>
  );
};

export default CandidateProfile;
