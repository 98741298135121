import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate, useLocation, generatePath } from "react-router-dom";
import { IconButton, Typography, Box, Divider, Stack, Alert } from "@mui/joy";
import { NewReleasesRounded, OpenInNewRounded } from "@mui/icons-material";
import SelectGroup from "../Components/Inputs/SelectGroup";
import AppBarContext from "../Context/AppBarContext";
import {
  APP_ROUTES,
  HEADER_TYPES,
  DIVIDER_HEADER_SX_MY,
} from "../Utils/Constants";
import PageSpacer from "../Components/Utilities/PageSpacer";
import { useTranslation } from "react-i18next";
import useKeyToDict from "../Utils/useKeyToDict";
import SectionCreator from "../Components/Business/SectionCreator";
import SectionView from "../Components/Business/SectionView";
import SectionOrder from "../Components/Common/SectionOrder";
import { useBusinessAuth } from "../Context/AuthContext";
import { Item } from "../Services/Db/types";
import { FullBusinessService } from "../Services/Db/business";
import { DocumentReference } from "firebase/firestore";
import { usePlaceDetails } from "src/Components/Maps/PlaceDetails";
import LocationDropdown from "src/Components/Inputs/LocationDropdown";
import SelectLocationAlert from "src/Components/Business/SelectLocationAlert";
import { BusinessService } from "src/Services/Db/service";
import SignComponent from "src/Components/Business/SignComponent";

const SignMaker = () => {
  const navigate = useNavigate();
  const context = useContext(AppBarContext);
  const { t } = useTranslation(["business"]);

  const { business } = useBusinessAuth();

  useEffect(() => {
    context?.setHeaderType(HEADER_TYPES.BUSINESS);
  });

  const [locations, setLocations] = useState<string[]>([]);

  useEffect(() => {
    if (business) {
      FullBusinessService.getBusinessProfile(business.email!).then((res) => {
        if (res?.locations) {
          setLocations(res.locations.map((l) => l.id));
        }
      });
    }
  }, [business]);

  return (
    <React.Fragment>
      <PageSpacer>
        <Stack spacing={2}>
          <Typography level="sectionHeader">
            {t("sign_maker.header")}
          </Typography>
          <Typography level="h4">{t("sign_maker.header_sub")}</Typography>
          <Alert startDecorator={<NewReleasesRounded />}>
            {t("sign_maker.header_sub_2")}
          </Alert>
          {locations.map((id) => (
            <Fragment key={id}>
              <SignComponent placeId={id} />
            </Fragment>
          ))}
        </Stack>
      </PageSpacer>
    </React.Fragment>
  );
};

export default SignMaker;
