import { Businesses, Listings, Messages } from "../Services/Db/types";
import { BusinessService } from "../Services/Db/service";
import { DocumentReference } from "firebase/firestore";
import { useAuth } from "../Context/AuthContext";
import useKeyToDict from "./useKeyToDict";

import { Amplify, API } from "aws-amplify";
import awsExports from "../../src/aws-exports";
import {
  ApplicationsService,
  InterviewsService,
  UserService,
  MessagesService,
} from "../Services/Db/service";
import { getApplicationInfo } from "../Services/Db/utils";
import { User } from "firebase/auth";
import dayjs from "dayjs";
Amplify.configure(awsExports);

let apiName = "server";

function useText() {
  const { user } = useAuth();

  const clearLastMessageAndCreateNewMessage = async (
    uid: string,
    sentMessage: Messages
  ) => {
    const userBeingMessaged = await UserService.getOne(uid);

    //remove prev message from the database
    MessagesService.remove(
      userBeingMessaged?.currentTextSessionLastMessage?.id!
    );

    //create new message to be tracked
    MessagesService.create({
      sentMessage,
    }).then(async (message) => {
      //set the user's current text session
      await UserService.update(uid, {
        currentTextSessionLastMessage: message,
      });
    });
  };

  const sendText = async (message: string, phone?: string) => {
    const data = { body: { to: phone || user?.phoneNumber!, body: message } };

    console.log("SENT with data:", data);

    await API.post(apiName, "/text", data)
      .then((response: string) => {
        console.log("SENT TEXT with response", response);
      })
      .catch((error: string) => {
        console.log("ERROR SENDING TEXT", error);
      });
  };

  const textAdminsAlert = (message: string) => {
    sendText(message, process.env.REACT_APP_ADMIN_PHONE_1);
    sendText(message, process.env.REACT_APP_ADMIN_PHONE_2);
  };

  const textAlex = (
    email: string,
    phone: string,
    name: string,
    business: string,
    slot: Date
  ) => {
    const message = `${name} (${phone}, ${email}) wants a call at ${dayjs(
      slot
    ).format("H:mma, dddd, MMMM D")}`;
    sendText(message, process.env.REACT_APP_ADMIN_PHONE_2);
  };

  const textInviteToInterview = (
    business: Businesses | null,
    phone: string
  ) => {
    sendText(
      `You've been invited to interview! Go to www.hourlead.com/applications to select your interview time with ${business?.name}!`,
      phone
    );
  };

  const getBusinessName = async (businessRef: DocumentReference) => {
    const loadedBusiness = await BusinessService.getResolvedReference(
      businessRef
    );
    return loadedBusiness?.name;
  };

  const { getRoleNameFromRole } = useKeyToDict("lists");

  const textConfirmApplication = (listing: Listings) => {
    const role = getRoleNameFromRole({
      category: listing.category,
      item: listing.role,
    });

    if (listing.businessRef) {
      getBusinessName(listing.businessRef).then((name) => {
        sendText(
          `We got your application for ${role} at ${name}. If you're a good fit, ${name} will contact you to schedule an interview.`
        );
      });
    } else {
      sendText(`We got your application for ${role}`);
    }
  };

  const textRescheduleInterview = (business: string, phone: string) => {
    sendText(
      `${business}'s availability has changed, please reschedule your interview at www.hourlead.com/applications.`,
      phone
    );
  };

  const textInviteToInterviewReminder = (
    businessName: string,
    phone: string
  ) => {
    sendText(
      `Don't forget to schedule your interview with ${businessName} at www.hourlead.com/applications!`,
      phone
    );
  };

  const textCancelInterview = () => {
    sendText("Interview cancelled.");
  };

  const _getReminderMsg = (business: string, msg?: string) => {
    if (msg !== undefined && msg !== null && msg.length > 0) {
      return `\n\n${business} says, "${msg}"`;
    }
    return "";
  };

  const textConfirmInterviewReminder = (
    phone: string,
    business: string,
    interviewId: string
  ) => {
    const msg = `Confirm your interview with ${business} at www.hourlead.com/interviewConfirmation/${interviewId}!`;
    sendText(msg, phone);
  };

  const textConfirmInterview = (
    applicationId: string,
    interviewDate: string
  ) => {
    getApplicationInfo(applicationId).then((info) => {
      const { businessName, role, reminderMsg } = info;
      sendText(
        `Your interview for ${getRoleNameFromRole(
          role
        )} at ${businessName} is confirmed for ${interviewDate}!${_getReminderMsg(
          businessName,
          reminderMsg
        )}`
      );
    });
  };

  return {
    textInviteToInterview,
    textRescheduleInterview,
    textCancelInterview,
    textConfirmApplication,
    textInviteToInterviewReminder,
    textAdminsAlert,
    textConfirmInterview,
    textConfirmInterviewReminder,
    textAlex,
    sendText,
  };
}

export default useText;
