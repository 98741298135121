import { useTranslation } from "react-i18next";
import { Item } from "../Services/Db/types";

function useKeyToDict(ns: string) {
  const { t } = useTranslation(ns);

  const keyToObjArray = (key: string, key2?: string) => {
    const jsonObject: { [key: string]: any } = t(key, {
      returnObjects: true,
      ns: [ns],
    });

    //inner json
    if (key2 || key2 === "") {
      //failsafe in case we mess up json and key isn't accounted for
      if (!(key2 in jsonObject)) {
        key2 = "other"; //ensure we have a key "other" with default values
      }
      const jsonObject20: { [key: string]: any } = jsonObject[key2];
      if (jsonObject20 === undefined) {
        return [];
      }
      return Object.keys(jsonObject20).map((key) => [key, jsonObject20[key]]);
    }

    return Object.keys(jsonObject).map((key) => [key, jsonObject[key]]);
  };

  const keyToDict = (key: string, key2?: string) => {
    const jsonObject: { [key: string]: any } = t(key, {
      returnObjects: true,
      ns: [ns],
    });

    if (key2 === undefined) {
      const ret = Object.fromEntries(
        Object.keys(jsonObject).map((key) => {
          return [key, jsonObject[key]];
        })
      );

      return ret;
    }

    //inner json

    //failsafe in case we mess up json and key isn't accounted for
    if (!(key2 in jsonObject)) {
      key2 = "other"; //ensure we have a key "other" with default values
    }
    const jsonObject4: { [key: string]: any } = jsonObject[key2];

    if (jsonObject4 === undefined) {
      return {};
    }

    const ret = Object.fromEntries(
      Object.keys(jsonObject4).map((key) => {
        return [key, jsonObject4[key]];
      })
    );

    return ret;
  };

  const getItemValueFromItemAndHeader = (item: Item, headerKey: string) => {
    const list = keyToDict(headerKey);
    console.log(list);
    if (list[item.category] === undefined) {
      return undefined;
    }
    return list[item.category][item.item];
  };

  const getCategory = (headersKey: string, category: string) => {
    const headers = keyToDict(headersKey);
    return headers[category];
  };

  const itemsToValues = (key: string, items: Item[]) => {
    const jsonObject: { [key: string]: any } = t(key, {
      returnObjects: true,
      ns: [ns],
    });

    const ret = items.map((item: Item) => {
      const key = item.item;
      const category = item.category;

      if (!(category in jsonObject)) {
        return [key];
      }

      return jsonObject[category][key];
    });

    return ret;
  };

  const keyToValueInList = (key: string | null, listKey: string) => {
    if (key === null) {
      return "";
    }

    const jsonObject: { [key: string]: any } = t(listKey, {
      returnObjects: true,
      ns: [ns],
    });

    return jsonObject[key];
  };

  const getRoleNameFromRole = (role: Item | null) => {
    if (!!role) {
      const dict: { [key: string]: any } = keyToDict("roles", role.category);
      return dict[role.item];
    }
    return "";
  };

  const faqArray = (key: string) => {
    const jsonObject: { [key: string]: any } = t(key, {
      returnObjects: true,
      ns: [ns],
    });

    return jsonObject;
  };

  return {
    keyToObjArray,
    keyToValueInList,
    faqArray,
    keyToDict,
    getRoleNameFromRole,
    itemsToValues,
    getCategory,
    getItemValueFromItemAndHeader,
  };
}

export default useKeyToDict;
