import {
  Stack,
  Typography,
  Box,
  Button,
  Input,
  Card,
  CardContent,
  CardOverflow,
  AspectRatio,
  Alert,
  Divider,
} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext, Dispatch } from "react";
import AppBarContext from "../Context/AppBarContext";
import { CheckCircle, Edit, Paid } from "@mui/icons-material";

import { Stepper, Step, StepContent } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  HEADER_TYPES,
  USER_TYPES,
  DIVIDER_HEADER_SX_MY,
} from "../Utils/Constants";

// Role
import SelectGroupSingle from "../Components/Inputs/SelectGroupSingle";

// Components
import FAQ from "../Components/NewUserFlow/FAQ";
import { APP_ROUTES } from "../Utils/Constants";
import StepNumber from "../Components/NewUserFlow/StepNumber";
import { NewUserAction } from "./NewUserFlow";

const FAQs = {
  role: [
    {
      question:
        "We need to know your most recent role to give you personalized job recommendations",
      answer:
        "For instance, if you were a barista making $13 an hour, we'll show you similar barista roles that pay more in your area.",
    },
  ],
  wage: [
    {
      question:
        "Over 93% of Gen-Z and Millenials are comfortable sharing their wages",
      answer:
        "Wage transparency is the future!\n\nRest assured that any and all info you share is completely anonymous — we don't even know your name!",
    },
    {
      question: "Why we ask you to share your wage",
      answer:
        "Employers know things (like wage) that job-seekers don't. We depend on information pooled from businesses and our community to help you get a better paying job.",
    },
  ],
  location: [
    {
      question:
        "Everything you share on Hourlead is anonymous. We don’t even know your name!",
      answer: "Don't worry: your information is fully anonymous.",
    },
  ],
};

interface Props {
  dispatch: Dispatch<NewUserAction>;
  //setUserType: (userType: USER_TYPES) => void;
  //setStep: (step: "selectUserType" | "stepper" | "phone") => void;
}

const NotEmployedStepper: React.FC<Props> = ({ dispatch }) => {
  const navigate = useNavigate();

  const { t } = useTranslation(["common", "user_flow", "lists"]);

  const [role, setRole] = useState<string | null>(null);

  const [wage, setWage] = useState("");
  const [tips, setTips] = useState("");

  const [address, setAddress] = useState("123 Main St, New York, NY");
  const [businessName, setBusinessName] = useState("Monuts");
  const [editBusinessName, setEditBusinessName] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [completedTo, setCompletedTo] = useState(0);

  const handleNext = () => {
    //if we're moving to the next step, we've completed the current step, so we set completedTo to the next step
    setCompletedTo((prevCompletedTo) =>
      Math.max(prevCompletedTo, activeStep + 1)
    );
    //move on to the next step
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleWage = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setWage(e.target.value);
    }
  };

  const handleTips = (e: any) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value == "" || regex.test(e.target.value)) {
      setTips(e.target.value);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleTouch = (step: number) => {
    if (step <= completedTo) {
      setActiveStep(step);
    }
  };

  var employment_options = t("employment_options", {
    returnObjects: true,
    ns: ["lists"],
  });

  const employment_options_dict: Record<string, string>[] = Object.values(
    employment_options
  ).reduce((acc, curr) => {
    const key = Object.keys(curr)[0];
    acc[key] = curr[key];
    return acc;
  }, {});

  console.log("employment_options_dict", employment_options_dict);

  return (
    <Stack>
      <Stepper activeStep={activeStep} orientation="vertical" sx={{ mt: -2 }}>
        <Step key={"role"}>
          <StepNumber
            stepNumber={1}
            title={"Role"}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />

          <StepContent>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "end",
                  flexWrap: "wrap",
                }}
              >
                <Typography level="h6" sx={{ fontWeight: "bold" }}>
                  {t("what_was_your_most_recent_role", { ns: ["user_flow"] })}
                </Typography>
                <Typography
                  sx={{
                    cursor: "pointer",
                    "&:hover": { textDecoration: "underline" },
                  }}
                  level="body2"
                >
                  {t("never_been_employed", { ns: ["user_flow"] })}
                </Typography>
              </Box>

              <Divider sx={DIVIDER_HEADER_SX_MY} />

              <SelectGroupSingle
                handleNext={handleNext}
                selected={role}
                setSelected={setRole}
                items={employment_options_dict}
              />
            </Card>

            <FAQ section="role" />
          </StepContent>
        </Step>

        <Step key={"wage"}>
          <StepNumber
            stepNumber={2}
            title={"Wage"}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />
          <StepContent>
            <Card>
              <Typography level="h5" sx={{ fontWeight: "bold" }}>
                {t("what_was_your_hourly_wage", { ns: ["user_flow"] })}
              </Typography>

              <Divider sx={DIVIDER_HEADER_SX_MY} />

              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                <Input
                  placeholder={t("base_wage", { ns: ["user_flow"] }) || ""}
                  onChange={(e) => {
                    handleWage(e);
                  }}
                  autoFocus
                  startDecorator="$"
                  endDecorator={
                    <Typography level="body2">
                      {t("/hr", { ns: ["common"] })}
                    </Typography>
                  }
                  value={wage}
                  sx={{ mr: 2, width: 200 }}
                />
                {Boolean(wage) ? (
                  <Input
                    placeholder={
                      t("tips_placeholder", { ns: ["user_flow"] }) || ""
                    }
                    onChange={(e) => {
                      handleTips(e);
                    }}
                    startDecorator="$"
                    endDecorator={
                      <Typography level="body2">
                        {t("/hr", { ns: ["common"] })}
                      </Typography>
                    }
                    value={tips}
                    sx={{ width: 200 }}
                  />
                ) : null}
              </Box>
            </Card>

            {Boolean(wage) ? (
              <Stack spacing={2}>
                <Alert
                  sx={{ mt: 2 }}
                  startDecorator={<Paid />}
                  color={Boolean(wage) ? "success" : "neutral"}
                >
                  <Typography>
                    {t("you_could_make", {
                      ns: ["user_flow"],
                    })}{" "}
                    <Typography sx={{ fontWeight: "bold" }}>
                      {Boolean(wage) ? "$4" : "$X"}{" "}
                      {t("more_per_hour", {
                        ns: ["user_flow"],
                      })}
                    </Typography>{" "}
                    {t("as_a_role_in_location", {
                      ns: ["user_flow"],
                      role: role,
                      location: "Durham",
                    })}
                  </Typography>
                </Alert>
                <Button
                  variant="solid"
                  onClick={() => {
                    handleNext();
                  }}
                  fullWidth
                >
                  {t("next", {
                    ns: ["common"],
                  })}
                </Button>
              </Stack>
            ) : (
              <FAQ section="wage" />
            )}
          </StepContent>
        </Step>

        <Step key={"map"}>
          <StepNumber
            stepNumber={3}
            title={"Location"}
            completedTo={completedTo}
            handleTouch={handleTouch}
          />
          <StepContent>
            <Card>
              <CardOverflow>
                <AspectRatio ratio={4}>
                  <Box
                    sx={{
                      backgroundColor: "cornflowerblue",
                    }}
                  >
                    <Typography level="h4" sx={{ p: 2, color: "common.white" }}>
                      MAP GOES HERE (like George's demo)
                    </Typography>
                  </Box>
                </AspectRatio>
              </CardOverflow>

              <CardContent sx={{ mt: 2 }}>
                <Stack direction="row">
                  {editBusinessName ? (
                    <Input
                      value={businessName}
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                  ) : (
                    <Typography level="h4">{businessName}</Typography>
                  )}
                  <Button
                    variant="plain"
                    sx={{ ml: 1, px: 1 }}
                    onClick={() => setEditBusinessName(!editBusinessName)}
                  >
                    {editBusinessName ? (
                      <CheckCircle sx={{ color: "green" }} />
                    ) : (
                      <Edit />
                    )}
                  </Button>
                </Stack>
                <Typography level="body1">{address}</Typography>
              </CardContent>
            </Card>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
              <Button
                color="neutral"
                variant="outlined"
                onClick={() => handleBack()}
              >
                {t("back", {
                  ns: ["common"],
                })}
              </Button>
              <Button
                variant="solid"
                color={Boolean(wage) ? "primary" : "neutral"}
                fullWidth
              >
                Get Started
              </Button>
            </Stack>

            <FAQ section="location" />
          </StepContent>
        </Step>
      </Stepper>
    </Stack>
  );
};

export default NotEmployedStepper;
