import React from "react";
import { styled, alpha } from "@mui/material/styles";
import StyledInputBase from "@mui/material/InputBase";
import {
  Search as SearchIcon,
  PlaceOutlined as PlaceOutlinedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
import { Button, Chip, Box, Divider, IconButton } from "@mui/joy";
import "./locationSearchStyles.css";
import Autocomplete from "./Autocomplete";
import { MapContext } from "../../Context/MapContext";

export const SearchBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1.2px solid #D8D8DE",
  backgroundColor: "none",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

// FIXME style autocomplete dropdown at link below
// https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
const HeaderLocationSearch: React.FC = () => {
  const mapContext = React.useContext(MapContext);
  return (
    <SearchBox
      sx={{
        "&:hover": {
          backgroundColor: "grey.50",
        },
        height: "2.5rem",
      }}
    >
      <Box
        sx={{
          width: "3rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          // bgcolor: "grey.50",
          height: "100%",
          borderTopLeftRadius: 9,
          borderBottomLeftRadius: 9,
        }}
      >
        <SearchIcon sx={{ fontSize: "1.25rem" }} />
      </Box>

      <Divider orientation="vertical" sx={{ opacity: 1 }} />
      <Autocomplete
      //setBounds={mapContext?.setBounds}
      //setZoom={mapContext?.setZoom}
      //setCenter={mapContext?.setCenter}
      //setPlaceId={mapContext?.setPlaceId}
      />
    </SearchBox>
  );
};

export default HeaderLocationSearch;
