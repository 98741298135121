import React from "react";

export function usePlaceDetails(placeId: string | undefined, fields: string[]) {
  const placesService = new google.maps.places.PlacesService(
    document.createElement("div")
  );

  const [placeDetails, setPlaceDetails] =
    React.useState<google.maps.places.PlaceResult>();

  function placesCallback(
    place: google.maps.places.PlaceResult | null,
    status: google.maps.places.PlacesServiceStatus
  ) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
      if (place) {
        setPlaceDetails(place);
      }
    }
  }

  React.useEffect(() => {
    if (placeId) {
      const request = {
        placeId: placeId,
        fields: fields,
      };
      placesService.getDetails(request, placesCallback);
    }
  }, [placeId]);

  return placeDetails;
}
