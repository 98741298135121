import * as React from "react";
import { generatePath, useNavigate } from "react-router-dom";
import {
  Card,
  Divider,
  Typography,
  Button,
  Box,
  Stack,
  LinearProgress,
  Chip,
  Modal,
  IconButton,
} from "@mui/joy";
import {
  APP_ROUTES,
  DIVIDER_HEADER_SX_MY,
  DIVIDER_HEADER_SX_NO_Y,
} from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import {
  WysiwygRounded,
  SendRounded,
  CloseRounded,
  RemoveCircleRounded,
  ScheduleRounded,
} from "@mui/icons-material";
import useToast from "../../Utils/useToast";
import { Applications, Businesses, Listings } from "../../Services/Db/types";
import {
  ApplicationsService,
  BusinessService,
  ListingsService,
} from "../../Services/Db/service";
import useKeyToDict from "../../Utils/useKeyToDict";
import { useAuth } from "../../Context/AuthContext";
import { FullUserService } from "../../Services/Db/user";
import { DocumentReference, Timestamp } from "firebase/firestore";
import dayjs from "dayjs";
import { SelectInterviewSlotModal } from "../Inputs/SelectInterviewSlot";
import useConfirm from "../../Utils/useConfirm";
import useText from "../../Utils/useText";
import PopupSlider from "../Apply/ApplySteps";
import RoleHeader from "./RoleHeader";

interface RoleProps {
  listingId: string;
  displayingFull?: boolean;
}

const RoleGeneric: React.FC<RoleProps> = ({ listingId, displayingFull }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const toast = useToast();
  const [listing, setListing] = React.useState<Listings | null>(null);
  const loadedListing = React.useRef(false);
  const [business, setBusiness] = React.useState<Businesses | null>();
  const loadedBusiness = React.useRef(false);
  const { getRoleNameFromRole } = useKeyToDict("lists");
  const { user } = useAuth();

  const [showApplyModal, setShowApplyModal] = React.useState(false);
  const [applyModalHeader, setApplyModalHeader] = React.useState("");

  React.useEffect(() => {
    if (!loadedListing.current) {
      const loadListing = async (id: string) => {
        await ListingsService.getOne(id).then((listing) => {
          setListing(listing);
          loadedListing.current = true;
        });
      };

      loadListing(listingId);
    }
  }, [listingId]);

  React.useEffect(() => {
    if (listing && !loadedBusiness.current) {
      const getBusinessInfo = async (businessRef: DocumentReference) => {
        const business = await BusinessService.getResolvedReference(
          businessRef
        );
        setBusiness(business);
      };
      getBusinessInfo(listing.businessRef!);
    }
  }, [listing]);

  return (
    <React.Fragment>
      <Card>
        <RoleHeader listing={listing} business={business} />
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "nowrap" },
            gap: { xs: 1, md: 2 },
            alignItems: "center",
          }}
        >
          {!displayingFull && (
            <Button
              color="primary"
              fullWidth
              onClick={() => {
                navigate(
                  generatePath(APP_ROUTES.ROLE, {
                    listingId: listingId,
                  })
                );
              }}
              startDecorator={<WysiwygRounded fontSize="small" />}
            >
              {t("view", { ns: ["common"] })}
            </Button>
          )}
          <Button
            color="primary"
            fullWidth
            onClick={() => setShowApplyModal(true)}
            startDecorator={<SendRounded fontSize="small" />}
          >
            {t("apply", { ns: ["common"] })}
          </Button>
        </Box>
      </Card>
      {/* MODALS */}
      <Modal
        open={showApplyModal}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 4,
        }}
        onClose={() => setShowApplyModal(false)}
      >
        <Card
          sx={{
            maxHeight: { xs: "80vh", sm: "70vh" },
            width: { xs: "90%", sm: "70%", md: "60%", lg: "50%" },

            pb: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography level="cardHeader">{applyModalHeader}</Typography>

            <IconButton
              variant="plain"
              color="primary"
              size="md"
              onClick={() => {
                setShowApplyModal(false);
              }}
            >
              <CloseRounded />
            </IconButton>
          </Box>

          <Divider sx={DIVIDER_HEADER_SX_MY} />
          {listing && (
            <PopupSlider
              setHeader={setApplyModalHeader}
              placeId={listing.businessRef?.id!}
              directApplyToListing={listing}
            />
          )}
        </Card>
      </Modal>
    </React.Fragment>
  );
};

export default RoleGeneric;
