import useKeyToDict from "../../Utils/useKeyToDict";
import { Listings, PlaceDetails } from "../../Services/Db/types";
import { usePlaceDetails } from "../Maps/PlaceDetails";
import React, { useEffect } from "react";
import {
  Stack,
  Button,
  Box,
  AspectRatio,
  Typography,
  Divider,
  Chip,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import {
  CheckCircleOutlineRounded,
  CheckRounded,
  ChevronRight,
} from "@mui/icons-material";
import { APP_ROUTES, DEFAULT_MARKER_VALS } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";

interface ReceiptStepProps {
  markerPopupDetails: PlaceDetails | undefined;
  selectedListing: Listings | null;
}

const ReceiptStep: React.FC<ReceiptStepProps> = ({
  markerPopupDetails,
  selectedListing,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["apply", "common"]);
  const { getRoleNameFromRole } = useKeyToDict("lists");

  return (
    <Stack sx={{ display: "flex", alignItems: "center", py: 2 }} spacing={2}>
      <Chip
        startDecorator={<CheckCircleOutlineRounded />}
        color="success"
        variant="soft"
        size="lg"
        sx={{ fontSize: "1.5em", borderRadius: 1 }}
      >
        {t("application_sent", { ns: "apply" })}
      </Chip>
      <Typography level="h5" sx={{ maxWidth: "15rem", textAlign: "center" }}>
        {t("application_sent_for", {
          ns: "apply",
          role: selectedListing
            ? getRoleNameFromRole({
                category: selectedListing.category,
                item: selectedListing.role,
              })
            : "",
          business: markerPopupDetails?.name,
        })}
      </Typography>
      <Button
        onClick={() => navigate(APP_ROUTES.CREATE_PROFILE)}
        variant="soft"
        color="neutral"
      >
        {t("view_profile", { ns: "apply" })}
      </Button>
    </Stack>
  );
};

export default ReceiptStep;
