import PageSpacer from "../Components/Utilities/PageSpacer";
import { useContext, useEffect } from "react";
import AppBarContext from "../Context/AppBarContext";
import { HEADER_TYPES } from "../Utils/Constants";

const PrivacyPolicy = () => {
  const headerContext = useContext(AppBarContext);

  useEffect(() => {
    headerContext?.setHeaderType(HEADER_TYPES.NONE);
  });

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n",
        }}
      />
      <PageSpacer>
        <div data-custom-class="body">
          <div>
            <strong>
              <span style={{ fontSize: 26 }}>
                <span data-custom-class="title">PRIVACY NOTICE</span>
              </span>
            </strong>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <strong>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="subtitle">
                    Last updated{" "}
                    <span className="question">February 20, 2023</span>
                  </span>
                </span>
              </strong>
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: "1.5" }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  This privacy notice for{" "}
                  <span className="question">
                    Hourlead, Inc.
                    <span className="block-component" />
                  </span>{" "}
                  (<span className="block-component" />"
                  <span className="block-component" />
                  <strong>Company</strong>
                  <span className="statement-end-if-in-editor" />
                  ," "<strong>we</strong>," "<strong>us</strong>," or "
                  <strong>our</strong>"
                  <span className="statement-end-if-in-editor" />
                </span>
                <span data-custom-class="body_text">
                  ), describes how and why we might collect, store, use, and/or
                  share (<span className="block-component" />"
                  <strong>process</strong>"
                  <span className="statement-end-if-in-editor" />) your
                  information when you use our services (
                  <span className="block-component" />"<strong>Services</strong>
                  "
                  <span className="statement-end-if-in-editor" />
                  ), such as when you:
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span data-custom-class="body_text">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <ul>
            <li style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Visit our website
                    <span className="block-component" /> at{" "}
                    <span className="question">
                      <a
                        href="http://www.hourlead.com"
                        target="_blank"
                        data-custom-class="link"
                        rel="noreferrer"
                      >
                        http://www.hourlead.com
                      </a>
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span className="statement-end-if-in-editor">
                                , or any website of ours that links to this
                                privacy notice
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div>
            <span className="block-component">
              <span style={{ fontSize: 15 }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class="body_text">
                          <span className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      Engage with us in other related ways, including any sales,
                      marketing, or events
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span data-custom-class="body_text">
                    <strong>Questions or concerns?&nbsp;</strong>Reading this
                    privacy notice will help you understand your privacy rights
                    and choices. If you do not agree with our policies and
                    practices, please do not use our Services. If you still have
                    any questions or concerns, please contact us at{" "}
                    <span className="question">
                      hourlead.founders@gmail.com
                    </span>
                    .
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <strong>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="heading_1">
                    SUMMARY OF KEY POINTS
                  </span>
                </span>
              </strong>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>
                      This summary provides key points from our privacy notice,
                      but you can find out more details about any of these
                      topics by clicking the link following each key point or by
                      using our table of contents below to find the section you
                      are looking for. You can also click&nbsp;
                    </em>
                  </strong>
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <strong>
                      <em>here</em>
                    </strong>
                  </span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>&nbsp;to go directly to our table of contents.</em>
                  </strong>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>What personal information do we process?</strong> When
                  you visit, use, or navigate our Services, we may process
                  personal information depending on how you interact with{" "}
                  <span className="block-component" />
                  <span className="question">Hourlead, Inc.</span>
                  <span className="statement-end-if-in-editor" /> and the
                  Services, the choices you make, and the products and features
                  you use. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#personalinfo">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">&nbsp;to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we process any sensitive personal information?
                  </strong>{" "}
                  <span className="block-component" />
                  We do not process sensitive personal information.
                  <span className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    Do we receive any information from third parties?
                  </strong>{" "}
                  <span className="block-component" />
                  We do not receive any information from third parties.
                  <span className="else-block" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>How do we process your information?</strong> We
                  process your information to provide, improve, and administer
                  our Services, communicate with you, for security and fraud
                  prevention, and to comply with law. We may also process your
                  information for other purposes with your consent. We process
                  your information only when we have a valid legal reason to do
                  so. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#infouse">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">&nbsp;to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    In what situations and with which{" "}
                    <span className="block-component" />
                    parties do we share personal information?
                  </strong>{" "}
                  We may share information in specific situations and with
                  specific <span className="block-component" />
                  third parties. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#whoshare">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  &nbsp;to learn more.
                  <span className="block-component" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>How do we keep your information safe?</strong> We have{" "}
                  <span className="block-component" />
                  organizational
                  <span className="statement-end-if-in-editor" /> and technical
                  processes and procedures in place to protect your personal
                  information. However, no electronic transmission over the
                  internet or information storage technology can be guaranteed
                  to be 100% secure, so we cannot promise or guarantee that
                  hackers, cybercriminals, or other{" "}
                  <span className="block-component" />
                  unauthorized
                  <span className="statement-end-if-in-editor" /> third parties
                  will not be able to defeat our security and improperly
                  collect, access, steal, or modify your information.
                  Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  &nbsp;to learn more.
                  <span className="statement-end-if-in-editor" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>What are your rights?</strong> Depending on where you
                  are located geographically, the applicable privacy law may
                  mean you have certain rights regarding your personal
                  information. Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#privacyrights">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">&nbsp;to learn more.</span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>How do you exercise your rights?</strong> The easiest
                  way to exercise your rights is by filling out our data subject
                  request form available <span className="block-component" />
                </span>
              </span>
              <a
                data-custom-class="link"
                href="https://app.termly.io/notify/aa8109e4-40d8-4d66-ac53-63bdc9f2ee29"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span className="block-component" />, or by contacting us. We
                  will consider and act upon any request in accordance with
                  applicable data protection laws.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Want to learn more about what{" "}
                  <span className="block-component" />
                  <span className="question">Hourlead, Inc.</span>
                  <span className="statement-end-if-in-editor" /> does with any
                  information we collect? Click&nbsp;
                </span>
              </span>
              <a data-custom-class="link" href="#toc">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">here</span>
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  &nbsp;to review the notice in full.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="toc" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(0, 0, 0)" }}>
                    <strong>
                      <span data-custom-class="heading_1">
                        TABLE OF CONTENTS
                      </span>
                    </strong>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infocollect">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infouse">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                    <span className="block-component" />
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#whoshare">
                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </a>
                </span>
                <span data-custom-class="body_text">
                  <span className="block-component" />
                </span>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="block-component" />
                      </span>
                    </span>
                    &nbsp;
                  </span>
                  &nbsp;
                </span>
                &nbsp;
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#cookies">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span className="block-component" />
                          </span>
                        </span>
                        <span className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#inforetain">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#infosafe">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                </a>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ color: "rgb(89, 89, 89)" }}>
                        <span className="statement-end-if-in-editor" />
                        <span className="block-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  <a data-custom-class="link" href="#privacyrights">
                    7. WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#DNT">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    8. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#caresidents">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </a>
              </span>
              <span className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#virginia">
                <span style={{ fontSize: 15 }}>
                  10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </span>
              </a>
              <span style={{ fontSize: 15 }}>
                <span className="statement-end-if-in-editor">
                  <span data-custom-class="body_text" />
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <a data-custom-class="link" href="#policyupdates">
                  <span style={{ color: "rgb(89, 89, 89)" }}>
                    11. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </a>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#contact">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <a data-custom-class="link" href="#request">
                <span style={{ color: "rgb(89, 89, 89)" }}>
                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </span>
              </a>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="infocollect" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            1. WHAT INFORMATION DO WE COLLECT?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div id="personalinfo" style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: 15 }}>
                  <strong>Personal information you disclose to us</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>&nbsp;</em>
                            </strong>
                            <em>
                              We collect personal information that you provide
                              to us.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We collect personal information that you voluntarily provide
                    to us when you{" "}
                    <span style={{ fontSize: 15 }}>
                      <span className="block-component" />
                    </span>
                    register on the Services,&nbsp;
                  </span>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      express an interest in obtaining information about us or
                      our products and Services, when you participate in
                      activities on the Services, or otherwise when you contact
                      us.
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>Personal Information Provided by You.</strong> The
                    personal information that we collect depends on the context
                    of your interactions with us and the Services, the choices
                    you make, and the products and features you use. The
                    personal information we collect may include the following:
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">names</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">phone numbers</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">email addresses</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">job titles</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">work history</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">education history</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span className="question">reference contacts</span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="forloop-component" />
                      </span>
                      <span data-custom-class="body_text">
                        <span className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>Sensitive Information.</strong>{" "}
                  <span className="block-component" />
                  We do not process sensitive information.
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span className="else-block" />
                </span>
              </span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="block-component">
                          <span className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span className="block-component" />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    All personal information that you provide to us must be
                    true, complete, and accurate, and you must notify us of any
                    changes to such personal information.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: 15 }}>
                  <strong>Information automatically collected</strong>
                </span>
              </span>
            </div>
            <div>
              <div>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:</em>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>&nbsp;</em>
                            </strong>
                            <em>
                              Some information — such as your Internet Protocol
                              (IP) address and/or browser and device
                              characteristics — is collected automatically when
                              you visit our Services.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    We automatically collect certain information when you visit,
                    use, or navigate the Services. This information does not
                    reveal your specific identity (like your name or contact
                    information) but may include device and usage information,
                    such as your IP address, browser and device characteristics,
                    operating system, language preferences, referring URLs,
                    device name, country, location, information about how and
                    when you use our Services, and other technical information.
                    This information is primarily needed to maintain the
                    security and operation of our Services, and for our internal
                    analytics and reporting purposes.
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    Like many businesses, we also collect information through
                    cookies and similar technologies.{" "}
                    <span className="block-component" />
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span className="statement-end-if-in-editor">
                      <span data-custom-class="body_text" />
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The information we collect includes:
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Log and Usage Data.</em> Log and usage data is
                      service-related, diagnostic, usage, and performance
                      information our servers automatically collect when you
                      access or use our Services and which we record in log
                      files. Depending on how you interact with us, this log
                      data may include your IP address, device information,
                      browser type, and settings and information about your
                      activity in the Services
                      <span style={{ fontSize: 15 }}>&nbsp;</span>(such as the
                      date/time stamps associated with your usage, pages and
                      files viewed, searches, and other actions you take such as
                      which features you use), device event information (such as
                      system activity, error reports (sometimes called{" "}
                      <span className="block-component" />
                      "crash dumps"
                      <span className="statement-end-if-in-editor" />
                      ), and hardware settings).
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Device Data.</em> We collect device data such as
                      information about your computer, phone, tablet, or other
                      device you use to access the Services. Depending on the
                      device used, this device data may include information such
                      as your IP address (or proxy server), device and
                      application identification numbers, location, browser
                      type, hardware model, Internet service provider and/or
                      mobile carrier, operating system, and system configuration
                      information.
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span className="block-component">
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text" />
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <em>Location Data.</em> We collect location data such as
                      information about your device's location, which can be
                      either precise or imprecise. How much information we
                      collect depends on the type and settings of the device you
                      use to access the Services. For example, we may use GPS
                      and other technologies to collect geolocation data that
                      tells us your current location (based on your IP address).
                      You can opt out of allowing us to collect this information
                      either by refusing access to the information or by
                      disabling your Location setting on your device. However,
                      if you choose to opt out, you may not be able to use
                      certain aspects of the Services.
                      <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <span className="block-component">
                <span style={{ fontSize: 15 }} />
              </span>
              <span className="statement-end-if-in-editor" />
              <span data-custom-class="body_text">
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class="body_text">
                        <span className="statement-end-if-in-editor">
                          <span className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <span className="block-component" />
                </span>
              </span>
            </div>
            <div id="infouse" style={{ lineHeight: "1.5" }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class="heading_1">
                            2. HOW DO WE PROCESS YOUR INFORMATION?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <strong>
                              <em>In Short:&nbsp;</em>
                            </strong>
                            <em>
                              We process your information to provide, improve,
                              and administer our Services, communicate with you,
                              for security and fraud prevention, and to comply
                              with law. We may also process your information for
                              other purposes with your consent.
                            </em>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <br />
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <strong>
                      We process your personal information for a variety of
                      reasons, depending on how you interact with our Services,
                      including:
                    </strong>
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To facilitate account creation and authentication and
                        otherwise manage user accounts.&nbsp;
                      </strong>
                      We may process your information so you can create and log
                      in to your account, as well as keep your account in
                      working order.
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span style={{ fontSize: 15 }}>
                              <span style={{ color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span className="statement-end-if-in-editor" />
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <strong>
                        To deliver and facilitate delivery of services to the
                        user.&nbsp;
                      </strong>
                      We may process your information to provide you with the
                      requested service.
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span data-custom-class="body_text">
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span data-custom-class="body_text">
                                  <span style={{ fontSize: 15 }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                      <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                          <span
                                            style={{ color: "rgb(89, 89, 89)" }}
                                          >
                                            <span data-custom-class="body_text">
                                              <span className="statement-end-if-in-editor" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    <span className="block-component" />
                  </span>
                </span>
              </span>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class="body_text">
                      <span className="block-component" />
                    </span>
                  </span>
                </span>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        <span className="block-component" />
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: "1.5" }}>
                    <span className="block-component">
                      <span style={{ fontSize: 15 }} />
                    </span>
                    <div style={{ lineHeight: "1.5" }}>
                      <span className="block-component">
                        <span style={{ fontSize: 15 }} />
                      </span>
                      <div style={{ lineHeight: "1.5" }}>
                        <span className="block-component">
                          <span style={{ fontSize: 15 }} />
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                          <span className="block-component">
                            <span style={{ fontSize: 15 }}>
                              <span data-custom-class="body_text" />
                            </span>
                          </span>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <span className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </span>
                          </p>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                      <strong>
                                        To enable user-to-user
                                        communications.&nbsp;
                                      </strong>
                                      We may process your information if you
                                      choose to use any of our offerings that
                                      allow for communication with another user.
                                      <span
                                        style={{ color: "rgb(89, 89, 89)" }}
                                      >
                                        <span
                                          style={{ color: "rgb(89, 89, 89)" }}
                                        >
                                          <span data-custom-class="body_text">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span data-custom-class="body_text">
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span data-custom-class="body_text">
                                                    <span className="statement-end-if-in-editor" />
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <span className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </span>
                          </p>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <span className="block-component" />
                          </p>
                          <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                            <span className="block-component" />
                          </p>
                          <div style={{ lineHeight: "1.5" }}>
                            <span className="block-component">
                              <span style={{ fontSize: 15 }} />
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                              <span className="block-component">
                                <span style={{ fontSize: 15 }} />
                              </span>
                              <div style={{ lineHeight: "1.5" }}>
                                <span className="block-component">
                                  <span style={{ fontSize: 15 }} />
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                  <span style={{ fontSize: 15 }}>
                                    <span className="block-component">
                                      <span data-custom-class="body_text" />
                                    </span>
                                  </span>
                                  <div style={{ lineHeight: "1.5" }}>
                                    <span className="block-component">
                                      <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                      </span>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <span className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </span>
                                    </div>
                                    <ul>
                                      <li style={{ lineHeight: "1.5" }}>
                                        <span data-custom-class="body_text">
                                          <span style={{ fontSize: 15 }}>
                                            <strong>
                                              To protect our Services.
                                            </strong>{" "}
                                            We may process your information as
                                            part of our efforts to keep our
                                            Services safe and secure, including
                                            fraud monitoring and prevention.
                                          </span>
                                        </span>
                                        <span className="statement-end-if-in-editor">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </span>
                                      </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                      <span className="block-component">
                                        <span style={{ fontSize: 15 }}>
                                          <span data-custom-class="body_text" />
                                        </span>
                                      </span>
                                      <div style={{ lineHeight: "1.5" }}>
                                        <span className="block-component">
                                          <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text" />
                                          </span>
                                        </span>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </span>
                                        </div>
                                        <ul>
                                          <li style={{ lineHeight: "1.5" }}>
                                            <span data-custom-class="body_text">
                                              <span style={{ fontSize: 15 }}>
                                                <strong>
                                                  To evaluate and improve our
                                                  Services, products, marketing,
                                                  and your experience.
                                                </strong>{" "}
                                                We may process your information
                                                when we believe it is necessary
                                                to identify usage trends,
                                                determine the effectiveness of
                                                our promotional campaigns, and
                                                to evaluate and improve our
                                                Services, products, marketing,
                                                and your experience.
                                              </span>
                                            </span>
                                            <span className="statement-end-if-in-editor">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                          <span className="block-component">
                                            <span style={{ fontSize: 15 }}>
                                              <span data-custom-class="body_text" />
                                            </span>
                                          </span>
                                          <div style={{ lineHeight: "1.5" }}>
                                            <span className="block-component">
                                              <span style={{ fontSize: 15 }}>
                                                <span data-custom-class="body_text" />
                                              </span>
                                            </span>
                                            <div style={{ lineHeight: "1.5" }}>
                                              <span className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                  <span data-custom-class="body_text" />
                                                </span>
                                              </span>
                                              <div
                                                style={{ lineHeight: "1.5" }}
                                              >
                                                <span className="block-component">
                                                  <span
                                                    style={{ fontSize: 15 }}
                                                  >
                                                    <span data-custom-class="body_text" />
                                                  </span>
                                                </span>
                                                <div
                                                  style={{ lineHeight: "1.5" }}
                                                >
                                                  <span className="block-component">
                                                    <span
                                                      style={{ fontSize: 15 }}
                                                    >
                                                      <span data-custom-class="body_text" />
                                                    </span>
                                                  </span>
                                                  <div
                                                    style={{
                                                      lineHeight: "1.5",
                                                    }}
                                                  >
                                                    <span className="block-component">
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text" />
                                                      </span>
                                                    </span>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </span>
                                                      <span className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </span>
                                                      <span className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </span>
                                                      <span className="block-component">
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      id="whoshare"
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          color:
                                                            "rgb(127, 127, 127)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                id="control"
                                                                style={{
                                                                  color:
                                                                    "rgb(0, 0, 0)",
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span data-custom-class="heading_1">
                                                                    3. WHEN AND
                                                                    WITH WHOM DO
                                                                    WE SHARE
                                                                    YOUR
                                                                    PERSONAL
                                                                    INFORMATION?
                                                                  </span>
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              <em>In Short:</em>
                                                            </strong>
                                                            <em>
                                                              &nbsp;We may share
                                                              information in
                                                              specific
                                                              situations
                                                              described in this
                                                              section and/or
                                                              with the following{" "}
                                                              <span className="block-component" />
                                                              third parties.
                                                            </em>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <strong>
                                                            Vendors,
                                                            Consultants, and
                                                            Other Third-Party
                                                            Service Providers.
                                                          </strong>{" "}
                                                          We may share your data
                                                          with third-party
                                                          vendors, service
                                                          providers,
                                                          contractors, or agents
                                                          (
                                                          <span className="block-component" />
                                                          "
                                                          <strong>
                                                            third parties
                                                          </strong>
                                                          "
                                                          <span className="statement-end-if-in-editor" />
                                                          ) who perform services
                                                          for us or on our
                                                          behalf and require
                                                          access to such
                                                          information to do that
                                                          work.{" "}
                                                          <span className="block-component" />
                                                          We have contracts in
                                                          place with our third
                                                          parties, which are
                                                          designed to help
                                                          safeguard your
                                                          personal information.
                                                          This means that they
                                                          cannot do anything
                                                          with your personal
                                                          information unless we
                                                          have instructed them
                                                          to do it. They will
                                                          also not share your
                                                          personal information
                                                          with any{" "}
                                                          <span className="block-component" />
                                                          organization
                                                          <span className="statement-end-if-in-editor" />{" "}
                                                          apart from us. They
                                                          also commit to protect
                                                          the data they hold on
                                                          our behalf and to
                                                          retain it for the
                                                          period we instruct.{" "}
                                                          <span className="statement-end-if-in-editor" />
                                                          The{" "}
                                                          <span className="block-component" />
                                                          third parties we may
                                                          share personal
                                                          information with are
                                                          as follows:
                                                        </span>
                                                      </span>
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span data-custom-class="body_text">
                                                        <span className="block-component" />
                                                      </span>
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Functionality
                                                                and
                                                                Infrastructure{" "}
                                                                <span className="block-component" />
                                                                Optimization
                                                                <span className="statement-end-if-in-editor" />
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="forloop-component">
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="block-component" />
                                                                      <span className="question">
                                                                        Firebase
                                                                        Realtime
                                                                        Database{" "}
                                                                      </span>
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="forloop-component" />
                                                              <span className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                User Account
                                                                Registration and
                                                                Authentication
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="forloop-component">
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="block-component" />
                                                                      <span className="question">
                                                                        Firebase
                                                                        Authentication
                                                                      </span>
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="forloop-component" />
                                                              <span className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                              color:
                                                                "rgb(89, 89, 89)",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <strong>
                                                                Web and Mobile
                                                                Analytics
                                                              </strong>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{ marginLeft: 40 }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 15,
                                                          color:
                                                            "rgb(89, 89, 89)",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                            color:
                                                              "rgb(89, 89, 89)",
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span className="forloop-component">
                                                                  <span data-custom-class="body_text" />
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="block-component" />
                                                                      <span className="question">
                                                                        Google
                                                                        Analytics
                                                                      </span>
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                              <span className="forloop-component" />
                                                              <span className="statement-end-if-in-editor" />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span className="block-component" />
                                                      <span data-custom-class="body_text" />
                                                      <span data-custom-class="body_text" />
                                                      <span data-custom-class="body_text" />
                                                      <span data-custom-class="body_text" />
                                                      <span data-custom-class="body_text" />
                                                      <span data-custom-class="body_text" />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <br />
                                                    </div>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          We{" "}
                                                          <span className="block-component" />
                                                          also{" "}
                                                          <span className="statement-end-if-in-editor" />
                                                          may need to share your
                                                          personal information
                                                          in the following
                                                          situations:
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              Business
                                                              Transfers.
                                                            </strong>{" "}
                                                            We may share or
                                                            transfer your
                                                            information in
                                                            connection with, or
                                                            during negotiations
                                                            of, any merger, sale
                                                            of company assets,
                                                            financing, or
                                                            acquisition of all
                                                            or a portion of our
                                                            business to another
                                                            company.
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span data-custom-class="body_text">
                                                          <span className="block-component" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <ul>
                                                      <li
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            <strong>
                                                              When we use Google
                                                              Maps Platform
                                                              APIs.
                                                            </strong>{" "}
                                                            We may share your
                                                            information with
                                                            certain Google Maps
                                                            Platform APIs (e.g.
                                                            <span className="block-component" />
                                                            ,
                                                            <span className="statement-end-if-in-editor" />{" "}
                                                            Google Maps API,
                                                            Places API). To find
                                                            out more about
                                                            Google’s Privacy
                                                            Policy, please refer
                                                            to this&nbsp;
                                                          </span>
                                                        </span>
                                                        <a
                                                          data-custom-class="link"
                                                          href="https://policies.google.com/privacy"
                                                          rel="noopener noreferrer"
                                                          target="_blank"
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              link
                                                            </span>
                                                          </span>
                                                        </a>
                                                        <span
                                                          style={{
                                                            fontSize: 15,
                                                          }}
                                                        >
                                                          <span data-custom-class="body_text">
                                                            .
                                                            <span className="block-component" />
                                                            <span className="block-component" />{" "}
                                                            We obtain and store
                                                            on your device (
                                                            <span className="block-component" />
                                                            "cache"
                                                            <span className="statement-end-if-in-editor" />
                                                            ) your location
                                                            <span className="block-component" />
                                                            . You may revoke
                                                            your consent anytime
                                                            by contacting us at
                                                            the contact details
                                                            provided at the end
                                                            of this document.
                                                            <span className="statement-end-if-in-editor" />
                                                            <span className="block-component" />
                                                          </span>
                                                        </span>
                                                      </li>
                                                    </ul>
                                                    <div
                                                      style={{
                                                        lineHeight: "1.5",
                                                      }}
                                                    >
                                                      <span
                                                        style={{ fontSize: 15 }}
                                                      >
                                                        <span className="block-component">
                                                          <span data-custom-class="body_text" />
                                                        </span>
                                                      </span>
                                                      <div
                                                        style={{
                                                          lineHeight: "1.5",
                                                        }}
                                                      >
                                                        <span className="block-component">
                                                          <span
                                                            style={{
                                                              fontSize: 15,
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text" />
                                                          </span>
                                                        </span>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </span>
                                                        </div>
                                                        <ul>
                                                          <li
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  Other Users.
                                                                </strong>{" "}
                                                                When you share
                                                                personal
                                                                information
                                                                <span className="block-component" />{" "}
                                                                (for example, by
                                                                posting
                                                                comments,
                                                                contributions,
                                                                or other content
                                                                to the Services)
                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                or otherwise
                                                                interact with
                                                                public areas of
                                                                the Services,
                                                                such personal
                                                                information may
                                                                be viewed by all
                                                                users and may be
                                                                publicly made
                                                                available
                                                                outside the
                                                                Services in
                                                                perpetuity.
                                                                <span className="block-component" />{" "}
                                                                Similarly, other
                                                                users will be
                                                                able to view
                                                                descriptions of
                                                                your activity,
                                                                communicate with
                                                                you within our
                                                                Services, and
                                                                view your
                                                                profile.
                                                                <span className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                          </li>
                                                        </ul>
                                                        <div
                                                          style={{
                                                            lineHeight: "1.5",
                                                          }}
                                                        >
                                                          <span className="block-component">
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                          </span>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span className="block-component">
                                                                        <span data-custom-class="heading_1" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="cookies"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          4. DO
                                                                          WE USE
                                                                          COOKIES
                                                                          AND
                                                                          OTHER
                                                                          TRACKING
                                                                          TECHNOLOGIES?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;We may
                                                                    use cookies
                                                                    and other
                                                                    tracking
                                                                    technologies
                                                                    to collect
                                                                    and store
                                                                    your
                                                                    information.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We may use
                                                                  cookies and
                                                                  similar
                                                                  tracking
                                                                  technologies
                                                                  (like web
                                                                  beacons and
                                                                  pixels) to
                                                                  access or
                                                                  store
                                                                  information.
                                                                  Specific
                                                                  information
                                                                  about how we
                                                                  use such
                                                                  technologies
                                                                  and how you
                                                                  can refuse
                                                                  certain
                                                                  cookies is set
                                                                  out in our
                                                                  Cookie Notice
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      .
                                                                    </span>
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span className="block-component" />
                                                                                </span>
                                                                                <span className="block-component">
                                                                                  <span data-custom-class="body_text">
                                                                                    <span className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="inforetain"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          5. HOW
                                                                          LONG
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We keep your
                                                                    information
                                                                    for as long
                                                                    as necessary
                                                                    to{" "}
                                                                    <span className="block-component" />
                                                                    fulfill
                                                                    <span className="statement-end-if-in-editor" />{" "}
                                                                    the purposes
                                                                    outlined in
                                                                    this privacy
                                                                    notice
                                                                    unless
                                                                    otherwise
                                                                    required by
                                                                    law.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We will only
                                                                  keep your
                                                                  personal
                                                                  information
                                                                  for as long as
                                                                  it is
                                                                  necessary for
                                                                  the purposes
                                                                  set out in
                                                                  this privacy
                                                                  notice, unless
                                                                  a longer
                                                                  retention
                                                                  period is
                                                                  required or
                                                                  permitted by
                                                                  law (such as
                                                                  tax,
                                                                  accounting, or
                                                                  other legal
                                                                  requirements).
                                                                  <span className="block-component" />{" "}
                                                                  No purpose in
                                                                  this notice
                                                                  will require
                                                                  us keeping
                                                                  your personal
                                                                  information
                                                                  for longer
                                                                  than{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  <span className="block-component" />
                                                                  the period of
                                                                  time in which
                                                                  users have an
                                                                  account with
                                                                  us
                                                                  <span className="block-component" />
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="else-block" />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  When we have
                                                                  no ongoing
                                                                  legitimate
                                                                  business need
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  we will either
                                                                  delete or{" "}
                                                                  <span className="block-component" />
                                                                  anonymize
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  such
                                                                  information,
                                                                  or, if this is
                                                                  not possible
                                                                  (for example,
                                                                  because your
                                                                  personal
                                                                  information
                                                                  has been
                                                                  stored in
                                                                  backup
                                                                  archives),
                                                                  then we will
                                                                  securely store
                                                                  your personal
                                                                  information
                                                                  and isolate it
                                                                  from any
                                                                  further
                                                                  processing
                                                                  until deletion
                                                                  is possible.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="block-component" />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="infosafe"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          6. HOW
                                                                          DO WE
                                                                          KEEP
                                                                          YOUR
                                                                          INFORMATION
                                                                          SAFE?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    We aim to
                                                                    protect your
                                                                    personal
                                                                    information
                                                                    through a
                                                                    system of{" "}
                                                                    <span className="block-component" />
                                                                    organizational
                                                                    <span className="statement-end-if-in-editor" />{" "}
                                                                    and
                                                                    technical
                                                                    security
                                                                    measures.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We have
                                                                  implemented
                                                                  appropriate
                                                                  and reasonable
                                                                  technical and{" "}
                                                                  <span className="block-component" />
                                                                  organizational
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  security
                                                                  measures
                                                                  designed to
                                                                  protect the
                                                                  security of
                                                                  any personal
                                                                  information we
                                                                  process.
                                                                  However,
                                                                  despite our
                                                                  safeguards and
                                                                  efforts to
                                                                  secure your
                                                                  information,
                                                                  no electronic
                                                                  transmission
                                                                  over the
                                                                  Internet or
                                                                  information
                                                                  storage
                                                                  technology can
                                                                  be guaranteed
                                                                  to be 100%
                                                                  secure, so we
                                                                  cannot promise
                                                                  or guarantee
                                                                  that hackers,
                                                                  cybercriminals,
                                                                  or other{" "}
                                                                  <span className="block-component" />
                                                                  unauthorized
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  third parties
                                                                  will not be
                                                                  able to defeat
                                                                  our security
                                                                  and improperly
                                                                  collect,
                                                                  access, steal,
                                                                  or modify your
                                                                  information.
                                                                  Although we
                                                                  will do our
                                                                  best to
                                                                  protect your
                                                                  personal
                                                                  information,
                                                                  transmission
                                                                  of personal
                                                                  information to
                                                                  and from our
                                                                  Services is at
                                                                  your own risk.
                                                                  You should
                                                                  only access
                                                                  the Services
                                                                  within a
                                                                  secure
                                                                  environment.
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span className="statement-end-if-in-editor" />
                                                                  </span>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="privacyrights"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          7.
                                                                          WHAT
                                                                          ARE
                                                                          YOUR
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In Short:
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    &nbsp;
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <em>
                                                                            <span className="block-component" />
                                                                          </em>
                                                                        </span>
                                                                      </span>
                                                                      &nbsp;
                                                                    </span>
                                                                    You may
                                                                    review,
                                                                    change, or
                                                                    terminate
                                                                    your account
                                                                    at any time.
                                                                  </em>
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              &nbsp;
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in the
                                                                  EEA or UK and
                                                                  you believe we
                                                                  are unlawfully
                                                                  processing
                                                                  your personal
                                                                  information,
                                                                  you also have
                                                                  the right to
                                                                  complain to
                                                                  your local
                                                                  data
                                                                  protection
                                                                  supervisory
                                                                  authority. You
                                                                  can find their
                                                                  contact
                                                                  details here:{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(48, 48, 241)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                                              </span>
                                                                            </a>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  located in
                                                                  Switzerland,
                                                                  the contact
                                                                  details for
                                                                  the data
                                                                  protection
                                                                  authorities
                                                                  are available
                                                                  here:{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(48, 48, 241)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <a
                                                                                data-custom-class="link"
                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"
                                                                              >
                                                                                https://www.edoeb.admin.ch/edoeb/en/home.html
                                                                              </a>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="withdrawconsent"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Withdrawing
                                                                      your
                                                                      consent:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  If we are
                                                                  relying on
                                                                  your consent
                                                                  to process
                                                                  your personal
                                                                  information,
                                                                  <span className="block-component" />{" "}
                                                                  which may be
                                                                  express and/or
                                                                  implied
                                                                  consent
                                                                  depending on
                                                                  the applicable
                                                                  law,
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  you have the
                                                                  right to
                                                                  withdraw your
                                                                  consent at any
                                                                  time. You can
                                                                  withdraw your
                                                                  consent at any
                                                                  time by
                                                                  contacting us
                                                                  by using the
                                                                  contact
                                                                  details
                                                                  provided in
                                                                  the section{" "}
                                                                  <span className="block-component" />
                                                                  "
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    HOW CAN YOU
                                                                    CONTACT US
                                                                    ABOUT THIS
                                                                    NOTICE?
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span className="block-component" />
                                                                  "
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  below
                                                                  <span className="block-component" />
                                                                  .
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                However, please
                                                                note that this
                                                                will not affect
                                                                the lawfulness
                                                                of the
                                                                processing
                                                                before its
                                                                withdrawal nor,
                                                                <span className="block-component" />{" "}
                                                                when applicable
                                                                law allows,
                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                will it affect
                                                                the processing
                                                                of your personal
                                                                information
                                                                conducted in
                                                                reliance on
                                                                lawful
                                                                processing
                                                                grounds other
                                                                than consent.
                                                                <span className="block-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <strong>
                                                                  <u>
                                                                    Opting out
                                                                    of marketing
                                                                    and
                                                                    promotional
                                                                    communications:
                                                                  </u>
                                                                </strong>
                                                                <strong>
                                                                  <u>&nbsp;</u>
                                                                </strong>
                                                                You can
                                                                unsubscribe from
                                                                our marketing
                                                                and promotional
                                                                communications
                                                                at any time by
                                                                <span className="block-component" />
                                                                <span className="block-component" />{" "}
                                                                replying{" "}
                                                                <span className="block-component" />
                                                                "STOP" or
                                                                "UNSUBSCRIBE"
                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                to the SMS
                                                                messages that we
                                                                send,
                                                                <span className="statement-end-if-in-editor" />
                                                                <span className="block-component" />{" "}
                                                                or by contacting
                                                                us using the
                                                                details provided
                                                                in the section{" "}
                                                                <span className="block-component" />
                                                                "
                                                                <span className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                            <a
                                                              data-custom-class="link"
                                                              href="#contact"
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  HOW CAN YOU
                                                                  CONTACT US
                                                                  ABOUT THIS
                                                                  NOTICE?
                                                                </span>
                                                              </span>
                                                            </a>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span className="block-component" />
                                                                "
                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                below. You will
                                                                then be removed
                                                                from the
                                                                marketing lists.
                                                                However, we may
                                                                still
                                                                communicate with
                                                                you — for
                                                                example, to send
                                                                you
                                                                service-related
                                                                messages that
                                                                are necessary
                                                                for the
                                                                administration
                                                                and use of your
                                                                account, to
                                                                respond to
                                                                service
                                                                requests, or for
                                                                other
                                                                non-marketing
                                                                purposes.
                                                              </span>
                                                              <span data-custom-class="body_text">
                                                                <span className="statement-end-if-in-editor" />
                                                              </span>
                                                            </span>
                                                            <span className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="heading_2">
                                                                <strong>
                                                                  Account
                                                                  Information
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                If you would at
                                                                any time like to
                                                                review or change
                                                                the information
                                                                in your account
                                                                or terminate
                                                                your account,
                                                                you can:
                                                                <span className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span className="question">
                                                                    Log in to
                                                                    your account
                                                                    settings and
                                                                    update your
                                                                    user
                                                                    account.
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span className="forloop-component" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Upon your
                                                                request to
                                                                terminate your
                                                                account, we will
                                                                deactivate or
                                                                delete your
                                                                account and
                                                                information from
                                                                our active
                                                                databases.
                                                                However, we may
                                                                retain some
                                                                information in
                                                                our files to
                                                                prevent fraud,
                                                                troubleshoot
                                                                problems, assist
                                                                with any
                                                                investigations,
                                                                enforce our
                                                                legal terms
                                                                and/or comply
                                                                with applicable
                                                                legal
                                                                requirements.
                                                              </span>
                                                            </span>
                                                            <span className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <u>
                                                                      Cookies
                                                                      and
                                                                      similar
                                                                      technologies:
                                                                    </u>
                                                                  </strong>{" "}
                                                                  Most Web
                                                                  browsers are
                                                                  set to accept
                                                                  cookies by
                                                                  default. If
                                                                  you prefer,
                                                                  you can
                                                                  usually choose
                                                                  to set your
                                                                  browser to
                                                                  remove cookies
                                                                  and to reject
                                                                  cookies. If
                                                                  you choose to
                                                                  remove cookies
                                                                  or reject
                                                                  cookies, this
                                                                  could affect
                                                                  certain
                                                                  features or
                                                                  services of
                                                                  our Services.
                                                                  To opt out of
                                                                  interest-based
                                                                  advertising by
                                                                  advertisers on
                                                                  our Services
                                                                  visit{" "}
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(48, 48, 241)",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <a
                                                                        data-custom-class="link"
                                                                        href="http://www.aboutads.info/choices/"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank"
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          http://www.aboutads.info/choices/
                                                                        </span>
                                                                      </a>
                                                                    </span>
                                                                  </span>
                                                                  .{" "}
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="block-component" />
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span className="statement-end-if-in-editor" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                            <span className="block-component">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                If you have
                                                                questions or
                                                                comments about
                                                                your privacy
                                                                rights, you may
                                                                email us at{" "}
                                                                <span className="question">
                                                                  hourlead.founders@gmail.com
                                                                </span>
                                                                .
                                                              </span>
                                                            </span>
                                                            <span className="statement-end-if-in-editor">
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text" />
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="DNT"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          8.
                                                                          CONTROLS
                                                                          FOR
                                                                          DO-NOT-TRACK
                                                                          FEATURES
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  Most web
                                                                  browsers and
                                                                  some mobile
                                                                  operating
                                                                  systems and
                                                                  mobile
                                                                  applications
                                                                  include a
                                                                  Do-Not-Track (
                                                                  <span className="block-component" />
                                                                  "DNT"
                                                                  <span className="statement-end-if-in-editor" />
                                                                  ) feature or
                                                                  setting you
                                                                  can activate
                                                                  to signal your
                                                                  privacy
                                                                  preference not
                                                                  to have data
                                                                  about your
                                                                  online
                                                                  browsing
                                                                  activities
                                                                  monitored and
                                                                  collected. At
                                                                  this stage no
                                                                  uniform
                                                                  technology
                                                                  standard for{" "}
                                                                  <span className="block-component" />
                                                                  recognizing
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  and
                                                                  implementing
                                                                  DNT signals
                                                                  has been{" "}
                                                                  <span className="block-component" />
                                                                  finalized
                                                                  <span className="statement-end-if-in-editor" />
                                                                  . As such, we
                                                                  do not
                                                                  currently
                                                                  respond to DNT
                                                                  browser
                                                                  signals or any
                                                                  other
                                                                  mechanism that
                                                                  automatically
                                                                  communicates
                                                                  your choice
                                                                  not to be
                                                                  tracked
                                                                  online. If a
                                                                  standard for
                                                                  online
                                                                  tracking is
                                                                  adopted that
                                                                  we must follow
                                                                  in the future,
                                                                  we will inform
                                                                  you about that
                                                                  practice in a
                                                                  revised
                                                                  version of
                                                                  this privacy
                                                                  notice.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            id="caresidents"
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                color:
                                                                  "rgb(127, 127, 127)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      id="control"
                                                                      style={{
                                                                        color:
                                                                          "rgb(0, 0, 0)",
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="heading_1">
                                                                          9. DO
                                                                          CALIFORNIA
                                                                          RESIDENTS
                                                                          HAVE
                                                                          SPECIFIC
                                                                          PRIVACY
                                                                          RIGHTS?
                                                                        </span>
                                                                      </strong>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    <em>
                                                                      In
                                                                      Short:&nbsp;
                                                                    </em>
                                                                  </strong>
                                                                  <em>
                                                                    Yes, if you
                                                                    are a
                                                                    resident of
                                                                    California,
                                                                    you are
                                                                    granted
                                                                    specific
                                                                    rights
                                                                    regarding
                                                                    access to
                                                                    your
                                                                    personal
                                                                    information.
                                                                  </em>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  California
                                                                  Civil Code
                                                                  Section
                                                                  1798.83, also
                                                                  known as the{" "}
                                                                  <span className="block-component" />
                                                                  "Shine The
                                                                  Light"
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  law, permits
                                                                  our users who
                                                                  are California
                                                                  residents to
                                                                  request and
                                                                  obtain from
                                                                  us, once a
                                                                  year and free
                                                                  of charge,
                                                                  information
                                                                  about
                                                                  categories of
                                                                  personal
                                                                  information
                                                                  (if any) we
                                                                  disclosed to
                                                                  third parties
                                                                  for direct
                                                                  marketing
                                                                  purposes and
                                                                  the names and
                                                                  addresses of
                                                                  all third
                                                                  parties with
                                                                  which we
                                                                  shared
                                                                  personal
                                                                  information in
                                                                  the
                                                                  immediately
                                                                  preceding
                                                                  calendar year.
                                                                  If you are a
                                                                  California
                                                                  resident and
                                                                  would like to
                                                                  make such a
                                                                  request,
                                                                  please submit
                                                                  your request
                                                                  in writing to
                                                                  us using the
                                                                  contact
                                                                  information
                                                                  provided
                                                                  below.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If you are
                                                                  under 18 years
                                                                  of age, reside
                                                                  in California,
                                                                  and have a
                                                                  registered
                                                                  account with
                                                                  Services, you
                                                                  have the right
                                                                  to request
                                                                  removal of
                                                                  unwanted data
                                                                  that you
                                                                  publicly post
                                                                  on the
                                                                  Services. To
                                                                  request
                                                                  removal of
                                                                  such data,
                                                                  please contact
                                                                  us using the
                                                                  contact
                                                                  information
                                                                  provided below
                                                                  and include
                                                                  the email
                                                                  address
                                                                  associated
                                                                  with your
                                                                  account and a
                                                                  statement that
                                                                  you reside in
                                                                  California. We
                                                                  will make sure
                                                                  the data is
                                                                  not publicly
                                                                  displayed on
                                                                  the Services,
                                                                  but please be
                                                                  aware that the
                                                                  data may not
                                                                  be completely
                                                                  or
                                                                  comprehensively
                                                                  removed from
                                                                  all our
                                                                  systems (e.g.
                                                                  <span className="block-component" />
                                                                  ,
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  backups,
                                                                  etc.).
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              data-custom-class="heading_2"
                                                              style={{
                                                                color:
                                                                  "rgb(0, 0, 0)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                }}
                                                              >
                                                                <strong>
                                                                  CCPA Privacy
                                                                  Notice
                                                                </strong>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <div>
                                                              <br />
                                                            </div>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  color:
                                                                    "rgb(127, 127, 127)",
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      "rgb(89, 89, 89)",
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          The
                                                                          California
                                                                          Code
                                                                          of
                                                                          Regulations
                                                                          defines
                                                                          a{" "}
                                                                          <span className="block-component" />
                                                                          "resident"
                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                          as:
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                              marginLeft: 20,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  (1) every
                                                                  individual who
                                                                  is in the
                                                                  State of
                                                                  California for
                                                                  other than a
                                                                  temporary or
                                                                  transitory
                                                                  purpose and
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                              marginLeft: 20,
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  (2) every
                                                                  individual who
                                                                  is domiciled
                                                                  in the State
                                                                  of California
                                                                  who is outside
                                                                  the State of
                                                                  California for
                                                                  a temporary or
                                                                  transitory
                                                                  purpose
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  All other
                                                                  individuals
                                                                  are defined as{" "}
                                                                  <span className="block-component" />
                                                                  "non-residents."
                                                                  <span className="statement-end-if-in-editor" />
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  If this
                                                                  definition of{" "}
                                                                  <span className="block-component" />
                                                                  "resident"
                                                                  <span className="statement-end-if-in-editor" />{" "}
                                                                  applies to
                                                                  you, we must
                                                                  adhere to
                                                                  certain rights
                                                                  and
                                                                  obligations
                                                                  regarding your
                                                                  personal
                                                                  information.
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <strong>
                                                                    What
                                                                    categories
                                                                    of personal
                                                                    information
                                                                    do we
                                                                    collect?
                                                                  </strong>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  We have
                                                                  collected the
                                                                  following
                                                                  categories of
                                                                  personal
                                                                  information in
                                                                  the past
                                                                  twelve (12)
                                                                  months:
                                                                  <span
                                                                    style={{
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tbody>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          Category
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          Examples
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    textAlign:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          Collected
                                                                        </strong>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          A.
                                                                          Identifiers
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Contact
                                                                          details,
                                                                          such
                                                                          as
                                                                          real
                                                                          name,
                                                                          alias,
                                                                          postal
                                                                          address,
                                                                          telephone
                                                                          or
                                                                          mobile
                                                                          contact
                                                                          number,
                                                                          unique
                                                                          personal
                                                                          identifier,
                                                                          online
                                                                          identifier,
                                                                          Internet
                                                                          Protocol
                                                                          address,
                                                                          email
                                                                          address,
                                                                          and
                                                                          account
                                                                          name
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    verticalAlign:
                                                                      "middle",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      YES
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="forloop-component" />
                                                                      <span className="block-component" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          B.
                                                                          Personal
                                                                          information
                                                                          categories
                                                                          listed
                                                                          in the
                                                                          California
                                                                          Customer
                                                                          Records
                                                                          statute
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Name,
                                                                          contact
                                                                          information,
                                                                          education,
                                                                          employment,
                                                                          employment
                                                                          history,
                                                                          and
                                                                          financial
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span className="forloop-component" />
                                                                          <span className="block-component" />
                                                                          YES
                                                                          <span className="statement-end-if-in-editor" />
                                                                          <span className="forloop-component" />
                                                                          <span className="block-component" />
                                                                          <span className="statement-end-if-in-editor" />
                                                                          <span className="block-component" />
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          C.
                                                                          Protected
                                                                          classification
                                                                          characteristics
                                                                          under
                                                                          California
                                                                          or
                                                                          federal
                                                                          law
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Gender
                                                                          and
                                                                          date
                                                                          of
                                                                          birth
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span className="block-component" />
                                                                    <span className="block-component" />
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          D.
                                                                          Commercial
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Transaction
                                                                          information,
                                                                          purchase
                                                                          history,
                                                                          financial
                                                                          details,
                                                                          and
                                                                          payment
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span className="block-component" />
                                                                    <span className="block-component" />
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          E.
                                                                          Biometric
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Fingerprints
                                                                          and
                                                                          voiceprints
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span className="block-component" />
                                                                    <span className="block-component" />
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          F.
                                                                          Internet
                                                                          or
                                                                          other
                                                                          similar
                                                                          network
                                                                          activity
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Browsing
                                                                          history,
                                                                          search
                                                                          history,
                                                                          online{" "}
                                                                          <span className="block-component" />
                                                                          behavior
                                                                          <span className="statement-end-if-in-editor" />
                                                                          ,
                                                                          interest
                                                                          data,
                                                                          and
                                                                          interactions
                                                                          with
                                                                          our
                                                                          and
                                                                          other
                                                                          websites,
                                                                          applications,
                                                                          systems,
                                                                          and
                                                                          advertisements
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span className="block-component" />
                                                                    <span className="block-component" />
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          G.
                                                                          Geolocation
                                                                          data
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Device
                                                                          location
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      YES
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="forloop-component" />
                                                                      <span className="block-component" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          H.
                                                                          Audio,
                                                                          electronic,
                                                                          visual,
                                                                          thermal,
                                                                          olfactory,
                                                                          or
                                                                          similar
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Images
                                                                          and
                                                                          audio,
                                                                          video
                                                                          or
                                                                          call
                                                                          recordings
                                                                          created
                                                                          in
                                                                          connection
                                                                          with
                                                                          our
                                                                          business
                                                                          activities
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span className="block-component" />
                                                                    <span className="block-component" />
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "33.8274%",
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          I.
                                                                          Professional
                                                                          or
                                                                          employment-related
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "51.4385%",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Business
                                                                          contact
                                                                          details
                                                                          in
                                                                          order
                                                                          to
                                                                          provide
                                                                          you
                                                                          our
                                                                          Services
                                                                          at a
                                                                          business
                                                                          level
                                                                          or job
                                                                          title,
                                                                          work
                                                                          history,
                                                                          and
                                                                          professional
                                                                          qualifications
                                                                          if you
                                                                          apply
                                                                          for a
                                                                          job
                                                                          with
                                                                          us
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    width:
                                                                      "14.9084%",
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      YES
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="forloop-component" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      <span className="forloop-component" />
                                                                      <span className="block-component" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    width:
                                                                      "33.8274%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          J.
                                                                          Education
                                                                          Information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    width:
                                                                      "51.4385%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Student
                                                                          records
                                                                          and
                                                                          directory
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    width:
                                                                      "14.9084%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="forloop-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      YES
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="forloop-component" />
                                                                    </span>
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      <span className="forloop-component" />
                                                                      <span className="block-component" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="statement-end-if-in-editor" />
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    borderWidth: 1,
                                                                    borderColor:
                                                                      "black",
                                                                    borderStyle:
                                                                      "solid",
                                                                    width:
                                                                      "33.8274%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          K.
                                                                          Inferences
                                                                          drawn
                                                                          from
                                                                          other
                                                                          personal
                                                                          information
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    borderBottom:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    width:
                                                                      "51.4385%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Inferences
                                                                          drawn
                                                                          from
                                                                          any of
                                                                          the
                                                                          collected
                                                                          personal
                                                                          information
                                                                          listed
                                                                          above
                                                                          to
                                                                          create
                                                                          a
                                                                          profile
                                                                          or
                                                                          summary
                                                                          about,
                                                                          for
                                                                          example,
                                                                          an
                                                                          individual’s
                                                                          preferences
                                                                          and
                                                                          characteristics
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    textAlign:
                                                                      "center",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderBottom:
                                                                      "1px solid black",
                                                                    borderTop:
                                                                      "1px solid black",
                                                                    width:
                                                                      "14.9084%",
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                      NO
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color:
                                                                            "rgb(89, 89, 89)",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span className="statement-end-if-in-editor" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                              <tr>
                                                                <td
                                                                  style={{
                                                                    borderLeft:
                                                                      "1px solid black",
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderBottom:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    L. Sensitive
                                                                    Personal
                                                                    Information
                                                                  </span>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderBottom:
                                                                      "1px solid black",
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span className="block-component">
                                                                    <span data-custom-class="body_text" />
                                                                  </span>
                                                                </td>
                                                                <td
                                                                  style={{
                                                                    borderRight:
                                                                      "1px solid black",
                                                                    borderBottom:
                                                                      "1px solid black",
                                                                  }}
                                                                >
                                                                  <div
                                                                    data-empty="true"
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                  <div
                                                                    data-custom-class="body_text"
                                                                    data-empty="true"
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span className="block-component">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                    NO
                                                                    <span className="statement-end-if-in-editor">
                                                                      <span data-custom-class="body_text" />
                                                                    </span>
                                                                  </div>
                                                                  <div
                                                                    data-empty="true"
                                                                    style={{
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    <br />
                                                                  </div>
                                                                </td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  "rgb(89, 89, 89)",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 15,
                                                                  color:
                                                                    "rgb(89, 89, 89)",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color:
                                                                        "rgb(89, 89, 89)",
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color:
                                                                          "rgb(89, 89, 89)",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              "rgb(89, 89, 89)",
                                                                            fontSize: 15,
                                                                          }}
                                                                        >
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <span className="statement-end-if-in-editor" />
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <br />
                                                          </div>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span className="block-component">
                                                              <span data-custom-class="body_text" />
                                                            </span>
                                                            <span data-custom-class="body_text">
                                                              We will use and
                                                              retain the
                                                              collected personal
                                                              information as
                                                              needed to provide
                                                              the Services or
                                                              for:
                                                              <span className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Category A -{" "}
                                                                <span className="question">
                                                                  As long as the
                                                                  user has an
                                                                  account with
                                                                  us
                                                                </span>
                                                                <span className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span className="block-component" />
                                                            </span>
                                                          </div>
                                                          <ul>
                                                            <li
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                Category B -{" "}
                                                                <span className="question">
                                                                  As long as the
                                                                  user has an
                                                                  account with
                                                                  us
                                                                </span>
                                                                <span className="statement-end-if-in-editor" />
                                                              </span>
                                                            </li>
                                                          </ul>
                                                          <div
                                                            style={{
                                                              lineHeight: "1.5",
                                                            }}
                                                          >
                                                            <span data-custom-class="body_text">
                                                              <span className="block-component" />
                                                            </span>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.5",
                                                              }}
                                                            >
                                                              <span data-custom-class="body_text">
                                                                <span className="block-component" />
                                                              </span>
                                                              <div
                                                                style={{
                                                                  lineHeight:
                                                                    "1.5",
                                                                }}
                                                              >
                                                                <span data-custom-class="body_text">
                                                                  <span className="block-component" />
                                                                </span>
                                                                <div
                                                                  style={{
                                                                    lineHeight:
                                                                      "1.5",
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span className="block-component" />
                                                                  </span>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                    </span>
                                                                  </div>
                                                                  <ul>
                                                                    <li
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        Category
                                                                        G -{" "}
                                                                        <span className="question">
                                                                          As
                                                                          long
                                                                          as the
                                                                          user
                                                                          has an
                                                                          account
                                                                          with
                                                                          us
                                                                        </span>
                                                                        <span className="statement-end-if-in-editor" />
                                                                      </span>
                                                                    </li>
                                                                  </ul>
                                                                  <div
                                                                    style={{
                                                                      lineHeight:
                                                                        "1.5",
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span className="block-component" />
                                                                    </span>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Category
                                                                          I -{" "}
                                                                          <span className="question">
                                                                            As
                                                                            long
                                                                            as
                                                                            the
                                                                            user
                                                                            has
                                                                            an
                                                                            account
                                                                            with
                                                                            us
                                                                          </span>
                                                                          <span className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                    </div>
                                                                    <ul>
                                                                      <li
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          Category
                                                                          J -{" "}
                                                                          <span className="question">
                                                                            As
                                                                            long
                                                                            as
                                                                            the
                                                                            user
                                                                            has
                                                                            an
                                                                            account
                                                                            with
                                                                            us
                                                                          </span>
                                                                          <span className="statement-end-if-in-editor" />
                                                                        </span>
                                                                      </li>
                                                                    </ul>
                                                                    <div
                                                                      style={{
                                                                        lineHeight:
                                                                          "1.5",
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <span className="block-component" />
                                                                      </span>
                                                                      <div
                                                                        style={{
                                                                          lineHeight:
                                                                            "1.5",
                                                                        }}
                                                                      >
                                                                        <span data-custom-class="body_text">
                                                                          <span className="block-component" />
                                                                        </span>
                                                                        <span className="statement-end-if-in-editor">
                                                                          <span data-custom-class="body_text" />
                                                                        </span>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span className="block-component" />
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                We
                                                                                may
                                                                                also
                                                                                collect
                                                                                other
                                                                                personal
                                                                                information
                                                                                outside
                                                                                of
                                                                                these
                                                                                categories
                                                                                through
                                                                                instances
                                                                                where
                                                                                you
                                                                                interact
                                                                                with
                                                                                us
                                                                                in
                                                                                person,
                                                                                online,
                                                                                or
                                                                                by
                                                                                phone
                                                                                or
                                                                                mail
                                                                                in
                                                                                the
                                                                                context
                                                                                of:
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <ul>
                                                                          <li
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Receiving
                                                                                  help
                                                                                  through
                                                                                  our
                                                                                  customer
                                                                                  support
                                                                                  channels;
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </li>
                                                                        </ul>
                                                                        <div>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <ul>
                                                                          <li
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Participation
                                                                                  in
                                                                                  customer
                                                                                  surveys
                                                                                  or
                                                                                  contests;
                                                                                  and
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </li>
                                                                        </ul>
                                                                        <div>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <ul>
                                                                          <li
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Facilitation
                                                                                  in
                                                                                  the
                                                                                  delivery
                                                                                  of
                                                                                  our
                                                                                  Services
                                                                                  and
                                                                                  to
                                                                                  respond
                                                                                  to
                                                                                  your
                                                                                  inquiries.
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </li>
                                                                        </ul>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <strong>
                                                                                  How
                                                                                  do
                                                                                  we
                                                                                  use
                                                                                  and
                                                                                  share
                                                                                  your
                                                                                  personal
                                                                                  information?
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span className="block-component" />
                                                                                    <span className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span className="statement-end-if-in-editor" />
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      More
                                                                                      information
                                                                                      about
                                                                                      our
                                                                                      data
                                                                                      collection
                                                                                      and
                                                                                      sharing
                                                                                      practices
                                                                                      can
                                                                                      be
                                                                                      found
                                                                                      in
                                                                                      this
                                                                                      privacy
                                                                                      notice
                                                                                      <span className="block-component" />

                                                                                      .
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                You
                                                                                may
                                                                                contact
                                                                                us{" "}
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                                by
                                                                                email
                                                                                at&nbsp;
                                                                              </span>
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span className="question">
                                                                                    hourlead.founders@gmail.com
                                                                                  </span>

                                                                                  ,{" "}
                                                                                  <span className="statement-end-if-in-editor" />
                                                                                  <span className="block-component" />
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  <span className="block-component" />
                                                                                  <span className="block-component">
                                                                                    <span data-custom-class="body_text" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            or
                                                                            by
                                                                            referring
                                                                            to
                                                                            the
                                                                            contact
                                                                            details
                                                                            at
                                                                            the
                                                                            bottom
                                                                            of
                                                                            this
                                                                            document.
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                If
                                                                                you
                                                                                are
                                                                                using
                                                                                an{" "}
                                                                                <span className="block-component" />
                                                                                authorized
                                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                                agent
                                                                                to
                                                                                exercise
                                                                                your
                                                                                right
                                                                                to
                                                                                opt
                                                                                out
                                                                                we
                                                                                may
                                                                                deny
                                                                                a
                                                                                request
                                                                                if
                                                                                the{" "}
                                                                                <span className="block-component" />
                                                                                authorized
                                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                                agent
                                                                                does
                                                                                not
                                                                                submit
                                                                                proof
                                                                                that
                                                                                they
                                                                                have
                                                                                been
                                                                                validly{" "}
                                                                                <span className="block-component" />
                                                                                authorized
                                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                                to
                                                                                act
                                                                                on
                                                                                your
                                                                                behalf.
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <strong>
                                                                                  Will
                                                                                  your
                                                                                  information
                                                                                  be
                                                                                  shared
                                                                                  with
                                                                                  anyone
                                                                                  else?
                                                                                </strong>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                We
                                                                                may
                                                                                disclose
                                                                                your
                                                                                personal
                                                                                information
                                                                                with
                                                                                our
                                                                                service
                                                                                providers
                                                                                pursuant
                                                                                to
                                                                                a
                                                                                written
                                                                                contract
                                                                                between
                                                                                us
                                                                                and
                                                                                each
                                                                                service
                                                                                provider.
                                                                                Each
                                                                                service
                                                                                provider
                                                                                is
                                                                                a
                                                                                for-profit
                                                                                entity
                                                                                that
                                                                                processes
                                                                                the
                                                                                information
                                                                                on
                                                                                our
                                                                                behalf,
                                                                                following
                                                                                the
                                                                                same
                                                                                strict
                                                                                privacy
                                                                                protection
                                                                                obligations
                                                                                mandated
                                                                                by
                                                                                the
                                                                                CCPA.
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                              color:
                                                                                "rgb(89, 89, 89)",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                We
                                                                                may
                                                                                use
                                                                                your
                                                                                personal
                                                                                information
                                                                                for
                                                                                our
                                                                                own
                                                                                business
                                                                                purposes,
                                                                                such
                                                                                as
                                                                                for
                                                                                undertaking
                                                                                internal
                                                                                research
                                                                                for
                                                                                technological
                                                                                development
                                                                                and
                                                                                demonstration.
                                                                                This
                                                                                is
                                                                                not
                                                                                considered
                                                                                to
                                                                                be{" "}
                                                                                <span className="block-component" />
                                                                                "selling"
                                                                                <span className="statement-end-if-in-editor" />{" "}
                                                                                of
                                                                                your
                                                                                personal
                                                                                information.
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      "rgb(89, 89, 89)",
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span className="block-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <span className="block-component" />
                                                                          </span>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <br />
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            lineHeight:
                                                                              "1.5",
                                                                          }}
                                                                        >
                                                                          <span className="block-component">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text" />
                                                                            </span>
                                                                          </span>
                                                                          <span data-custom-class="body_text">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="question">
                                                                                Hourlead,
                                                                                Inc.
                                                                              </span>{" "}
                                                                              has
                                                                              not
                                                                              sold
                                                                              or
                                                                              shared
                                                                              any
                                                                              personal
                                                                              information
                                                                              to
                                                                              third
                                                                              parties
                                                                              for
                                                                              a
                                                                              business
                                                                              or
                                                                              commercial
                                                                              purpose
                                                                              in
                                                                              the
                                                                              preceding
                                                                              twelve
                                                                              (12)
                                                                              months.&nbsp;
                                                                            </span>
                                                                          </span>
                                                                          <span className="statement-end-if-in-editor">
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text" />
                                                                            </span>
                                                                          </span>
                                                                          <span
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  "rgb(89, 89, 89)",
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span className="question">
                                                                                    Hourlead,
                                                                                    Inc.
                                                                                  </span>
                                                                                </span>
                                                                                <span data-custom-class="body_text">
                                                                                  &nbsp;has
                                                                                  disclosed
                                                                                  the
                                                                                  following
                                                                                  categories
                                                                                  of
                                                                                  personal
                                                                                  information
                                                                                  to
                                                                                  third
                                                                                  parties
                                                                                  for
                                                                                  a
                                                                                  business
                                                                                  or
                                                                                  commercial
                                                                                  purpose
                                                                                  in
                                                                                  the
                                                                                  preceding
                                                                                  twelve
                                                                                  (12)
                                                                                  months:
                                                                                  <span className="forloop-component" />
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </span>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                              <span className="block-component" />
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    "rgb(89, 89, 89)",
                                                                                }}
                                                                              >
                                                                                <span className="forloop-component" />
                                                                              </span>
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </span>
                                                                            <span data-custom-class="body_text">
                                                                              <span className="block-component" />
                                                                            </span>
                                                                            <span className="forloop-component">
                                                                              <span data-custom-class="body_text" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <p
                                                                            style={{
                                                                              fontSize: 15,
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                          </p>
                                                                          <div
                                                                            style={{
                                                                              lineHeight:
                                                                                "1.5",
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </span>
                                                                            <p
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                            </p>
                                                                            <p
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component" />
                                                                              <span className="block-component" />
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span className="forloop-component" />
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </p>
                                                                            <p
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span className="forloop-component">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                />
                                                                              </span>
                                                                            </p>
                                                                            <p
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span className="forloop-component" />
                                                                              </span>
                                                                            </p>
                                                                            <div>
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span className="forloop-component" />
                                                                              </span>
                                                                              <div>
                                                                                <span className="forloop-component">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                                <span className="block-component">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                                <span className="forloop-component" />
                                                                                <span className="block-component">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                              </div>
                                                                              <ul>
                                                                                <li
                                                                                  style={{
                                                                                    lineHeight:
                                                                                      "1.5",
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        "rgb(89, 89, 89)",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        Category
                                                                                        I.
                                                                                        Professional
                                                                                        or
                                                                                        employment-related
                                                                                        information,
                                                                                        such
                                                                                        as
                                                                                        business
                                                                                        contact
                                                                                        details
                                                                                        in
                                                                                        order
                                                                                        to
                                                                                        provide
                                                                                        you
                                                                                        our
                                                                                        Services
                                                                                        at
                                                                                        a
                                                                                        business
                                                                                        level
                                                                                        or
                                                                                        job
                                                                                        title,
                                                                                        work
                                                                                        history,
                                                                                        and
                                                                                        professional
                                                                                        qualifications
                                                                                        if
                                                                                        you
                                                                                        apply
                                                                                        for
                                                                                        a
                                                                                        job
                                                                                        with
                                                                                        us.
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                  <span className="forloop-component" />
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                              <div>
                                                                                <span className="forloop-component">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  />
                                                                                </span>
                                                                                <div>
                                                                                  <span className="forloop-component">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    />
                                                                                  </span>
                                                                                  <span className="block-component">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    />
                                                                                  </span>
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span className="forloop-component" />
                                                                                      </span>
                                                                                      <span className="block-component">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        />
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </div>
                                                                                <ul>
                                                                                  <li
                                                                                    style={{
                                                                                      lineHeight:
                                                                                        "1.5",
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          "rgb(89, 89, 89)",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          Category
                                                                                          J.
                                                                                          Education
                                                                                          information,
                                                                                          such
                                                                                          as
                                                                                          student
                                                                                          records
                                                                                          and
                                                                                          directory
                                                                                          information.
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                              <span data-custom-class="body_text">
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span className="forloop-component" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </li>
                                                                                </ul>
                                                                                <div>
                                                                                  <span className="block-component">
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    />
                                                                                  </span>
                                                                                  <div>
                                                                                    <span className="block-component" />
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            The
                                                                                            categories
                                                                                            of
                                                                                            third
                                                                                            parties
                                                                                            to
                                                                                            whom
                                                                                            we
                                                                                            disclosed
                                                                                            personal
                                                                                            information
                                                                                            for
                                                                                            a
                                                                                            business
                                                                                            or
                                                                                            commercial
                                                                                            purpose
                                                                                            can
                                                                                            be
                                                                                            found
                                                                                            under{" "}
                                                                                            <span className="block-component" />
                                                                                            "
                                                                                            <span className="statement-end-if-in-editor" />
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <a
                                                                                                  data-custom-class="link"
                                                                                                  href="#whoshare"
                                                                                                >
                                                                                                  WHEN
                                                                                                  AND
                                                                                                  WITH
                                                                                                  WHOM
                                                                                                  DO
                                                                                                  WE
                                                                                                  SHARE
                                                                                                  YOUR
                                                                                                  PERSONAL
                                                                                                  INFORMATION?
                                                                                                </a>
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span className="block-component" />
                                                                                                                "
                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>

                                                                                            .
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                        }}
                                                                                                      >
                                                                                                        <span
                                                                                                          style={{
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                }}
                                                                                                              >
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    fontSize: 15,
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <span
                                                                                                                    style={{
                                                                                                                      color:
                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                    }}
                                                                                                                  >
                                                                                                                    <span
                                                                                                                      style={{
                                                                                                                        fontSize: 15,
                                                                                                                      }}
                                                                                                                    >
                                                                                                                      <span
                                                                                                                        style={{
                                                                                                                          color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        }}
                                                                                                                      >
                                                                                                                        <span
                                                                                                                          style={{
                                                                                                                            fontSize: 15,
                                                                                                                          }}
                                                                                                                        >
                                                                                                                          <span
                                                                                                                            style={{
                                                                                                                              color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            }}
                                                                                                                          >
                                                                                                                            <span
                                                                                                                              style={{
                                                                                                                                fontSize: 15,
                                                                                                                              }}
                                                                                                                            >
                                                                                                                              <span
                                                                                                                                style={{
                                                                                                                                  color:
                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                }}
                                                                                                                              >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                  <span className="statement-end-if-in-editor">
                                                                                                                                    <span
                                                                                                                                      style={{
                                                                                                                                        fontSize: 15,
                                                                                                                                      }}
                                                                                                                                    >
                                                                                                                                      <span
                                                                                                                                        style={{
                                                                                                                                          color:
                                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                                        }}
                                                                                                                                      >
                                                                                                                                        <span
                                                                                                                                          style={{
                                                                                                                                            fontSize: 15,
                                                                                                                                          }}
                                                                                                                                        >
                                                                                                                                          <span
                                                                                                                                            style={{
                                                                                                                                              color:
                                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                                            }}
                                                                                                                                          >
                                                                                                                                            <span
                                                                                                                                              style={{
                                                                                                                                                fontSize: 15,
                                                                                                                                              }}
                                                                                                                                            >
                                                                                                                                              <span
                                                                                                                                                style={{
                                                                                                                                                  color:
                                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                                }}
                                                                                                                                              >
                                                                                                                                                <span
                                                                                                                                                  style={{
                                                                                                                                                    fontSize: 15,
                                                                                                                                                  }}
                                                                                                                                                >
                                                                                                                                                  <span
                                                                                                                                                    style={{
                                                                                                                                                      color:
                                                                                                                                                        "rgb(89, 89, 89)",
                                                                                                                                                    }}
                                                                                                                                                  >
                                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                                      <span className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                  </span>
                                                                                                                                                </span>
                                                                                                                                              </span>
                                                                                                                                            </span>
                                                                                                                                          </span>
                                                                                                                                        </span>
                                                                                                                                      </span>
                                                                                                                                    </span>
                                                                                                                                  </span>
                                                                                                                                </span>
                                                                                                                              </span>
                                                                                                                            </span>
                                                                                                                          </span>
                                                                                                                        </span>
                                                                                                                      </span>
                                                                                                                    </span>
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(0, 0, 0)",
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span className="block-component" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <strong>
                                                                                              Your
                                                                                              rights
                                                                                              with
                                                                                              respect
                                                                                              to
                                                                                              your
                                                                                              personal
                                                                                              data
                                                                                            </strong>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <u>
                                                                                              Right
                                                                                              to
                                                                                              request
                                                                                              deletion
                                                                                              of
                                                                                              the
                                                                                              data
                                                                                              —
                                                                                              Request
                                                                                              to
                                                                                              delete
                                                                                            </u>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            You
                                                                                            can
                                                                                            ask
                                                                                            for
                                                                                            the
                                                                                            deletion
                                                                                            of
                                                                                            your
                                                                                            personal
                                                                                            information.
                                                                                            If
                                                                                            you
                                                                                            ask
                                                                                            us
                                                                                            to
                                                                                            delete
                                                                                            your
                                                                                            personal
                                                                                            information,
                                                                                            we
                                                                                            will
                                                                                            respect
                                                                                            your
                                                                                            request
                                                                                            and
                                                                                            delete
                                                                                            your
                                                                                            personal
                                                                                            information,
                                                                                            subject
                                                                                            to
                                                                                            certain
                                                                                            exceptions
                                                                                            provided
                                                                                            by
                                                                                            law,
                                                                                            such
                                                                                            as
                                                                                            (but
                                                                                            not
                                                                                            limited
                                                                                            to)
                                                                                            the
                                                                                            exercise
                                                                                            by
                                                                                            another
                                                                                            consumer
                                                                                            of
                                                                                            his
                                                                                            or
                                                                                            her
                                                                                            right
                                                                                            to
                                                                                            free
                                                                                            speech,
                                                                                            our
                                                                                            compliance
                                                                                            requirements
                                                                                            resulting
                                                                                            from
                                                                                            a
                                                                                            legal
                                                                                            obligation,
                                                                                            or
                                                                                            any
                                                                                            processing
                                                                                            that
                                                                                            may
                                                                                            be
                                                                                            required
                                                                                            to
                                                                                            protect
                                                                                            against
                                                                                            illegal
                                                                                            activities.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <u>
                                                                                              Right
                                                                                              to
                                                                                              be
                                                                                              informed
                                                                                              —
                                                                                              Request
                                                                                              to
                                                                                              know
                                                                                            </u>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Depending
                                                                                            on
                                                                                            the
                                                                                            circumstances,
                                                                                            you
                                                                                            have
                                                                                            a
                                                                                            right
                                                                                            to
                                                                                            know:
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              whether
                                                                                              we
                                                                                              collect
                                                                                              and
                                                                                              use
                                                                                              your
                                                                                              personal
                                                                                              information;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              the
                                                                                              categories
                                                                                              of
                                                                                              personal
                                                                                              information
                                                                                              that
                                                                                              we
                                                                                              collect;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              the
                                                                                              purposes
                                                                                              for
                                                                                              which
                                                                                              the
                                                                                              collected
                                                                                              personal
                                                                                              information
                                                                                              is
                                                                                              used;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              whether
                                                                                              we
                                                                                              sell
                                                                                              or
                                                                                              share
                                                                                              personal
                                                                                              information
                                                                                              to
                                                                                              third
                                                                                              parties;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              the
                                                                                              categories
                                                                                              of
                                                                                              personal
                                                                                              information
                                                                                              that
                                                                                              we
                                                                                              sold,
                                                                                              shared,
                                                                                              or
                                                                                              disclosed
                                                                                              for
                                                                                              a
                                                                                              business
                                                                                              purpose;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              the
                                                                                              categories
                                                                                              of
                                                                                              third
                                                                                              parties
                                                                                              to
                                                                                              whom
                                                                                              the
                                                                                              personal
                                                                                              information
                                                                                              was
                                                                                              sold,
                                                                                              shared,
                                                                                              or
                                                                                              disclosed
                                                                                              for
                                                                                              a
                                                                                              business
                                                                                              purpose;
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              the
                                                                                              business
                                                                                              or
                                                                                              commercial
                                                                                              purpose
                                                                                              for
                                                                                              collecting,
                                                                                              selling,
                                                                                              or
                                                                                              sharing
                                                                                              personal
                                                                                              information;
                                                                                              and
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span className="block-component">
                                                                                        <span data-custom-class="body_text" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          the
                                                                                          specific
                                                                                          pieces
                                                                                          of
                                                                                          personal
                                                                                          information
                                                                                          we
                                                                                          collected
                                                                                          about
                                                                                          you.
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            In
                                                                                            accordance
                                                                                            with
                                                                                            applicable
                                                                                            law,
                                                                                            we
                                                                                            are
                                                                                            not
                                                                                            obligated
                                                                                            to
                                                                                            provide
                                                                                            or
                                                                                            delete
                                                                                            consumer
                                                                                            information
                                                                                            that
                                                                                            is
                                                                                            de-identified
                                                                                            in
                                                                                            response
                                                                                            to
                                                                                            a
                                                                                            consumer
                                                                                            request
                                                                                            or
                                                                                            to
                                                                                            re-identify
                                                                                            individual
                                                                                            data
                                                                                            to
                                                                                            verify
                                                                                            a
                                                                                            consumer
                                                                                            request.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <u>
                                                                                              Right
                                                                                              to
                                                                                              Non-Discrimination
                                                                                              for
                                                                                              the
                                                                                              Exercise
                                                                                              of
                                                                                              a
                                                                                              Consumer’s
                                                                                              Privacy
                                                                                              Rights
                                                                                            </u>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            We
                                                                                            will
                                                                                            not
                                                                                            discriminate
                                                                                            against
                                                                                            you
                                                                                            if
                                                                                            you
                                                                                            exercise
                                                                                            your
                                                                                            privacy
                                                                                            rights.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <u>
                                                                                        <span data-custom-class="body_text">
                                                                                          Right
                                                                                          to
                                                                                          Limit
                                                                                          Use
                                                                                          and
                                                                                          Disclosure
                                                                                          of
                                                                                          Sensitive
                                                                                          Personal
                                                                                          Information
                                                                                        </span>
                                                                                      </u>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span className="block-component">
                                                                                        <span data-custom-class="body_text" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        We
                                                                                        do
                                                                                        not
                                                                                        process
                                                                                        consumer's
                                                                                        sensitive
                                                                                        personal
                                                                                        information.
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span className="statement-end-if-in-editor">
                                                                                        <span data-custom-class="body_text" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <u>
                                                                                              Verification
                                                                                              process
                                                                                            </u>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Upon
                                                                                            receiving
                                                                                            your
                                                                                            request,
                                                                                            we
                                                                                            will
                                                                                            need
                                                                                            to
                                                                                            verify
                                                                                            your
                                                                                            identity
                                                                                            to
                                                                                            determine
                                                                                            you
                                                                                            are
                                                                                            the
                                                                                            same
                                                                                            person
                                                                                            about
                                                                                            whom
                                                                                            we
                                                                                            have
                                                                                            the
                                                                                            information
                                                                                            in
                                                                                            our
                                                                                            system.
                                                                                            These
                                                                                            verification
                                                                                            efforts
                                                                                            require
                                                                                            us
                                                                                            to
                                                                                            ask
                                                                                            you
                                                                                            to
                                                                                            provide
                                                                                            information
                                                                                            so
                                                                                            that
                                                                                            we
                                                                                            can
                                                                                            match
                                                                                            it
                                                                                            with
                                                                                            information
                                                                                            you
                                                                                            have
                                                                                            previously
                                                                                            provided
                                                                                            us.
                                                                                            For
                                                                                            instance,
                                                                                            depending
                                                                                            on
                                                                                            the
                                                                                            type
                                                                                            of
                                                                                            request
                                                                                            you
                                                                                            submit,
                                                                                            we
                                                                                            may
                                                                                            ask
                                                                                            you
                                                                                            to
                                                                                            provide
                                                                                            certain
                                                                                            information
                                                                                            so
                                                                                            that
                                                                                            we
                                                                                            can
                                                                                            match
                                                                                            the
                                                                                            information
                                                                                            you
                                                                                            provide
                                                                                            with
                                                                                            the
                                                                                            information
                                                                                            we
                                                                                            already
                                                                                            have
                                                                                            on
                                                                                            file,
                                                                                            or
                                                                                            we
                                                                                            may
                                                                                            contact
                                                                                            you
                                                                                            through
                                                                                            a
                                                                                            communication
                                                                                            method
                                                                                            (e.g.
                                                                                            <span className="block-component" />
                                                                                            ,
                                                                                            <span className="statement-end-if-in-editor" />{" "}
                                                                                            phone
                                                                                            or
                                                                                            email)
                                                                                            that
                                                                                            you
                                                                                            have
                                                                                            previously
                                                                                            provided
                                                                                            to
                                                                                            us.
                                                                                            We
                                                                                            may
                                                                                            also
                                                                                            use
                                                                                            other
                                                                                            verification
                                                                                            methods
                                                                                            as
                                                                                            the
                                                                                            circumstances
                                                                                            dictate.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            We
                                                                                            will
                                                                                            only
                                                                                            use
                                                                                            personal
                                                                                            information
                                                                                            provided
                                                                                            in
                                                                                            your
                                                                                            request
                                                                                            to
                                                                                            verify
                                                                                            your
                                                                                            identity
                                                                                            or
                                                                                            authority
                                                                                            to
                                                                                            make
                                                                                            the
                                                                                            request.
                                                                                            To
                                                                                            the
                                                                                            extent
                                                                                            possible,
                                                                                            we
                                                                                            will
                                                                                            avoid
                                                                                            requesting
                                                                                            additional
                                                                                            information
                                                                                            from
                                                                                            you
                                                                                            for
                                                                                            the
                                                                                            purposes
                                                                                            of
                                                                                            verification.
                                                                                            However,
                                                                                            if
                                                                                            we
                                                                                            cannot
                                                                                            verify
                                                                                            your
                                                                                            identity
                                                                                            from
                                                                                            the
                                                                                            information
                                                                                            already
                                                                                            maintained
                                                                                            by
                                                                                            us,
                                                                                            we
                                                                                            may
                                                                                            request
                                                                                            that
                                                                                            you
                                                                                            provide
                                                                                            additional
                                                                                            information
                                                                                            for
                                                                                            the
                                                                                            purposes
                                                                                            of
                                                                                            verifying
                                                                                            your
                                                                                            identity
                                                                                            and
                                                                                            for
                                                                                            security
                                                                                            or
                                                                                            fraud-prevention
                                                                                            purposes.
                                                                                            We
                                                                                            will
                                                                                            delete
                                                                                            such
                                                                                            additionally
                                                                                            provided
                                                                                            information
                                                                                            as
                                                                                            soon
                                                                                            as
                                                                                            we
                                                                                            finish
                                                                                            verifying
                                                                                            you.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <u>
                                                                                              Other
                                                                                              privacy
                                                                                              rights
                                                                                            </u>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              You
                                                                                              may
                                                                                              object
                                                                                              to
                                                                                              the
                                                                                              processing
                                                                                              of
                                                                                              your
                                                                                              personal
                                                                                              information.
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(0, 0, 0)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              You
                                                                                              may
                                                                                              request
                                                                                              correction
                                                                                              of
                                                                                              your
                                                                                              personal
                                                                                              data
                                                                                              if
                                                                                              it
                                                                                              is
                                                                                              incorrect
                                                                                              or
                                                                                              no
                                                                                              longer
                                                                                              relevant,
                                                                                              or
                                                                                              ask
                                                                                              to
                                                                                              restrict
                                                                                              the
                                                                                              processing
                                                                                              of
                                                                                              the
                                                                                              information.
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(0, 0, 0)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              You
                                                                                              can
                                                                                              designate
                                                                                              an{" "}
                                                                                              <span className="block-component" />
                                                                                              authorized
                                                                                              <span className="statement-end-if-in-editor" />{" "}
                                                                                              agent
                                                                                              to
                                                                                              make
                                                                                              a
                                                                                              request
                                                                                              under
                                                                                              the
                                                                                              CCPA
                                                                                              on
                                                                                              your
                                                                                              behalf.
                                                                                              We
                                                                                              may
                                                                                              deny
                                                                                              a
                                                                                              request
                                                                                              from
                                                                                              an{" "}
                                                                                              <span className="block-component" />
                                                                                              authorized
                                                                                              <span className="statement-end-if-in-editor" />{" "}
                                                                                              agent
                                                                                              that
                                                                                              does
                                                                                              not
                                                                                              submit
                                                                                              proof
                                                                                              that
                                                                                              they
                                                                                              have
                                                                                              been
                                                                                              validly{" "}
                                                                                              <span className="block-component" />
                                                                                              authorized
                                                                                              <span className="statement-end-if-in-editor" />{" "}
                                                                                              to
                                                                                              act
                                                                                              on
                                                                                              your
                                                                                              behalf
                                                                                              in
                                                                                              accordance
                                                                                              with
                                                                                              the
                                                                                              CCPA.
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(0, 0, 0)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                        color:
                                                                                                          "rgb(89, 89, 89)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                            fontSize: 15,
                                                                                                          }}
                                                                                                        >
                                                                                                          <span data-custom-class="body_text">
                                                                                                            <span className="block-component" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              You
                                                                                              may
                                                                                              request
                                                                                              to
                                                                                              opt
                                                                                              out
                                                                                              from
                                                                                              future
                                                                                              selling
                                                                                              or
                                                                                              sharing
                                                                                              of
                                                                                              your
                                                                                              personal
                                                                                              information
                                                                                              to
                                                                                              third
                                                                                              parties.
                                                                                              Upon
                                                                                              receiving
                                                                                              an
                                                                                              opt-out
                                                                                              request,
                                                                                              we
                                                                                              will
                                                                                              act
                                                                                              upon
                                                                                              the
                                                                                              request
                                                                                              as
                                                                                              soon
                                                                                              as
                                                                                              feasibly
                                                                                              possible,
                                                                                              but
                                                                                              no
                                                                                              later
                                                                                              than
                                                                                              fifteen
                                                                                              (15)
                                                                                              days
                                                                                              from
                                                                                              the
                                                                                              date
                                                                                              of
                                                                                              the
                                                                                              request
                                                                                              submission.
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span
                                                                                                      style={{
                                                                                                        color:
                                                                                                          "rgb(0, 0, 0)",
                                                                                                      }}
                                                                                                    >
                                                                                                      <span
                                                                                                        style={{
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(0, 0, 0)",
                                                                                                            }}
                                                                                                          >
                                                                                                            <span
                                                                                                              style={{
                                                                                                                fontSize: 15,
                                                                                                              }}
                                                                                                            >
                                                                                                              <span data-custom-class="body_text">
                                                                                                                <span className="statement-end-if-in-editor" />
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            To
                                                                                            exercise
                                                                                            these
                                                                                            rights,
                                                                                            you
                                                                                            can
                                                                                            contact
                                                                                            us&nbsp;
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span className="block-component" />
                                                                                                  by
                                                                                                  email
                                                                                                  at{" "}
                                                                                                  <span className="question">
                                                                                                    hourlead.founders@gmail.com
                                                                                                  </span>

                                                                                                  ,{" "}
                                                                                                  <span className="statement-end-if-in-editor" />
                                                                                                  <span className="block-component" />
                                                                                                </span>
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span className="block-component" />
                                                                                                  <span className="block-component">
                                                                                                    <span data-custom-class="body_text" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span data-custom-class="body_text">
                                                                                        or
                                                                                        by
                                                                                        referring
                                                                                        to
                                                                                        the
                                                                                        contact
                                                                                        details
                                                                                        at
                                                                                        the
                                                                                        bottom
                                                                                        of
                                                                                        this
                                                                                        document.
                                                                                        If
                                                                                        you
                                                                                        have
                                                                                        a
                                                                                        complaint
                                                                                        about
                                                                                        how
                                                                                        we
                                                                                        handle
                                                                                        your
                                                                                        data,
                                                                                        we
                                                                                        would
                                                                                        like
                                                                                        to
                                                                                        hear
                                                                                        from
                                                                                        you.
                                                                                      </span>
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component">
                                                                                            <span className="block-component">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span
                                                                                                        style={{
                                                                                                          color:
                                                                                                            "rgb(89, 89, 89)",
                                                                                                          fontSize: 15,
                                                                                                        }}
                                                                                                      >
                                                                                                        <span data-custom-class="body_text">
                                                                                                          <span
                                                                                                            style={{
                                                                                                              color:
                                                                                                                "rgb(89, 89, 89)",
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span data-custom-class="body_text">
                                                                                                              <span
                                                                                                                style={{
                                                                                                                  color:
                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                  fontSize: 15,
                                                                                                                }}
                                                                                                              >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                  <span className="statement-end-if-in-editor">
                                                                                                                    <span className="statement-end-if-in-editor" />
                                                                                                                  </span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            </span>
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span className="block-component">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        id="virginia"
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <strong>
                                                                                          <span data-custom-class="heading_1">
                                                                                            10.
                                                                                            DO
                                                                                            VIRGINIA
                                                                                            RESIDENTS
                                                                                            HAVE
                                                                                            SPECIFIC
                                                                                            PRIVACY
                                                                                            RIGHTS?
                                                                                          </span>
                                                                                        </strong>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <em>
                                                                                          <strong>
                                                                                            <span data-custom-class="body_text">
                                                                                              In
                                                                                              Short:
                                                                                            </span>
                                                                                          </strong>
                                                                                          <span data-custom-class="body_text">
                                                                                            &nbsp;Yes,
                                                                                            if
                                                                                            you
                                                                                            are
                                                                                            a
                                                                                            resident
                                                                                            of
                                                                                            Virginia,
                                                                                            you
                                                                                            may
                                                                                            be
                                                                                            granted
                                                                                            specific
                                                                                            rights
                                                                                            regarding
                                                                                            access
                                                                                            to
                                                                                            and
                                                                                            use
                                                                                            of
                                                                                            your
                                                                                            personal
                                                                                            information.
                                                                                          </span>
                                                                                        </em>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <strong>
                                                                                          <span data-custom-class="heading_2">
                                                                                            Virginia
                                                                                            CDPA
                                                                                            Privacy
                                                                                            Notice
                                                                                          </span>
                                                                                        </strong>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          Under
                                                                                          the
                                                                                          Virginia
                                                                                          Consumer
                                                                                          Data
                                                                                          Protection
                                                                                          Act
                                                                                          (CDPA):
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                          "Consumer"
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          means
                                                                                          a
                                                                                          natural
                                                                                          person
                                                                                          who
                                                                                          is
                                                                                          a
                                                                                          resident
                                                                                          of
                                                                                          the
                                                                                          Commonwealth
                                                                                          acting
                                                                                          only
                                                                                          in
                                                                                          an
                                                                                          individual
                                                                                          or
                                                                                          household
                                                                                          context.
                                                                                          It
                                                                                          does
                                                                                          not
                                                                                          include
                                                                                          a
                                                                                          natural
                                                                                          person
                                                                                          acting
                                                                                          in
                                                                                          a
                                                                                          commercial
                                                                                          or
                                                                                          employment
                                                                                          context.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                          "Personal
                                                                                          data"
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          means
                                                                                          any
                                                                                          information
                                                                                          that
                                                                                          is
                                                                                          linked
                                                                                          or
                                                                                          reasonably
                                                                                          linkable
                                                                                          to
                                                                                          an
                                                                                          identified
                                                                                          or
                                                                                          identifiable
                                                                                          natural
                                                                                          person.{" "}
                                                                                          <span className="block-component" />
                                                                                          "Personal
                                                                                          data"
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          does
                                                                                          not
                                                                                          include
                                                                                          de-identified
                                                                                          data
                                                                                          or
                                                                                          publicly
                                                                                          available
                                                                                          information.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />
                                                                                          "Sale
                                                                                          of
                                                                                          personal
                                                                                          data"
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          means
                                                                                          the
                                                                                          exchange
                                                                                          of
                                                                                          personal
                                                                                          data
                                                                                          for
                                                                                          monetary
                                                                                          consideration.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          If
                                                                                          this
                                                                                          definition{" "}
                                                                                          <span className="block-component" />
                                                                                          "consumer"
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          applies
                                                                                          to
                                                                                          you,
                                                                                          we
                                                                                          must
                                                                                          adhere
                                                                                          to
                                                                                          certain
                                                                                          rights
                                                                                          and
                                                                                          obligations
                                                                                          regarding
                                                                                          your
                                                                                          personal
                                                                                          data.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          The
                                                                                          information
                                                                                          we
                                                                                          collect,
                                                                                          use,
                                                                                          and
                                                                                          disclose
                                                                                          about
                                                                                          you
                                                                                          will
                                                                                          vary
                                                                                          depending
                                                                                          on
                                                                                          how
                                                                                          you
                                                                                          interact
                                                                                          with{" "}
                                                                                          <span className="question">
                                                                                            Hourlead,
                                                                                            Inc.
                                                                                          </span>{" "}
                                                                                          and
                                                                                          our
                                                                                          Services.
                                                                                          To
                                                                                          find
                                                                                          out
                                                                                          more,
                                                                                          please
                                                                                          visit
                                                                                          the
                                                                                          following
                                                                                          links:
                                                                                        </span>
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <a
                                                                                            data-custom-class="link"
                                                                                            href="#infocollect"
                                                                                          >
                                                                                            Personal
                                                                                            data
                                                                                            we
                                                                                            collect
                                                                                          </a>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <a
                                                                                            data-custom-class="link"
                                                                                            href="#infouse"
                                                                                          >
                                                                                            How
                                                                                            we
                                                                                            use
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </a>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <a
                                                                                            data-custom-class="link"
                                                                                            href="#whoshare"
                                                                                          >
                                                                                            When
                                                                                            and
                                                                                            with
                                                                                            whom
                                                                                            we
                                                                                            share
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </a>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <u>
                                                                                          <span data-custom-class="body_text">
                                                                                            Your
                                                                                            rights
                                                                                            with
                                                                                            respect
                                                                                            to
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </span>
                                                                                        </u>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            be
                                                                                            informed
                                                                                            whether
                                                                                            or
                                                                                            not
                                                                                            we
                                                                                            are
                                                                                            processing
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </span>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            access
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </span>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            correct
                                                                                            inaccuracies
                                                                                            in
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </span>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            request
                                                                                            deletion
                                                                                            of
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                          </span>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            obtain
                                                                                            a
                                                                                            copy
                                                                                            of
                                                                                            the
                                                                                            personal
                                                                                            data
                                                                                            you
                                                                                            previously
                                                                                            shared
                                                                                            with
                                                                                            us
                                                                                          </span>
                                                                                          <span className="statement-end-if-in-editor" />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <ul>
                                                                                      <li
                                                                                        style={{
                                                                                          lineHeight:
                                                                                            "1.5",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            opt
                                                                                            out
                                                                                            of
                                                                                            the
                                                                                            processing
                                                                                            of
                                                                                            your
                                                                                            personal
                                                                                            data
                                                                                            if
                                                                                            it
                                                                                            is
                                                                                            used
                                                                                            for
                                                                                            targeted
                                                                                            advertising,
                                                                                            the
                                                                                            sale
                                                                                            of
                                                                                            personal
                                                                                            data,
                                                                                            or
                                                                                            profiling
                                                                                            in
                                                                                            furtherance
                                                                                            of
                                                                                            decisions
                                                                                            that
                                                                                            produce
                                                                                            legal
                                                                                            or
                                                                                            similarly
                                                                                            significant
                                                                                            effects
                                                                                            (
                                                                                            <span className="block-component" />
                                                                                            "profiling"
                                                                                            <span className="statement-end-if-in-editor" />

                                                                                            )
                                                                                          </span>
                                                                                        </span>
                                                                                        <span className="statement-end-if-in-editor">
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          />
                                                                                        </span>
                                                                                      </li>
                                                                                    </ul>
                                                                                    <div
                                                                                      data-custom-class="body_text"
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="block-component" />
                                                                                        <span className="question">
                                                                                          Hourlead,
                                                                                          Inc.
                                                                                        </span>{" "}
                                                                                        has
                                                                                        not
                                                                                        sold
                                                                                        any
                                                                                        personal
                                                                                        data
                                                                                        to
                                                                                        third
                                                                                        parties
                                                                                        for
                                                                                        business
                                                                                        or
                                                                                        commercial
                                                                                        purposes.{" "}
                                                                                        <span className="question">
                                                                                          Hourlead,
                                                                                          Inc.
                                                                                        </span>{" "}
                                                                                        will
                                                                                        not
                                                                                        sell
                                                                                        personal
                                                                                        data
                                                                                        in
                                                                                        the
                                                                                        future
                                                                                        belonging
                                                                                        to
                                                                                        website
                                                                                        visitors,
                                                                                        users,
                                                                                        and
                                                                                        other
                                                                                        consumers.
                                                                                        <span className="else-block" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <u>
                                                                                        <span
                                                                                          data-custom-class="body_text"
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          Exercise
                                                                                          your
                                                                                          rights
                                                                                          provided
                                                                                          under
                                                                                          the
                                                                                          Virginia
                                                                                          CDPA
                                                                                        </span>
                                                                                      </u>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        More
                                                                                        information
                                                                                        about
                                                                                        our
                                                                                        data
                                                                                        collection
                                                                                        and
                                                                                        sharing
                                                                                        practices
                                                                                        can
                                                                                        be
                                                                                        found
                                                                                        in
                                                                                        this
                                                                                        privacy
                                                                                        notice
                                                                                        <span className="block-component" />

                                                                                        .
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span className="block-component" />
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          You
                                                                                          may
                                                                                          contact
                                                                                          us
                                                                                          by
                                                                                          email
                                                                                          at{" "}
                                                                                          <span className="question">
                                                                                            hourlead.founders@gmail.com
                                                                                          </span>

                                                                                          ,{" "}
                                                                                          <span className="block-component" />
                                                                                          by
                                                                                          visiting
                                                                                          our&nbsp;
                                                                                        </span>
                                                                                        <a
                                                                                          href="https://app.termly.io/notify/aa8109e4-40d8-4d66-ac53-63bdc9f2ee29"
                                                                                          rel="noopener noreferrer"
                                                                                          target="_blank"
                                                                                        >
                                                                                          <span data-custom-class="link">
                                                                                            data
                                                                                            subject
                                                                                            request
                                                                                            form
                                                                                          </span>
                                                                                        </a>
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="block-component" />

                                                                                          ,
                                                                                          or
                                                                                          by
                                                                                          referring
                                                                                          to
                                                                                          the
                                                                                          contact
                                                                                          details
                                                                                          at
                                                                                          the
                                                                                          bottom
                                                                                          of
                                                                                          this
                                                                                          document.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          If
                                                                                          you
                                                                                          are
                                                                                          using
                                                                                          an{" "}
                                                                                          <span className="block-component" />
                                                                                          authorized
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          agent
                                                                                          to
                                                                                          exercise
                                                                                          your
                                                                                          rights,
                                                                                          we
                                                                                          may
                                                                                          deny
                                                                                          a
                                                                                          request
                                                                                          if
                                                                                          the{" "}
                                                                                          <span className="block-component" />
                                                                                          authorized
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          agent
                                                                                          does
                                                                                          not
                                                                                          submit
                                                                                          proof
                                                                                          that
                                                                                          they
                                                                                          have
                                                                                          been
                                                                                          validly{" "}
                                                                                          <span className="block-component" />
                                                                                          authorized
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          to
                                                                                          act
                                                                                          on
                                                                                          your
                                                                                          behalf.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <u>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            Verification
                                                                                            process
                                                                                          </span>
                                                                                        </span>
                                                                                      </u>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          We
                                                                                          may
                                                                                          request
                                                                                          that
                                                                                          you
                                                                                          provide
                                                                                          additional
                                                                                          information
                                                                                          reasonably
                                                                                          necessary
                                                                                          to
                                                                                          verify
                                                                                          you
                                                                                          and
                                                                                          your
                                                                                          consumer's
                                                                                          request.
                                                                                          If
                                                                                          you
                                                                                          submit
                                                                                          the
                                                                                          request
                                                                                          through
                                                                                          an{" "}
                                                                                          <span className="block-component" />
                                                                                          authorized
                                                                                          <span className="statement-end-if-in-editor" />{" "}
                                                                                          agent,
                                                                                          we
                                                                                          may
                                                                                          need
                                                                                          to
                                                                                          collect
                                                                                          additional
                                                                                          information
                                                                                          to
                                                                                          verify
                                                                                          your
                                                                                          identity
                                                                                          before
                                                                                          processing
                                                                                          your
                                                                                          request.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          Upon
                                                                                          receiving
                                                                                          your
                                                                                          request,
                                                                                          we
                                                                                          will
                                                                                          respond
                                                                                          without
                                                                                          undue
                                                                                          delay,
                                                                                          but
                                                                                          in
                                                                                          all
                                                                                          cases,
                                                                                          within
                                                                                          forty-five
                                                                                          (45)
                                                                                          days
                                                                                          of
                                                                                          receipt.
                                                                                          The
                                                                                          response
                                                                                          period
                                                                                          may
                                                                                          be
                                                                                          extended
                                                                                          once
                                                                                          by
                                                                                          forty-five
                                                                                          (45)
                                                                                          additional
                                                                                          days
                                                                                          when
                                                                                          reasonably
                                                                                          necessary.
                                                                                          We
                                                                                          will
                                                                                          inform
                                                                                          you
                                                                                          of
                                                                                          any
                                                                                          such
                                                                                          extension
                                                                                          within
                                                                                          the
                                                                                          initial
                                                                                          45-day
                                                                                          response
                                                                                          period,
                                                                                          together
                                                                                          with
                                                                                          the
                                                                                          reason
                                                                                          for
                                                                                          the
                                                                                          extension.
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <u>
                                                                                          <span data-custom-class="body_text">
                                                                                            Right
                                                                                            to
                                                                                            appeal
                                                                                          </span>
                                                                                        </u>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <br />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          If
                                                                                          we
                                                                                          decline
                                                                                          to
                                                                                          take
                                                                                          action
                                                                                          regarding
                                                                                          your
                                                                                          request,
                                                                                          we
                                                                                          will
                                                                                          inform
                                                                                          you
                                                                                          of
                                                                                          our
                                                                                          decision
                                                                                          and
                                                                                          reasoning
                                                                                          behind
                                                                                          it.&nbsp;
                                                                                        </span>
                                                                                        <span data-custom-class="body_text">
                                                                                          If
                                                                                          you
                                                                                          wish
                                                                                          to
                                                                                          appeal
                                                                                          our
                                                                                          decision,
                                                                                          please
                                                                                          email
                                                                                          us
                                                                                          at{" "}
                                                                                          <span className="block-component" />
                                                                                          <span className="question">
                                                                                            hourlead.founders@gmail.com
                                                                                          </span>
                                                                                          <span className="else-block" />

                                                                                          .
                                                                                          Within
                                                                                          sixty
                                                                                          (60)
                                                                                          days
                                                                                          of
                                                                                          receipt
                                                                                          of
                                                                                          an
                                                                                          appeal,
                                                                                          we
                                                                                          will
                                                                                          inform
                                                                                          you
                                                                                          in
                                                                                          writing
                                                                                          of
                                                                                          any
                                                                                          action
                                                                                          taken
                                                                                          or
                                                                                          not
                                                                                          taken
                                                                                          in
                                                                                          response
                                                                                          to
                                                                                          the
                                                                                          appeal,
                                                                                          including
                                                                                          a
                                                                                          written
                                                                                          explanation
                                                                                          of
                                                                                          the
                                                                                          reasons
                                                                                          for
                                                                                          the
                                                                                          decisions.
                                                                                          If
                                                                                          your
                                                                                          appeal
                                                                                          if
                                                                                          denied,
                                                                                          you
                                                                                          may
                                                                                          contact
                                                                                          the
                                                                                          Attorney
                                                                                          General
                                                                                          to&nbsp;
                                                                                        </span>
                                                                                      </span>
                                                                                      <a
                                                                                        href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="link">
                                                                                            submit
                                                                                            a
                                                                                            complaint
                                                                                          </span>
                                                                                        </span>
                                                                                      </a>
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          .
                                                                                        </span>
                                                                                        <span className="statement-end-if-in-editor" />
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      id="policyupdates"
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(127, 127, 127)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                id="control"
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <strong>
                                                                                                  <span data-custom-class="heading_1">
                                                                                                    11.
                                                                                                    DO
                                                                                                    WE
                                                                                                    MAKE
                                                                                                    UPDATES
                                                                                                    TO
                                                                                                    THIS
                                                                                                    NOTICE?
                                                                                                  </span>
                                                                                                </strong>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <em>
                                                                                              <strong>
                                                                                                In
                                                                                                Short:&nbsp;
                                                                                              </strong>
                                                                                              Yes,
                                                                                              we
                                                                                              will
                                                                                              update
                                                                                              this
                                                                                              notice
                                                                                              as
                                                                                              necessary
                                                                                              to
                                                                                              stay
                                                                                              compliant
                                                                                              with
                                                                                              relevant
                                                                                              laws.
                                                                                            </em>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            We
                                                                                            may
                                                                                            update
                                                                                            this
                                                                                            privacy
                                                                                            notice
                                                                                            from
                                                                                            time
                                                                                            to
                                                                                            time.
                                                                                            The
                                                                                            updated
                                                                                            version
                                                                                            will
                                                                                            be
                                                                                            indicated
                                                                                            by
                                                                                            an
                                                                                            updated{" "}
                                                                                            <span className="block-component" />
                                                                                            "Revised"
                                                                                            <span className="statement-end-if-in-editor" />{" "}
                                                                                            date
                                                                                            and
                                                                                            the
                                                                                            updated
                                                                                            version
                                                                                            will
                                                                                            be
                                                                                            effective
                                                                                            as
                                                                                            soon
                                                                                            as
                                                                                            it
                                                                                            is
                                                                                            accessible.
                                                                                            If
                                                                                            we
                                                                                            make
                                                                                            material
                                                                                            changes
                                                                                            to
                                                                                            this
                                                                                            privacy
                                                                                            notice,
                                                                                            we
                                                                                            may
                                                                                            notify
                                                                                            you
                                                                                            either
                                                                                            by
                                                                                            prominently
                                                                                            posting
                                                                                            a
                                                                                            notice
                                                                                            of
                                                                                            such
                                                                                            changes
                                                                                            or
                                                                                            by
                                                                                            directly
                                                                                            sending
                                                                                            you
                                                                                            a
                                                                                            notification.
                                                                                            We
                                                                                            encourage
                                                                                            you
                                                                                            to
                                                                                            review
                                                                                            this
                                                                                            privacy
                                                                                            notice
                                                                                            frequently
                                                                                            to
                                                                                            be
                                                                                            informed
                                                                                            of
                                                                                            how
                                                                                            we
                                                                                            are
                                                                                            protecting
                                                                                            your
                                                                                            information.
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      id="contact"
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(127, 127, 127)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                id="control"
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <strong>
                                                                                                  <span data-custom-class="heading_1">
                                                                                                    12.
                                                                                                    HOW
                                                                                                    CAN
                                                                                                    YOU
                                                                                                    CONTACT
                                                                                                    US
                                                                                                    ABOUT
                                                                                                    THIS
                                                                                                    NOTICE?
                                                                                                  </span>
                                                                                                </strong>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            If
                                                                                            you
                                                                                            have
                                                                                            questions
                                                                                            or
                                                                                            comments
                                                                                            about
                                                                                            this
                                                                                            notice,
                                                                                            you
                                                                                            may{" "}
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span className="block-component" />
                                                                                                email
                                                                                                us
                                                                                                at{" "}
                                                                                                <span className="question">
                                                                                                  hourlead.founders@gmail.com
                                                                                                </span>
                                                                                                <span className="statement-end-if-in-editor" />
                                                                                              </span>
                                                                                            </span>
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  &nbsp;or
                                                                                                  by
                                                                                                  post
                                                                                                  to:
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(89, 89, 89)",
                                                                                                }}
                                                                                              >
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span data-custom-class="body_text">
                                                                                                    <span className="question">
                                                                                                      Hourlead,
                                                                                                      Inc.
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span data-custom-class="body_text">
                                                                                                <span className="block-component" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="question">
                                                                                            651
                                                                                            N
                                                                                            Broad
                                                                                            St
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span className="block-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="question">
                                                                                          Suite
                                                                                          201
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span className="statement-end-if-in-editor" />
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          <span className="question">
                                                                                            Middletown
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span className="block-component" />
                                                                                              <span className="block-component" />

                                                                                              ,{" "}
                                                                                              <span className="question">
                                                                                                DE
                                                                                              </span>
                                                                                              <span className="statement-end-if-in-editor" />
                                                                                              <span className="block-component" />
                                                                                              <span className="block-component" />{" "}
                                                                                              <span className="question">
                                                                                                19709
                                                                                              </span>
                                                                                              <span className="statement-end-if-in-editor" />
                                                                                              <span className="block-component" />
                                                                                              <span className="block-component" />
                                                                                              <span className="block-component" />
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        data-custom-class="body_text"
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                        }}
                                                                                      >
                                                                                        <span className="question">
                                                                                          United
                                                                                          States
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span
                                                                                                    style={{
                                                                                                      color:
                                                                                                        "rgb(89, 89, 89)",
                                                                                                    }}
                                                                                                  >
                                                                                                    <span
                                                                                                      style={{
                                                                                                        fontSize: 15,
                                                                                                      }}
                                                                                                    >
                                                                                                      <span data-custom-class="body_text">
                                                                                                        <span
                                                                                                          style={{
                                                                                                            color:
                                                                                                              "rgb(89, 89, 89)",
                                                                                                          }}
                                                                                                        >
                                                                                                          <span
                                                                                                            style={{
                                                                                                              fontSize: 15,
                                                                                                            }}
                                                                                                          >
                                                                                                            <span className="statement-end-if-in-editor" />
                                                                                                          </span>
                                                                                                        </span>
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span className="block-component">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                      </span>
                                                                                      <span className="statement-end-if-in-editor" />
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                            }}
                                                                                          >
                                                                                            <span className="statement-end-if-in-editor" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span className="statement-end-if-in-editor">
                                                                                                <span
                                                                                                  style={{
                                                                                                    color:
                                                                                                      "rgb(89, 89, 89)",
                                                                                                  }}
                                                                                                >
                                                                                                  <span
                                                                                                    style={{
                                                                                                      fontSize: 15,
                                                                                                    }}
                                                                                                  >
                                                                                                    <span data-custom-class="body_text">
                                                                                                      <span className="block-component">
                                                                                                        <span className="block-component" />
                                                                                                      </span>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <span data-custom-class="body_text">
                                                                                                  <span className="statement-end-if-in-editor">
                                                                                                    <span className="block-component" />
                                                                                                  </span>
                                                                                                </span>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      id="request"
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            "rgb(127, 127, 127)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              fontSize: 15,
                                                                                              color:
                                                                                                "rgb(89, 89, 89)",
                                                                                            }}
                                                                                          >
                                                                                            <span
                                                                                              style={{
                                                                                                fontSize: 15,
                                                                                                color:
                                                                                                  "rgb(89, 89, 89)",
                                                                                              }}
                                                                                            >
                                                                                              <span
                                                                                                id="control"
                                                                                                style={{
                                                                                                  color:
                                                                                                    "rgb(0, 0, 0)",
                                                                                                }}
                                                                                              >
                                                                                                <strong>
                                                                                                  <span data-custom-class="heading_1">
                                                                                                    13.
                                                                                                    HOW
                                                                                                    CAN
                                                                                                    YOU
                                                                                                    REVIEW,
                                                                                                    UPDATE,
                                                                                                    OR
                                                                                                    DELETE
                                                                                                    THE
                                                                                                    DATA
                                                                                                    WE
                                                                                                    COLLECT
                                                                                                    FROM
                                                                                                    YOU?
                                                                                                  </span>
                                                                                                </strong>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <br />
                                                                                    </div>
                                                                                    <div
                                                                                      style={{
                                                                                        lineHeight:
                                                                                          "1.5",
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          fontSize: 15,
                                                                                          color:
                                                                                            "rgb(89, 89, 89)",
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                            color:
                                                                                              "rgb(89, 89, 89)",
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span className="block-component" />
                                                                                            You
                                                                                            have
                                                                                            the
                                                                                            right
                                                                                            to
                                                                                            request
                                                                                            access
                                                                                            to
                                                                                            the
                                                                                            personal
                                                                                            information
                                                                                            we
                                                                                            collect
                                                                                            from
                                                                                            you,
                                                                                            change
                                                                                            that
                                                                                            information,
                                                                                            or
                                                                                            delete
                                                                                            it.{" "}
                                                                                            <span className="statement-end-if-in-editor" />
                                                                                            To
                                                                                            request
                                                                                            to
                                                                                            review,
                                                                                            update,
                                                                                            or
                                                                                            delete
                                                                                            your
                                                                                            personal
                                                                                            information,
                                                                                            please{" "}
                                                                                            <span className="block-component" />
                                                                                            submit
                                                                                            a
                                                                                            request
                                                                                            form
                                                                                            by
                                                                                            clicking&nbsp;
                                                                                          </span>
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                "rgb(48, 48, 241)",
                                                                                            }}
                                                                                          >
                                                                                            <span data-custom-class="body_text">
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                <a
                                                                                                  data-custom-class="link"
                                                                                                  href="https://app.termly.io/notify/aa8109e4-40d8-4d66-ac53-63bdc9f2ee29"
                                                                                                  rel="noopener noreferrer"
                                                                                                  target="_blank"
                                                                                                >
                                                                                                  here
                                                                                                </a>
                                                                                              </span>
                                                                                            </span>
                                                                                          </span>
                                                                                          <span className="block-component">
                                                                                            <span data-custom-class="body_text" />
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                      <span data-custom-class="body_text">
                                                                                        .
                                                                                      </span>
                                                                                    </div>
                                                                                    <style
                                                                                      dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                          "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    ",
                                                                                      }}
                                                                                    />
                                                                                  </div>
                                                                                  <div
                                                                                    style={{
                                                                                      color:
                                                                                        "#595959",
                                                                                      fontSize: 14,
                                                                                      fontFamily:
                                                                                        "Arial",
                                                                                      paddingTop: 16,
                                                                                    }}
                                                                                  >
                                                                                    This
                                                                                    privacy
                                                                                    policy
                                                                                    was
                                                                                    created
                                                                                    using
                                                                                    Termly's{" "}
                                                                                    <a
                                                                                      style={{
                                                                                        color:
                                                                                          "rgb(48, 48, 241) !important",
                                                                                      }}
                                                                                      href="https://termly.io/products/privacy-policy-generator/"
                                                                                    >
                                                                                      Privacy
                                                                                      Policy
                                                                                      Generator
                                                                                    </a>

                                                                                    .
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSpacer>
    </>
  );
};

export default PrivacyPolicy;
