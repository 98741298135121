import { useEffect, useState } from "react";
import loadScript from "../Utilities/Script";

const LocationSelector = () => {
  const [businessApiLoaded, setBusinessApiLoaded] = useState(false);
  const [authApiLoaded, setAuthApiLoaded] = useState(false);

  useEffect(() => {
    if (!authApiLoaded)
      loadScript({
        src: "https://accounts.google.com/gsi/client",
        callback: () => setAuthApiLoaded(true),
        id: "googleAuth",
      });
  }, []);

  useEffect(() => {
    if (!businessApiLoaded)
      loadScript({
        src: "https://mybusinessbusinessinformation.googleapis.com/$discovery/rest?version=v1",
        callback: () => setBusinessApiLoaded(true),
        id: "googleBusinesses",
      });
  }, []);

  useEffect(() => {
    if (authApiLoaded) {
      console.log("api loaded");
      console.log(process.env.REACT_APP_GOOGLE_API_CLIENT_ID);
      const client = google.accounts.oauth2.initTokenClient({
        client_id: process.env.REACT_APP_GOOGLE_API_CLIENT_ID!,
        scope: "https://www.googleapis.com/auth/business.manage",
        callback: (response: any) => {
          console.log(response, "response");
        },
      });
      client.requestAccessToken();
    }
  }, [authApiLoaded]);

  return null;
};

export default LocationSelector;
