import React, { useState } from "react";
import {
  Card,
  Input,
  Typography,
  Divider,
  Textarea,
  Button,
  Box,
} from "@mui/joy";
import { useTranslation } from "react-i18next";
import { DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import useConfirm from "../../Utils/useConfirm";
import { Item } from "../../Services/Db/types";

interface SectionViewProps {
  editable?: boolean;
  sections: Item[];
  setSections: (sections: Item[]) => void;
  index: number; //to edit the correct section
  setSave: (save: boolean) => void;
}

const SectionView: React.FC<SectionViewProps> = ({
  editable,
  sections,
  setSections,
  index,
  setSave,
}) => {
  const { t } = useTranslation(["business"]);
  const confirm = useConfirm();

  const [edit, setEdit] = useState(false);

  const updateTitle = (newTitle: string) => {
    const newSections = [...sections];
    newSections[index].category = newTitle;
    setSections(newSections);
  };

  const updateDescription = (newDescription: string) => {
    const newSections = [...sections];
    newSections[index].item = newDescription;
    setSections(newSections);
  };

  if (edit) {
    return (
      <Card>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Input
            value={sections[index].category}
            onChange={(e) => updateTitle(e.target.value)}
            placeholder={t("section_title") || ""}
            variant="plain"
            sx={{
              fontSize: "1.5rem",
              fontWeight: 600,
            }}
          />

          <Box
            sx={{
              display: "flex",
              gap: 1,
              alignItems: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                // const newSection = {
                //   category: title,
                //   item: description,
                // };

                // const newSections = [...sections];
                // newSections[index] = newSection;

                // setSections(newSections);
                setEdit(false);
                setSave(true);
              }}
            >
              {t("save_changes", { ns: "common" })}
            </Button>
            <Button
              variant="plain"
              onClick={() => {
                confirm(
                  "business",
                  "confirm_delete_section",
                  "confirm_delete_section_subtitle",
                  "delete",
                  () => {
                    const newSections = [...sections];
                    newSections.splice(index, 1);
                    setSections(newSections);
                    setEdit(false);
                  },
                  [sections[index].category]
                );
              }}
            >
              {t("delete", { ns: "common" })}
            </Button>
          </Box>
        </Box>

        <Divider sx={DIVIDER_HEADER_SX_MY} />
        <Textarea
          value={sections[index].item}
          onChange={(e) => updateDescription(e.target.value)}
          placeholder={
            t("section_description", {
              header: sections[index].item || "title",
            }) || ""
          }
          variant="plain"
        />
      </Card>
    );
  }

  return (
    <Card sx={{ width: "100%", borderRadius: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography level="cardHeader">{sections[index].category}</Typography>
        {editable && (
          <Button
            onClick={() => setEdit(true)}
            variant="plain"
            sx={{ textDecoration: "underline" }}
          >
            {t("edit", { ns: "common" })}
          </Button>
        )}
      </Box>

      <Divider sx={DIVIDER_HEADER_SX_MY} />
      <Typography>{sections[index].item}</Typography>
    </Card>
  );
};

export default SectionView;
