import { auth, db } from "../../firebase";
import {
  arrayUnion,
  deleteField,
  doc,
  DocumentReference,
  FieldValue,
  Timestamp,
  where,
} from "firebase/firestore";
import { TABLES } from "./consts";
import {
  ApplicationsService,
  BusinessAccService,
  BusinessService,
  InterviewAvailabilitiesService,
  InterviewPrefsService,
  InterviewsService,
  LinkedUsersService,
  ListingsService,
} from "./service";
import {
  Applications,
  BusinessAccount,
  InterviewAvailabilities,
  InterviewPrefs,
  Interviews,
  Item,
  Listings,
  MapField,
} from "./types";
import { ensureBusinessExists, getBusinessRef } from "./utils";
import { deleteUser, sendEmailVerification, updateEmail } from "firebase/auth";
import DatabaseError from "./errors";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import InterviewAvailability from "src/Pages_Business/Interviews/InterviewAvailability";
const _ = require("lodash");

class BusinessDbService {
  constructor() {}

  /* CREATE */
  createBusiness = async (bid: string) => {
    if (
      (await BusinessAccService.getOne(bid)) ||
      (await LinkedUsersService.getOne(bid))
    )
      return;
    await BusinessAccService.create({}, bid);
  };

  createBusinessLocations = async (
    bid: string,
    locations: string[],
    unverifiedPid?: string
  ) => {
    await BusinessAccService.update(bid, {
      locations: locations.map((l) => doc(db, TABLES.BUSINESSES, l)),
    });
    // if unverified, business, add to DB
    if (unverifiedPid) {
      await ensureBusinessExists(unverifiedPid);
    }

    locations.forEach(async (l) => {
      ensureBusinessExists(l);
    });

    // get all listings with businessRef === bid
    // add all listings to current business account
    const listingRefs: DocumentReference[][] = await Promise.all(
      locations.map(async (l) => {
        const listings = await ListingsService.getMany(
          where("businessRef", "==", doc(db, TABLES.BUSINESSES, l))
        );

        return listings.map((listing) => doc(db, TABLES.LISTINGS, listing.id!));
      })
    );

    var payload;
    if (unverifiedPid) {
      payload = {
        listings: listingRefs.reduce((prev, next) => prev.concat(next), []),
        unverifiedPid: unverifiedPid,
      };
    } else {
      payload = {
        listings: listingRefs.reduce((prev, next) => prev.concat(next), []),
      };
    }

    await BusinessAccService.update(bid, payload);
  };

  _addInterviewAvailabilitiesToBusiness = async (
    placeId: string,
    interviews: Date[],
    type: "recurring" | "one-time" | "remove"
  ): Promise<void> => {
    const interviewRefs: DocumentReference[] = [];
    for (const interview of interviews) {
      const interviewRef: DocumentReference =
        await InterviewAvailabilitiesService.create({
          type: type,
          time: Timestamp.fromDate(interview),
        });
      interviewRefs.push(interviewRef);
    }

    const business = await BusinessService.getOne(placeId);
    if (!business) return;
    BusinessService.update(placeId, {
      interviewAvailabilities: [
        ...interviewRefs,
        ...(business.interviewAvailabilities ?? []),
      ],
    });
  };

  createBusinessInterviewAvailability = async (
    recurring: Date[],
    removeRecurring: Date[],
    oneTime: Date[],
    pid: string
  ): Promise<void> => {
    var locations: DocumentReference[] = [];
    console.log("CREATING FOR PID");
    const business = await BusinessService.getOne(pid!);
    if (!business) return;
    const location = doc(db, TABLES.BUSINESSES, pid!);

    return await BusinessService.update(location.id, {
      interviewAvailabilities: [],
    }).then(
      async () => {
        await this._addInterviewAvailabilitiesToBusiness(
          location.id,
          recurring,
          "recurring"
        );
        await this._addInterviewAvailabilitiesToBusiness(
          location.id,
          removeRecurring,
          "remove"
        );
        await this._addInterviewAvailabilitiesToBusiness(
          location.id,
          oneTime,
          "one-time"
        );
      },

      (err) => {
        console.log(err);
      }
    );
  };

  createBusinessInterviewAvailabilityAllLocations = async (
    recurring: Date[],
    removeRecurring: Date[],
    oneTime: Date[],
    bid: string
  ): Promise<void> => {
    const business = await BusinessAccService.getOne(bid);
    const locations = business?.locations ?? [];
    for (const location of locations) {
      await this.createBusinessInterviewAvailability(
        recurring,
        removeRecurring,
        oneTime,
        location.id!
      );
    }
  };

  createBusinessInterviewPrefs = async (
    interviewPrefs: InterviewPrefs,
    pid: string
  ): Promise<void> => {
    const business = await BusinessService.getOne(pid);
    const existingPrefsRef = business?.interviewPrefs;

    var interviewPrefsRef: DocumentReference;
    if (existingPrefsRef) {
      InterviewPrefsService.update(existingPrefsRef.id, interviewPrefs);
      interviewPrefsRef = existingPrefsRef;
    } else {
      interviewPrefsRef = await InterviewPrefsService.create(interviewPrefs);
    }

    await BusinessService.update(pid, {
      interviewPrefs: interviewPrefsRef,
    });
  };

  createBusinessInterviewPrefsAllLocations = async (
    interviewPrefs: InterviewPrefs,
    bid: string
  ): Promise<void> => {
    const business = await BusinessAccService.getOne(bid);
    const locations = business?.locations ?? [];
    for (const location of locations) {
      await this.createBusinessInterviewPrefs(interviewPrefs, location.id!);
    }
  };

  _doBusinessListingHealthCheck = async (
    bid: string,
    listingIds: string[]
  ): Promise<void> => {
    const business = await BusinessAccService.getOne(bid);
    // check to make sure each listingId is in business.listings
    if (!business?.listings) {
      BusinessAccService.update(bid, {
        listings: listingIds.map((l) => doc(db, TABLES.LISTINGS, l)),
      });
      return;
    }

    const businessListingIds = business?.listings.map((l) => l.id) ?? [];
    const invalidListingIds = listingIds.filter(
      (l) => !businessListingIds.includes(l)
    );
    if (invalidListingIds.length > 0) {
      // add those listingRefs to business listings
      const listingRefs = invalidListingIds.map((l) =>
        doc(db, TABLES.LISTINGS, l)
      );
      await BusinessAccService.update(bid, {
        listings: [...listingRefs, ...(business?.listings ?? [])],
      });
    }
  };

  createBusinessListing = async (
    bid: string,
    listing: Listings
  ): Promise<string> => {
    const listingRef = await ListingsService.create(listing);

    this.updateLocationWageMap(
      listing.businessRef!,
      listing.role,
      listing.wage,
      listing.tips
    );

    return listingRef.id;
  };

  createBusinessListingAllLocations = async (
    bid: string,
    listing: Listings,
    locations: string[]
  ): Promise<string[]> => {
    if (locations.length === 1) {
      const listingId = await this.createBusinessListing(bid, {
        ...listing,
        businessRef: doc(db, TABLES.BUSINESSES, locations[0]),
      });
      BusinessAccService.update(bid, {
        listings: arrayUnion(doc(db, TABLES.LISTINGS, listingId)),
      });
      return [listingId];
    }

    const listingIds: string[] = await Promise.all(
      locations.map(async (l) => {
        const listingId = await this.createBusinessListing(bid, {
          ...listing,
          businessRef: doc(db, TABLES.BUSINESSES, l),
        });
        return listingId;
      })
    );

    BusinessAccService.update(bid, {
      listings: arrayUnion(
        ...listingIds.map((l) => doc(db, TABLES.LISTINGS, l))
      ),
    });

    await Promise.all(
      listingIds.map(async (l, i) => {
        await ListingsService.update(l, {
          linkedListings: listingIds
            .filter((_, j) => j !== i)
            .map((l) => doc(db, TABLES.LISTINGS, l)),
        });
      })
    );

    return listingIds;
  };

  createBusinessLogo = async (
    uid: string, // NOTE USAGE OF UID, NOT BID/EMAIL
    placeId: string,
    logo: File
  ): Promise<string> => {
    const storage = getStorage();
    const storageRef = ref(storage, `/logos/${uid}/${placeId}`);
    console.log();
    return uploadBytes(storageRef, logo)
      .then((snapshot) => {
        const url = getDownloadURL(snapshot.ref);
        BusinessService.update(placeId, { logoPath: url, logoName: logo.name });
        return url;
      })
      .catch((error) => {
        console.log(error);
        return "";
      });
  };

  /* READ */
  getBusinessInterviewAvailabilities = async (
    pid?: string,
    bid?: string
  ): Promise<InterviewAvailabilities[] | null> => {
    if (!pid && bid) {
      const business = await BusinessAccService.getOne(bid);
      if (!business) throw new DatabaseError("Business not found");
      const locations = business.locations;
      const interviewAvailabilities: InterviewAvailabilities[][] = [];
      for (const location of locations ?? []) {
        const locationInterviewAvailabilities =
          await this.getBusinessInterviewAvailabilities(location.id!);
        if (locationInterviewAvailabilities) {
          console.log(
            "INTERVIEW AVAILABILITIES FOR",
            locationInterviewAvailabilities
          );
          interviewAvailabilities.push(locationInterviewAvailabilities);
        }
      }
      console.log("FULL AVAIL", interviewAvailabilities);
      if (interviewAvailabilities.length !== locations?.length) {
        throw new DatabaseError(
          "Interview availabilities for locations do not match"
        );
      }
      console.log("interview availabilities", interviewAvailabilities);
      const columnHeight = interviewAvailabilities[0].length;
      for (const interviewAvailability of interviewAvailabilities) {
        console.log("HEIGHT", interviewAvailability.length);
        if (interviewAvailability.length !== columnHeight) {
          throw new DatabaseError(
            "Interview availabilities for locations do not match"
          );
        }
      }

      // interviewAvailabilities.every((iA) =>
      //   _(_.omit(iA, ["id"]))
      //     .differenceWith(
      //       _.omit(interviewAvailabilities[0], ["id"]),
      //       _.isEqual
      //     )
      //     .isEmpty()
      // )

      return interviewAvailabilities[0];
    }
    const business = await BusinessService.getOne(pid!);

    const interviewAvailabilities: InterviewAvailabilities[] = [];
    if (!business || !business.interviewAvailabilities) return null;
    const interviewAvailabilitiesRefs: DocumentReference[] =
      business.interviewAvailabilities;
    const interviewAvailabilitiesForLocation: (InterviewAvailabilities | null)[] =
      await Promise.all(
        interviewAvailabilitiesRefs.map(async (interviewAvailabilitiesRef) => {
          return await InterviewAvailabilitiesService.getResolvedReference(
            interviewAvailabilitiesRef
          );
        })
      );
    interviewAvailabilities.push(
      ...(interviewAvailabilitiesForLocation.filter(
        (interviewAvailabilities) => interviewAvailabilities !== null
      ) as InterviewAvailabilities[])
    );

    return interviewAvailabilities;
  };

  getBusinessInterviewPrefs = async (
    pid?: string,
    bid?: string
  ): Promise<InterviewPrefs | null> => {
    if (!pid && bid) {
      const business = await BusinessAccService.getOne(bid!);
      if (!business) return null;
      const locations: DocumentReference[] = business.locations ?? [];
      const prefs = (
        await Promise.all(
          locations.map(async (location) => {
            const business = await BusinessService.getOne(location.id!);
            if (!business) return null;
            const interviewPrefsRef: DocumentReference | undefined =
              business.interviewPrefs;

            if (!interviewPrefsRef) return null;
            return await InterviewPrefsService.getResolvedReference(
              interviewPrefsRef
            );
          })
        )
      ).filter((pref) => pref !== null) as InterviewPrefs[];

      if (
        !prefs.every((pref) => {
          return _.isEqual(_.omit(pref, ["id"]), _.omit(prefs[0], ["id"]));
        }) ||
        locations.length === 0
      ) {
        throw new DatabaseError(
          "Businesses have different interview preferences"
        );
      } else {
        return this.getBusinessInterviewPrefs(locations[0].id);
      }
    }
    const business = await BusinessService.getOne(pid!);
    if (!business) return null;
    const interviewPrefsRef: DocumentReference | undefined =
      business.interviewPrefs;

    if (!interviewPrefsRef) return null;
    return await InterviewPrefsService.getResolvedReference(interviewPrefsRef);
  };

  getBusinessIsSetup = async (bid: string): Promise<boolean> => {
    const business = await BusinessAccService.getOne(bid);
    return Boolean(
      business?.locations &&
        business?.locations.length > 0 &&
        business?.unverifiedPid === undefined &&
        business?.listings &&
        business?.listings.length > 0
    );
  };

  getProxyUser = async (email: string): Promise<string | null> => {
    const proxy = await LinkedUsersService.getOne(email);
    console.log(proxy, "proxy");
    if (proxy) {
      return proxy.businessAccRef.id;
    }
    return null;
  };

  getBusinessIsAwaitingVerification = async (bid: string): Promise<boolean> => {
    const business = await BusinessAccService.getOne(bid);
    return Boolean(business?.unverifiedPid);
  };

  getBusinessExists = async (bid: string): Promise<boolean> => {
    const business = await BusinessAccService.getOne(bid);
    const linkedBusiness = await LinkedUsersService.getOne(bid);
    return Boolean(business) || Boolean(linkedBusiness);
  };

  getFullBusinessInterviews = async (
    bid: string
  ): Promise<
    (Applications & { role: Item; interview?: Interviews; business: string })[]
  > => {
    const business = await BusinessAccService.getOne(bid);
    const listings = business?.listings;
    if (!listings) return [];
    const apps: (Applications & {
      role: Item;
      interview?: Interviews;
      business: string;
    })[] = [];
    for (const listing of listings) {
      const listingApps = await ApplicationsService.getMany(
        where("listingRef", "==", listing),
        where("status", "==", "interviewing")
      );
      const resolvedListing = await ListingsService.getResolvedReference(
        listing
      );
      if (resolvedListing) {
        for (const app of listingApps) {
          var resolvedInterview = null;
          if (app.interviewRef) {
            resolvedInterview = await InterviewsService.getResolvedReference(
              app.interviewRef
            );
          }
          const resolvedBusinessName =
            (
              await BusinessService.getResolvedReference(
                resolvedListing.businessRef!
              )
            )?.name || "";
          apps.push({
            ...app,
            role: {
              item: resolvedListing.role,
              category: resolvedListing.category,
            },
            interview: resolvedInterview || undefined,
            business: resolvedBusinessName,
          });
        }
      }
    }
    return apps;
  };

  getFullBusinessApplications = async (
    bid: string
  ): Promise<(Applications & { role: Item; requirements: string[] })[]> => {
    const business = await BusinessAccService.getOne(bid);
    const listings = business?.listings;
    if (!listings) return [];
    console.log("LISTINGS", listings);
    const apps: (Applications & { role: Item; requirements: string[] })[] = [];
    for (const listing of listings) {
      console.log("LISTING", listing);
      const listingApps = await ApplicationsService.getMany(
        where("listingRef", "==", listing)
      );
      console.log("apps", listingApps);
      for (const app of listingApps) {
        const resolvedListing: Listings | null =
          await ListingsService.getResolvedReference(app.listingRef);
        if (resolvedListing) {
          apps.push({
            ...app,
            role: {
              item: resolvedListing.role,
              category: resolvedListing.category,
            },
            requirements: resolvedListing.application_requirements,
          });
        }
      }
    }
    return apps;
  };

  getBusinessProfile = async (bid: string): Promise<BusinessAccount | null> => {
    const business = await BusinessAccService.getOne(bid);
    return business;
  };

  getBusinessCanEditListing = async (
    bid: string,
    businessRef: DocumentReference
  ): Promise<boolean> => {
    if (businessRef && bid) {
      console.log(businessRef, "businessref");
      const acc = await BusinessAccService.getOne(bid);
      console.log(acc, "acc");
      if (acc?.locations?.some((loc) => loc.id === businessRef.id)) return true;
    }
    return false;
  };

  getLinkedBusinessRef = async (email: string): Promise<DocumentReference> => {
    const businessAccs = await BusinessAccService.getMany(
      where("addedUsers", "array-contains", email)
    );

    if (businessAccs.length === 0) {
      throw new DatabaseError("No business account found");
    }

    return doc(db, TABLES.BUSINESS_ACCOUNTS, businessAccs[0].id);
  };

  /* UPDATE */
  updateAddedUsers = async (bid: string, email: string): Promise<void> => {
    const business = await BusinessAccService.getOne(bid);
    const addedUsers = business?.addedUsers || [];
    await BusinessAccService.update(bid, {
      addedUsers: [...addedUsers, email],
    });
    await LinkedUsersService.create(
      { businessAccRef: doc(db, TABLES.BUSINESS_ACCOUNTS, bid) },
      email
    );
  };

  updateLocationWageMap = async (
    businessRef: DocumentReference,
    role: string,
    wage: number,
    tips: number = 0,
    change: "add" | "remove" | "update" = "add",
    prevWage?: number
  ): Promise<void> => {
    const loadedBusiness = await BusinessService.getResolvedReference(
      businessRef
    );
    var listingWageMap: MapField = {};
    var listingCtMap: MapField = {};
    if (
      loadedBusiness &&
      loadedBusiness.listingCtMap &&
      loadedBusiness.listingWageMap
    ) {
      listingWageMap = { ...loadedBusiness.listingWageMap };
      listingCtMap = { ...loadedBusiness.listingCtMap };
      const avg = loadedBusiness.listingWageMap[role] || 0;
      const ct = loadedBusiness.listingCtMap[role] || 0;
      if (change === "remove") {
        if (ct === 1) {
          delete listingWageMap[role];
          delete listingCtMap[role];
        } else {
          listingWageMap[role] = (avg * ct - wage - tips) / (ct - 1);
          listingCtMap[role] = ct - 1;
        }
      } else if (change === "add") {
        listingWageMap[role] = (wage + tips + avg * ct) / (ct + 1);
        listingCtMap[role] = ct + 1;
      } else {
        if (prevWage) {
          listingWageMap[role] = (avg * ct - prevWage + wage + tips) / ct;
          listingCtMap[role] = ct;
        }
      }
    } else {
      listingWageMap[role] = wage + tips;
      listingCtMap[role] = 1;
    }

    await BusinessService.update(businessRef.id, {
      listingWageMap: listingWageMap,
      listingCtMap: listingCtMap,
    });
  };

  updateLocationListing = async (listing: Listings, listingId: string) => {
    const prevListing = await ListingsService.getOne(listingId);
    const prevWage: number =
      (prevListing?.wage || 0) + (prevListing?.tips || 0);

    // exclude linked listings, id, and businessref from update for original and linked listings
    const { id, linkedListings, businessRef, ...listingData } = listing;
    ListingsService.update(listingId, listingData);
    if (listing.wage + listing.tips !== prevWage && prevWage > 0) {
      this.updateLocationWageMap(
        listing.businessRef!,
        listing.role,
        listing.wage,
        listing.tips,
        "update",
        prevWage
      );
    }

    if (linkedListings) {
      for (const linkedListing of linkedListings) {
        const resolvedLinkedListing =
          await ListingsService.getResolvedReference(linkedListing);
        if (resolvedLinkedListing) {
          if (listing.wage + listing.tips !== prevWage && prevWage > 0) {
            this.updateLocationWageMap(
              resolvedLinkedListing.businessRef!,
              resolvedLinkedListing.role,
              listing.wage,
              listing.tips,
              "update",
              prevWage
            );
            ListingsService.update(linkedListing.id, listingData);
          }
        }
      }
    }
  };

  updateBusinessProfile = async (
    bid: string,
    business: Omit<BusinessAccount, "id">
  ): Promise<void> => {
    await BusinessAccService.update(bid, business);
  };

  updateLocations = async (bid: string, pid: string) => {
    const business = await BusinessAccService.getOne(bid);
    ensureBusinessExists(pid);
    if (!business || !business.locations) return;
    await BusinessAccService.update(bid, {
      locations: [...business.locations, getBusinessRef(pid)],
    });
  };

  updateBusinessEmail = async (bid: string, email: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;

    updateEmail(currentUser, email).then(() => {
      sendEmailVerification(currentUser);
      BusinessAccService.getOne(bid).then((business) => {
        if (!business) return;
        BusinessAccService.create(business, email).then(() => {
          BusinessAccService.remove(bid);
        });
      });
    });
  };

  updateGroupCode = async (bid: string, code: string) => {
    const withCode = await BusinessAccService.getMany(
      where("groupCode", "==", code.toUpperCase())
    );
    if (withCode.length > 0) {
      throw new DatabaseError("Group code already exists");
    }
    await BusinessAccService.update(bid, { groupCode: code.toUpperCase() });
  };

  updateListingStatus = async (
    listingId: string,
    status: "active" | "inactive",
    recursed?: boolean
  ) => {
    const listing = await ListingsService.getOne(listingId);
    if (listing === null) return;
    const { businessRef, role, wage, tips } = listing;
    this.updateLocationWageMap(
      businessRef!,
      role,
      wage,
      tips,
      status === "active" ? "add" : "remove"
    );
    ListingsService.update(listingId, { status: status });

    if (!recursed) {
      listing.linkedListings?.forEach((linkedListing) => {
        this.updateListingStatus(linkedListing.id, status, true);
      });
    }
  };

  /* DELETE */

  removeLocationListing = async (bid: string, listingId: string) => {
    const listing = await ListingsService.getOne(listingId);
    if (listing === null) return;
    const { businessRef, role, wage } = listing;
    this.updateLocationWageMap(businessRef!, role, wage, 0, "remove");
    ListingsService.remove(listingId);
    listing.linkedListings?.forEach((linkedListing) => {
      this.removeLocationListing(bid, linkedListing.id);
    });

    const business = await BusinessAccService.getOne(bid);
    if (!business || !business.listings) return;
    await BusinessAccService.update(bid, {
      listings: business.listings.filter((l) => l.id !== listingId),
    });
  };

  removeCurrentBusiness = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const email = currentUser.email;
    if (!email) return;
    deleteUser(currentUser).then(async () => {
      await BusinessAccService.remove(email);
    });
  };

  removeLocation = async (bid: string, placeId: string) => {
    const business = await BusinessAccService.getOne(bid);
    if (!business || !business.locations) return;
    await BusinessAccService.update(bid, {
      locations: business.locations.filter((l) => l.id !== placeId),
    });
    if (business.listings) {
      business.listings.forEach(async (listingRef) => {
        const listing = await ListingsService.getResolvedReference(listingRef);
        if (listing && listing.businessRef?.id === placeId) {
          ListingsService.remove(listingRef.id);
          if (business.listings) {
            BusinessAccService.update(bid, {
              listings: business.listings.filter((l) => l.id !== listingRef.id),
            });
          }
        }
        if (listing && listing.linkedListings) {
          listing.linkedListings.forEach(async (linkedListingRef) => {
            const linkedListing = await ListingsService.getResolvedReference(
              linkedListingRef
            );
            if (linkedListing && linkedListing.linkedListings) {
              ListingsService.remove(linkedListingRef.id);
            }
          });
        }
      });
    }
    BusinessService.update(placeId, { code: deleteField() });
  };

  removeAddedUser = async (bid: string, uid: string) => {
    const business = await BusinessAccService.getOne(bid);
    if (!business || !business.addedUsers) return;
    await BusinessAccService.update(bid, {
      addedUsers: business.addedUsers.filter((u) => u !== uid),
    });
    LinkedUsersService.remove(uid);
  };
}

export const FullBusinessService = new BusinessDbService();
