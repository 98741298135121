import {
  Card,
  Switch,
  Typography,
  IconButton,
  Divider,
  Box,
  Stack,
} from "@mui/joy";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  AutoDeleteRounded,
  CheckRounded,
  CloseRounded,
} from "@mui/icons-material";
import { DIVIDER_HEADER_SX, DIVIDER_HEADER_SX_MY } from "../../Utils/Constants";
import InterviewContext from "src/Pages_Business/Interviews/InterviewContext";
import useInterviewLogicTime from "src/Pages_Business/Interviews/useInterviewLogicTime";

const RecurringSwitch = () => {
  const context = useContext(InterviewContext);
  const markerDate = context?.holding ? context!.holding[0] : null;

  const { applyAdd, applyRemove, clearHolding } = useInterviewLogicTime();

  const boxRight =
    document.getElementById("interview_availability_box")?.offsetWidth || 0;

  const popup = document.getElementById("recurring-switch") || null;
  var popupWidth = popup?.offsetWidth || 0;
  var popupHeight = popup?.offsetHeight || 0;

  const tracker = document.getElementById("#" + markerDate?.toString());
  const trackerWidth = tracker?.offsetWidth || 0;

  var top = tracker ? tracker.offsetTop - popupHeight - 4 : -10000;

  var anchor = tracker ? tracker.offsetLeft + trackerWidth / 2 : 10000; //render way off the screen to start to calculate rendered width -- instead of display: "none"
  var right = anchor + popupWidth / 2;
  var left = anchor - popupWidth / 2;

  if (right > boxRight) {
    left = boxRight - popupWidth + trackerWidth / 2;
  }

  if (left < 0) {
    left = 0;
  }

  const { t } = useTranslation(["interview"]);

  return (
    <Card
      variant="outlined"
      id="recurring-switch"
      sx={{
        boxShadow: "3px 8px 10px rgba(0, 0, 0, 0.25)",
        top: top,
        left: left,
        position: "absolute",
        p: 1,
        zIndex: 100000,
      }}
    >
      <Stack direction="row" sx={{ gap: 1 }}>
        <Switch
          color={context?.recurring ? "primary" : "neutral"}
          checked={context?.recurring}
          onChange={(e) => context?.setRecurring(e.target.checked)}
        />
        <Typography
          onClick={() => context?.setRecurring(true)}
          noWrap
          sx={{
            color: context?.recurring ? "primary.main" : "black",
            fontWeight: 500,
            cursor: "poointer",
          }}
        >
          {t(context?.recurring ? "every_week" : "one_time")}
        </Typography>
        <Divider sx={{ opacity: 1, my: -1 }} orientation="vertical" />
        <IconButton
          sx={{ m: -1, borderRadius: 0 }}
          onClick={() => clearHolding()}
        >
          <CloseRounded />
        </IconButton>
        <Divider sx={{ opacity: 1, my: -1 }} orientation="vertical" />
        {context?.changeType === "adding" ? (
          <IconButton
            sx={{
              m: -1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              color: "black",
            }}
            onClick={() => {
              applyAdd();
            }}
            color="success"
          >
            <CheckRounded />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              m: -1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              color: "black",
            }}
            onClick={() => {
              applyRemove();
            }}
            color="success"
          >
            <AutoDeleteRounded />
          </IconButton>
        )}
      </Stack>
    </Card>
  );
};

export default RecurringSwitch;
