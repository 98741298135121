import { Box, Typography } from "@mui/joy";
import { Skeleton } from "@mui/material";
import { usePlaceDetails } from "../Maps/PlaceDetails";

interface LocationListItemProps {
  placeId: string;
}

const LocationListItem: React.FC<LocationListItemProps> = ({ placeId }) => {
  const placeDetails = usePlaceDetails(placeId, ["name", "formatted_address"]);

  if (placeDetails === undefined) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Skeleton variant="text" width={250} height={30} />
        <Skeleton variant="text" width={150} height={20} />
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography level="h6">{placeDetails.name}</Typography>
        <Typography level="body2">
          {placeDetails.formatted_address?.split(",")[0]}
        </Typography>
      </Box>
    );
  }
};

export default LocationListItem;
