import { Typography } from "@mui/joy";
import Slider, { sliderClasses } from "@mui/joy/Slider";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface WageSliderProps {
  defaultVal: number | number[];
  setWage: (wage: any) => void;
}

const WageSlider: React.FC<WageSliderProps> = ({ defaultVal, setWage }) => {
  const { t } = useTranslation(["common"]);

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (newValue instanceof Array) {
      setWage(newValue.map((val) => exponential(val)));
    } else {
      setWage(exponential(newValue));
    }
  };

  const exponential = (x: number) => {
    return Math.floor(Math.pow(x / 60, 1.5));
  };

  const deExponential = (x: number) => {
    return Math.floor(Math.pow(x, 1 / 1.5) * 60);
  };

  return (
    <Slider
      key={`wage-slider-${defaultVal}`}
      onChange={handleChange}
      max={999}
      defaultValue={
        defaultVal instanceof Array
          ? defaultVal.map((val) => deExponential(val))
          : deExponential(defaultVal)
      }
      min={0}
      valueLabelFormat={(value) => (
        <Typography fontSize="1.3rem" sx={{ color: "#FFF" }}>
          {t("value_per_hour", { ns: ["common"], value: exponential(value) })}
        </Typography>
      )}
      marks={[
        {
          value: 999,
          label: "$" + t("/hr", { ns: ["common"] }),
        },
      ]}
      valueLabelDisplay="on"
      sx={{
        // Need both of the selectors to make it works on the server-side and client-side
        [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
          [`&.${sliderClasses.markLabel}`]: {
            transform: "none",
          },
          [`& .${sliderClasses.valueLabel}`]: {
            left: "calc(var(--Slider-thumb-size) / 2)",
            borderBottomLeftRadius: 0,
            "&::before": {
              left: 0,
              transform: "translateY(100%)",
              borderLeftColor: "currentColor",
            },
          },
        },
        [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
          [`&.${sliderClasses.markLabel}`]: {
            transform: "translateX(-100%)",
          },
          [`& .${sliderClasses.valueLabel}`]: {
            right: "calc(var(--Slider-thumb-size) / 2)",
            borderBottomRightRadius: 0,
            "&::before": {
              left: "initial",
              right: 0,
              transform: "translateY(100%)",
              borderRightColor: "currentColor",
            },
          },
        },
      }}
    />
  );
};

export default WageSlider;
