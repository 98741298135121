import { Box, Button, Stack, styled, Input, Typography } from "@mui/joy";
import {
  ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
  UserCredential,
} from "firebase/auth";
import * as React from "react";
import { useTranslation } from "react-i18next";
import NumberField from "../Inputs/NumberField";
import { auth } from "../../firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { FullUserService } from "../../Services/Db/user";
import useToast from "../../Utils/useToast";
import { APP_ROUTES } from "../../Utils/Constants";
import { UserService } from "../../Services/Db/service";
import TermsAndPrivacySub from "../Common/TermsAndPrivacySub";

export const NumberFieldBox = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.grey[100],
  borderRadius: "9px",
  padding: theme.spacing(1),
  border: "1px solid #DDD",
}));

export interface SignInProps {
  onComplete: (u: User) => void;
  noDisclaimer?: boolean;
  newUser?: boolean;
}

const PhoneSignIn: React.FC<SignInProps> = ({ onComplete, noDisclaimer }) => {
  const [codeSent, setCodeSent] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string[]>(new Array(10).fill(""));
  const [code, setCode] = React.useState<string[]>(new Array(6).fill(""));
  const { t } = useTranslation(["common"]);

  const [recaptcha, setRecaptcha] = React.useState<RecaptchaVerifier>();
  const [confirmationResult, setConfirmationResult] =
    React.useState<ConfirmationResult>();
  // Need this b/c need to render recaptcha on mount but don't want to twice
  const [isRecaptchaRendered, setIsRecaptchaRendered] =
    React.useState<boolean>(false);
  // Need this b/c on recaptcha callback, should allow submission but can't
  // call signInWithPhoneNumber directly b/c one argument is the recaptcha obj
  const [shouldSubmit, setShouldSubmit] = React.useState<boolean>(false);
  // routing
  const toast = useToast();

  // IF THE USER IS SIGNED IN, CHECK IF THEY'RE ALREADY SIGNED UP
  // FIXME edge case problem if signed in user tries to make an account
  // TODO removing sign up differentiation for now
  /*
  React.useEffect(() => {
    if (user) {
      const handleUserLogIn = async () => {
        const exists = await FullUserService.checkUserExists(user.phoneNumber!);
        if (isSignUp) {
          // if is a sign up, user cannot already exist
          if (exists) {
            if (code.join("").length == 6) {
              // throw and reset -- this phone number is already associated with an account
              // FIXME this acction doesnt seem to work?
              toast("user_flow", "user_already_exists", "sign_up", () =>
                navigate(APP_ROUTES.NEW_USER_FLOW)
              );
            } else {
              // if the code has not been set yet, means should sign user out instead of letting him kknow he's a dupe
              auth.signOut();
            }
          } else {
            onComplete();
          }
        } else {
          // if is log in, user must already exist
          if (!exists) {
            // throw and reset -- prompt to sign up
            toast("user_flow", "user_does_not_exist", "sign_up", () =>
              navigate(APP_ROUTES.NEW_USER_FLOW)
            );
          } else {
            onComplete();
          }
        }
      };
      handleUserLogIn();
    }
  }, [user]);
  */

  // On componentDidMount, bind recaptcha to button
  React.useEffect(() => {
    setRecaptcha(
      new RecaptchaVerifier(
        "signin-button",
        {
          size: "invisible",
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // need to get phone value before disappearing form
            setShouldSubmit(true);
          },
        },
        auth
      )
    );
  }, []);

  // Render recaptcha after instantiation
  React.useEffect(() => {
    if (recaptcha && !isRecaptchaRendered) {
      setIsRecaptchaRendered(true);
      recaptcha.render(); // FIXME what to catch here
    }
  }, [recaptcha]);

  // Do sign in after recaptcha callback succeeds
  React.useEffect(() => {
    if (shouldSubmit) {
      doSignInWithPhone();
    }
  }, [shouldSubmit]);

  // actually do sign in
  async function doSignInWithPhone() {
    if (recaptcha) {
      // TODO hardcoded US (i think that's ok?)
      signInWithPhoneNumber(auth, "+1" + phone.join(""), recaptcha)
        .then((confirmationResult) => {
          setConfirmationResult(confirmationResult);
          setCodeSent(true);
        })
        .catch((error) => {
          // rerender recaptcha
          console.log(error);
          toast("user_flow", "recaptcha_failed", "", () => {});
          setShouldSubmit(false);
          recaptcha.clear();
          setIsRecaptchaRendered(false);
        });
    }
  }

  // send confirmation code
  React.useEffect(() => {
    if (code.join("").length == 6 && confirmationResult) {
      confirmationResult
        .confirm(code.join(""))
        .then((result) => {
          FullUserService.createUser(result.user.phoneNumber!);
          onComplete(result.user);
        })
        .catch(() => {
          toast("user_flow", "invalid_code", "", () => {});
        });
    }
  }, [code]);

  return (
    <Stack spacing={1}>
      <NumberFieldBox>
        <NumberField numbers={phone} setNumbers={setPhone} isPhone />
      </NumberFieldBox>
      {codeSent ? (
        <Stack>
          <Typography level="body2" sx={{ fontWeight: "bold" }}>
            {t("enter_confirmation_code_sent_to", {
              ns: ["common"],
              phone: phone.join(""),
            })}
          </Typography>
          <NumberFieldBox>
            <NumberField numbers={code} setNumbers={setCode} autoFocus />
          </NumberFieldBox>
        </Stack>
      ) : (
        <Button
          onSubmit={() => {
            setCodeSent(true);
          }}
          type="submit"
          id="signin-button"
          disabled={phone.join("").length < 10}
        >
          {t("get_code", { ns: ["common"] })}
        </Button>
      )}
      {noDisclaimer ? null : <TermsAndPrivacySub />}
    </Stack>
  );
};

export default PhoneSignIn;
