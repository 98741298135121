import React from "react";

interface ConfirmationModalContextType {
  show: boolean;
  setShow: (show: boolean) => void;
  namespace: string;
  setNamespace: (namespace: string) => void;
  mainMessageKey: string;
  setMainMessageKey: (mainMessageKey: string) => void;
  subtitleKey: string;
  setSubtitleKey: (subtitleKey: string) => void;
  actionKey: string;
  setActionKey: (actionKey: string) => void;
  toDo: () => void;
  setToDo: (toDo: () => () => void) => void;
  toInsertInSubtitle?: string[];
  setToInsertInSubtitle: (toInsertInSubtitle: string[]) => void;
  requiresReauth?: "user" | "business";
  setRequiresReauth: (mode: "user" | "business" | undefined) => void;
}

// Context for confirmation modal
const ConfirmationModalContext =
  React.createContext<ConfirmationModalContextType | null>(null);

export default ConfirmationModalContext;
