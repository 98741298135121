import { Stack, Typography, IconButton } from "@mui/joy";
import { Email, Phone, KeyboardBackspace } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useKeyToDict from "../../Utils/useKeyToDict";

interface ReferenceItemProps {
  name: string;
  referenceType: string;
  phone: string | null;
  email: string | null;
}

const ReferenceItem: React.FC<ReferenceItemProps> = ({
  name,
  referenceType,
  phone,
  email,
}) => {
  const { t } = useTranslation(["education_builder"]);

  const { keyToValueInList } = useKeyToDict("lists");

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ pr: 1 }}
      alignItems="center"
    >
      <Stack>
        <Typography level="h5">
          {" "}
          {keyToValueInList(referenceType, "reference_types")}
        </Typography>
        <Typography level="body2">{name}</Typography>
      </Stack>
      <Stack alignItems={"flex-end"}>
        <Stack direction="row">
          {email && (
            <IconButton
              variant="plain"
              sx={{ color: "text.primary", ml: -1 }}
              onClick={() => {
                window.open(`mailto:${email}`);
              }}
            >
              <Email />
            </IconButton>
          )}
          {phone && (
            <IconButton
              variant="plain"
              sx={{ color: "text.primary" }}
              onClick={() => {
                window.open(`tel:${phone}`);
              }}
            >
              <Phone />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReferenceItem;
