import { Card, CardContent, Typography, Stack, Grid } from "@mui/joy";
import { EmojiPeople, School, PersonSearch } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Dispatch, useContext } from "react";
import { styled } from "@mui/material/styles";
import { APP_ROUTES, USER_TYPES } from "../Utils/Constants";
import { useTranslation } from "react-i18next";
import LogInModal from "../Components/Common/LogInModal";
import React from "react";
import { NewUserAction, SIGNUP_STEPS } from "./NewUserFlow";

const StyledCard = styled(Card)(({ theme }) => ({
  transition: "none",
  "&:hover": {
    boxShadow: "0 0 .2rem .2rem rgba(0,0,0,.25)",
  },
  cursor: "pointer",
  paddingVertical: theme.breakpoints.up("xs")
    ? theme.spacing(10)
    : theme.spacing(2),
  height: "100%",
}));

const IconWrapper = styled("div")(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(2),
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "3rem",
}));

const TextWrapper = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface SelectUserTypeProps {
  dispatch: Dispatch<NewUserAction>;
  //setUserType: (userType: USER_TYPES) => void;
  //setStep: (step: "selectUserType" | "stepper" | "phone") => void;
}

const SelectUserType: React.FC<SelectUserTypeProps> = ({ dispatch }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["user_flow", "common"]);

  const handleSelect = (userType: USER_TYPES) => {
    dispatch({ type: SIGNUP_STEPS.SELECTED_USERTYPE, usertype: userType });
    //setUserType(userType);
    //setStep("stepper");
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack spacing={4}>
      <Typography level="h3" textAlign="center" sx={{ fontWeight: 600 }}>
        {t("lets_find_better_job", { ns: ["user_flow"] })}
      </Typography>
      <Typography level="h6" textAlign="center">
        {t("what_best_describes_you", { ns: ["user_flow"] })}
      </Typography>

      <Grid container spacing={{ xs: 1, sm: 4 }}>
        <Grid xs={12} sm={4}>
          <StyledCard onClick={() => handleSelect(USER_TYPES.EMPLOYED)}>
            <Stack direction={{ xs: "row", sm: "column" }}>
              <IconWrapper>
                <EmojiPeople sx={{ fontSize: "inherit" }} />
              </IconWrapper>
              <TextWrapper>
                <Typography level="h5" textAlign={"center"}>
                  {t("im_an_hourly_worker", { ns: ["user_flow"] })}
                </Typography>
              </TextWrapper>
            </Stack>
          </StyledCard>
        </Grid>

        <Grid xs={12} sm={4}>
          <StyledCard onClick={() => handleSelect(USER_TYPES.NOT_EMPLOYED)}>
            <Stack direction={{ xs: "row", sm: "column" }}>
              <IconWrapper>
                <PersonSearch sx={{ fontSize: "inherit" }} />
              </IconWrapper>
              <TextWrapper>
                <Typography level="h5" textAlign={"center"}>
                  {t("im_not_employed", { ns: ["user_flow"] })}
                </Typography>
              </TextWrapper>
            </Stack>
          </StyledCard>
        </Grid>

        <Grid xs={12} sm={4}>
          <StyledCard onClick={() => handleSelect(USER_TYPES.STUDENT)}>
            <Stack direction={{ xs: "row", sm: "column" }}>
              <IconWrapper>
                <School sx={{ fontSize: "inherit" }} />
              </IconWrapper>
              <TextWrapper>
                <Typography level="h5" textAlign={"center"}>
                  {t("im_a_student", { ns: ["user_flow"] })}
                </Typography>
              </TextWrapper>
            </Stack>
          </StyledCard>
        </Grid>

        <Grid xs={12} sx={{ pt: 4 }}>
          <Typography level="h6" textAlign={"center"}>
            {t("already_have_account", { ns: ["user_flow"] })}{" "}
            <Typography
              level="h6"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleClick}
            >
              {t("log_in", { ns: ["common"] })}
            </Typography>
          </Typography>
          <Typography level="body2" textAlign={"center"}>
            {t("looking_to_create_business_account", { ns: ["user_flow"] })}{" "}
            <Typography
              level="body2"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                fontWeight: "bold",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <Typography
                onClick={() => navigate(APP_ROUTES.NEW_BUSINESS)}
                sx={{ fontWeight: "bold" }}
              >
                {t("create_business_account", { ns: ["user_flow"] })}
              </Typography>
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <LogInModal anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Stack>
  );
};

export default SelectUserType;
