import * as React from "react";
import { Chip, Tabs, TabList, Box } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";

interface TabsPageProps {
  index: number;
  updateIndex: (index: number) => void;
  options: { title: string; value?: number }[];
}

const TabsPage: React.FC<TabsPageProps> = ({ index, updateIndex, options }) => {
  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "scroll",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Tabs
        aria-label="Pipeline"
        value={index}
        onChange={(_, val) => updateIndex(val as number)}
        sx={{
          "--Tabs-gap": "0px",
          width: 0,
          pb: 1,
        }}
      >
        <TabList
          variant="plain"
          sx={{
            width: "100%",

            mx: "auto",
            pt: 0,
            alignSelf: "flex-start",
            [`& .${tabClasses.root}`]: {
              bgcolor: "transparent",
              boxShadow: "none",
              "&:hover": {
                bgcolor: "transparent",
              },
              [`&.${tabClasses.selected}`]: {
                color: "primary",
                fontWeight: "lg",
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  zIndex: 1,
                  bottom: "-1px",
                  left: "var(--List-item-paddingLeft)",
                  right: "var(--List-item-paddingRight)",
                  height: "3px",
                  borderTopLeftRadius: "3px",
                  borderTopRightRadius: "3px",
                  borderRadius: "3px",
                  bgcolor: "primary.main",
                },
              },
            },
          }}
        >
          {options.map((option) => (
            <Tab key={option.title}>
              {option.title} {/* confirm that option has a non-zero value */}
              {(option.value || 0) > 0 && (
                <Chip
                  size="sm"
                  variant="solid"
                  color={"primary"}
                  sx={{ ml: 1 }}
                >
                  {option.value}
                </Chip>
              )}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </Box>
  );
};

export default TabsPage;

{
  /* <Box
          sx={(theme) => ({
            "--bg": theme.vars.palette.background.level3,
            height: "1px",
            background: "var(--bg)",
            boxShadow: "0 0 0 100vmax var(--bg)",
            clipPath: "inset(0 -100vmax)",
          })}
        /> */
}
