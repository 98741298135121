import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { CircularProgress, Box } from "@mui/joy";

interface LoaderProps {
  libraries?: string[];
  children?: React.ReactNode;
}

// TODO should have better loading animation
const render = (_status: Status) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

const GoogleMapsLoader: React.FC<LoaderProps> = ({ libraries, children }) => {
  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}
      render={render}
      version={"beta"}
      // @ts-ignore (marker not a library, but still loads)
      libraries={libraries ? libraries : ["places", "marker", "geometry"]}
    >
      {children}
    </Wrapper>
  );
};

export default GoogleMapsLoader;
