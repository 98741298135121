import React from "react";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import InputBase from "@mui/material/InputBase";
import { DEFAULT_MAP_PARAMS } from "../../Utils/Constants";
import AppBarContext from "../../Context/AppBarContext";

import { useTheme, IconButton } from "@mui/joy";
import { CloseRounded } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useToast from "../../Utils/useToast";

interface AutocompleteProps extends google.maps.places.AutocompleteOptions {
  value?: string;
  setBounds?: (bounds: google.maps.LatLngBounds) => void;
  setZoom?: (zoom: number) => void;
  setCenter?: (center: google.maps.LatLng) => void;
  setPlaceId?: (placeId: string) => void;
}

// FIXME style autocomplete dropdown at link below
// https://developers.google.com/maps/documentation/javascript/place-autocomplete#style-autocomplete
const Autocomplete: React.FC<AutocompleteProps> = ({
  value,
  setBounds,
  setZoom,
  setCenter,
  setPlaceId,
  ...options
}) => {
  const ref = React.useRef<HTMLInputElement>(null);
  const [autocomplete, setAutocomplete] =
    React.useState<google.maps.places.Autocomplete>();
  const appBarContext = React.useContext(AppBarContext);
  const { t } = useTranslation(["header", "common"]);
  const toast = useToast();

  React.useEffect(() => {
    if (ref.current && !autocomplete) {
      setAutocomplete(
        new google.maps.places.Autocomplete(ref.current, {
          ...options,
        })
      );
    }
  }, [ref, autocomplete]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    if (autocomplete) {
      ["place_changed"].forEach((event) =>
        google.maps.event.clearListeners(autocomplete, event)
      );

      autocomplete.addListener("place_changed", async function () {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        const geometry: google.maps.places.PlaceGeometry | undefined =
          place.geometry;
        // avoid if place is not found
        if (
          place.place_id === undefined ||
          place.place_id === null ||
          place.place_id === "" ||
          geometry === undefined
        ) {
          // TODO add place.name as an argument
          toast("error", t("place_not_found", { ns: "common" }), "", () => {});
          return;
        }

        if (geometry?.viewport && setBounds) {
          setBounds(geometry?.viewport);
        } else if (setCenter && setZoom && geometry?.location) {
          setCenter(geometry?.location);
          setZoom(DEFAULT_MAP_PARAMS.zoom);
        }
        if (setPlaceId && place.place_id) setPlaceId(place.place_id);
        appBarContext?.setShowHeader(mobile ? false : true);
      });
    }
  });

  return (
    <InputBase
      placeholder={
        value ||
        (ref.current && ref.current.value) ||
        t("search", { ns: ["header"] })!
      }
      inputProps={{ "aria-label": "search" }}
      inputRef={ref}
      sx={{ width: "100%", ml: 2 }}
      autoFocus={!mobile}
      onSubmit={(e) => e.preventDefault()}
      endAdornment={
        ref.current && ref.current.value !== "" ? (
          <IconButton
            sx={{
              width: "3rem",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTopRightRadius: 9,
              borderBottomRightRadius: 9,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              // bgcolor: "grey.50",
            }}
            onClick={() => {
              if (ref.current) ref.current.value = "";
              if (setPlaceId) setPlaceId("");
            }}
          >
            <CloseRounded sx={{ fontSize: "1.25rem" }} />
          </IconButton>
        ) : null
      }
    />
  );
};

export default Autocomplete;
